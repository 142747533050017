import React, { useMemo } from 'react'
import { Link } from 'react-router-dom';
import { parseDate } from '../../utils/parseDate';
import { Modal } from '../Modal/Modal'
import { Portal } from '../Portal/Portal';
import { FiCalendar, FiUser, FiClock, FiFileText, FiBriefcase } from 'react-icons/fi';
import { BiEuro } from "react-icons/bi";
import { getDifferenceInNaturalTimeFromToday } from '../../utils/getDifferenceInNaturalTime';

export function SeeMoreModalNonOfficial({ company, setSeeMoreModal, isSeeMoreModalOpen }) {
  const companyDate = useMemo(() => {
    const date = new Date(Number(company.date))

    return parseDate(date);
  }, [company]);

  const parsedSalary = useMemo(() => {
    return company?.salaryNegotiable ? true : company?.salaryPerHour
  }, [company]);

  const timeSincePost = useMemo(() => {
    const date = new Date(Number(company.date))

    return getDifferenceInNaturalTimeFromToday(date);
  }, [company]);

  return (
    <Modal closeModal={() => setSeeMoreModal(false)} isOpen={isSeeMoreModalOpen}>
      <div className="flex flex-col items-center w-[80vw] sm:px-0 lg:w-[750px] h-[600px] h-[480px]">

        <h1 className="-mt-3 absolute h-12 flex items-center justify-center bg-primary-700 text-white w-96 rounded-xl transition duration-500 hover:bg-primary-900 w-[200px] sm:w-96">
          {/* <Link to={`/companies/${company._id}`}> */}
            {company.name}
          {/* </Link> */}
        </h1>

        <div className="w-full h-[auto] sm:h-[95%] lg:w-[720px] items-center bg-slate-200 p-5 rounded-lg mt-3">
          <h1 className="text-2xl mt-3 flex items-baseline justify-center">{company.jobTitle} <span className='text-red-500 font-medium text-sm ml-1'>{company.deleted && `(Job already closed)`}</span></h1>

          {/* <span className="font-normal text-xs text-gray-400 flex justify-center">Posted {timeSincePost}</span> */}

          <div className="mt-3">

            <ul className='grid grid-cols-1 gap-1 sm:grid-cols-2'>
              {<li className='flex items-center'>
                <BiEuro className="mr-2" />{(parsedSalary === true) ? 'Salary Negotiable' : `${parsedSalary} per hour`}
              </li>}
              <li className='flex items-center'>
                <FiUser className="mr-2" />{company.positionsAvailable} position{company.positionsAvailable !== 1 && 's'}</li>
              <li className='flex items-center'>
                <FiBriefcase className="mr-2" />{company.jobCategory}
              </li>
              <li className='flex items-center'>
                <FiBriefcase className="mr-2" />{company.contractType}
              </li>
              <li className='flex items-center'>
                <FiFileText className="mr-2" />{company.jobType}
              </li>
              <li className='flex items-center'>
                <FiClock className="mr-2" />{company.hoursWeek} Hours Per Week
              </li>
            </ul>

          </div>
        
          {company.jobDescription && (
            <div className="mt-5">
              <p className="mb-3 text-center"><strong>Description</strong></p>
              <p>{company.jobDescription}</p>
            </div>
          )}

        </div>
      </div>
    </Modal>
  )
}
