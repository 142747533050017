import React, { useState, useMemo, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_VACANCIES } from '../../Pages/ManageVacancies/ManageVacancies.services';
import { Button } from '../Button/Button';
import { Select } from '../Forms/Select/Select';
import { Textarea } from '../Forms/Textarea/Textarea';
import { INVITE_USER_TO_VACANCY } from './InviteUserToVacancy.services';
import { LoadingSpin } from '../LoadingSpin/LoadingSpin';
import { useForm } from 'react-hook-form';
import { FiMail, FiPhone } from 'react-icons/fi';

export function InviteUserToVacancy({ jobSeeker }) {
  const { data, loading } = useQuery(GET_COMPANY_VACANCIES);
  const [inviteUser, { loading: loadingInvite }] = useMutation(INVITE_USER_TO_VACANCY);
  const { handleSubmit, register, control } = useForm();

  const countryCode = useMemo(() => {
    return jobSeeker?.countryCode.split(' ')[0].replace('[', '').replace(']', '');
  }, [jobSeeker]);

  const handleInviteToJob = useCallback(async (data) => {
    const variables = {
      message: data.message,
      vacancyId: data.companyVacancy,
      jobSeekerId: jobSeeker._id,
    }

    try {
      await inviteUser({
        variables,
      });

      return alert('The Jobseeker was successfully invited to this job.');
    } catch (err) {
      if (err?.message === 'Already invited user for this job') {
        return alert('The JobSeeker has been invited for this job already.')
      }

      if (err?.message === 'Jobseeker already applied for this job') {
        return alert('The JobSeeker has already applied for this job.')
      }
    }

  }, [jobSeeker, inviteUser]);

  const companyVacancies = useMemo(() => {
    if (loading) {
      return []
    };

    return data.company_vacancies.filter(vacancy => !vacancy.deleted).map(vacancy => ({
      label: vacancy.jobName,
      value: vacancy._id,
    }))
  }, [data]);

  if (!jobSeeker || loading) {
    return;
  }

  return (
    <div className="w-full h-full flex flex-col items-center">
      {loadingInvite && <LoadingSpin />}

      {!loadingInvite && (
        <>
          <h1 className="text-lg">Invite {jobSeeker.name} to a Job</h1>

          <div className="mt-5 mb-5 font-bold text-lg">Contact Informations</div>
          
          <section className="grid lg:grid-cols-2 gap-4">

            <div className="flex">
              <FiMail size={18} className="text-black" />
              <a href={`mailto:` + jobSeeker?.email} className="ml-3"> {jobSeeker?.email}</a>
            </div>

            <div className="flex">
              <FiPhone size={18} className="text-black" />
              <a href={`tel:` + countryCode + ' ' + jobSeeker?.phone} className="ml-3">{countryCode} {jobSeeker?.phone}</a>
            </div>
          </section>

          {companyVacancies.length > 0
          ? (
            <>
              <form className="w-full items-center flex flex-col" onSubmit={handleSubmit(handleInviteToJob, () => {})}>
                <Select
                  control={control}
                  options={companyVacancies}
                  className="my-8 w-56"
                  rules={{ required: true }}
                  label="Select a Job"
                  name="companyVacancy"
                  id="companyVacancy"
                />

                <Textarea
                  className="mb-8 w-3/4"
                  label="Message"
                  rows={5}
                  defaultValue={`Hi ${jobSeeker.name}, we love your profile! Are you still looking for work? Please apply with us.`}
                  {...register('message', { required: true })}
                />

                <Button className="mb-3 w-32" type="submit">
                  Invite
                </Button>
              </form>
            </>
          ) : (
            <>
              <div className="mt-16">
                <h1>You need to post a job in order to invite {jobSeeker.name} to a job.</h1>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
