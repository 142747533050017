import { gql } from "@apollo/client";

export const GET_VACANCIES = gql`
  query getVacancies(
    $shouldGetStaticCompanies: Boolean
    $filters: FiltersInput
  ) {
    getMapData(
      shouldGetStaticCompanies: $shouldGetStaticCompanies
      filters: $filters
    ) {
      staticCompanies {
        latlon
        company {
          _id
          name
          email
          phone
          site
          address
          latlon
          jobTitle
          jobCategory
          salaryPerHour
          salaryNegotiable
          hoursWeek
          jobType
          contractType
          positionsAvailable
          jobDescription
          photoUrl
          isStaticCompany
          jobTitle
        }
      }
      vacancies {
        latlon
        vacancy {
          _id
          jobName
          salary
          period
          category
          contractType
          disponibleVacancies
          hourPerWeek
          applicants
          description
          date
          hasUserApplied
          hasUserSaved

          company {
            _id
            name
            latlon
            photo
          }
        }
      }
    }
  }
`;
