import Leaflet from "leaflet";

import non_official_pin from "../../assets/non-official-pin.svg";

export const nonOfficialCompanyPin = Leaflet.icon({
  iconUrl: non_official_pin,

  iconSize: [20, 22.5],
  iconAnchor: [10, 22.5],
  popupAnchor: [0, -22.5 - 8]
});
