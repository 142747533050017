import React, { useCallback, useMemo } from "react";
import { GET_MAP_DATA_WITHOUT_LOGIN } from "../../services/mapWithoutLogin.services";
import { useQuery } from "@apollo/client";
import SwantedInitialVideo from "../../assets/initial-video/Swanted_compress.mp4";
import SwantedInitialVideo1 from "../../assets/initial-video/swanted-pitch-video.mp4";
import benefitsCompany from "../../assets/homePage/Section 2 - Image 1 Companies.png";
import benefitsJobSeekers from "../../assets/homePage/Section 2 - Image 2 Jobseekers.png";
import pin from "../../assets/homePage/pin.png";
import img1Section3 from "../../assets/homePage/Section 3 - Image 1.png";
import img2Section3 from "../../assets/homePage/Section 3 - Image 2.png";
import img3Section3 from "../../assets/homePage/Section 3 - Image 3.png";
import logo from "../../assets/homePage/Section 6 - Swanted Logo.png";
import logo2 from "../../assets/homePage/logo white transparent.png";
import { faqQuestionsCompany } from "./faqQuestionsCompany";
import { faqQuestionsCandidates } from "./faqQuestionsCandidates";
import "react-accessible-accordion/dist/fancy-example.css";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { Link } from "react-router-dom";
import { HamburguerButton } from "../../assets/icons/HamburguerButton";
import section2Background from "../../assets/homePage/section-2-frame.png";
import arrow1 from "../../assets/homePage/Arrow 1.png";
import leftArrow from "../../assets/homePage/left-arrow.png";
import rightArrow from "../../assets/homePage/right-arrow.png";
import orangeLine from "../../assets/homePage/orange-underline.png";
import purpleLine from "../../assets/homePage/purple-underline.png";
import tickCircle from "../../assets/homePage/Tick Circle.png";
import orangeArrow from "../../assets/homePage/orange-arrow.png";
import purpleArrow from "../../assets/homePage/purple-arrow.png";
import Down from "../../assets/homePage/Down 1.png";
import tiktok from "../../assets/homePage/tiktok.png";
import facebook from "../../assets/homePage/fb.png";
import whatsapp from "../../assets/homePage/whatsapp.png";
import instagram from "../../assets/homePage/insta.png";
import linkedin from '../../assets/homePage/linkedin.png'
import footerImg from "../../assets/homePage/footer.png";
import downArrow from "../../assets/homePage/down-arrow.png";
import { MapContainer } from "react-leaflet";
import { MapContent } from "../Map/MapContent";
import "./styles.css";
import { isInRange } from "../../utils/map/isInRange";

import { useState } from "react";
import LoginModal from "../Login/LoginModal";
import { TermsAndPolicy } from "../../Components/TermsAndPolicy/TermsAndPolicy";
import { TermsAndConditions } from "../../Components/TermsAndPolicy/TermsAndConditions";
import { PrivacyPolicy } from "../../Components/TermsAndPolicy/PrivacyPolicy";
import { CookiePolicy } from "../../Components/TermsAndPolicy/CookiePolicy";
import { useNavigate } from "react-router";
import { Modal } from '../../Components/Modal/Modal';
import { Button } from '../../Components/NewButton/Button';

export function HomePage() {
  const navigator = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  let getWidth = window.screen.width;
  const isMobile = getWidth < 750 ? true : false;
  const [isOpened, setIsOpened] = useState(false);
  const [clickedQuestion, setClickedQuestion] = useState(0);

  const handleClickedQuestion = (index) => { setClickedQuestion(index)};

  const [openModalNeedLogin, setOpenModalNeedLogin] = useState(false);

  // login dialog
  const [open, setOpen] = React.useState(false);

  const point = [53.3498114, -6.2602525]; // set the "point" to the Spire

  // const [focusedPos, setFocusedPos] = useState(user.jobSeeker.latlon.map(Number));
  const [focusedPos, setFocusedPos] = useState(point);

  // const [userPos] = useState(user.jobSeeker.latlon.map(Number));
  const [userPos] = useState(point);
  const [searchedKm, setSearchedKm] = useState(null);

  const {
    data: vacanciesData = {},
    loading: loadingVacancies,
    refetch,
  } = useQuery(GET_MAP_DATA_WITHOUT_LOGIN, {
    variables: { shouldGetStaticCompanies: true },
  });

  const staticCompanies = useMemo(() => {
    const { getMapDataWithoutLogin } = vacanciesData;

    if (!getMapDataWithoutLogin) {
      return [];
    }

    return getMapDataWithoutLogin.staticCompanies;
  }, [vacanciesData]);

  const filterByDistance = useCallback(
    (elements, isVacancy) => {
      if (!elements.length) {
        return [];
      }
      if (!searchedKm) {
        return elements;
      }
      return elements.filter((element) => {
        let elementLatLon = null;

        elementLatLon = element.latlon.split(",").map(Number).reverse();

        if (elementLatLon.some(Number.isNaN)) {
          return;
        }

        return isInRange(
          elementLatLon,
          [focusedPos[1], focusedPos[0]],
          searchedKm
        );
      });
    },
    [focusedPos, searchedKm]
  );
  
  const vacancies = useMemo(() => {
    const { getMapDataWithoutLogin } = vacanciesData;

    if (!getMapDataWithoutLogin) {
      return [];
    }

    return getMapDataWithoutLogin.vacancies;
  }, [vacanciesData]);

  const handleClickOpen = () => { setOpen(true) };
  const handleClose = () => { setOpen(false) };

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showCookiesPolicy, setShowCookiesPolicy] = useState(false);

  return (
    <>

      {openModalNeedLogin ? <Modal maxWidth={800} closeModal={() => setOpenModalNeedLogin(false)} isOpen={openModalNeedLogin}>

        <div
          className="text-[18px] xl:text-[32px] max-w-[300px] xl:max-w-[544px] text-center whyte-bold mt-3"
          id="modal-title"
        >
          Create your account to see this and many more jobs
        </div>

        <div className="bg-white-50 px-4 py-3 sm:flex sm:flex-row justify-center sm:px-6 m-5">
          <Button
            className=" min-w-60 xl:min-w-60 h-[62px] whyte-bold text-[20px] xl:text-[24px] xl:px-[30px] rounded-full w-full"
            onClick={() => {
              setOpenModalNeedLogin(false);
              handleClickOpen();
            }}
          >
            Get Started
          </Button>
        </div>

      </Modal> : null}

      {/* <div className=" h-screen bg-gradient-to-b from-[#630DB1]  via-[#551DBE] to-[#116AFF]"> */}
      <div className="section-1">
        <nav className="Navbar">
          <img className="h-[80px]" src={logo2} alt="Swanted Logo" />
          <div className={`nav-items ${isOpened && "open"}`}>
            <a
              className={`nav-item `}
              onClick={() =>
                setTimeout(() => {
                  setIsOpened(false);
                }, 200)
              }
              href="#benifits"
            >
              Benefits
            </a>
            <a
              className={`nav-item `}
              onClick={() =>
                setTimeout(() => {
                  setIsOpened(false);
                }, 200)
              }
              href="#advantages"
            >
              Advantages
            </a>
            <a
              className={`nav-item `}
              onClick={() =>
                setTimeout(() => {
                  setIsOpened(false);
                }, 200)
              }
              href="#demo"
            >
              Demo
            </a>
            {/* <Link  to="/login" className={` get-started`}> */}
            <button
              className="get-started hover:shadow-lg"
              onClick={handleClickOpen}
            >
              Get Started
            </button> 
            {/* </Link> */}
          </div>
          <div
            className={`nav-toggle ${isOpened && "open"}`}
            onClick={() => setIsOpened(!isOpened)}
          >
            <div className="bar"></div>
          </div>
        </nav>

        <div className="w-full">
          <LoginModal open={open} handleClose={handleClose} />
        </div>

        <div className="h-[calc(100vh-200px)] flex justify-center items-center px-[8%]">
          <div className="flex flex-col  lg:flex-row lg:justify-between lg:items-start w-full gap-[5%] 2xl:gap-[8%]  ">
            <div className="lg:w-1/2 whyte-heavy font-black text-[30px] xl:text-[50px] 2xl:text-[56px] leading-[112%]  text-white">
              The platform that matches Jobseekers and Companies{" "}
              <span className="text-[#FBC4C5]">locally.</span>
            </div>
            <div className="lg:w-1/2 mt-10 lg:mt-0">
              <div className=" gotham-light text-white text-[20px] xl:text-[28px] 2xl:text-[30px]  leading-[112%]   xl:mt-0  xl:min-w-[520px]   mb-[30px] ">
                We place your company on our interactive map allowing those
                jobseekers in your area to find your open positions.
              </div>
              <a
                href="#advantages"
                className=" xl:mt-[30px] pt-2 xl:pt-2 whyte-heavy text-[20px] xl:text-[30px] leading-[112%]  text-white border-[1px] border-[#FBC4C5]  w-[270px] h-[60px] xl:w-[390px] xl:h-[97px] rounded-[80px]  flex justify-center items-center"
              >
                Discover what we do
              </a>
            </div>
          </div>
        </div>

        <div className="flex justify-center w-full  " id="demo">
          <MapContainer
            center={focusedPos}
            zoom={12}
            style={{ width: "1000px", height: "500px" }}
          >
            <MapContent
              setFocusedPos={setFocusedPos}
              userPos={userPos}
              searchedKm={searchedKm}
              jobs={filterByDistance(vacancies, true)}
              filteredStaticCompanies={filterByDistance(staticCompanies, false)}
              focusedPos={focusedPos}
              refetch={refetch}
              openModalNeedLogin={openModalNeedLogin}
              setOpenModalNeedLogin={setOpenModalNeedLogin}
            />
          </MapContainer>

          {/* <div className=" xl:z-[99]  w-[95%] xl:w-[84%]  h-fit rounded-[41px] "> */}

            {/* <div className={"w-full min-h-[80vh] transition duration-600"}> */}
              {/* <div id="vk-map" style={{ backgroundColor: "red", width: '1000px', height: '137px' }}> */}

                

              {/* </div> */}
              
            {/* </div> */}
            
            {/* <video
              muted
              autoPlay={true}
              controls
              loop
              playsInline
              alt="Map"
              className="h-full z-[99]  object-contain xl:object-contain object-center rounded-[41px]  bg-gray-300 m-auto w-full md:object-cover"
            >
              <source src={SwantedInitialVideo1} type="video/mp4" />
            </video> */}
          {/* </div> */}
        </div>

      </div>

      <div className="mx-auto container h-[75vh]  xl:h-[80vh]   relative">
        <div className="h-full flex justify-center ">
          <img
            className="z-[-1] xl:z-[1] object-contain xl:object-cover h-[987px] relative   -top-[150px] xl:-top-[250px]"
            src={section2Background}
            alt="section 2 background"
          />
        </div>

        <div className="absolute  top-40 left-[4%] xl:left-[8%] flex flex-col xl:flex-row xl:gap-[50px] justify-center   ">
          <div className="z-40 h-fit whyte-heavy font-black text-[24px] min-w-[250px] xl:text-[52px] 2xl:text-[64px] leading-[112%] max-w-[620px] xl:min-w-[560px] text-black bg-white p-4 pt-2">
            See candidates first, then hire <br /> them. That simple!
          </div>
          <div className=" flex relative xl:bottom-6 2xl:p-4 my-10 xl:my-0 items-center justify-center xl:justify-start">
            <img
              className=" block xl:hidden"
              src={downArrow}
              alt="down arrow"
            />
            <img
              className=" hidden xl:block w-[100px] "
              src={arrow1}
              alt="Arrow"
            />
          </div>
          <div className=" relative  xl:top-32 gotham-light w-full xl:max-w-[450px]  flex  xl:self-end  text-[22px]  xl:text-[30px] 2xl:text-[32px] leading-[112%] bg-white p-4 font-normal  z-40 2xl:ml-10">
            Introducing Swanted, the revolutionary way to hire staff using video
            applications. Over 80% agree that personality and communication
            skills are key. Discover top local talent and find the perfect match
            for your company. The future of recruitment is here!
          </div>
        </div>
      </div>

      <div className="bg-[#FAFAF6] xl:mt-40  ">
        <div
          className=" mx-auto container section-3 min-h-screen  py-[50px] xl:py-[100px] "
          id="benifits"
        >
          <div className="h-fit flex justify-center text-center whyte-heavy font-black text-[32px] xl:text-[64px] leading-[112%]  text-black  p-4">
            Big or small,grow <br /> with Swanted
          </div>
          <div className="hidden xl:flex gap-[40%] justify-center">
            <img src={leftArrow} alt="left-arrow" />
            <img src={rightArrow} alt="right-arrow" />
          </div>
          <div className="flex flex-col   xl:flex-row xl:justify-center xl:gap-[4%] mt-[35px] ">
            <div className="flex flex-col ">
              <div className=" whyte-heavy   text-[24px] xl:text-[36px]  leading-[112%] flex justify-center  xl:justify-start">
                Benefits for{" "}
                <div className="text-[#F37925] flex  flex-col mx-2">
                  {" "}
                  Companies{" "}
                  <img
                    className="relative right-10 mt-[10px] xl:mt-[40px] w-[190px]  xl:w-[296px]"
                    src={orangeLine}
                    alt="orange underline"
                  />{" "}
                </div>
              </div>
              <div className="mt-[20px] xl:mt-[30px] pl-[4%] xl:mx-0 xl:ml-0 ">
                <ul className=" 2xl:min-w-[570px] whyte max-w-[375px]  xl:max-w-[689px] flex flex-col   gap-[32px] xl:gap-[44px] text-[20px] xl:text-[26px] 2xl:text-[30px] leading-[112%] ">
                  <li key={"li-1"} className="flex items-start gap-[15px] xl:gap-[35px]">
                    <img src={tickCircle} alt="tick Circle" />
                    Hire locally.
                    <br />
                    Reduce turnover of staff by 20%.
                  </li>
                  <li key={"li-2"} className="flex items-start gap-[15px] xl:gap-[35px]">
                    <img src={tickCircle} alt="tick Circle" />
                    Hire 5x faster.
                    <br />
                    Don’t wait for applicants. Use our <br /> candidates
                    database and find <br /> candidates yourself.
                  </li>
                  <li key={"li-3"} className="flex items-start gap-[15px] xl:gap-[35px]">
                    <img src={tickCircle} alt="tick Circle" />
                    See who they are.
                    <br />
                    Receive cover videos and <br />
                    select the best match for your <br />
                    company.
                  </li>
                  <li
                    key={"li-4"}
                    onClick={() => navigator("/register-company")}
                    className=" cursor-pointer flex items-start ml-[6%] xl:ml-[75px] text-[#F37925] whyte-heavy   gap-[10px] xl:gap-[25px]"
                  >
                    <div>
                      Find local Candidates
                      <img
                        className="mt-[22px]  w-[180px] xl:w-full "
                        src={orangeLine}
                        alt="orange arrow"
                      />
                    </div>
                    <img src={orangeArrow} alt="orange arrow" />
                  </li>
                </ul>
              </div>
            </div>

            <div className=" hidden xl:block xl:mt-[100px] border-r-[1px] border-[#FAC670] h-[452px]"></div>

            <div className=" mt-[50px] xl:mt-0 ">
              <div className=" whyte-heavy   text-[24px] xl:text-[36px]  leading-[112%] flex justify-center  xl:justify-start">
                Benefits for{" "}
                <div className="text-[#9E5BF6] flex  flex-col mx-2">
                  {" "}
                  Jobseekers{" "}
                  <img
                    className="relative right-10 mt-[10px] xl:mt-[40px] w-[190px]  xl:w-[296px]"
                    src={purpleLine}
                    alt="purple underline"
                  />{" "}
                </div>
              </div>
              <div className="mt-[20px] xl:mt-[30px] pl-[4%] xl:mx-0  xl:ml-0 ">
                <ul className=" 2xl:min-w-[570px] whyte max-w-[375px]  xl:max-w-[689px] flex flex-col    gap-[32px] xl:gap-[44px] text-[20px] xl:text-[26px] 2xl:text-[30px] leading-[112%] ">
                  <li key={"li-5"} className="flex items-start gap-[15px] xl:gap-[35px]">
                    <img src={tickCircle} alt="tick Circle" />
                    Know exactly where you'll work.
                    <br />
                    Use our interactive map to spot the
                    <br /> location of each position.
                  </li>
                  <li key={"li-6"} className="flex items-start gap-[15px] xl:gap-[35px]">
                    <img src={tickCircle} alt="tick Circle" />
                    Stand out from the crowd.
                    <br />
                    Apply with videos and increase your
                    <br /> chances of being hired by 70%.
                  </li>
                  <li key={"li-7"} className="flex items-start gap-[15px] xl:gap-[35px]">
                    <img src={tickCircle} alt="tick Circle" />
                    Save up to 20 hrs a month!
                    <br />
                    Find local work and replace your <br /> commute time with
                    something that <br /> you truly love to do. 
                  </li>
                  <li
                    onClick={() => navigator("/register-jobseeker")}
                    className=" cursor-pointer flex items-start ml-[6%] xl:ml-[75px]  whyte-heavy   gap-[10px] xl:gap-[25px] text-[#9E5BF6] whyte-heavy  "
                  >
                    <div>
                      Get hired today
                      <img
                        className="mt-[22px] w-[180px] xl:w-full "
                        src={purpleLine}
                        alt="purple arrow"
                      />
                    </div>
                    <img src={purpleArrow} alt="purple arrow" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" mx-auto container section-4 min-h-fit mb-28 xl:mb-52"
        id="advantages"
      >
        <div className="flex  flex-col    xl:flex-row  xl:justify-center   mt-[80px] xl:mt-[180px]  ">
          <div className=" flex flex-col xl:w-[65%] px-[6%] xl:px-[4%]  xl:mx-0 ">
            <div className=" whyte-heavy   text-[32px] xl:text-[64px] leading-[112%] max-w-full xl:max-w-[544px]  ">
              Why is Swanted <br className="hidden xl:block " /> Different?
            </div>
            <div className=" w-full flex  justify-center">
              <div className="flex xl:hidden  justify-center mt-10  bg-[#FAFAF6] rounded-full w-[340px]   h-[340px] ">
                <img
                  className=" relative top-6  my-[20px] object-contain"
                  src={benefitsJobSeekers}
                  alt="benefits JobSeekers"
                />
              </div>
            </div>
            <div className="  mt-[30px] xl:mt-[80px] uppercase text-[20px] xl:text-[24px] whyte-medium text-[#9E5BF6] font-bold  tracking-[0.27rem] ">
              for Jobseekers
            </div>

            <div className="mt-[35px] gotham text-[18px] xl:text-[28px] 2xl:text-[32px]  font-normal leading-[112%] text-[#4B4B4B] max-w-[100%] xl:max-w-[700px] 2xl:max-w-[820px] max-h-[324px]">
              Say goodbye to endless scrolling and generic job lists. Our
              revolutionary platform uses geolocation technology to match you
              with job opportunities near your home. Plus, our seamless video
              application feature allows you to showcase your skills and
              personality, increasing your chances of standing out and getting
              hired. Discover the power of geolocation and elevate your job
              search today!
            </div>
          </div>
          <div className=" flex justify-center items-center w-[35%] xl:max-h-[449px]  2xl:max-h-[537px]  bg-[#FAFAF6] rounded-full ">
            <img
              className="   relative top-10  hidden xl:block xl:max-h-[508px] 2xl:max-h-[548px] "
              src={benefitsJobSeekers}
              alt="benefits JobSeekers"
            />
          </div>
        </div>
      </div>

      <div className=" mx-auto container section-5 min-h-fit mb-32 xl:mb-72">
        <div className="flex flex-col xl:flex-row xl:justify-center px-[6%] xl:px-[4%]  xl:mx-0 mt-[0px] w-full  ">
          <div className="hidden xl:flex justify-center items-center w-[340px] xl:w-[40%] bg-[#FAFAF6] h-[340px] rounded-full ">
            <img
              className="relative  right-8 xl:right-10 xl:top-6 2xl:max-w-[500px] 2xl:max-h-[560px]   my-[20px] max-h-[448px] "
              src={img3Section3}
              alt="benefits companies"
            />
          </div>

          <div className=" xl:hidden flex w-full justify-center">
            <div className=" flex justify-center items-center w-[340px] xl:w-[40%] bg-[#FAFAF6] h-[340px] rounded-full ">
              <img
                className="relative  right-8 xl:right-10 xl:top-6 2xl:max-w-[500px] 2xl:max-h-[560px]   my-[20px] max-h-[448px] "
                src={img3Section3}
                alt="benefits companies"
              />
            </div>
          </div>

          <div className=" flex flex-col xl:w-[60%]  xl:pl-[4%]">
            <div className=" xl:mt-[20px] uppercase text-[20px] mt-16 xl:text-[24px] whyte-medium text-[#F37925] font-bold  tracking-[0.27rem] ">
              For Companies
            </div>
            <div className="mt-[35px] gotham text-[18px] xl:text-[28px] 2xl:text-[32px]  font-normal leading-[112%] text-[#4B4B4B] max-w-[100%] xl:max-w-[826px] max-h-[324px]">
              Introducing the most efficient and effective way to find the
              perfect staff members for your business: Swanted uses geolocation
              technology to match you with local talent. Post your job on our
              interactive map and let jobseekers come to you. The video
              applications and our database allow you to quickly and easily
              assess a candidate's skills and personality. This will save you
              time and money. Join the hiring revolution today!
            </div>
          </div>
        </div>
      </div>

      <div className="hidden xl:block  section-6 min-h-screen z-40   ">
        <div className="flex  container mx-auto ">
          <div className="z-40 bg-[#FAFAF6] rounded-[35px] py-[20px] px-[40px] 2xl:px-[60px] w-1/2 mr-[80px] ">
            <div className="whyte-heavy  xl:text-[38px]  2xl:text-[42px] ">
              Need more info?
            </div>
            <div>
              {faqQuestionsCandidates.map((i, index) => (
                <div
                  onClick={() => handleClickedQuestion(index)}
                  className={`${
                    index === clickedQuestion
                      ? " bg-[#9E5BF6] text-white font-bold whyte-heavy  "
                      : " "
                  }   transition-all  flex my-3  px-[30px]  gap-[20px] 2xl:gap-[30px]  items-center pt-1 b border-[1px] border-[#DDDDDD] rounded-[35px] max-w-[777px]  xl:h-[80px] 2xl:h-[90px] xl:text-[24px] 2xl:text-[28px] whyte-medium `}
                >
                  <img
                    className={`${
                      index === clickedQuestion ? " opacity-0" : " opacity-[1]"
                    }`}
                    src={Down}
                    alt="down arrow"
                  />
                  {i.heading}
                </div>
              ))}
            </div>
          </div>
          <div className=" z-40   w-1/2 bg-[#9E5BF6] rounded-[35px]  text-white   px-[60px] pt-[60px]  ">
            <div className=" pb-[20px] text-[32px] whyte-medium border-b-[1px] border-[#FFFFFF] ">
              Answer
            </div>
            <div className="mt-[40px] xl:text-[26px] 2xl:text-[30px] whyte-heavy ">
              {faqQuestionsCandidates[clickedQuestion].answerHeader}
            </div>
            <div className="mt-[30px] whyte-medium xl:text-[26px] 2xl:text-[30px] leading-[112%] ">
              {faqQuestionsCandidates[clickedQuestion].panel}
              <br />
              <br />
              {faqQuestionsCandidates[clickedQuestion]?.panel1 ? (
                faqQuestionsCandidates[clickedQuestion]?.panel1
              ) : (
                <></>
              )}
              <br />
              <br />
              {faqQuestionsCandidates[clickedQuestion]?.panel2 ? (
                faqQuestionsCandidates[clickedQuestion]?.panel2
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" block xl:hidden  section-6 min-h-screen z-40   mt-[100px] ">
        <div className="flex flex-col container mx-auto px-[6%]">
          <div className="z-40 bg-[#FAFAF6] rounded-[35px] py-[6%] px-[4%]  xl:p-[70px] w-[100%] xl:w-full ">
            <div className="whyte-heavy   text-[32px] xl:text-[48px] ">
              Need more info?
            </div>
            <div>
              {faqQuestionsCandidates.map((i, index) => (
                <>
                  <div
                    onClick={() => handleClickedQuestion(index)}
                    className={`${
                      index === clickedQuestion
                        ? " bg-[#9E5BF6] text-white font-bold whyte-heavy  "
                        : " "
                    }   transition-all  flex my-3 px-[13px] xl:px-[33px] gap-[16px] xl:gap-[36px]  items-center  border-[1px] border-[#DDDDDD] rounded-[35px]  xl:max-w-[777px] h-[80px] xl:h-[107px] text-[18px] xl:text-[32px] whyte-medium `}
                  >
                    <img
                      className={`${
                        index === clickedQuestion
                          ? " opacity-0"
                          : " opacity-[1]"
                      }`}
                      src={Down}
                      alt="down arrow"
                    />
                    {i.heading}
                  </div>
                  <div
                    className={` ${
                      index === clickedQuestion ? " block" : "hidden "
                    } transition-all ease-in-out delay-150 z-40 w-full bg-[#9E5BF6] rounded-[35px]  text-white   px-[20px] xl:px-[100px] py-[20px] xl:pt-[60px]  `}
                  >
                    <div className=" pb-[20px] text-[22px] xl:text-[32px] whyte-medium border-b-[1px] border-[#FFFFFF] ">
                      Answer
                    </div>
                    <div className=" mt-[10px] xl:mt-[90px]  text-[24px] xl:text-[32px] whyte-heavy ">
                      {faqQuestionsCandidates[clickedQuestion].heading}
                    </div>
                    <div className=" mt-[15px] xl:mt-[60px] whyte-medium text-[18px]  xl:text-[32px] leading-[112%] ">
                      {faqQuestionsCandidates[clickedQuestion].panel}
                      <br />
                      <br />
                      {faqQuestionsCandidates[clickedQuestion]?.panel1 ? (
                        faqQuestionsCandidates[clickedQuestion]?.panel1
                      ) : (
                        <></>
                      )}
                      <br />
                      <br />
                      {faqQuestionsCandidates[clickedQuestion]?.panel2 ? (
                        faqQuestionsCandidates[clickedQuestion]?.panel2
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className=" hidden xl:block  h-screen  relative w-full ">
        <div className="section-7  flex h-full w-full flex-col justify-center items-center   ">
          <div className=" whyte-bold text-[64px]  bg-white  p-4">
            Join Swanted Now
          </div>
          <div className=" gotham text-[#9A9A9A] font-medium leading-[112%] text-[32px]   bg-white  p-[40px] ">
            <div className="max-w-[990px]">
              Discover the benefits of geolocation and video technology in the
              hiring process. Swanted is designed to reduce hiring process time,
              increased personalisation, and give access to a great pool of
              local talent. Join the hiring revolution today!
            </div>
          </div>

          <div className=" mt-[20px] text-[#9E5BF6] text-[32px] whyte-bold flex flex-col  justify-center items-center relative ">
            <div
              onClick={handleClickOpen}
              className="cursor-pointer get-startedd"
            >
              Get Started
            </div>
            <img className="mt-[20px]" src={purpleLine} alt="purple line" />
          </div>
        </div>

        <div className="flex justify-center z-index-[999] relative -top-[300px]">
          {/* <img
            className="relative  top-[50px] max-w-[559px] "
            src={img3Section3}
            alt=" img 3 Section3 "
          /> */}
          <img
            className=" max-w-[559px] "
            src={img2Section3}
            alt=" img 3 Section3 "
          />
        </div>
      </div>

      <div className=" block xl:hidden  h-screen    ">
        <div className="section-7  flex h-full w-[100%] flex-col justify-center items-center  container px-[6%]  ">
          <div className=" whyte-bold text-[32px]  ">Join Swanted Now</div>
          <div className=" gotham text-[#9A9A9A] font-medium leading-[112%] text-[20px] xl:text-[32px] max-w-[990px]  bg-white py-[40px] ">
            Discover the benefits of geolocation and video technology in the
            hiring process. Swanted is designed to reduce hiring process time,
            increased personalisation, and give access to a great pool of local
            talent. Join the hiring revolution today!
          </div>

          <div className=" mt-[20px] text-[#9E5BF6] text-[20px] xl:text-[32px] whyte-bold flex flex-col  justify-center items-center relative ">
            <div
              onClick={handleClickOpen}
              className="cursor-pointer get-startedd"
            >
              Get Started
            </div>
            <img
              className="mt-[20px] w-[180px] "
              src={purpleLine}
              alt="purple line"
            />
          </div>
        </div>

        <div className="flex justify-center z-index-[999] relative -top-[250px]">
          {/* <img
            className="relative  top-[50px] max-w-[170px] "
            src={img3Section3}
            alt=" img 3 Section3 "
          /> */}
          <img
            className=" max-w-[270px] "
            src={img2Section3}
            alt=" img 3 Section3 "
          />
        </div>
      </div>

      <div className=" footer mt-[100px]  xl:mt-[400px] relative w-full bg-[black] ">
        <div className=" px-[6%]  xl:mx-auto container flex flex-col items-start  xl:flex-row xl:gap-[80px] xl:items-end h-full pt-[100px] xl:pt-[150px] ">
          <div className=" xl:min-w-[306px]  2xl:min-w-[505px] text-white">
            <div className=" h-[27px] uppercase  text-[20px] xl:text-[24px] leading-[112%]  tracking-[0.23em] whyte font-bold">
              Stay up to date
            </div>
            <div className=" whyte-heavy  text-[32px] xl:text-[52px] 2xl:text-[64px] leading-[112%] mt-[30px]  ">
              Contact Us
            </div>
            <div className=" mt-[25px]  text-[24px] xl:text-[28px] 2xl:text-[32px] font-medium whyte leading-[162%] ">
              <a href="mailto:contact@swanted.io">Contact@Swanted.io</a>
              <br />
              <a href="tel:+353 87 1481796" className="">
                +353 87 1481796
              </a>
            </div>
          </div>

          <div className=" mt-[20px] xl:mt-0 text-white  2xl:min-w-[505px] pb-3">
            <div className="whyte-heavy  text-[32px] xl:text-[36px]">
              Address :
            </div>
            <div className=" mt-[5px] xl:mt-[25px] whyte-medium text-[20px]  xl:text-[28px] 2xl:text-[32px]  leading-[112%]">
              The Digital Hub, Thomas Street,
              <br />
              Dublin 8, Ireland.
            </div>
          </div>

          <div className="flex  flex-col whyte-heavy  xl:min-w-[306px]  2xl:min-w-[505px] text-[30px]  text-white justify-between items-start mt-[25px] xl:mt-0 xl:gap-[50px] h-full">
            <div>App is coming soon</div>
            <div className="flex  items-center  gap-[5px] justify-between xl:w-[299px]  w-[75%]">
              <a href="https://www.instagram.com/swanted_app/" target="_blank">
                <img className=" " src={instagram} alt="instagram" />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=353871481796&text&type=phone_number&app_absent=0"
                target="_blank"
              >
                <img src={whatsapp} alt="whatsapp" />
              </a>
              <a href="https://www.tiktok.com/@swantedapp" target="_blank">
                <img src={tiktok} alt="tiktok" />
              </a>

              <a href="https://www.facebook.com/Swanted.io" target="_blank">
                <img className="" src={facebook} alt="facebook" />
              </a>
              <a href="https://www.linkedin.com/company/swanted/" target="_blank">
                <img className=" " src={linkedin} alt="linkedin" />
              </a>
            </div>
          </div>
        </div>

        <div className=" mx-auto container xl:border-b-[1px] xl:border-[#FBC4C5] my-[50px] "></div>
        <div className=" container flex flex-col xl:flex-row justify-between px-[6%] xl:mx-auto">
          <div className="text-[18px] xl:text-[24px] text-white gotham-medium  flex flex-col xl:flex-row  gap-[20px] xl:gap-[30px]">
            <a onClick={() => setShowTermsAndConditions(true)}>
              Terms and Conditions
            </a>

            <TermsAndConditions
              shouldShowTermsAndConditions={showTermsAndConditions}
              setShouldShowTermsAndConditions={() =>
                setShowTermsAndConditions(false)
              }
            />

            <a onClick={() => setShowPrivacyPolicy(true)}>Privacy Policy</a>
            <PrivacyPolicy
              shouldShowPrivacyPolicy={showPrivacyPolicy}
              setShouldShowPrivacyPolicy={setShowPrivacyPolicy}
            />

            <a onClick={() => setShowCookiesPolicy(true)}>Cookies Policy</a>
            <CookiePolicy
              shouldShowCookiePolicy={showCookiesPolicy}
              setShouldShowCookiePolicy={setShowCookiesPolicy}
            />
          </div>
          <div className=" py-[40px] xl:py-0 text-[20px] xl:text-[24px] text-white gotham-medium ">
            Powered by{" "}
            <a
              href="https://www.wecodeforyou.io/"
              target="_blank"
              className=" gotham-bold "
            >
              “Wecodeforyou.io”{" "}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
