import Leaflet from "leaflet";

import blue_dot from "../../assets/blue_dot.svg";

export const blueDot = Leaflet.icon({
  iconUrl: blue_dot,

  iconSize: [10, 10],
  iconAnchor: [0, 0],
  popupAnchor: [0, 0]
});
