import { gql } from "@apollo/client";

export const UPDATE_VACANCY_INVITES = gql`
  mutation declineVacancyInvite(
    $jobInviteId: ID!
  ) {
    declineVacancyInvite(
      jobInviteId: $jobInviteId
    ) {
      _id
      message
      hasUserAccepted
      userNotInterested
      vacancy {
        _id
        jobName
        salary
        period
        category
        contractType
        disponibleVacancies
        hourPerWeek
        applicants
        date
        hasUserApplied
        hasUserSaved
        description
        isSalaryNegotiable
      }
    }
  }
`;
