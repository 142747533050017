import React from 'react';
import ReactModal from "react-modal";
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css";

const rootEl = document.getElementById("root");
const root = ReactDOM.createRoot(rootEl);

ReactModal.setAppElement(rootEl);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
