import React, { useState, useCallback, useMemo , useEffect} from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useNavigate } from "react-router";
import { getDifferenceInNaturalTimeFromToday } from "../../utils/getDifferenceInNaturalTime";
import { SelectActionModal } from "../../Components/SelectActionModal/SelectActionModal";

export function SavedVacanciesPainel({ focusedVacancy, disabled, refetch }) {
  const [parent] = useAutoAnimate();

  const navigator = useNavigate();
  const [job, setJob] = useState(null);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const handleSelectVacancy = useCallback((selectedJob) => {
    setJob(selectedJob);
    setIsSelectModalOpen((state) => !state);
  }, []);

  const timeSincePost = useMemo(() => {
    if (!focusedVacancy?.date) {
      return null;
    }

    const date = new Date(Number(focusedVacancy.date));

    return getDifferenceInNaturalTimeFromToday(date);
  }, [focusedVacancy]);

  const parsedSalary = useMemo(() => {
    if (!focusedVacancy?.salary) {
      return null;
    }

    return focusedVacancy?.salary;
  }, [focusedVacancy]);

  return (
    <>
      <SelectActionModal
        job={job}
        isSelectModalOpen={isSelectModalOpen}
        setIsSelectModalOpen={setIsSelectModalOpen}
      />
      <div
        ref={parent}
        onClick={() => handleSelectVacancy(focusedVacancy)}
        className={` bg-white rounded-[10px] flex flex-col  ${windowDimensions.height < 750 ? " xl:h-[270px]": " min-h-[450px] xl:min-h-[450px] "} overflow-auto pb-10 border-[1px] hover:border-[#630DB1] `}
      >
        <div className="p-6 h-32 ">
          <div className="text-[20px] lg:text-[24px] whyte-bold ">
            {focusedVacancy?.jobName}
          </div>
          <div className=" whyte text-[16px] lg:text-[18px] text-[#A3A3A3]">
            {focusedVacancy?.company.name}
          </div>

          <div className="text-[#2E9367] my-3 ">Posted {timeSincePost}</div>
          <div className="whyte text-[#546787] ">
            {focusedVacancy?.period} • {focusedVacancy?.hourPerWeek} hours •{" "}
            {focusedVacancy.contractType}{" "}
            {parsedSalary ? " • " + parsedSalary + "  € per hour " : "  "} •
            {focusedVacancy?.disponibleVacancies} position
            {focusedVacancy?.disponibleVacancies !== 1 && "s"}
          </div>
          <hr className="my-6" />

          <div>
            <div className="text-[20px] lg:text-[24px] whyte-bold ">
              Job Details
            </div>
            <div className="flex flex-col gap-[10px] text-[#546787] mt-4  whyte-medium">
              <div className="flex gap-[10%]">
                <span className="w-2/5">Job Type:</span>
                <span> {focusedVacancy?.period}</span>
              </div>
              <div className="flex gap-[10%]">
                <span className="w-2/5">Salary:</span>
                <span> {parsedSalary ? parsedSalary + " per hour " : ""}</span>
              </div>
              <div className="flex gap-[10%]">
                <span className="w-2/5">Job Categories:</span>
                <span>{focusedVacancy?.category}</span>
              </div>

              <div className="flex gap-[10%]">
                <span className="w-2/5">Positions:</span>
                <span>
                  {focusedVacancy?.disponibleVacancies} position
                  {focusedVacancy?.disponibleVacancies !== 1 && "s"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
