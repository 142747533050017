import React from 'react'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import ReactSlickSlider from 'react-slick';
import * as S from './Slider.styles';

const Arrow = ({ type, onClick }) => {
  return (
    <S.ArrowContainer arrowType={type} onClick={onClick} className="text-gray-600 bg-white">
      {type === 'prev'
      ? <FiArrowLeft size={32} />
      : <FiArrowRight size={32} />}
    </S.ArrowContainer>
  )
}

export const Slider = ({ children, arrows = false, autoplaySpeed=0, ...props }) => {
  return (
    <ReactSlickSlider
      arrows={arrows}
      prevArrow={<Arrow type="prev" />}
      nextArrow={<Arrow type="next" />}
      autoplay={!!autoplaySpeed}
      autoplaySpeed={autoplaySpeed}
      infinite
      {...props}
    >
      {children}
    </ReactSlickSlider>
  )
}
