import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $jobSeekerName: String!
    $jobSeekerSurname: String!
    $jobSeekerAvailability: AvailabilityInput!
    $jobSeekerAddress: String!
    # $jobSeekerEircode: String!
    $jobSeekerGender: String!
    $jobSeekerBirth: String!
    # $jobSeekerCountry: String!
    $jobSeekerLookingFor: String!
    $jobSeekerCountryCode: String!
    $jobSeekerPhone: String!
    $jobSeekerSocialMedias: [SocialMediaInput]!
    $jobSeekerLanguages: [LanguagesInput]!
    $jobSeekerPhoto: FileInput
    $jobSeekerVideo: FileInput
    $jobSeekerCv: FileInput
    $jobSeekerExperiences: [ExperienceInput]!
  ) {
    createUser(
      user: {
        email: $email
        password: $password
        jobSeeker: {
          name: $jobSeekerName
          availability: $jobSeekerAvailability
          surname: $jobSeekerSurname
          address: $jobSeekerAddress
          # eircode: $jobSeekerEircode
          gender: $jobSeekerGender
          birth: $jobSeekerBirth
          # country: $jobSeekerCountry
          lookingFor: $jobSeekerLookingFor
          countryCode: $jobSeekerCountryCode
          phone: $jobSeekerPhone
          socialMedias: $jobSeekerSocialMedias
          languages: $jobSeekerLanguages
          photo: $jobSeekerPhoto
          video: $jobSeekerVideo
          cv: $jobSeekerCv
          experiences: $jobSeekerExperiences
        }
      }
    ) {
      ok
    }
  }
`;
