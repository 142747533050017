import React, { useMemo, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Navbar } from "../../../../Components/Navbar/NewNavbar";
import "../../../HomePage/styles.css";
import tiktok from "../../../../assets/icons/tik-tok-icon.png";
import instagram from "../../../../assets/icons/instagram-icon.png";
import { Button } from "../../../../Components/NewButton/Button";
import { Modal } from "../../../../Components/NewModal/Modal";
import { VideoPlayer } from "../../../../Components/VideoPlayer/VideoPlayer";
import { PDF } from "../../../../Components/PDF/pdf";
import { useMutation, useQuery } from "@apollo/client";
import { GET_COMPANY_VACANCIES } from "../../../../Pages/ManageVacancies/ManageVacancies.services";
import { INVITE_USER_TO_VACANCY } from "../../../../Components/JobSeekerModal/InviteUserToVacancy.services";
import { LoadingSpin } from "../../../../Components/LoadingSpin/LoadingSpin";
import { Select } from "../../../../Components/Forms/Select/Select";
import { Textarea } from "../../../../Components/Forms/Textarea/Textarea";
import { useForm } from "react-hook-form";
import { FiMail, FiPhone } from "react-icons/fi";
import { getDifferenceInNaturalTimeFromToday } from "../../../../utils/getDifferenceInNaturalTime";
import { FiAlertTriangle } from "react-icons/fi";
import { ReportModal } from "../../../../Components/ReportModal/ReportModal";

const JobSeekerPainelProfile = () => {
  const { data, loading } = useQuery(GET_COMPANY_VACANCIES);
  const [inviteUser, { loading: loadingInvite }] = useMutation(
    INVITE_USER_TO_VACANCY
  );

  const [closeCVModal, setCloseCVnModal] = useState(false);
  const [closeInviteModal, setCloseInviteModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const handleOpenReportModal = useCallback(() => {
    setIsReportModalOpen((state) => !state);
  }, []);

  const { state } = useLocation();

  const { handleSubmit, register, control } = useForm();

  const countryCode = useMemo(() => {
    return state?.countryCode.split(" ")[0].replace("[", "").replace("]", "");
  }, [state]);

  const handleInviteToJob = useCallback(
    async (data) => {
      const variables = {
        message: data.message,
        vacancyId: selectedJob,
        jobSeekerId: state._id,
      };

      try {
        await inviteUser({
          variables,
        });

        return alert("The Jobseeker was successfully invited to this job.");
      } catch (err) {
        if (err?.message === "Already invited user for this job") {
          return alert("You have already invited this jobseeker to apply for this position.");
        }

        if (err?.message === "Jobseeker already applied for this job") {
          return alert("The JobSeeker has already applied for this job.");
        }
      }
    },
    [state, inviteUser, selectedJob]
  );

  const companyVacancies = useMemo(() => {
    if (loading) {
      return [];
    }

    return data.company_vacancies.filter((vacancy) => !vacancy.deleted);
    // .map((vacancy) => ({
    //   label: vacancy.jobName,
    //   value: vacancy._id,
    // })
    // );
  }, [data]);

  if (!state || loading) {
    return;
  }

  const handleDate = (d) => {
    const date = new Date(Number(d));

    return getDifferenceInNaturalTimeFromToday(date);
  };

  const handleSelectedJob = (id) => {
    // if (selectedJob === id) {
    //   setSelectedJob("");
    // } else {
    setSelectedJob(id);
    // }
  };

  return (
    <div className=" bg-gray-50 w-full h-full py-[20px] ">
      {isReportModalOpen && (
        <ReportModal
          type={"job seeker"}
          jobSeeker={state}
          isReportModalOpen={isReportModalOpen}
          setIsReportModalOpen={setIsReportModalOpen}
          // onClose={() => setModalJobSeeker(null)}
        />
      )}

      {closeCVModal && (
        <Modal
          isOpen={closeCVModal}
          closeModal={() => setCloseCVnModal(false)}
          width={"80%"}
          maxWidth={1200}
        >
          <div className="flex flex-col items-center">
            <PDF jobSeeker={state} />
          </div>
        </Modal>
      )}

      {closeInviteModal && (
        <Modal
          isOpen={closeInviteModal}
          closeModal={() => setCloseInviteModal(false)}
          width={"80%"}
          height="85%"
          maxWidth={800}
        >
          <div className="w-full h-full flex flex-col items-center">
            {loadingInvite && <LoadingSpin />}

            {!loadingInvite && (
              <>
                <div className="whyte-bold text-[22px] ">
                  Invite {state.name} to a Job
                </div>

                {/* <div className="mt-5 mb-5  whyte-medium text-[22px]">
                  Contact Informations
                </div> */}

                {/* <section className="grid lg:grid-cols-2 gap-4">
                  <div className="flex">
                    <FiMail size={18} className="text-black" />
                    <a href={`mailto:` + state?.email} className="ml-3">
                      {" "}
                      {state?.email}
                    </a>
                  </div>

                  <div className="flex">
                    <FiPhone size={18} className="text-black" />
                    <a
                      href={`tel:` + countryCode + " " + state?.phone}
                      className="ml-3"
                    >
                      {countryCode} {state?.phone}
                    </a>
                  </div>
                </section> */}

                {companyVacancies.length > 0 ? (
                  <>
                    <form
                      className="w-full items-center flex flex-col"
                      onSubmit={handleSubmit(handleInviteToJob, () => {})}
                    >
                      <div className=" h-[300px] lg:h-[350px] overflow-y-scroll my-5 lg:w-[80%]  ">
                        {companyVacancies.map((v) => (
                          <div
                            className={` cursor-pointer transition-all  ${
                              selectedJob === v._id
                                ? "border-[#9E5BF6]"
                                : " border-[#D3D3D3]"
                            }  border-[1px]  rounded-[20px] mx-6 my-3 p-4 flex flex-col`}
                            key={v._id}
                            onClick={() => handleSelectedJob(v._id)}
                          >
                            <div className="flex flex-col w-full">
                              <div className="w-full  flex justify-between">
                                <div className="flex flex-col justify-start items-start">
                                  <div>
                                    <span className="text-[20px] xl:text-[24px] whyte-bold ">
                                      {v?.jobName}
                                    </span>
                                  </div>
                                  <div className=" whyte text-[18px] text-[#A3A3A3]   ">
                                    {v?.company.name}
                                  </div>
                                  <div className="text-[#2E9367] ">
                                    Posted {handleDate(v?.date)}
                                  </div>
                                </div>
                                <div>
                                  <img
                                    className="h-0 w-0 sm:w-16 sm:h-16 rounded-full bg-gray-400 object-cover object-center sm:ml-2"
                                    src={v?.company.photo}
                                    alt={v?.company.name}
                                  />
                                </div>
                              </div>
                              <hr className="my-4" />

                              <div className="flex justify-start whyte text-[#546787]    ">
                                {v?.period}
                                {" - "}
                                {v?.hourPerWeek} Hours
                                {"  - "}
                                {v?.category}
                                {" - "}
                                {v?.contractType}
                                {" - "}
                                {v?.disponibleVacancies} position
                                {v?.disponibleVacancies !== 1 && "s"}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <Select
                        control={control}
                        options={companyVacancies}
                        className="my-8 w-56"
                        rules={{ required: true }}
                        label="Select a Job"
                        name="companyVacancy"
                        id="companyVacancy"
                      /> */}

                      {selectedJob ? (
                        <Textarea
                          className="mb-8 w-3/4"
                          label="Message"
                          rows={5}
                          defaultValue={`Hi ${state.name}, we love your profile! Are you still looking for work? Please apply with us.`}
                          {...register("message", { required: true })}
                        />
                      ) : (
                        <></>
                      )}

                      <button
                        className="mb-3 rounded-full  w-56 min-w-32 whyte-bold text-[24px] text-white  h-[68px]  bg-[#9E5BF6] hover:bg-primary-800"
                        type="submit"
                      >
                        Invite
                      </button>
                    </form>
                  </>
                ) : (
                  <>
                    <div className="mt-16">
                      <h1>
                        You need to post a job before inviting {state.name} to apply for this position on Swanted.
                      </h1>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Modal>
      )}
      <Navbar />
      <div className=" px-[6%] flex xl:flex-row flex-col  w-full h-full bg-gray-50  text-[#1E1E1E] gap-[30px] pt-28 xl:py-14 ">
        <div className="flex flex-col xl:w-2/3">
          <div className="flex flex-col xl:flex-row xl:items-center xl:justify-between min-h-[305px] py-[20px] xl:py-0  px-[5%]  bg-white border-[1px] border-[#D3D3D3] rounded-[20px]   ">
            <div className=" flex justify-between  ">
              <div className=" flex  xl:items-center xl:flex-row flex-col ">
                <img
                  className=" h-[120px] w-[120px] xl:h-[220px]  xl:w-[220px] object-cover rounded-full "
                  src={state.photo}
                  alt={state.name}
                />
                <div className=" flex flex-col leading-[112%] xl:mx-[35px] ">
                  <span className="text-[24px] xl:text-[30px] gotham-bold text-[#1E1E1E]  ">
                    {state.name} {state.surname}
                  </span>
                  <span className=" text-[#546787] mt-4 text-[20px] xl:text-[24px] ">
                    {state.experiences[0]?.jobTitle}
                  </span>
                  <span className=" text-[#C5C5C5] mt-4 text-[20px] xl:text-[20px]  ">
                    <a href={`mailto:${state.email}`}>{state.email}</a>
                  </span>
                  <span className="flex  gap-[20px] mt-6">
                    {state.socialMedias[0].url ? (
                      <a href={state.socialMedias[0].url} target="_blank">
                        <img src={instagram} alt={instagram} />
                      </a>
                    ) : (
                      <></>
                    )}

                    {state.socialMedias[1].url ? (
                      <a href={state.socialMedias[1].url} target="_blank">
                        <img src={tiktok} alt={instagram} />
                      </a>
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
              </div>
              <div className="block xl:hidden">
                <button onClick={handleOpenReportModal}>
                  <FiAlertTriangle
                    size={24}
                    className={
                      "text-slate-800 fill-white hover:text-primary-800 transition duration-500 "
                    }
                  />
                </button>
              </div>
            </div>
            <div className="hidden  xl:flex flex-col justify-end items-end h-full pt-3 ">
              <button onClick={handleOpenReportModal}>
                <FiAlertTriangle
                  size={24}
                  className={
                    "text-slate-800 fill-white hover:text-primary-800 transition duration-500 "
                  }
                />
              </button>
              <div className="flex h-full items-center ">
                {state?.cv ? (
                  <Button
                    onClick={() => setCloseCVnModal(true)}
                    className=" px-[5%] text-[18px] xl:text-[24px] rounded-full whyte-medium h-[52px] xl:h-[62px] min-w-[160px] xl:min-w-[160px] max-w-[220px] "
                  >
                    {" "}
                    View CV
                  </Button>
                ) : (
                  <Button
                    invert={true}
                    className=" px-[1%] text-[18px] xl:text-[24px] rounded-full whyte-medium h-[52px] xl:h-[62px] min-w-[160px] xl:min-w-[200px] max-w-[250px] "
                  >
                    {" "}
                    CV not added
                  </Button>
                )}
              </div>
            </div>
            <div className="flex xl:hidden h-full items-center ">
              {state?.cv ? (
                <Button
                  onClick={() => setCloseCVnModal(true)}
                  className=" px-[5%] text-[18px] xl:text-[24px] rounded-full whyte-medium h-[52px] xl:h-[62px] min-w-[160px] xl:min-w-[160px] max-w-[220px] "
                >
                  {" "}
                  View CV
                </Button>
              ) : (
                <Button
                  invert={true}
                  className=" px-[1%] text-[18px] xl:text-[24px] rounded-full whyte-medium h-[52px] xl:h-[62px] min-w-[160px] xl:min-w-[200px] max-w-[250px] "
                >
                  {" "}
                  CV not added
                </Button>
              )}
            </div>
          </div>

          <div className=" mt-[12px] flex flex-col min-h-[305px]  px-[5%] py-[4%]  bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] xl:text-[24px]  ">
              Personal Information
            </div>
            <div className="flex  gap-[40px] ">
              <div className="flex flex-col text-[#546787] text-[18px] xl:text-[24px] whyte-medium gap-[10px] mt-8 xl:mt-0">
                <div>Looking for</div>
                <div>Phone no.</div>

                <div>Languages</div>
              </div>
              <div className="flex flex-col text-[#546787] text-[18px] xl:text-[24px] whyte-medium gap-[10px]  mt-8 xl:mt-0 ">
                <div>{state.lookingFor}</div>
                <div>
                  <a
                    href={`tel:${state.countryCode.split(" ")[0]}${
                      state?.phone
                    }`}
                    className=""
                  >
                    {" "}
                    {state?.countryCode?.length > 5 ? (
                      <>
                        {" "}
                        {state.countryCode.split("(")[0]} {state.phone}{" "}
                      </>
                    ) : (
                      <>
                        {state.countryCode} {state.phone}
                      </>
                    )}
                  </a>
                </div>

                <div>
                  {" "}
                  {state.languages.map((l, index) => {
                    if (index === state.languages.length - 1) {
                      return "[ " + l.name + " - " + l.proeficiency + " ]";
                    } else {
                      return (
                        "[ " + l.name + " - " + l.proeficiency + " ]" + " , "
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-[12px] flex flex-col h-fit   px-[5%] py-[4%]  bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] xl:text-[24px]  ">
              Availability :
              <span className="text-[#546787]">
                {state.availability.afternoon ? " Afternoon " : <></>}
                {state.availability.morning ? (
                  state.availability.afternoon ? (
                    " - Morning "
                  ) : (
                    " Morning "
                  )
                ) : (
                  <></>
                )}

                {state.availability.night ? (
                  state.availability.morning || state.availability.afternoon ? (
                    " - Night "
                  ) : (
                    " Night "
                  )
                ) : (
                  <></>
                )}

                {state.availability.weekends ? (
                  state.availability.night ||
                  state.availability.morning ||
                  state.availability.afternoon ? (
                    " - Weekends "
                  ) : (
                    " Weekends "
                  )
                ) : (
                  <></>
                )}
              </span>
              <br />
              <div className=" whyte-bold text-[20px] xl:text-[24px]  ">
                Notes
              </div>
              <div className="text-[20px]  whyte-bold text-[#546787]">
                {state.availability.note}
              </div>
            </div>
          </div>

          <div className=" mt-[12px] flex flex-col h-fit   px-[5%] py-[4%]  bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] xl:text-[24px]  ">
              Experience
            </div>
            {state?.experiences?.map((experience, index) => (
              <div
                key={index}
                className="border-b-[1px] border-b-[#D3D3D3] mt-6 pb-3"
              >
                <div className="flex flex-col ">
                  <span className="whyte-bold text-[20px] xl:text-[24px] ">
                    {experience?.jobTitle}
                  </span>
                  <span className=" text-[#A3A3A3] text-[18px] xl:text-[18px] whyte-medium">
                    {experience?.companyName} - {experience?.jobCategory}
                  </span>
                  <span className=" text-[#2E9367] text-[18px] xl:text-[18px] ">
                    {experience?.startDate} -{" "}
                    {experience?.currentlyWorking
                      ? "Present"
                      : experience?.endDate}
                  </span>
                  <span className=" my-4 text-[18px] xl:text-[18px] ">
                    {experience?.jobDescription}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col xl:w-1/3">
          <div className="flex flex-col w-full max-w-[600px] h-fit bg-white border-[1px] p-[2%] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] xl:text-[24px]  ">
              Intro video
            </div>
            <div className="mt-6 min-h-[400px] max-h-[768px] ">
              {/* <video
              // onClick={() => handleSelectJobSeeker(application)}
              controls={true}
                        
              playsInline
              // alt={application.jobSeeker.name}
              className="h-full object-contain object-center rounded-[25px]  bg-gray-300 m-auto w-full md:object-cover"
            >
              <source src={state?.video} type="video/mp4" />
            </video> */}

              <VideoPlayer srcVideo={state?.video} />
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center mt-[30px] bg-white border-[1px] p-[5%] border-[#D3D3D3] rounded-[20px]">
            <div className=" whyte-bold text-[20px] xl:text-[24px]  ">
              What do you think about the Candidate?
            </div>
            <button
              onClick={() => setCloseInviteModal(true)}
              className="w-full h-[52px] xl:h-[68px] mt-[20px] text-white text-[18px] xl:text-[22px] whyte-medium bg-[#9E5BF6] rounded-full "
            >
              Invite Candidate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSeekerPainelProfile;
