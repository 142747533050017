export function parseDate(date, options = {}) {
  const { inputDateFormat = false } = options;

  function addZeroOptionally(number) {
    return String(number).padStart(2, "0");
  }

  const day = addZeroOptionally(date.getDate());
  const month = addZeroOptionally(date.getMonth() + 1);
  const year = date.getFullYear();

  if (inputDateFormat) {
    return `${year}-${month}-${day}`;
  }
  return `${day}/${month}/${year}`;
}
