import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
      /* --background: #F0F2F5;
      --background-two: #24283b; */
    }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    html {
      @media (max-width: 1080px) {
          font-size: 93.75%; // 15px
      }

      @media (max-width: 720px) {
          font-size: 87.5%; // 14px
      }

      overflow-x: hidden;
    }

    body {
      -webkit-font-smoothing: antialiased;
    }
    
    body, input, textarea, button {
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-weight: 400;
      color: var(---text-title);
    }

    html,
    body,
    #root {
      width: 100%;
      min-height: 100%;
    }
    
    h1, h2, h3, h4, h5, h6, strong {
      font-family: Inter, Arial, Helvetica, sans-serif;
      font-weight: 600;
        
    }

    button {
      cursor: pointer;
    }

    [disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }

    /* LEAFLET */
      
  .leaflet-container {
    z-index: 2;
  }

  .leaflet-popup-content-wrapper {
    background-color: rgb(255, 255, 255);
    border-radius: 20px;

    box-shadow: none;
    transform-origin: bottom;
    animation: appear .5s ease both;

    @keyframes appear {
      0% {
        transform: translateY(12%);
        opacity: 0.5;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .leaflet-popup-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 16px;
    font-weight: 700;
    margin: 8px 12px;
    width: fit-content !important;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

`;