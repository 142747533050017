export const jobCategoryCompany = [
  "Café",
  "Restaurant",
  "Bar",
  "Other accommodation",
  "Hair & Beauty",
  "Food Retail",
  "Retail",
  "Hotel & Leisure",
  "Sales & Marketing",
  "Customer Service",
  "Personal Care",
  "Delivery & Transport",
  "Cleaning & Maintenance",
  "General Labour",
  "Office",
  "Other"
];
