import differenceInDays from "date-fns/differenceInDays";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInYears from "date-fns/differenceInYears";

export function getDifferenceInNaturalTimeFromToday(date) {
  return getDifferenceInNaturalTime(new Date(), date);
}

export function getDifferenceInNaturalTime(date1, date2) {
  const distanceInDays = differenceInDays(date1, date2);

  if (distanceInDays >= 31) {
    const distanceInMonths = differenceInMonths(date1, date2);

    if (distanceInMonths > 12) {
      const distanceInYears = differenceInYears(date1, date2);

      return `${distanceInYears} years ago`;
    }

    return `${distanceInMonths} months ago`;
  }

  if (distanceInDays === 0) {
    return "today";
  } else if (distanceInDays === 1) {
    return "yesterday";
  } else {
    return `${distanceInDays} days ago`;
  }
}
