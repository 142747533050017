import { Fragment, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/homePage/logo white transparent.png";
import "./newNavbar.css";
import logo2 from "../../assets/homePage/logo white transparent.png";
import LoginModal from "../../Pages/Login/LoginModal";
export const NewNavbar = () => {
  const [isOpened, setIsOpened] = useState(false);

  // login dialog
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <LoginModal open={open} handleClose={handleClose} />
      <nav
        className="newNavbar"
        style={{
          background: "linear-gradient(270deg, #0648B7 4.15%, #630DB1 65.44%)",
        }}
      >
        <img className=" h-[50px] lg:h-[80px]" src={logo2} alt="Swanted Logo" />
        <div className={`new-nav-items ${isOpened && "open"}`}>
          <Link
            className=" new-nav-item "
            to="/#benefits"
            onClick={() =>
              setTimeout(() => {
                setIsOpened(false);
              }, 200)
            }
          >
            Benefits
          </Link>
          <Link
            className=" new-nav-item "
            onClick={() =>
              setTimeout(() => {
                setIsOpened(false);
              }, 200)
            }
            to="/#advantages"
          >
            Advantages
          </Link>
          <Link
            className=" new-nav-item "
            onClick={() =>
              setTimeout(() => {
                setIsOpened(false);
              }, 200)
            }
            to="/#demo"
          >
            Demo
          </Link>
          {/* <Link  to="/login" className={` get-started`}> */}
          <button
            className="new-get-started hover:shadow-lg"
            onClick={handleClickOpen}
          >
            Get Started
          </button>
          {/* </Link> */}
        </div>
        <div
          className={`nav-toggle ${isOpened && "open"}`}
          onClick={() => setIsOpened(!isOpened)}
        >
          <div className="bar"></div>
        </div>
      </nav>
    </>
  );
};
