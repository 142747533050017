import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { LoadingScreen } from "../../Components/LoadingScreen/LoadingScreen";
import { GET_VACANCY_INVITES } from "./MyVacancyInvites.services";
import { Navbar } from "../../Components/Navbar/NewNavbar";
import { Vacancy } from "../../Components/NewVacancy/Vacancy";
import { Footer } from "../../Components/Footer/Footer";
import { MyVacancyInvitesPainel } from "./MyVacancyInvitesInvitesPainel";
import { useAutoAnimate } from "@formkit/auto-animate/react";

export function MyVacancyInvites() {
  const [parent] = useAutoAnimate();
  const { data, loading, refetch } = useQuery(GET_VACANCY_INVITES);
  const [focusedVacancy, setFocusedVacancy] = useState(null);

  const handleSelectApplication = useCallback((selectedApplication) => { setFocusedVacancy(selectedApplication) }, []);

  const filteredVacancies = useMemo(() => {
    if (loading) {
      return [];
    }
    return data?.vacancy_invites;
  }, [data, loading]);

  useEffect(() => {
    if (filteredVacancies?.length === 0) {
      setFocusedVacancy(null);
      return;
    }

    setFocusedVacancy(filteredVacancies);
  }, [filteredVacancies]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-gray-50 w-full h-full pt-[20px]  ">
      <Navbar />

      <div className="mt-10 xl:mt-0 bg-gray-50 w-full h-full pt-6 min-h-[93vh]">
        <div className="flex  flex-col mx-14 ">
          <p className="flex whyte-bold text-[24px] xl:text-[32px] ">Inbox</p>
          <p className="my-2  whyte-bold text-[#A3A3A3] text-[16px] xl:text-[20px]">
            Here you will receive notifications from companies that want to hire
            you.
          </p>
        </div>

        <aside className="w-full h-full px-6 xl:px-12" aria-label="Sidebar">
          <div className="relative lg:flex overflow-y-auto  px-3 rounded h-full flex-row-reverse">
            {filteredVacancies?.length === 0 ? (
              <div className="w-full h-full flex justify-center">
                <div className="my-6 flex flex-col items-center">
                  {/* <h1 className="flex justify-center text-lg font-bold">
                    Inbox{" "}
                  </h1>

                  <p className="my-5">
                    Here you will receive notifications from companies that want
                    to hire you.
                  </p> */}

                  <p>You haven't received a job invite yet.</p>
                </div>
              </div>
            ) : (
              <>
                <div className="  relative  flex flex-col w-full xl:w-2/3 h-fit rounded-[20px] lg:m-8 ">
                  {focusedVacancy && (
                    <div className="xl:fixed xl:top-[200px] xl:w-[30%]">
                      <MyVacancyInvitesPainel
                        setFocusedVacancy={setFocusedVacancy}
                        focusedVacancy={focusedVacancy}
                        refetch={refetch}
                      />
                    </div>
                  )}
                </div>

                <ul className="space-y-2 w-full mt-6">
                  <div
                    className="lg:flex lg:flex-col lg:items-center"
                    ref={parent}
                  >
                    {filteredVacancies?.map((vacancyInvite, index) => (
                      <Vacancy
                        key={vacancyInvite._id}
                        job={vacancyInvite.vacancy}
                        onClick={() => {
                          handleSelectApplication(filteredVacancies[index]);
                        }}
                      />
                    ))}
                  </div>
                </ul>
              </>
            )}
          </div>
        </aside>
      </div>

      <Footer />
    </div>
  );
}
