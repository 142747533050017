import { Router } from "./routes/Router";
import { ApolloProvider } from "@apollo/client";
import { GlobalStyle } from "./Styles/global";
import { AuthProvider } from "./hooks/Auth";
import { client } from "./config/apollo";
// import ReactGA from 'react-ga';
import { LoadScript } from "@react-google-maps/api";

import "leaflet/dist/leaflet.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  // const TRACKING_ID = "UA-240353440-1"; // OUR_TRACKING_ID
  
  // ReactGA.initialize(TRACKING_ID);

  return (
    <>
      <ApolloProvider client={client}>
        <GlobalStyle />
        <AuthProvider>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_PLACES_API}
            libraries={["places"]}
          >
            <Router />
          </LoadScript>
        </AuthProvider>
      </ApolloProvider>
    </>
  );
}

export default App;
