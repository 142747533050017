import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation updateUser(
    $email: String!
    $password: String!
    $jobSeekerAvailability: AvailabilityInput!
    $jobSeekerName: String!
    $jobSeekerSurname: String!
    $jobSeekerAddress: String!
    # $jobSeekerEircode: String!
    $jobSeekerGender: String!
    $jobSeekerBirth: String!
    # $jobSeekerCountry: String!
    $jobSeekerLookingFor: String!
    $jobSeekerCountryCode: String!
    $jobSeekerPhone: String!
    $jobSeekerSocialMedias: [SocialMediaInput]!
    $jobSeekerLanguages: [LanguagesInput]!
    $jobSeekerPhoto: FileInput
    $jobSeekerVideo: FileInput
    $jobSeekerCv: FileInput
    $jobSeekerExperiences: [ExperienceInput]!
  ) {
    updateUser(
      user: {
        email: $email
        password: $password
        jobSeeker: {
          availability: $jobSeekerAvailability
          name: $jobSeekerName
          surname: $jobSeekerSurname
          address: $jobSeekerAddress
          # eircode: $jobSeekerEircode
          gender: $jobSeekerGender
          birth: $jobSeekerBirth
          lookingFor: $jobSeekerLookingFor
          # country: $jobSeekerCountry
          countryCode: $jobSeekerCountryCode
          phone: $jobSeekerPhone
          socialMedias: $jobSeekerSocialMedias
          languages: $jobSeekerLanguages
          photo: $jobSeekerPhoto
          video: $jobSeekerVideo
          cv: $jobSeekerCv
          experiences: $jobSeekerExperiences
        }
      }
    ) {
      _id
      email
      roles
      jobSeeker {
        _id
        name
        surname
        address
        # eircode
        gender
        birth
        lookingFor
        # country
        countryCode
        latlon
        phone
        availability {
          morning
          afternoon
          night
          weekends
          note
        }
        experiences {
          companyName
          currentlyWorking
          endDate
          jobCategory
          jobDescription
          jobTitle
          startDate
        }
        socialMedias {
          name
          url
        }
        languages {
          name
          proeficiency
        }
        photo
        video
        cv
      }
      company {
        _id
        name
        latlon
        address
        # eircode
        photo
        owner
        manager
        website
        numberOfEmployees
        companyRegistrationNumber
        companyCategory
        companyDescription
        socialMedias {
          name
          url
        }
        about
      }
    }
  }
`;
