// import facebook from '../assets/social-media/facebook.png';
import instagram from '../assets/social-media/instagram.png';
// import linkedin from '../assets/social-media/linkedin.png';
import tiktok from '../assets/social-media/tiktok.png';

export const socialMedia = [
  // {
  //   name: "Facebook",
  //   logo: facebook,
  //   urlPattern:
  //   /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/
  // },
  {
    name: "Instagram",
    logo: instagram,
    // urlPattern: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/,
    // urlPattern: ^(?!www|http:\/\/|https:\/\/).*,
    url: "https://www.instagram.com/",
    tips: 
      <>
        <p className="mb-3 mt-6">Only input your user name after the "/"</p>
        <i>Example: https://www.instagram.com/<span style={{ "color": "#9E5BF6", fontWeight: "bold" }}>swanted_app</span></i>
      </>
  },
  // {
  //   name: "LinkedIn",
  //   logo: linkedin,
  //   urlPattern: /(?:(?:http|https):\/\/)?(?:www.)?(?:linkedin.com\/in)\/(\w+)/
  // },
  {
    name: "Tiktok",
    logo: tiktok,
    // urlPattern: /(?:(?:http|https):\/\/)?(?:www.)?(?:tiktok.com)\/@(\w+)/,
    // urlPattern: "^(?!www|http:\/\/|https:\/\/).*",
    url: "https://www.tiktok.com/@",
    tips: 
      <>
        <p className="mb-3 mt-6">Only input your user name after the "@" sign</p>
        <i>Example: https://www.tiktok.com/@<span style={{ "color": "#9E5BF6", fontWeight: "bold" }}>swantedapp</span></i>
      </>
  }
];
