import { gql } from "@apollo/client";

export const GET_APPLICATIONS = gql`
  query getApplications($vacancyId: ID!) {
    applications(vacancyId: $vacancyId) {
      _id
      photo
      video
      cv
      jobSeeker {
        _id
        name
        surname
        eircode
        gender
        lookingFor
        birth
        availability {
          morning
          afternoon
          night
          weekends
          note
        }
        country
        email
        countryCode
        phone
        experiences {
          companyName
          currentlyWorking
          endDate
          jobCategory
          jobDescription
          jobTitle
          startDate
        }
        socialMedias {
          name
          url
        }
        languages {
          name
          proeficiency
        }
        photo
        video
        cv
      }
      vacancy {
        _id
        jobName
        period
        salary
        category
        contractType
        date
        disponibleVacancies
        hourPerWeek
        company {
          _id
          name
        }
      }
    }
  }
`;

export const EDIT_VACANCY = gql`
  mutation editVacancy(
    $vacancyId: ID!
    $vacancyJobName: String!
    $vacancyPeriod: String!
    $vacancySalary: String
    $vacancyCategory: String!
    $vacancyContractType: String!
    $vacancySalaryNegotiable: Boolean!
    $vacancyDescription: String!
    $vacancyDisponibleVacancies: Int!
    $vacancyHourPerWeek: String!
  ) {
    editVacancy(
      id: $vacancyId
      vacancy: {
        jobName: $vacancyJobName
        period: $vacancyPeriod
        isSalaryNegotiable: $vacancySalaryNegotiable
        description: $vacancyDescription
        salary: $vacancySalary
        category: $vacancyCategory
        contractType: $vacancyContractType
        disponibleVacancies: $vacancyDisponibleVacancies
        hourPerWeek: $vacancyHourPerWeek
      }
    ) {
      _id
      jobName
      period
      salary
      category
      contractType
      date
      disponibleVacancies
      isSalaryNegotiable
      description
      hourPerWeek
      applicants
      deleted
      company {
        _id
        name
        photo
      }
    }
  }
`;

export const DELETE_VACANCY = gql`
  mutation deleteVacancy($vacancyId: ID!) {
    deleteVacancy(vacancyId: $vacancyId) {
      ok
    }
  }
`;

export const CLOSE_POSITION = gql`
  mutation closeVacancyPosition(
    $vacancyId: ID!
    $hireThroughSwanted: Boolean!
  ) { 
    closeVacancyPosition(
      vacancyId: $vacancyId
      hireThroughSwanted: $hireThroughSwanted
    ) {
      _id
    }
  }
`;

export const CLOSE_EDIT = gql`
  mutation closeVacancyEdit(
    $vacancyId: ID!
    $hireThroughSwanted: Boolean!
  ) {
    closeVacancyEdit(
      vacancyId: $vacancyId
      hireThroughSwanted: $hireThroughSwanted
    ) {
      _id
    }
  }
`;


