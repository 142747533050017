import React, { useMemo, useState, useCallback } from 'react'
import { Link } from 'react-router-dom';
import { parseDate } from '../../utils/parseDate';
import { Modal } from '../Modal/Modal'
import { Portal } from '../Portal/Portal';
import { FiCalendar, FiUser, FiClock, FiFileText, FiBriefcase, FiAlertTriangle } from 'react-icons/fi';
import { BiEuro } from "react-icons/bi";
import { getDifferenceInNaturalTimeFromToday } from '../../utils/getDifferenceInNaturalTime';
import { ReportModal } from '../ReportModal/ReportModal.jsx';

export function SeeMoreModal({ job, setSeeMoreModal, isSeeMoreModalOpen }) {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const handleOpenReportModal = useCallback(() => {
    setIsReportModalOpen(state => !state);
  }, []);
  const jobDate = useMemo(() => {
    const date = new Date(Number(job.date))

    return parseDate(date);
  }, [job]);

  const parsedSalary = useMemo(() => {
    if (!job?.salary) {
      return null;
    }

    return job?.salary;
  }, [job]);

  const timeSincePost = useMemo(() => {
    const date = new Date(Number(job.date))

    return getDifferenceInNaturalTimeFromToday(date);
  }, [job]);

  return (
    <>
      {isReportModalOpen && (
        <ReportModal
          type={'job'}
          job={job}
          isReportModalOpen={isReportModalOpen}
          setIsReportModalOpen={setIsReportModalOpen}
        />
      )}
      <Modal
        headerItems={(
        <button onClick={handleOpenReportModal}>
          <FiAlertTriangle
            size={24}
            className={"ml-2 text-slate-800 fill-white hover:text-primary-800 transition duration-500 "}
          />
        </button>
      )}
        closeModal={() => setSeeMoreModal(false)}
        isOpen={isSeeMoreModalOpen}
      >
        <div className="flex flex-col items-center w-[80vw] sm:px-0 lg:w-[750px] h-[600px] h-[480px]">

          <h1 className="-mt-3 absolute h-12 flex items-center justify-center bg-primary-700 text-white w-96 rounded-xl transition duration-500 hover:bg-primary-900 w-[200px] sm:w-96">
            <Link to={`/companies/${job.company._id}`}>
              {job.company.name}
            </Link>
          </h1>

          <div className="w-full h-[auto] sm:h-[95%] lg:w-[720px] items-center bg-slate-200 p-5 rounded-lg mt-3">
            <h1 className="text-2xl mt-2 flex items-baseline justify-center">{job.jobName} <span className='text-red-500 font-medium text-sm ml-1'>{job.deleted && `(Job already closed)`}</span></h1>

            <span className="font-normal text-xs text-gray-400 flex justify-center">Posted {timeSincePost}</span>

            <div className="mt-3">
              <ul className="grid grid-cols-1 gap-1 sm:grid-cols-2">
                <li className='flex items-center'>
                  <BiEuro className="mr-2" />{parsedSalary ? `${parsedSalary} per hour` : 'Salary Negotiable'}
                </li>
                <li className='flex items-center'>
                  <FiUser className="mr-2" />{job.disponibleVacancies} position{job.disponibleVacancies !== 1 && 's'}
                </li>
                <li className='flex items-center'>
                  <FiBriefcase className="mr-2" />{job.category}
                </li>
                <li className='flex items-center'>
                  <FiBriefcase className="mr-2" />{job.contractType}
                </li>
                <li className='flex items-center'>
                  <FiFileText className="mr-2" />{job.period}
                </li>
                <li className='flex items-center'>
                  <FiClock className="mr-2" />{job.hourPerWeek} Hours Per Week
                </li>
              </ul>
            
              {job.description && (
                <div className="mt-5">
                  <p className="mb-3 text-center"><strong>Description</strong></p>
                  <p>{job.description}</p>
                </div>
              )}
            
            </div>


          </div>
        </div>
      </Modal>
    </>
  )
}
