import React, { forwardRef } from 'react'
import { Modal } from '../NewModal/Modal';
import Term from "../../assets/banners/term.png"

export const TermsAndConditions = forwardRef(({
  className,
  ariaLabel,
  value,
  maxLength = 100,
  shouldShowTermsAndConditions,
  setShouldShowTermsAndConditions,
  ...props
}, ref) => {

  return (
    <>
      <Modal  width={"90%"}  className=" z-50 " maxWidth={800} height={600} closeModal={() => setShouldShowTermsAndConditions(false)} isOpen={shouldShowTermsAndConditions}>
        <div className='p-3 xl:p-12'>
          <img className="mb-5 rounded-3xl" src={Term} alt="Privacy Policy Image" /> 
          <p>
            Welcome to the Swanted website, being the website “Swanted.io” and any other web page containing that domain name (the “Website”), which is operated by Swanted Limited (a company incorporated in Ireland with company registration number 727378 and a registered office at Swanted Limited, The Digital Hub, 161 Thomas Street, Dublin 8 (the “Company”, “we”, “us” or “our”)
          </p>
          <h1 className='mt-5'>1 USERS, YOUR AGREEMENT AND PRIORITY</h1>
          <p className='mt-5'>
            1.1      These terms of use together with our Privacy Policy comprise the “Terms of Use”. In the event of a conflict between these terms of use and the terms of the Privacy Policy, the terms of the Privacy Policy will prevail. The Terms of Use shall apply to the use of the Website by any visitor, jobseeker, recruiter, registered user or unregistered user of the Website (each a “user”, “you” or “your”) as follows:<br />
            1.1.1      If you are a visitor, jobseeker, recruiter, registered user or unregistered user of the Website other than a Contract User or an Ecommerce User, these Terms of Use and any other terms and conditions referred to in them comprises your Agreement with us and the term Agreement shall be construed accordingly throughout these Terms of Use.<br />
            1.1.2      If you are a Contract User these Terms of Use form part of your agreement with us, which comprises, in the following order of priority in the event of a conflict:
            the Contract Form;
            the Terms and Conditions; and
            these Terms of Use and any other terms and conditions referred to in these Terms of Use, and the term Agreement shall be construed accordingly in these Terms of Use.<br />
            1.1.3      If you are an Ecommerce User, the Ecommerce Terms (as defined and set out in Section 11 of this Agreement) form part of your agreement with us which comprises, in the following order of priority in the event of a conflict:
            the Ecommerce Terms; and
            these Terms of Use, and the term Agreement shall be construed accordingly in these Terms of Use.
            these Terms of Use and any other terms and conditions referred to in these Terms of Use, and the term Agreement shall be construed accordingly in these Terms of Use.<br />
            1.2      Use of or access to the Website is subject to and constitutes your acceptance of the Agreement which take effect on the earlier of: (a) the date which you first access the Website, or, (b) if you are a Contract User or an Ecommerce User, when you sign a Contract Form or when you submit payment of a Fee on the Website in respect of a request for Ecommerce Services, respectively.<br />
            1.3      When you access or use our Website you conclude a legally binding agreement, comprising the Agreement, based on these Terms of Use and any other terms and conditions referred to in the Terms of Use, with the Company.<br />
            1.4      The website makes use of Google Places API in the provision of its Services. By using our Website, you are also bound by Google’s Terms of Services, available here: google.com/intl/en/policies/terms<br />
            1.5      We reserve the right to update these Terms of Use (including any Website Services or Products) at any time with or without notice to you. Users are responsible for regularly reviewing the Website to receive such changes. Your continued use of the Website, and the Website Services after changes are posted constitutes your acceptance of the Agreement, including the updated Terms of Use.<br />
            1.6      Other than the Terms of Use, if we wish to change any terms and conditions in your Agreement with us, we will provide you with 30 days’ notice of the change(s) we intend to make. If upon the expiry of 30 days following our provision of this notice, we do not receive a written response from you indicating that you do not agree to the proposed change(s), the change will have effect from the date notified by us.<br />
            1.7      We may terminate your registration and/or deny you access to the Website or any part of it (including any services, products or information available on or through the Website) at any time in our absolute discretion.<br />
            1.8      This Agreement constitutes the whole agreement between us and you and supersedes and terminates any previous arrangement, understanding or agreement between us and you relating to the Website and any of our Website Services including our Products. You acknowledge that you do not rely on any statement, representation, assurance or warranty of any person (whether a party to this agreement or not) other than as expressly set out in your Agreement with us.
          </p>
          <h1 className='mt-5'>2 CHANGES TO THE WEBSITE AND WEBSITE CONTENT</h1>
          <p>
            2.1      We may update our Website from time to time, and may change any information or material (including designs, text, graphics, logos, images and related content), presentation, layout or functionality of the Website whether written, published, shared or otherwise appearing on the Website in respect of any of the Website Services, and for the avoidance of doubt this shall include any and all information or material contained in any advertisements, CVs or User Content appearing on the Website, (the “Content”) at any time. However, please note that any of the Content on our Website may be out of date at any given time, and we are under no obligation to update it. We do not guarantee that our website, or any Content on it, will be free from errors or omissions.<br />
            2.2      We make services available to users on the Website from time to time, including the ability to create, publish and manage written advertisements for jobs, register CVs with us to appear on the Website, contact other users of the Website in response to advertisements published on the Website, search the Website for advertisements, and create and/or respond to profiles of employers (both user and non-user employers) (each an “Employer”), feedback, reviews, ratings or other information relating to any Employer (including an Employer's recruitment process, salaries, organisation, business, performance, competitiveness, and employee satisfaction) whether created by a user who is not an Employer, or created by an Employer in connection with such content (“User Content”), including the collation of User Content for data analysis, quality control, and to provide better search results for both jobseekers and Employers (the “Website Services”). For the purpose of performing the Website Services, the Company may cooperate with media partners and host the Website, or parts thereof, at further websites and/or on third party hardware.<br />
            2.3      The names, images and logos identifying the Company, or third parties and their products and services are proprietary marks of the Company and/or third parties and may only be used by users when using the Website in the ordinary course. We may use Employer-user names, logos and marks for the purpose of the provision of the Website Services to you and in connection with the management, use and provision of the Content, including the User Content.<br />
            2.4      We have no obligation to monitor or moderate any user's activity or use of our Website and/or the Website Services, however we retain the right at all times to monitor, retain and disclose any information as necessary to satisfy any Applicable Laws.<br />
            2.5      Without any admission of liability, we may from time to time assess any possible risks for users from third parties when they use the Website and/or Website Services and we will decide in each case whether it is appropriate to use moderation (including what kind of moderation to use) in the light of those risks. We may appoint certain users of the Website to act as moderators.<br />
            2.6      For the purpose of performing the Website Services, the Company may cooperate with media partners and host the Website, data relating to the users and Website Services or parts thereof, at further websites and/or on third party hardware.<br />
          </p>
          <p>
            3.1      We may provide links and pointers to websites or other resources maintained by third parties from our Website. Such websites are not under our control and we are not responsible for the contents (including the accuracy, legality or decency) of any such website or any link contained in such a website. We are providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by us of the linked website. We are not responsible for the copyright compliance of any linked website. We will not be liable for any damages or loss arising in any way out of or in connection with or incidental to any information or third party service provided by any third party and you acknowledge that the use of any third party website or resource is governed by the terms and conditions of use applicable to such website or resource.
          </p>
          <h1 className='mt-5'>4 YOUR USE OF THE WEBSITE AND YOUR LIABILITY</h1>
          <p>
            4.1      YOUR ACCOUNT AND PASSWORD<br />
            4.1.1      If any of the Website Services require you to register with us, you must complete the registration process by providing us with current, complete and accurate information as prompted by the applicable registration form. You are entirely responsible for maintaining the confidentiality of your password and account and you are entirely responsible for any and all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.<br />
            4.1.2      You may not use anyone else’s account at any time, without the permission of the account holder.
            Any links provided on the Website are provided for your interest or convenience only and do not represent or imply any endorsement by us of such linked websites and we cannot be held liable for any loss or damage which may arise from use of such third party websites or contracts entered into resulting from the use of such third party websites.
            You acknowledge that the use of such third party websites is governed by the terms and conditions of use applicable to such websites.<br />
            4.2      PRIVACY AND INFORMATION PROVIDED BY YOU INCLUDING USER CONTENT<br />
            4.2.1      We are committed to safeguarding your privacy online. Please refer to our Privacy Policy for full details of our firm commitment to privacy.<br />
            4.3      CREATING AND POSTING USER CONTENT<br />
            4.3.1      You may only create and publish User Content for purposes that are consistent with the Agreement and that are not illegal or otherwise breach Applicable Laws. You may not create any User Content that contains confidential or proprietary information, intellectual property, personal data relating to third parties (including an individual’s name or other information which identifies a living individual) or trade secrets, or which breaches your employment agreement.
            4.4      LICENCE OF USER CONTENT<br />
            4.4.1      By submitting User Content you hereby grant to us and our licensees a worldwide, unrestricted, irrevocable, perpetual, non-exclusive, transferable and royalty-free licence to use, copy, make, reproduce, display, perform, distribute, sub-licence, sell, delete, edit, prepare derivative works from, analyse and otherwise exploit the User Content for the purposes of managing our Website, providing the Website Services and operation of our businesses (including by compiling it together with other User Content and anonymising it to perform statistical analysis for comparison or benchmarking purposes) to the extent permitted by applicable laws<br />
            4.4.2      You acknowledge that we are owner of the intellectual property rights in the Content (whether jointly or solely) and that our prior written permission is required for any such use or removal of the Content. Your use of the Content does not give you any proprietary rights in the Content.<br />
            4.5      LIABILITY FOR USER CONTENT<br />
            4.5.1      By submitting User Content to this Website you acknowledge that any use, display, copying or reproduction that you make of a company trademark or logo or inclusion of Employer or third party text or other content (including by hyperlink text) constitutes use of third party intellectual property rights and you agree that you are wholly responsible for ensuring that your User Content is permitted.<br />
            4.5.2      If you have a clarification, feedback or a complaint regarding the accuracy of specific User Content, you may tell us about it by using the Report functionality beside the specific User Content on the Website.<br />
            4.6      LIABILITY FOR EMPLOYER REVIEWS<br />
            4.6.1      The results of your Employer reviews created by your User Content may, for example, be shown as star ratings for that Employer as part of all Website Services. You acknowledge that your ratings created from User Content are generated as an automated processing of User Content that represents users’ purported experiences, and do not represent our endorsement or view of the Employer.<br />
            4.7      UNLAWFUL & PROHIBITED USE<br />
            4.7.1      You may only use the Website and/or the Website Services for lawful purposes when seeking employment or help with your career, or when recruiting staff. You must not under any circumstances seek to undermine the security of the Website or any information submitted to or available through it. In particular, but without limitation, you must not seek to access, alter or delete any information on the Website to which you do not have authorised access, seek to overload the Website system via spamming or flooding, take any action or use any device, routine or software to crash, delay, damage or otherwise interfere with the operation of the Website or attempt to decipher, disassemble or modify any of the software, coding or information comprised in the Website. You are also responsible for ensuring that all information, data and files are free of viruses or other routines or engines that may damage or interfere with any system or data prior to being submitted to the Website.<br />
            4.7.2      You are prohibited from using the Website and/or Website Services in any manner deemed to be in competition with our business activities (as determined by us at our sole discretion).<br />
            4.7.3      You are prohibited from using or removing any Content from the Website by any means for any purposes deemed to be in competition with our business activities (as determined by us at our sole discretion).<br />
            4.7.4      We reserve the right to remove any information including any Content supplied by you from the Website at our sole discretion, at any time and for any reason without notice or any explanation.<br />
          </p>
          <h1 className='mt-5'>5 OUR LIABILITY</h1>
          <p>
            5.1      We do not warrant that the Website will be available on an uninterrupted basis, and we accept no liability in respect of losses or damages arising out of any unavailability of the Website.<br />
            5.2      We will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by us or another party due to someone else using your account or password as a result of your negligence or breach of this Agreement.<br />
            5.3      In no event will the Company be liable for any losses or damages including indirect or consequential damages, or any damages whatsoever arising from use or loss of use, data, or profits, whether in action of contract or negligence, arising out of or in connection with the use of the Website, including arising out of changes made to the Content of the Website by any third party. Your access to and use of the Website and Content is at your own risk.<br />
            5.4      You acknowledge that by accessing the Website, you may come into contact with Content that you find harmful, offensive, threatening, indecent or objectionable and you acknowledge that the Company shall have no liability to you for any User Content including explicit language and other potentially offensive material.<br />
            5.5      The Company does not endorse and is not responsible for any opinion, advice, information, or statement contained in the User Content. To the fullest extent permitted by law, the Company shall under no circumstances be liable for any use, misuse of or reliance upon, any User Content, for any claim made in relation to these Terms of Use, or for any damages, whether direct, indirect, incidental, consequential, special or punitive, and whether arising from use, misuse or reliance upon the User Content, from damage caused by information contained in the User Content or to damages incurred by reason of use of any links provided within the User Content. We expressly exclude our liability for any loss or damage arising from the use of the Website and/or Website Services by a user in contravention of our Agreement, whether the Website and/or Website Services are moderated or not. Any action that we might take in respect of a user or regarding Content, for example, deletion, editing or declining to publish, is neither an admission of liability on our part nor an indication of the breach or otherwise of any standard, Legal Provision or term by a user. Where we do moderate the Website and/or Website Services, we will normally provide you with a means of contacting the moderator or an administrator, should a concern or difficulty arise.<br />
            5.6      In accessing this Website you acknowledge that the Company is not liable for the misuse, alleged or otherwise, of third party intellectual property rights by users of this Website who create User Content.<br />
          </p>
          <h1 className='mt-5'>6 JURISDICTION AND INVALIDITY</h1>
          <p>
            6.1      This Agreement, and any issue connected with it, are governed by and construed in accordance with Irish law. The courts of Ireland have exclusive jurisdiction to settle any dispute arising out of or in connection with the use of the Website or this Agreement.<br />
            6.2      If any provision of the Agreement is held to be invalid by a court of competent jurisdiction, such invalidity shall not affect the validity of the remaining provisions, which shall remain in full force and effect.<br />
          </p>
          <h1 className='mt-5'>7 ENSURING QUALITY OF CONTENT</h1>
          <p>
            7.1      The following rules are in place to protect the quality of the Content on the Website. The maintenance of quality Content seeks to ensure that jobseekers can find the positions most relevant to their requirements quickly and easily and that Contract Users and Ecommerce Users receive relevant job applications.<br />
            7.2      These rules are intended to make the Website easier to use, thus attracting more jobseekers to apply for your jobs.<br />
            7.3      By enforcing these rules, we provide a level playing field for all Contract Users and Ecommerce Users using the Website Services while at the same time increasing the long term benefits you will reap from your subscription with us. Failure to adhere to these rules may result in action being taken by the Company, up to and including termination of our Agreement with you.<br />
            7.3.1      JOB ADVERTISEMENT: TITLE AND DESCRIPTION<br />
            The title of a job advertisement must accurately reflect the job’s description. It is to no-one’s advantage if a jobseeker has to click on the title of a job advertisement unnecessarily, only to find that the job description does not match.
            Job advertisements should not use excessive CAPITAL LETTERS or **** Punctuation!!!!
            Avoid generic job advertisement titles such as “TEMPS, TEMPS, TEMPS” or “Legal, Legal, Legal”.
            Job advertisement titles and descriptions should not contain excessive use of search keywords which would disproportionately affect the position of your job advertisement in the search rankings.
            Hidden or “white text” included in job advertisements or otherwise on the Website is prohibited.<br />
            7.3.2      JOB ADVERTISEMENT USAGE<br />
            Job advertisements perform best if they are allowed to remain on the Website for 30 days from the date they are first uploaded, ensuring the best possible response. Job advertisements will automatically expire after 30 days unless notified otherwise.
            Job advertisements must stay live on the Website for such minimum period of time as may be determined by the Company at our discretion. This is to ensure that jobseekers who receive your job advertisements by email will not find a job to have expired during that timeframe. The Company retains the right to amend the minimum live duration required in respect of jobs advertised on the Website.
            Each job may be advertised once only. Posting duplicate or similar job advertisements that relate to the same job provides unrealistic search results and is deeply frustrating for jobseekers. Advertisement of the same job twice, at the same time, is prohibited, including where the description of the job is changed or rephrased.<br />
            7.3.3      FILLED ROLES & CV COLLECTION<br />
            If the job being advertised has been filled, the job advertisement must be removed as soon as possible.
            If you are recruiting in order to build a panel of CVs for a job that is available from a certain date in the future but that is not vacant on the date the job advertisement is posted, this must be made clear to the jobseeker in the job description.<br />
            7.3.4      COST TO JOBSEEKER<br />
            If there is a cost involved to the jobseeker at any stage in the process of applying for or accepting a job advertised, this must be explained in the job advertisement.
            If the job advertised offers no basic salary (e.g. if the job operates on the basis of commission only, or is a position registered under or otherwise forming part of a state-sponsored “back-to-work” scheme or similar), this must be clearly stated in the job advertisement. Unpaid internships may not be advertised on the Website.<br />
            7.3.5      JOB CATEGORISATION<br />
            You must ensure that a job is advertised using the correct categories and locations. You may select up to 3 tags to apply to a job advertisement. You may only use tags which accurately describe the job being advertised.<br />
            7.3.6      CONTACT DETAILS IN JOB ADVERTISEMENTS<br />
            You may not include an email address in the job title, job description or anywhere in a job advertisement.
            You may not include a phone number in the job advertisement, nor include any information which is intended to, or which results in, a jobseeker being led away from the Website as part of their search for a job.
            You may not include third party URL links outside of the Company profile page or applicant tracking system (“ATS”) application links.
            All positions advertised in the featured category in the job listings on the Website must display at least national minimum salary in the section of the job description or you may not always wish to disclose the salary. In the event that this is the case, the following alternative options to describe the applicable salary level are available: "negotiable”
            You may not include third party URL links outside of the Company profile page or applicant tracking system (“ATS”) application links.<br />
          </p>
          <h1 className='mt-5'>8 OUR PRODUCTS</h1>
          <p>
            8.1      PRODUCT DEFINITIONS<br />
            8.1.1      Swanted Job<br />
            A “Swanted Job” is a job advertisement in respect of a single job vacancy.<br />
            8.1.2     Location Pin<br />
            A “Location Pin” is the advertising space on the Website to which a direct employer Swanted Job may be uploaded are available to direct corporate employers. The period of time which will apply to a particular “Location Pin” is set out in your Contract Form. One Swanted Job may be uploaded to Location Pin for a 30 day period, which commences on the date the Swanted Job is uploaded to the Location Pin. If a Swanted Job is removed from a Location Pin before the 30 day period applicable to that Swanted Job has expired, a new or replacement Swanted Job cannot be uploaded to that Location Pin until the 30 day period applicable to the original Swanted Job has expired.<br />
            8.1.3      Swanted Boost<br />
            The “Swanted Boost” function is designed to boost a Job Ad which is already live on the Website. A Swanted Boost may be applied to a Job Ad at a maximum frequency of once every 7 days. The frequency with which a Swanted Boost is available to users is at the discretion of the Company.<br />
            8.1.4     Swanted Ping<br />
            A "Swanted Ping" refers to an email communication that may be sent to recipients who have opted to receive it. A Ping comprises a list of Swanted Jobs which relate to the type of vacancies specified as being of interest to the recipient. The ‘Swanted Ping’ function is made available to you on an automated basis at the sole discretion of the Company, during the period of time immediately after Swanted Jobs are first uploaded to the Website.<br />
            8.1.5      Swanted Solo<br />
            A Swanted Solo is a job that has been individually paid and will be receiving jobseekers CVs, profiles, Intro Videos and Cover Videos. The job will last for 30 days in the system before being taken down automatically.<br />
            8.1.6   Video Database Subscription<br />
            When a company signs up to the database, they will have access to search our candidates and will obtain access to jobseekers’ intro videos, profiles, and CVs. The companies can also contact the candidates and motivate them to apply to their open positions. The companies can only access the video database if they have a live a paid job on The Website.<br />
            8.1.12      Swanted Ad<br />
            The "Swanted Ad" product comprises banner advertising which may be displayed across certain sections of the Website. This product is displayed on the Website in the form of leader board or MPU (“Mid Page Unit”) advertising formats. Links from Display Advertising must always link to a page on the Website.<br />
          </p>
          <h1 className='mt-5'>9 DEFINITIONS AND INTERPRETATION OF TERMS OF USE</h1>
          <p>
            Any words following the terms including, include, in particular, for example or any similar expression shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms.
            Agreement
            has the meaning given in section 1.2;
            Applicable Laws
            all applicable laws, statutes, regulations, codes and requirements from time to time in force;
            Content
            has the meaning given in section 2.1;
            Contract Form
            has the meaning given in the Contract;
            Contract User
            any person or entity who signs a Contract Form with the Company;
            Ecommerce User
            has the meaning given in the Ecommerce Terms;
            Employer
            has the meaning given in section 2.2;
            Privacy Policy
            the Website privacy policy published on the Website from time to time;
            Products
            the products listed under section 8 of these Terms of Use, as described in that section from time to time
            “user”, “you” and “your”
            any visitor, jobseeker, recruiter, registered user or unregistered user of the Website including a Contract User and an Ecommerce User, as applicable in accordance with these Terms of Use;
            User Content
            has the meaning given in section 2.2;
            Website Services
            has the meaning given in section 2.2; and
            Terms and Conditions
            means the terms and conditions attached to the Contract Form, and which are defined therein as the Terms and Conditions.
          </p>
          <h1 className='mt-5'>10 ECOMMERCE TERMS AND CONDITIONS</h1>
          <p>
            10.1      The terms and conditions in this section (the “Ecommerce Terms”) form part of the Terms of Use and apply only in relation to the Ecommerce Services (as defined below) which are requested by an Ecommerce User. The Ecommerce Terms will apply for the duration of the Ecommerce Term, in addition to the remaining Terms of Use.<br />
            10.2      Ecommerce Users (as defined below) are business customers that purchase exclusively online from the Company, Ecommerce Services comprising Products that last for the Ecommerce Term.<br />
            10.3      Where there is a conflict between the Ecommerce Terms and the Terms of Use, the Ecommerce Terms will prevail, followed by the Terms of Use.<br />
            10.4      PAYMENT OF FEE<br />
            10.4.1      Where an Ecommerce User submits payment of a Fee on the Website in respect of a request for Ecommerce Services, that User enters a binding Agreement with the Company, for the duration of the Ecommerce Term.<br />
            10.4.2      The Fee shall be paid by the Ecommerce User using an accepted payments card through the online portal for Ecommerce Services available on the Website. The Fee must be paid in full and in advance of the commencement of the Ecommerce Services.<br />
            10.5      ECOMMERCE SERVICES<br />
            10.5.1      The Ecommerce User undertakes to provide complete and accurate information in any request for Ecommerce Services, including in relation to any Ecommerce User requirements and any Job Specification. The Ecommerce User shall be responsible for any information placed on the Website pursuant to Ecommerce Services provided by the Company to that Ecommerce User. The Ecommerce User shall indemnify the Company during the Ecommerce Term and at any time thereafter for any loss, expense or damage incurred by the Company as a result of the Ecommerce User’s actions or omissions in connection with its use of the Website, information placed by the Ecommerce User on the Website, the use that it makes of Content provided through the Website or otherwise in connection with this Agreement.<br />
            10.5.2      The Ecommerce User shall supervise and control the use of the Website by the Ecommerce User’s employees and any authorised third parties in accordance with the terms of this Agreement.<br />
            10.5.3      Where the Ecommerce User deems a jobseeker to be suitable based on the Ecommerce User’s requirements, the Ecommerce User may contact that jobseeker to progress their application only where the jobseeker has provided consent to be contacted, using the method of contact to which the jobseeker has given consent.<br />
            10.5.4      The Ecommerce User will acknowledge all applications submitted to it on the Website by all jobseekers. The Company cannot guarantee that any Ecommerce User will recruit successfully using the Ecommerce Services.<br />
            10.5.5      The Company will be entitled to refuse delivery of the Ecommerce Services to an Ecommerce User at its discretion, notwithstanding its receipt of the Fee. In the event the Company elects not to deliver the Ecommerce Services to an Ecommerce User, then, subject to Applicable Law, the Company will refund the Fee made by that Ecommerce User in respect of those Ecommerce Services, within 28 working days. Payments made by Ecommerce User are otherwise non-refundable.<br />
            10.5.6      The Ecommerce User shall notify the Company promptly if the Ecommerce User becomes aware of any unauthorised access to, use of or copying of any part of the Website, including any Content.<br />
            10.5.7      The Company may review the information which the Ecommerce User intends to place or has placed on the Website and reserves the right to refuse to place or may remove, amend or discontinue displaying any information which is in breach of this Agreement, of any Applicable Laws or which it considers in its sole discretion to be contrary to the best interests of the Company. Any Fee paid for Ecommerce Services that relate to such information is non-refundable.<br />
            10.6      LIABILITY<br />
            10.6.1      The Ecommerce User will comply with all Applicable Laws, including the Disability Act 2005, the Equality Act 2004 (including all 9 grounds: the gender ground, the marital status ground, the family status ground, the sexual orientation ground, the religion ground, the age ground, the disability ground, the race ground, the traveller community ground) and the applicable data protection law including without limitation the EU Data Protection Directive 95/46/EC and the Electronic Communications Data Protection Directive 2002/58/EC, and from 25 May 2018 Regulation (EU) 2016/679 (“GDPR”), as implemented under applicable national law and as any of the foregoing may be amended, extended or re-enacted from time to time. The Ecommerce User shall indemnify the Company against any losses that arise in connection with any failure by the Ecommerce User, or any of its employees, contractors, agents or other authorised representatives, to comply with the obligations in this section 10.6.1.<br />
            10.6.2      If the Ecommerce User breaches any of its obligations in this Agreement, including if the Ecommerce User provides false or misleading information on the Website, or breaches any Applicable Laws, the Company may cease the Ecommerce Services immediately without prejudice to the Company's right to claim damages and other relief. Without prejudice to the indemnity provided in section 10.6.1, the Ecommerce User shall take all reasonable steps to ensure the reliability and trustworthiness of any employees or other of its authorised representatives who have access to personal data of any person that submits information to the Ecommerce User via the Website, or any registered user of the Website.<br />
            10.6.3      The Company provides the Ecommerce Services to enable the Ecommerce User to search for suitable potential employee profiles. The Company does not guarantee suitability or availability of potential employees and hereby excludes to the greatest extent permitted by law, and except as expressly set forth in this Agreement, all warranties, conditions, representations, statements, terms and provisions express or implied by statute, common law or otherwise are excluded to the greatest extent permitted by law, in relation to the Ecommerce Services.<br />
            10.6.4      Nothing in this Contract will be construed as creating a partnership, joint venture or relationship of employment between the parties for any purpose whatsoever.<br />
          </p>
          <h1 className='mt-5'>11. DATA PROTECTION OBLIGATIONS FOR ECOMMERCE CUSTOMERS</h1>
          <p>
            11.1      Database and Personal Data. In the use of the Products and Services, the Company provides and has access to, and the Customer will have access to the Recruitment Management System (also known as RMS) (the "Database”) which contains the following types of personal data relating to job applicant’s:- name; email address; CV information (such as applicant’s education, work experience, skills and interests); personal data contained in additional documents that an applicant attaches to a job applicant (“Personal Data”).<br />
            11.2      Purpose. The Personal Data is used by the Company to fulfil its obligations to data subjects who are job applicants and/or registered users of the Company and the Customer uses the Personal Data and Database to process the Personal Data for the purpose of hiring potential applicants and managing job applicants.<br />
            11.3      Joint Control. Each of the Company and the Customer jointly determine the purposes and means of processing of the Personal Data. Thus, the Parties are joint controllers with regard to such processing and the following clauses shall apply to their respective roles and responsibilities under Applicable Data Protection Law. To the extent either the Company or the Customer process personal data other than Personal Data via the Products and Services or otherwise, each of the Company and the Customer shall be separate data controllers in respect of such processing.<br />
            11.4      Joint Processing. The parties will handle all relevant processing of Personal Data jointly.<br />
            11.5      Responsibility. The parties will jointly be responsible for their processing of Personal Data in compliance with Applicable Data Protection Law. The Company and the Customer shall jointly ensure that the Personal Data is not otherwise processed without authorization and will restrict access to it on a need-to-know basis.<br />
            11.6      Data Protection Notices. The parties agree that it is each party’s responsibility under Applicable Data Protection Law to provide the information under Articles 13 and 14 GDPR regarding its data processing activities in an appropriate and legal manner. The parties shall mutually assist each other using reasonable means.<br />
            11.7      Essence of Joint Control Arrangement. The parties agree that it is each party’s responsibility to provide data subjects information on the essence of the parties’ data sharing agreement that is set forth within this Agreement.<br />
            11.8      Data Subject Rights. The parties acknowledge that relevant data subjects may have various rights with respect to their Personal Data (“Requests” or a “Request”). In particular, the relevant data subjects may have the right to Request access to and receive a copy of their Personal Data held by the Parties. Furthermore, the relevant data subjects may demand that the parties rectify, erase or restrict their Personal Data if these are incorrect or processed contrary to this Agreement or Applicable Data Protection Law. The relevant data subjects may potentially object, on grounds relating to their particular situation, at any time to processing of their Personal Data vis á vis the parties.<br />
            11.8.1      The parties agree that it is each party’s responsibility to answer a relevant data subject Request directed to it in an appropriate and legal manner. The parties shall mutually assist each other using reasonable means in case of relevant data subject Requests. As between the parties, the responsibilities are allocated as follows:
            Regarding the right of access (Art. 15 GDPR) and the right to data portability (Art. 20 GDPR) the Company is responsible regarding the data which the Company is able to access. The Customer is responsible regarding the Personal Data, which the Customer adds, which means the status of the relevant data subject’s application.
            The Company is responsible regarding the right to rectification (Art. 16 GDPR), the right to erasure (Art. 17 GDPR) and the right to restriction of processing (Art. 18 GDPR) and the right to object to processing (Article 21 GDPR).<br />
            11.8.2      Measures to fulfil the rights of the data subjects The parties acknowledge that data subjects may exercise their rights towards all parties
            All applications older than 18 months will automatically be deleted from the Database.
            Job applicants can request to have their Personal Data erased from the Database. Functionality is furnished to job applicants in their Jobseeker Account page to direct such requests to the Company. On receipt of such a request their jobseeker account and entire job application history will be deleted along with any applications stored in the Database. The Company will inform the Customer of the request for erasure in the event it relates to a job application that is less than 18 months old.
            Job applicants can request access to all of their personal data stored by the Company and to have this data provided in a machine readable format. Functionality is furnished to job applicants in their Jobseeker Account page to direct such requests to the Company.<br />
            11.9      Appropriate Technical and Organisational Measures. The Company is responsible to implement appropriate technical and organisational measures to ensure appropriate security for the Personal Data when held on the Database.<br />
            11.10      Records of Processing. If applicable, each party shall maintain a record of processing activities under its responsibility and the parties shall mutually provide each other with information necessary for maintaining a record of processing activities.<br />
            11.11      Automated Processing. Neither the Company nor the Customer is going to make any decisions based solely on automated processing.<br />
            11.12      Personal Data breach. It is each party’s responsibility to provide notification to the relevant supervisory data protection authority and – where applicable – to the affected data subjects in cases of a breach of security leading to the accidental or unlawful destruction, loss alteration, unauthorised disclosure of, or access to, Personal Data transmitted, stored or otherwise processed (“Personal Data Breach”) to the extent required by Art. 33 and 34 GDPR. The parties will collaborate in this regard as appropriate.<br />
            11.13      Data Protection Impact Assessment. The parties acknowledge that data protection impact assessments may be required in respect of their Joint Control of the Personal Data. Each party will carry out an assessment of potential high risks to the rights and freedoms of natural persons that may arise from the Party’s processing of Personal Data. If a data protection impact assessment becomes necessary, the parties shall collaborate in carrying out a data protection impact assessment regarding the joint data controlling processing of Personal Data. Each party remains responsible for the documentation of the data protection impact assessment and the consultation with its supervisory authority, as well as fulfilling potential further legal requirements.<br />
            11.14      Third Country Transfers. When transferring Personal Data to third countries, the parties ensure that they will take appropriate measures to secure the Personal Data appropriately in accordance with Data Protection Laws.<br />
            11.15      Change Management. Both parties shall, on an ongoing basis, observe changes regarding technical, legal and other concerns, which could affect data protection and data security, and shall accordingly, amend and update their technical, legal and other security practices as appropriate.<br />
            11.16      Liability and Indemnity. Subject to Clauses 10.6.3 of the terms and conditions, each party shall remain liable for its processing of Personal Data and any breach by it or its agents or representatives of Applicable Data Protection Law in respect of Personal Data and shall indemnify, keep fully and effectively indemnified and hold harmless the other party for any such breach.<br />
            11.17      Costs. Each party shall bear its own costs regarding this Agreement and compliance with the obligations set out in it.<br />
          </p>
          <h1 className='mt-5'>12 ECOMMERCE DEFINITIONS</h1>
          <p>
            In these Ecommerce Terms, the following terms have the following meaning:
            Ecommerce User
            any person or entity who submits a request for any of the Ecommerce Services on the Website;
            Fee
            the agreed once off payment made in advance by the Ecommerce User to the Company on the Website for the Ecommerce Services;
            Ecommerce Term
            the period of time for which the Company provides the Ecommerce Services to the Ecommerce User, being 30 days;
            Ecommerce Services
            in addition to the Website Services, the service provided by the Company to advertise the Ecommerce User’s contract details and recruitment requirement on the Website in accordance with the Content provided by the Ecommerce User, including the retention and storage of job applications received in respect of Ecommerce User vacancies in the Company’s database;
            Job Specification
            means information posted to the Website by an Ecommerce User or a Contract User, about employment, consultant or contractor vacancies on the Website, whether on behalf of that user, or, if a recruitment or consultancy, on behalf of a third party.
          </p>
          <h1 className='mt-5'>13 COPYRIGHT STATEMENT</h1>
          <p>
            Copyright is implied irrespective of whether a copyright symbol or a copyright statement is displayed. Content on the Swanted website can be downloaded for personal non-commercial use. Where use of other materials is desired the source must first approve and also be acknowledged.
            Permission to reproduce the Swanted copyright does not extend to any material on this site which may be the property of a third party. Authorisation to reproduce such material must be obtained from the copyright holders concerned.
          </p>
        </div>
      </Modal>
    </>
  )
});