import Logo from "../../assets/homePage/Section 3 - Image 3.png";
import { InputEmail } from "../../Components/Forms/InputEmail/InputEmail";
import { InputPassword } from "../../Components/Forms/InputPassword/InputPassword";
import closeButton from "../../assets/homePage/close-login.png";
import "../HomePage/styles.css";
import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo_swanted_purple.png";
import SwantedInitialVideo from "../../assets/initial-video/Swanted_compress.mp4";
import { Button } from "../../Components/NewButton/Button";
import { useAuth } from "../../hooks/Auth";
import { Modal } from "../../Components/NewModal/Modal";

const LoginModal = ({ open, handleClose }) => {
  const { signIn, loading } = useAuth();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = useCallback(
    async (data) => {
      try {
        setLoginError("");
        const { user } = await signIn({ data });
        navigate(
          user.roles.includes("jobSeeker") ? "/map" : "/manage-vacancies"
        );
      } catch (error) {
        if (error.message === "Invalid Email/Password combination") {
          setLoginError("Invalid Email/Password combination");
        }
      }
    },
    [navigate, signIn]
  );

  return (
    <Modal closeModal={handleClose} isOpen={open} maxWidth={1220} width={"90%"}>
      <div className=" flex  xl:gap-[80px] py-[4%] px-[1%]  xl:py-[4%] xl:px-[4%] items-center ">
        <div className="hidden xl:flex w-1/2">
          <img className="max-h-[616px]" src={Logo} alt="Image" />
        </div>
        <div className=" w-full xl:w-1/2 flex flex-col  flex-wrap">
          <div className=" whyte-bold text-[24px] xl:text-[40px] leading-[112%]  h-[45px] flex items-center gap-[10px] xl:gap-[120px] ">
            Welcome to Swanted
          </div>
          <div className=" whyte-medium mt-[15px]  text-[18px] xl:text-[28px] leading-[112%]  h-[45px] text-[#C5C5C5]">
            Let’s Get Started
          </div>
          <form onSubmit={handleSubmit(onSubmit, () => {})}>
            <div className=" xl:mt-[20px] xl:w-[550px] ">
              <div>
                <div className="  xl:mt-[20px] whyte-bold text-[16px] xl:text-[24px] text-[##1E1E1E]  ">
                  Email
                </div>
                <InputEmail
                  placeholder={"Ex. Swanted@gmail.com"}
                  {...register("email", {
                    required: true,
                    validate: (value) =>
                      /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(value) ||
                      "Invalid E-mail",
                  })}
                />
                {errors.email && (
                  <p className="text-red-500">{errors.email.message}</p>
                )}
              </div>

              <div className="xl:mt-[32px]">
                <div className="  mt-[20px] whyte-bold text-[16px] xl:text-[24px] text-[##1E1E1E]  ">
                  Password
                </div>
                <InputPassword
                  placeholder={"*************"}
                  {...register("password", {
                    required: true,
                  })}
                />
                {errors.password && (
                  <p className="text-red-500">{errors.password.message}</p>
                )}

                {loginError && (
                  <p className="text-red-500 mt-4">{loginError}</p>
                )}
              </div>
            </div>

            <div className="flex flex-col mt-6">
              <Link
                to="/reset-password"
                className="text-[#116AFF]  whyte-medium  text-[16px] xl:text-[20px] text-sm mb-4"
              >
                <a>Have you forgotten your password?</a>
              </Link>

              <div>
                <Button
                  type="submit"
                  loading={loading}
                  className="my-[15px] xl:my-[30px] w-full h-[54px] xl:h-[74px] bg-[#9E5BF6] rounded-[81px] text-white text-[20px] xl:text-[24px] flex justify-center items-center whyte-bold"
                >
                  Login
                </Button>
              </div>
              <span className="text-[#C5C5C5] text-[16px] xl:text-[20px] whyte-medium mb-4 flex justify-center">
                Don't have an account ?
              </span>
              <div className="flex flex-col xl:flex-row justify-between w-full ">
                <Link to="/register-jobseeker">
                  <span className="mt-[15px] whyte-medium text-[#116AFF]  font-medium text-[16px] xl:text-[20px]">
                    Create a jobseeker account
                  </span>
                </Link>
                <div className=" hidden xl:flex h-[42px]  border-r-[1px] border-[#C5C5C5]"></div>

                <Link to="/register-company">
                  <span className="mt-[15px] whyte-medium text-[#116AFF]  font-medium text-[16px] xl:text-[20px]">
                    Create a company account
                  </span>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
