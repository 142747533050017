export const faqQuestionsCandidates = [
  {
    "index": '0',
    "heading": "What is Swanted?",
    "answerHeader":"We are Swanted",
    "panel": "At Swanted, we revolutionise the way companies hire for the service industry by allowing jobseekers to replace boring cover letters with engaging video applications. This helps companies to evaluate jobseekers' communication skills and personalities before even picking up the phone for an interview. ",
    "panel1":"We also place the companies on our interactive map, making it easy for jobseekers to discover local open positions and apply easily.",
    "panel2":"Say hello to the future of hiring with Swanted."
  
  },
  {
    "index": '1',
    "heading": "Is it free to use?",
    "answerHeader":"Yes, use it for free!",
    "panel": "Swanted is 100% free for jobseekers. Companies can post their jobs on the map for free too. You don't need to provide a credit card to sign up. ",
    "panel1":"Join us today!"
  },
  {
    "index": '2',
    "heading": "What is a jobs map?",
    "answerHeader": "Our Job Map",
    "panel": "Geolocated jobs are open positions that you can see on our interactive map. Since we know that long commute times are a major turn off for jobseekers, Swanted displays all jobs close to you so you can decide if the location suits you or not. ",
    "panel1":"Hiring locally means that jobseekers can save up to 20 hours a month in commute times. This can also reduce turnover of staff by as much as 20%. Win-win! ",
    "panel2":"Simply open the map and see if your perfect job is just around the corner!"
  },
  {
    "index": '3',
    "heading": "What is an intro video?",
    "answerHeader":"What is an intro video?",
    "panel": "When a jobseeker creates a profile, they can upload a short intro video to talk about who they are and what they’re skilled at. This is an opportunity to shine and standout from the crowd by introducing yourself to potential employers. The profile and intro video can be directly searched in our database by companies who are looking for the perfect match. ", 
    "panel1":"Join Swanted today and get noticed!"
  },
  // {
  //   "index": '4',
  //   "heading": "What are cover videos?",
  //   "answerHeader":"What are cover videos?",
  //   "panel": "Say goodbye to typing boring and repetitive cover letters that don't help you (By the way, companies also hate reading your cover letters!). When you find a job that you are interested in, you have the option to send a short, personalised cover video directed to the company with your application that allows you to upsell your skills. Here you can talk about the company and tell them directly why you want to work with them."
  // },
  {
    "index": '4',
    "heading": "What are cover videos?",
    "answerHeader":"Send Cover Videos. Why?! Companies hate Cover Letters!",
    "panel": "…and we know you hate writing cover letters too.", 
    "panel1":"When you find a job you like, you can also add a cover video to each job application to tell the companies what you know about them and why you want to work for them. This can increase your chances of landing the job by as much as 70%.", 
    "panel2":"Join Swanted today and get noticed!"
  },
  {
    "index": '5',
    "heading": "What are the benefits of Swanted?",
    "answerHeader": "What are the benefits of Swanted?",
    "panel": "Be seen, get hired! Swanted allows you to complement your CV by adding a short intro video that you can use to give companies a good first impression of who you are, increasing your chances of landing a job by 70%. You can see and apply to local open positions in the service industry with our interactive job map. Getting a local job means shorter commute times saving up to 20 hours a month! Hiring managers can also contact you directly and offer you interviews if you are the right match. "
  },
  
  {
    "index": '6',
    "heading": " What’s a Jobseekers Database?",
    "answerHeader":" Hire 5x times faster.",
    "panel": "Companies don’t have to wait for applicants anymore. With a wide range of profiles available for review, you can easily find the perfect fit yourself. Many jobseekers choose to include an intro video on their profile, which you can use to evaluate them better. We will always show you the closest people to your business. ", 
    "panel1":" As a jobseeker, it's important to have your profile complete with an intro video and keep it up-to-date to increase your chances of ranking at the top of the database. But don't worry, we keep your personal information private by never disclosing your home address or exact location to anyone but you. Save time and money with our innovative jobseekers database."
  },
  {
    "index": '7',
    "heading": " Hire locally, help the environment.",
    "answerHeader":" Hire locally, help the environment.",
    "panel": "Hiring local staff is good for the environment. By reducing your staff’s commute times to work, you can help to reduce your company's carbon footprint. Hiring local talent keeps jobs and money in the area, creating a stronger and more sustainable local economy. It can also help to build a connection between your business and the community. ", 
    "panel1":" Join us today and start benefiting from the power of local hiring. "
  },
  {
    "index": '8',
    "heading": " Why Join Swanted?",
    "answerHeader":" Why Join Swanted?",
    "panel": "Swanted helps companies find and review qualified local jobseekers with our interactive database. Video applications allow for a more informed hiring decision, saving time and resources. Hiring local staff also has the added benefit of lower turnover, reduce carbon footprint and a stronger connection to the local community.", 
    "panel1":" As a jobseeker, Swanted's interactive map helps you discover local job opportunities. Finding a job in your area means saving up to 20 hours a month in commute times. The option to use video applications allows you to showcase your skills and communication abilities, helping you stand out to potential employers and getting hired 5x times faster. "
  }

 
]
