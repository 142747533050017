import React, { useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Navbar } from "../../Components/Navbar/NewNavbar";
import { Vacancy } from "../../Components/NewVacancy/Vacancy";
import { Footer } from "../../Components/Footer/Footer";
import { useQuery } from "@apollo/client";
import { GET_VACANCIES } from "./VacanciesList.services";
import { Button } from "../../Components/Button/Button";
import FiltersFormJobList from "../../Components/FiltersForm/NewFiltersFormJobList";
import { turnAnyInNull } from "../../utils/turnAnyInNull";
import { SelectActionModal } from "../../Components/SelectActionModal/SelectActionModal";

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 28,
//   height: 16,
//   padding: 0,
//   display: "flex",
//   "&:active": {
//     "& .MuiSwitch-thumb": {
//       width: 15,
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       transform: "translateX(9px)",
//     },
//   },
//   "& .MuiSwitch-switchBase": {
//     padding: 2,
//     "&.Mui-checked": {
//       transform: "translateX(12px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === "dark" ? "linear-gradient(180deg, #630DB1 0%, #116AFF 100%)" : "linear-gradient(180deg, #630DB1 0%, #116AFF 100%)",
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//     width: 12,
//     height: 12,
//     borderRadius: 6,
//     transition: theme.transitions.create(["width"], {
//       duration: 200,
//     }),
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor:
//       theme.palette.mode === "dark"
//         ? "rgba(255,255,255,.35)"
//         : "rgba(0,0,0,.25)",
//     boxSizing: "border-box",
//   },
// }));

export function VacanciesList() {
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  function toggle(value) {
    return !value;
  }
  const { data, loading, refetch } = useQuery(GET_VACANCIES);
  const [job, setJob] = useState(null);

  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  const handleSelectVacancy = useCallback((selectedJob) => {
    setJob(selectedJob);
    setIsSelectModalOpen((state) => !state);
  }, []);

  const filteredJobs = useMemo(() => {
    if (loading) {
      return [];
    }

    return data.getVacancyData.vacancies;
  }, [data, loading]);

  const handleChangeJobsView = useCallback(() => {
    navigate("/map");
  }, [navigate]);

  const onSubmit = useCallback(
    async (data) => {
      const { distance: _distance, ...filters } = turnAnyInNull(data);

      await refetch({
        filters,
      });
    },
    [refetch]
  );
  // const [checked, setChecked] = React.useState(true);

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <div className=" bg-gray-50 w-full h-full pt-[20px] ">
      <SelectActionModal
        job={job}
        isSelectModalOpen={isSelectModalOpen}
        setIsSelectModalOpen={setIsSelectModalOpen}
      />

      <Navbar />

      <div className="w-full min-h-[93vh] pt-10 xl:pt-0  bg-gray-50">
        <div className=" px-6 xl:px-12 mt-6 flex justify-between xl:flex-row flex-col">
          <div className="flex whyte-bold text-[24px] xl:text-[28px] mt-16 xl:mt-0 ">
            Jobs List
          </div>
          <div className="flex ">
            <div className="absolute flex justify-center  items-center whyte-bold text-[#546787] text-[16px] lg:text-[20px] left-[15%] lg:left-[70%] top-[10%] lg:top-[20%] z-[30] bg-[#FFFFFF] rounded-full w-[270px] lg:w-[388px] h-[45px] lg:h-[62px]  ">
              <span>List View</span>

              <input
                style={{ width: "0", height: "0", visibility: "hidden" }}
                type="checkbox"
                name="switch"
                id="switch"
                checked={checked}
                onChange={(e) => navigate("/map")}
              />
              <label
                id="Label"
                className=" w-[60px] h-[30px] xl:w-[80px] xl:h-[40px] block mx-[15px] relative "
                style={{
                  background:
                    "linear-gradient(180deg, #630DB1 0%, #116AFF 100%)",
                  borderRadius: "100px",
                  cursor: "pointer",
                  transition: "0.5s",
                  boxShadow: "0px 4px 39px rgba(124, 124, 124, 0.25)",
                }}
                htmlFor="switch"
              ></label>
              <span>Map View</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center md:items-start md:flex-row w-full h-full px-6 xl:px-12  my-4 xl:my-10">
          <aside className=" w-full md:w-80 md:h-screen flex flex-col justify-start md:mr-20 ">
            <FiltersFormJobList onSubmit={onSubmit} shouldShowDistance={false} />
            {/* 
            <Button className="mb-5" invert onClick={handleChangeJobsView}>
              See Job Map
            </Button> */}
          </aside>

          <div className="w-full h-full my-4">
            <ul className="space-y-2">
              <div className=" flex w-[100%] flex-wrap ">
                {filteredJobs.map((job) => (
                  <Vacancy
                    key={job._id}
                    job={job}
                    onClick={() => handleSelectVacancy(job)}
                  />
                ))}
              </div>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
