import React, { useRef, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from "react-router-dom";
import { validate } from 'uuid';

import logo from '../../assets/logo_swanted_purple.png';
import SwantedInitialVideo from '../../assets/initial-video/Swanted_compress.mp4';
import { InputPassword } from '../../Components/Forms/InputPassword/InputPassword';
import { Button } from '../../Components/Button/Button';
import { useMutation } from '@apollo/client';
import { VALIDATE_ACCOUNT } from './ValidateAccount.services';
import { useEffect } from 'react';

export const ValidateAccount = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [validateAccount, { loading }] = useMutation(VALIDATE_ACCOUNT)
  const [loginError, setLoginError] = useState('');
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  let passwordRef = useRef({});
  passwordRef.current = watch('password', '');

  useEffect(() => {
    if (code) {
      if (!validate(code)) {
        navigate('/');
      }
    }
  }, [code, navigate]);

  const onSubmit = useCallback(async () => {
    try {
      setLoginError('');
      await validateAccount({
        variables: {
          code,
        }
      });

      alert('You have successfully activated your account.');
    } catch (error) {
    } finally {
      navigate('/');
    }
  }, [navigate, code]);

  return (
    <>
      <div className="w-full min-h-screen flex">
        <div className="flex min-h-screen w-full">
          <div className="hidden md:block flex-auto w-32">
            <video
              controls={false}
              autoPlay
              muted
              loop
              playsInline
              alt="Map"
              className="h-full object-cover object-center rounded-tr-md rounded-br-md bg-gray-300"
            >
              <source src={SwantedInitialVideo} type="video/mp4" />
            </video>
          </div>

          <div className="flex flex-col items-center justify-center flex-1 w-full h-full overflow-y-auto overflow-x-hidden shadow-md">
            <div>
              <div>
                <img className="w-[250px] m-auto my-6" src={logo} alt="Workflow" />
                <p className="mt-2 text-center text-sm text-gray-600">See, Swipe, Hire. Simple!</p>
                
              </div>
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Activate Your Account</h2>
              <form className="flex flex-col mt-8 space-y-6 w-80" onSubmit={handleSubmit(onSubmit, () => {})}>
                <Button loading={loading} type="submit" className="mt-4 h-12" style={{width: '100%'}}>
                  Activate Account
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

