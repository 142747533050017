import { gql } from "@apollo/client";

export const MASS_IMPORT = gql`
  mutation massImport($file: FileInput!) {
    massImport(file: $file) {
      ok
    }
  }
`;

export const CLEAR_IMPORT = gql`
  mutation clearImport {
    clearImport {
      ok
    }
  }
`;
