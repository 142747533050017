import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";

import logo from '../../assets/logo_swanted_purple.png';
import SwantedInitialVideo from '../../assets/initial-video/Swanted_compress.mp4';
import { InputEmail } from '../../Components/Forms/InputEmail/InputEmail';
import { InputPassword } from '../../Components/Forms/InputPassword/InputPassword';
import { Button } from '../../Components/Button/Button';
import { useAuth } from '../../hooks/Auth';

export const Login = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = useCallback(async (data) => {
    try {
      setLoginError('');
      const { user } = await signIn({ data });

      navigate(
        user.roles.includes('jobSeeker') ? '/map' : '/manage-vacancies'
      );
    } catch (error) {
      if (error.message === 'Invalid Email/Password combination') {
        setLoginError('Invalid Email/Password combination')
      }
    }
  }, [navigate, signIn]);

  return (
    <>
      <div className="w-full min-h-screen flex">
        <div className="flex min-h-screen w-full">
          <div className="hidden md:block flex-auto w-32">
              <video
              controls={false}
              autoPlay
              muted
              loop
              playsInline
              alt="Map"
              className="h-full object-cover object-center rounded-tr-md rounded-br-md bg-gray-300"
            >
              <source src={SwantedInitialVideo} type="video/mp4" />
            </video>
          </div>

          <div className="flex flex-col items-center justify-center flex-1 w-full h-full overflow-y-auto overflow-x-hidden shadow-md">
            <div>
              
              <div>
                <img className="w-[250px] m-auto mt-16" src={logo} alt="Workflow" />
                <p className="mt-2 text-center text-sm text-gray-600">See, Swipe, Hire. Simple!</p>                
              </div>

              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in</h2>
              <form className="flex flex-col mt-8 space-y-6 w-96" onSubmit={handleSubmit(onSubmit, () => {})}>
                <InputEmail
                  placeholder={'Email'}
                  {...register('email', {
                    required: true,
                    validate: 
                      value => /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(value) || 'Invalid E-mail',
                  })}
                />
                {errors.email && <p className="text-red-500">{errors.email.message}</p>}

                <InputPassword
                  placeholder={'Password'}
                  {...register('password', {
                    required: true,
                  })}
                />
                {errors.password && <p className="text-red-500">{errors.password.message}</p>}

                {loginError && <p className="text-red-500 mt-4">{loginError}</p>}

                <Button type="submit" className="mt-4 h-12" style={{width: '100%'}}>
                  Sign In
                </Button>
              </form>

              <div className="flex flex-col mt-4">
                <Link to="/reset-password" className="text-blue-400 text-sm mb-4">
                  <a>
                    Have you forgotten your password?
                  </a>
                </Link>

                <span className="text-slate-400 text-sm mt-8 mb-4">Don't have an account ?</span>
                
                <Link to="/register-jobseeker">
                  <span className="mt-2 text-blue-400">Create a jobseeker account</span>
                </Link>

                <Link to="/register-company">
                  <span className="mt-2 text-blue-400">Create a company account</span>
                </Link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

