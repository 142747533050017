import { useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { UploadFile } from "../UploadFile/UploadFile";
import { Select } from "../Forms/Select/NewSelect";
import { InputText } from "../Forms/InputText/InputText";
import { Textarea } from "../Forms/Textarea/Textarea";
import { Button } from "../NewButton/Button";
import { InputDate } from "../Forms/InputDate/InputDate";
import { jobCategoryCandidate } from "../../utils/jobCategoryCandidate";
import { useCallback } from "react";
import { Modal } from "../Modal/Modal";
import { nanoid } from "nanoid";

export function JobExperienceForm({
  pushExperience,
  watch,
  setValue,
  control,
  register,
}) {
  let jobCategoryValue = useRef({});
  let currentlyWorkingValue = useRef({});
  let startDateValue = useRef({});
  let formValues = useRef({});

  formValues.current = watch(`experience`, {});
  jobCategoryValue.current = watch(`experience.jobCategory`, "");
  currentlyWorkingValue.current = watch(`experience.currentlyWorking`, "");
  startDateValue.current = watch(`experience.startDate`, "");

  const handleCheck = useCallback(() => {
    setValue("experience.endDate", "");
  }, [setValue]);

  const onSubmit = useCallback(
    (data) => {
      const experience = data || {};

      if (
        !experience.companyName ||
        (!experience.currentlyWorking && !experience.endDate) ||
        !experience.jobCategory ||
        !experience.jobTitle ||
        !experience.startDate
      ) {
        return;
      }

      let { category } = experience;

      if (category === "Other") {
        category = experience.otherJobCategory;
      }

      const newExperience = {
        id: nanoid(),
        ...experience,
        category,
      };

      pushExperience(newExperience);

      setValue("experience.companyName", "");
      setValue("experience.endDate", "");
      setValue("experience.jobCategory", "");
      setValue("experience.jobTitle", "");
      setValue("experience.startDate", "");
      setValue("experience.currentlyWorking", false);
      setValue("experience.jobDescription", "");
    },
    [pushExperience, setValue]
  );

  const handleSave = useCallback(() => {
    onSubmit(formValues.current);
  }, [onSubmit, formValues]);

  return (
    <div className="my-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="flex whyte-bold text-base xl:text-[24px]  text-[#344C70] ">
            Job Title*
          </div>
          <InputText
            style={{ height: "50px" }}
            placeholder=""
            className="mt-3 w-full"
            {...register(`experience.jobTitle`)}
          />
        </div>

        <div>
          <div className="flex whyte-bold text-base xl:text-[24px]  text-[#344C70] ">
            Company Name*
          </div>
          <InputText
            style={{ height: "50px" }}
            placeholder=""
            className="mt-2 w-full"
            {...register(`experience.companyName`)}
          />
        </div>

        <div>
          <div className="flex whyte-bold text-base xl:text-[24px]  text-[#344C70] ">
            Job Category*
          </div>
          <Select
            control={control}
            label=""
            className="mt-0 w-full"
            options={jobCategoryCandidate}
            {...register(`experience.jobCategory`)}
          />

          {jobCategoryValue.current === "Other" ? (
            <div className="mt-3">
              <div className="flex whyte-bold text-base xl:text-[24px]  text-[#344C70] ">
                Other Job Category*
              </div>
              <InputText
                style={{ height: "50px" }}
                autoComplete="off"
                placeholder=""
                className="mt-1"
                {...register("experience.otherJobCategory")}
              />
            </div>
          ) : null}
        </div>

        <div>
          <div className="flex whyte-bold text-base xl:text-[24px]  text-[#344C70] ">
            Start Date*
          </div>
          <InputDate
            style={{ height: "50px" }}
            type="month"
            className="mt-2 w-full"
            placeholder=""
            max={new Date().toLocaleDateString("en-ca")}
            {...register(`experience.startDate`)}
          />
        </div>

        <div>
          <div className="flex whyte-bold text-base xl:text-[24px]  text-[#344C70] ">
            {`End Date${currentlyWorkingValue.current ? "" : "*"}`}
          </div>
          <InputDate
            type="month"
            style={{ height: "50px" }}
            className="mt-2 w-full"
            min={startDateValue.current}
            disabled={!startDateValue.current || currentlyWorkingValue.current}
            {...register(`experience.endDate`, {
              required:
                !startDateValue.current || currentlyWorkingValue.current
                  ? false
                  : true,
            })}
          />
        </div>
      </div>

      <div className="mt-4 space-y-4">
        <div className="flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="currentlyWorking"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              {...register(`experience.currentlyWorking`, {
                onChange: handleCheck,
              })}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="currentlyWorking"
              className="font-medium text-gray-700"
            >
              I'm currently working here
            </label>
          </div>
        </div>
      </div>

      <br />
      <div>
        <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
          Short Description (Optional)
        </div>
        <Textarea
          label=""
          className="mt-3 w-full"
          rows={8}
          maxLength={10000}
          {...register(`experience.jobDescription`)}
        />
      </div>

      <Button
        className="px-6 mt-3 w-full xl:w-[320px] h-[52px] xl:h-[62px] rounded-full text-[20px] xl:text-[24px] whyte-bold bg-[#9E5BF6] "
        type="button"
        onClick={handleSave}
      >
        Add Experience
      </Button>
    </div>
  );
}
