import { gql } from "@apollo/client";

export const GET_MY_APPLICATIONS = gql`
  query applications {
    applications {
      _id
      photo
      video
      deleted
      cv
      vacancy {
        _id
        jobName
        period
        salary
        category
        contractType
        description
        date
        disponibleVacancies
        deleted
        hourPerWeek
        applicants
        company {
          _id
          name
          photo
        }
      }
    }
  }
`;
