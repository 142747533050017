import React, { useRef, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from "react-router-dom";
import { validate } from 'uuid';

import logo from '../../assets/logo_swanted_purple.png';
import SwantedInitialVideo from '../../assets/initial-video/Swanted_compress.mp4';
import { InputPassword } from '../../Components/Forms/InputPassword/InputPassword';
import { Button } from '../../Components/Button/Button';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from './ChangePassword.services';
import { useEffect } from 'react';

export const ChangePassword = () => {
  const { password_code: passwordCode } = useParams();
  const navigate = useNavigate();
  const [passwordChange, { loading }] = useMutation(CHANGE_PASSWORD)
  const [loginError, setLoginError] = useState('');
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  let passwordRef = useRef({});
  passwordRef.current = watch('password', '');

  useEffect(() => {
    if (passwordCode) {
      if (!validate(passwordCode)) {
        navigate('/');
      }
    }
  }, [passwordCode, navigate]);

  const onSubmit = useCallback(async ({ password }) => {
    try {
      setLoginError('');
      await passwordChange({
        variables: {
          password,
          passwordCode,
        }
      });

      alert('You have successfully changed your password.');
      navigate('/');
    } catch (error) {
      
    } finally {
    }
  }, [navigate, passwordChange, passwordCode]);

  return (
    <>
      <div className="w-full min-h-screen flex">
        <div className="flex min-h-screen w-full">
          <div className="hidden md:block flex-auto w-32">
            <video
              controls={false}
              autoPlay
              muted
              loop
              playsInline
              alt="Map"
              className="h-full object-cover object-center rounded-tr-md rounded-br-md bg-gray-300"
            >
              <source src={SwantedInitialVideo} type="video/mp4" />
            </video>
          </div>

          <div className="flex flex-col items-center justify-center flex-1 w-full h-full overflow-y-auto overflow-x-hidden shadow-md">
            <div>
              <div>
                <img className="w-[250px] m-auto my-6" src={logo} alt="Workflow" />
                <p className="mt-2 text-center text-sm text-gray-600">See, Swipe, Hire. Simple!</p>
                
              </div>
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Change your password</h2>
              <form className="flex flex-col mt-8 space-y-6 w-80" onSubmit={handleSubmit(onSubmit, () => {})}>
                <InputPassword
                  placeholder={'New Password'}
                  {...register('password', {
                    required: true,
                  })}
                />
                {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                <InputPassword
                  placeholder={'Confirm New Password'}
                  {...register('password_confirmation', {
                    required: true,
                    validate: 
                      value => value => value === passwordRef.current || "Passwords doesn't match",
                  })}
                />
                {errors.email && <p className="text-red-500">{errors.email.message}</p>}

                <Button loading={loading} type="submit" className="mt-4 h-12" style={{width: '100%'}}>
                  Change Password
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

