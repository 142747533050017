import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_PAYMENT_ORDER } from '../../services/Payment';
import { Button } from "../NewButton/Button";

export const PaymentButtons = ({ user }) => {
  const [createPaymentOrder, { data, loading }] = useMutation(CREATE_PAYMENT_ORDER);
  const [errorMessage, setErrorMessage] = useState(null);
  const { _id, company } = user;

  console.log('data', data);

  useEffect(() => {
    data && window.open(data?.createPaymentOrder?.url, "_blank"); // abre a nova aba com a URL
  }, [data?.createPaymentOrder?.url])

  const handleCheckout = async ({ premiumType }) => {

    try {
      await createPaymentOrder({
        variables: { 
          userId: company._id,
          userCompanyName: company.name,
          premiumType: premiumType
        }
      });

    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      {/* {errorMessage && <p>{errorMessage}</p>} */}

      <div className="flex flex-col">
        
        <Button
          type="submit" 
          className="flex items-center justify-center text-center w-100 rounded-lg py-2 px-3 duration-150 transition bg-[#9E5BF6] text-slate-100 hover:bg-primary-800 mt-4 rounded-full whyte-bold text-[18px] xl:text-[22px]"
          onClick={() => handleCheckout({ premiumType: "twoMonths" })}
          loading={loading}
        >Trial Swanted now for €100 for 2 months</Button>

        <Button
          type="submit" 
          className="flex items-center justify-center text-center w-100 rounded-lg py-2 px-3 duration-150 transition bg-[#9E5BF6] text-slate-100 hover:bg-primary-800 mt-4 rounded-full whyte-bold text-[18px] xl:text-[22px]"
          onClick={() => handleCheckout({ premiumType: "oneYear" })}
          loading={loading}
        >Buy a yearly subscription for €395</Button>

        <div className="mt-3">*See our T&Cs for breakdown of cost and access.</div>

      </div>  

    </>
  );
};
