import React, { useState, useMemo, useCallback } from "react";
import { useParams } from "react-router";
import { FiMapPin } from "react-icons/fi";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { nanoid } from "nanoid";
import { getDifferenceInNaturalTimeFromToday } from "../../../utils/getDifferenceInNaturalTime";
import { userLocationPin } from "../../../utils/map/userLocationPin";
import { Navbar } from "../../../Components/Navbar/NewNavbar";
import { LoadingScreen } from "../../../Components/LoadingScreen/LoadingScreen";
import { Vacancy } from "../../../Components/Vacancy/Vacancy";
import { ModalMessage } from "../../../Components/ModalMessage/ModalMessage";
import { Footer } from "../../../Components/Footer/Footer";
import { useQuery } from "@apollo/client";
import { GET_COMPANY_PROFILE } from "./CompanyProfile.services";
import { socialMedia } from "../../../staticData/socialMedias";
import { SelectActionModal } from "../../../Components/SelectActionModal/SelectActionModal";
import tiktokIcon from "../../../assets/icons/tik-tok-icon.png";
import InstaIcon from "../../../assets/icons/instagram-icon.png";
import webIcon from "../../../assets/icons/globe.png";

const ACCESS_TOKEN =
  "AdI1iHl8wEIi49YNXaZo3DkWIPliLlEFoesmCFuhDwdNT3ztuLyUJL3fnvIXUJ19";

export function CompanyProfile() {
  const { id } = useParams();
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [focusedJob, setFocusedJob] = useState(null);
  const { data, loading } = useQuery(GET_COMPANY_PROFILE, {
    variables: { id },
  });

  const company = useMemo(() => {
    if (loading) {
      return null;
    }

    const companyData = {
      ...data.company,
      jobs: data.company.jobs?.map((job) => {
        return {
          ...job,
          company: data.company,
        };
      }),
    };

    return companyData;
  }, [data, loading]);

  const [job, setJob] = useState(null);

  const handleSelectVacancy = useCallback((selectedJob) => {
    setJob(selectedJob);
    setIsSelectModalOpen((state) => !state);
  }, []);

  const handleOpenModalMessage = useCallback((job) => {
    setFocusedJob(job);
    setIsSelectModalOpen(true);
  }, []);

  if (loading || !company) {
    return (
      <>
        <LoadingScreen />
      </>
    );
  }

  const handleDate = (d) => {
    const date = new Date(Number(d));

    return getDifferenceInNaturalTimeFromToday(date);
  };

  return (
    <div className="bg-gray-50 w-full h-full pt-[20px] ">
      {focusedJob && (
        <SelectActionModal
          job={focusedJob}
          onClose={() => setFocusedJob(null)}
          isSelectModalOpen={isSelectModalOpen}
          setIsSelectModalOpen={setIsSelectModalOpen}
        />
      )}
      <SelectActionModal
        job={job}
        isSelectModalOpen={isSelectModalOpen}
        setIsSelectModalOpen={setIsSelectModalOpen}
      />

      <Navbar />
      <div className=" flex justify-center lg:flex-row flex-col mt-20 xl:mt-16  my-10 gap-[20px] w-full px-[6%] ">
        <div className=" w-full lg:w-1/2 flex flex-col gap-[20px]">
          <div className="w-full border-[1px] border-[#D3D3D3]  rounded-[20px]  bg-white  p-10 ">
            <div>
              <div className="flex gap-4 ">
                <div className="w-fit ">
                  <img
                    className="rounded-tl-3xl rounded-tr-3xl rounded-bl-2xl rounded-br-2xl w-full h-40 object-cover object-center"
                    src={company.photo}
                    alt={company.name}
                  />
                </div>
                <div className="flex flex-col ">
                  <div className="mt-3 whyte-bold text-[32px] ">
                    {company.name}
                  </div>
                  <div className="whyte-bold text-[20px] mb-2 text-[#A3A3A3] ">
                    {company.address}
                  </div>
                  <div className="flex gap-4 items-center">
                    {/* <a href={socialMedia.url + company.socialMedias[index].url} target='_blank' rel='noreferrer noopener'> */}
                    {company?.socialMedias[0]?.url ? (
                      <a
                        href={
                          socialMedia[0].url + company?.socialMedias[0]?.url
                        }
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          src={InstaIcon}
                          alt={company?.socialMedias[0]?.url}
                        />
                      </a>
                    ) : (
                      <></>
                    )}

                    {company?.socialMedias[1]?.url ? (
                      <a
                        href={
                          socialMedia[1].url + company?.socialMedias[1]?.url
                        }
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          src={tiktokIcon}
                          alt={company?.socialMedias[1]?.url}
                        />
                      </a>
                    ) : (
                      <></>
                    )}

                    {company?.website ? (
                      <a
                        href={company?.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >                       
                        <img src={webIcon} alt={company?.website} />
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full border-[1px] border-[#D3D3D3]  rounded-[20px]  bg-white  p-10 ">
            <div className="flex flex-col">
              <div className="whyte-bold text-[24px] ">About the Company</div>
              <div className="flex  gap-[40px] mt-6 ">
                <div className="flex flex-col text-[#546787] text-[20px] whyte-medium gap-[10px] ">
                  {/* {company?.manager ? <div>Manager type</div> : <></>} */}
                  {company?.companyCategory ? (
                    <div>Company category </div>
                  ) : (
                    <></>
                  )}
                  {company?.numberOfEmployees ? (
                    <div>Number of employees</div>
                  ) : (
                    <></>
                  )}
                  {/* {company?.phone ? <div>Phone</div> : <></>} */}
                </div>

                <div className="flex flex-col text-[#546787] text-[20px] whyte-medium gap-[10px] ">
                  {/* <div>{company?.manager ? company?.manager : <></>}</div> */}
                  <div>
                    {company?.companyCategory ? (
                      company?.companyCategory
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    {company?.numberOfEmployees ? (
                      company?.numberOfEmployees
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div>
                    <a
                      href={`tel:${company?.countryCode?.split(" ")[0]}${
                        company?.phone
                      }`}
                      className=""
                    >
                      {company?.countryCode?.split(" ")[0]}
                      {company?.phone}
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full border-[1px] border-[#D3D3D3]  rounded-[20px]  bg-white  p-10 ">
            <div className="flex flex-col">
              <div className="whyte-bold text-[24px] ">Company Description</div>
              <div className="gotham text-[20px] mt-4 ">{company?.companyDescription}</div>
            </div>
          </div>

          <div className="w-full border-[1px] border-[#D3D3D3]  rounded-[20px]   p-10 ">
            <div className="flex flex-col">
              <div className="whyte-bold text-[24px] ">Company Address</div>
              <div className="whyte-bold mt-4 text-[18px] text-[#A3A3A3] ">
                {company.address}
              </div>
              <div className="h-[332px] w-[100%] hidden block md:flex mt-4 ">
                <MapContainer
                  center={company.latlon.map(Number)}
                  zoom={15}
                  style={{ width: "100%", height: "100%", zIndex: 1 }}
                >
                  <TileLayer
                    url={`https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}.png?access-token=${ACCESS_TOKEN}`}
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                    maxZoom={20}
                    attribution={
                      '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank" class="jawg-attrib">&copy; <b>Jawg</b>Maps</a> | <a href="https://www.openstreetmap.org/copyright" title="OpenStreetMap is open data licensed under ODbL" target="_blank" class="osm-attrib">&copy; OSM contributors</a>'
                    }
                  />

                  <Marker
                    position={company.latlon.map(Number)}
                    icon={userLocationPin}
                  />
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2  p-10 h-fit bg-white rounded-[20px] border-[1px] border-[#D3D3D3] ">
          <div className="whyte-bold text-[24px] mb-5 ">Open Jobs</div>
          {company.jobs.map((job) =>
            job.deleted === true ? null : (
              <>
                <div
                  onClick={() => handleSelectVacancy(job)}
                  key={job._id}
                  className=" cursor-pointer flex  my-5 gap-5   "
                >
                  <div>
                    <img
                      className=" rounded-full w-[100px] "
                      src={job.company.photo}
                      alt={job.company.name}
                    />
                  </div>
                  <div className="flex flex-col gap-0">
                    <div className="whyte-bold text-[24px] hover:scale-105  transition-all duration-500 ">
                      {job.jobName}
                    </div>
                    <div className="whyte-bold text-[18px]  text-[#A3A3A3] only:">
                      {job.company.name} - {job.period}
                    </div>
                    <div className="text-[#2E9367] text-[18px] ">
                      {handleDate(job?.date)}
                    </div>
                  </div>
                </div>
                <hr className=" " />
              </>
            )
          )}
        </div>
      </div>

      <Footer className="z-[5]" />
    </div>
  );
}
