import { useState, useEffect } from 'react';
import { useMemo } from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import { MdLink } from 'react-icons/md';
import { formatBytes } from '../../utils/formatBytes';

import { Container, FileInfo, Preview } from './styles';

export const FileListItem = ({ file }) => {
  const [fileSrc, setFileSrc] = useState('#');

  useEffect(() => {
    setFileSrc(
      URL.createObjectURL(file)
    )
  }, [file]);
  
  function renderFile() {
    if (file.type.startsWith('image')) {
      return <img src={fileSrc} alt={file.name} className="w-20 h-w-20 mr-3 rounded-md object-center object-cover" />
    }  else if (file.type.startsWith('video')) {
      return <video controls={false} src={fileSrc} className="w-20 h-w-20 mr-3 rounded-md object-center object-cover" />
    } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return <div className="w-20 h-w-20 bg-slate-500 mr-3 rounded-md overflow-hidden" />      
    }  else {
      return <embed src={fileSrc} className="w-20 h-w-20 bg-slate-300 mr-3 rounded-md overflow-hidden" />
    }
  }

  return (
    <Container>
    <li>
      <FileInfo>
        {renderFile()}

        <div>
          <strong>{file.name}</strong>
          <span>{formatBytes(file.size)}</span>
        </div>
      </FileInfo>

      <div>
      </div>
    </li>
  </Container>
  )
}