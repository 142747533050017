import React, { useState } from "react";
import { Link } from "react-router-dom";

import { JobSeekerForm } from "../JobSeekerForm/JobSeekerForm";
import { CompanyForm } from "../company/CompanyForm/CompanyForm";
import logo from "../../assets/logo_swanted_purple.png";

export function Register({ isJobSeekerForm }) {
  const [sideImage, setSideImage] = useState(null);

  return (
    <>
      <div className="w-full min-h-screen  ">
        {isJobSeekerForm ? (
          <JobSeekerForm setSideImage={setSideImage} />
        ) : (
          <CompanyForm setSideImage={setSideImage} />
        )}
      </div>
    </>
  );
}