import { gql } from "@apollo/client";

export const GET_REPORTED_VACANCIES = gql`
  query reported_vacancies {
    reported_vacancies {
      _id
      jobName
      period
      salary
      category
      contractType
      date
      disponibleVacancies
      isSalaryNegotiable
      description
      hourPerWeek
      applicants
      deleted
      usersThatReported {
        reason
        user {
          jobSeeker {
            name
            surname
            photo
          }
        }
      }
      company {
        _id
        name
        photo
      }
    }
  }
`;
