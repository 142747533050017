import React, { useMemo, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { CardVideos } from "../../../Components/CardVideos/CardVideos";
import { Navbar } from "../../../Components/Navbar/Navbar";
import { Footer } from "../../../Components/Footer/Footer";
import { JobSeekerModal } from '../../../Components/JobSeekerModal/JobSeekerModal';
import { GET_JOBSEEKERS } from './ManageJobseekersReports.services';
import { LoadingScreen } from '../../../Components/LoadingScreen/LoadingScreen';
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Select } from '../../../Components/Forms/Select/Select';
import { useForm } from 'react-hook-form';
import { Button } from '../../../Components/Button/Button';
import { turnAnyInNull } from '../../../utils/turnAnyInNull';
import { jobCategoryCandidate } from '../../../utils/jobCategoryCandidate';

const distances = [
  { value: 'Any', label: 'Any' },
  { value: 1, label: '1 km' },
  { value: 2, label: '2 km' },
  { value: 5, label: '5 km' },
  { value: 10, label: '10 km' },
  { value: 15, label: '15 km' },
  { value: 20, label: '20 km' },
];

const coverVideoOptions = [
  { value: 'Any', label: 'Any' },
  { value: true, label: 'Only with Cover Video' },
];

const availabilities = [
  { value: 'Any', label: 'Any' },
  { value: 'morning', label: 'Morning' },
  { value: 'afternoon', label: 'Afternoon' },
  { value: 'night', label: 'Night' },
  { value: 'weekends', label: 'Weekends' },
]

const parsedJobCategories = [
  { value: 'Any', label: 'Any' },
  ...jobCategoryCandidate.filter(item => item !== ''),
]

export const ManageJobseekersReports = () => {
  const [parent] = useAutoAnimate();
  const { handleSubmit, register, control } = useForm();
  const { data, loading, refetch } = useQuery(GET_JOBSEEKERS, { variables: { filters: {} } });
  const [modalJobSeeker, setModalJobSeeker] = useState(null);

  const jobSeekers = useMemo(() => {
    if (loading) {
      return [];
    }

    return data.reportedJobSeekers;
  }, [data]);

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <>
      {modalJobSeeker && (
        <JobSeekerModal notCompany setModalJobSeeker={setModalJobSeeker} modalJobSeeker={modalJobSeeker} />
      )}
      <Navbar />

      <div className="bg-gray-50 pt-16 flex min-h-[93vh]">
        <div className="flex flex-col items-center w-full">
          <h1 className="font-bold text-lg my-5">JobSeekers</h1>
          {jobSeekers.length === 0 && (
            <h6>No jobseekers were reported yet.</h6>
          )}

          <div className="flex w-full flex-col md:flex-row md:p-0 md:px-16">
            <div className="flex flex-col items-center mb-16 md:mr-16 md:mt-0 px-6 md:px-0">
            </div>


            <ul
              className="list-none grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 w-screen"
              ref={parent}
            >
              {jobSeekers.map(jobSeeker => (
                <React.Fragment key={jobSeeker._id}>
                  <CardVideos
                    onClick={() => setModalJobSeeker({ ...jobSeeker, isFromDatabase: true })}
                    jobSeeker={jobSeeker}
                  />
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Footer className="mt-5" />
    </>
  )
}
