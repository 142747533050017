import React, { useState, useCallback } from "react";

import { CompanyLogin } from "./Step/CompanyLogin";
import { CompanyForm as StepCompanyForm } from "./Step/CompanyForm";
import { NewNavbar } from "../../../Components/NewNavbar/NewNavbar";

export function CompanyForm({ setSideImage }) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const handleUpdateFormData = useCallback((data) => {
    setFormData((state) => ({
      ...state,
      ...data,
    }));
  }, []);

  const handleNextStep = useCallback(() => {
    setStep((state) => state + 1);
  }, []);

  const handleStepBack = useCallback(() => {
    setStep((state) => state - 1);
  }, []);

  return (
    <>
      <div className=" lg:py-10 w-full h-full ">
        <nav className="flex justify-center">
          <NewNavbar />
        </nav>
        <div className=" px-[6%] mt-[60px] flex w-full flex-col xl:flex-row gap-[30px] xl:gap-[60px] ">
        <div className=" min-w-[321px] h-fit border-[1px] border-[#C5C5C5] rounded-[39px] px-[20px] py-[50px] ">
          <div className=" text-[#630DB1] text-[20px] xl:text-[24px] whyte">2 Steps</div>
          <div className=" text-[24px] xl:text-[32px] whyte-bold mt-[12px] mb-[30px] ">
            Create Account
          </div>
          <ul className="flex flex-col  gap-[20px] xl:gap-[30px]   ">
            <li
              className={` ${
                step === 1
                  ? " bg-[#9E5BF6] text-white text-[18px] xl:text-[20px]    rounded-[14px]  pt-[10px] pb-[8px]  "
                  : ""
              }  transition-all px-[20px]  text-[20px] xl:text-[24px] whyte`}
            >
              Login
            </li>
            <li
              className={` ${
                step === 2
                  ? " bg-[#9E5BF6] text-white text-[18px] xl:text-[20px]    rounded-[14px]  pt-[10px] pb-[8px]  "
                  : ""
              } transition-all px-[20px]  text-[20px] xl:text-[24px] whyte`}
            >
              General Information
            </li>
            
          </ul>
        </div>


        {step === 1 && (
          <CompanyLogin
            formData={formData}
            handleUpdateFormData={handleUpdateFormData}
            setSideImage={setSideImage}
            handleNextStep={handleNextStep}
          />
        )}
        {step === 2 && (
          <StepCompanyForm
            setSideImage={setSideImage}
            formData={formData}
            stepBack={handleStepBack}
          />
        )}
        </div>
      </div>
    </>
  );
}
