import { gql } from "@apollo/client";

export const GET_COMPANY_VACANCIES = gql`
  query company_vacancies($shouldQueryDeleted: Boolean) {
    company_vacancies(shouldQueryDeleted: $shouldQueryDeleted) {
      _id
      jobName
      period
      salary
      category
      contractType
      date
      disponibleVacancies
      isSalaryNegotiable
      description
      hourPerWeek
      applicants
      deleted
      company {
        _id
        name
        photo
      }
    }
  }
`;
