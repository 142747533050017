import React, { useState } from "react";
import { Dropzone } from "../Dropzone/NewDropzone";

export const UploadFile = ({
  uploadMessage,
  acceptedFile,
  type,
  mbMaxSize,
  onDrop = () => {},
  children
}) => {
  return (
    <div>
      <Dropzone
        type={type}
        uploadMessage={uploadMessage}
        onDrop={onDrop}
        mbMaxSize={mbMaxSize}
        acceptedFile={acceptedFile}
      >
        {children}
      </Dropzone>

    </div>
  );
};
