import { useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { UploadFile } from "../UploadFile/UploadFile";
import { Select } from "../Forms/NewSelect/Select";
import { InputText } from "../Forms/NewInputText/InputText";
import { Textarea } from "../Forms/Textarea/Textarea";
import { Button } from "../Button/Button";
import { InputDate } from "../Forms/NewInputDate/InputDate";
import { jobCategoryCandidate } from "../../utils/jobCategoryCandidate";
import { useCallback } from "react";
import { Modal } from "../Modal/Modal";
import { nanoid } from "nanoid";

export function JobExperienceForm({
  pushExperience,
  watch,
  setValue,
  control,
  register,
}) {
  let jobCategoryValue = useRef({});
  let currentlyWorkingValue = useRef({});
  let startDateValue = useRef({});
  let formValues = useRef({});

  formValues.current = watch(`experience`, {});
  jobCategoryValue.current = watch(`experience.jobCategory`, "");
  currentlyWorkingValue.current = watch(`experience.currentlyWorking`, "");
  startDateValue.current = watch(`experience.startDate`, "");

  const handleCheck = useCallback(() => {
    setValue("experience.endDate", "");
  }, [setValue]);

  const onSubmit = useCallback(
    (data) => {
      const experience = data || {};

      if (
        !experience.companyName ||
        (!experience.currentlyWorking && !experience.endDate) ||
        !experience.jobCategory ||
        !experience.jobTitle ||
        !experience.startDate
      ) {
        return;
      }

      let { category } = experience;

      if (category === "Other") {
        category = experience.otherJobCategory;
      }

      const newExperience = {
        id: nanoid(),
        ...experience,
        category,
      };

      pushExperience(newExperience);

      setValue("experience.companyName", "");
      setValue("experience.endDate", "");
      setValue("experience.jobCategory", "");
      setValue("experience.jobTitle", "");
      setValue("experience.startDate", "");
      setValue("experience.currentlyWorking", false);
      setValue("experience.jobDescription", "");
    },
    [pushExperience, setValue]
  );

  const handleSave = useCallback(() => {
    onSubmit(formValues.current);
  }, [onSubmit, formValues]);

  return (
    <div className="p-6 border-[1px] border-[#D3D3D3] rounded-[25px] ">
      <div className=" w-full xl:w-[90%]">
        <div className=" ">
          <div className=" my-[10px]  xl:my-[20px] whyte-bold  text-[24px] xl:text-[32px] text-[#1E1E1E] ">
            Job Title*
          </div>
          <InputText
            placeholder="Job Title .."
            className=" w-full"
            {...register(`experience.jobTitle`)}
          />
        </div>

        <div className=" xl:mt-[20px] flex flex-col xl:flex-row  xl:gap-[20px]">
          <div className="  mt-[10px] xl:mt-0 w-full xl:w-1/2">
            <div className="  my-[10px] xl:my-[20px] whyte-bold text-[24px] xl:text-[32px] text-[#1E1E1E] ">
              Company Name*
            </div>
            <InputText
              placeholder="Company Name"
              className="mt-3 w-full"
              {...register(`experience.companyName`)}
            />
          </div>
          <div className=" mt-[10px] xl:mt-0  w-full xl:w-1/2">
            <div className=" my-[10px]  xl:my-[12.5px] whyte-bold text-[24px] xl:text-[32px] text-[#1E1E1E] ">
              Job Category*
            </div>
            <Select
              control={control}
              className=" w-full"
              options={jobCategoryCandidate}
              {...register(`experience.jobCategory`)}
            />

            {jobCategoryValue.current === "Other" ? (
              <InputText
                autoComplete="off"
                placeholder="Other Job Category*"
                className="mt-3"
                {...register("experience.otherJobCategory")}
              />
            ) : null}
          </div>
        </div>

        <div className="mt-[20px] xl:mt-[30px] flex flex-col xl:flex-row xl:gap-[30px] ">
          <div className=" mt-[10px] xl:mt-0 w-full xl:w-1/2 ">
            <div className="  whyte-bold text-[24px] xl:text-[32px] text-[#1E1E1E] ">
              Start Date*
            </div>
            <InputDate
              type="month"
              className="mt-3 w-full"
              placeholder=""
              max={new Date().toLocaleDateString("en-ca")}
              {...register(`experience.startDate`)}
            />
          </div>
          <div className=" mt-[10px] xl:mt-0 w-full xl:w-1/2 ">
            <div className="  whyte-bold text-[24px] xl:text-[32px] text-[#1E1E1E] ">
              End Date*
            </div>
            <InputDate
              type="month"
              className="mt-3 w-full"
              min={startDateValue.current}
              disabled={
                !startDateValue.current || currentlyWorkingValue.current
              }
              // placeholder={`${
              //   currentlyWorkingValue.current ? "" : ""
              // }`}
              {...register(`experience.endDate`, {
                required:
                  !startDateValue.current || currentlyWorkingValue.current
                    ? false
                    : true,
              })}
            />
          </div>
        </div>
      </div>

      <div className="mt-4 space-y-4">
        <div className="flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="currentlyWorking"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              {...register(`experience.currentlyWorking`, {
                onChange: handleCheck,
              })}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="currentlyWorking"
              className="font-medium text-gray-700"
            >
              I'm currently working here
            </label>
          </div>
        </div>
      </div>

      <br />
      <div>
        <div className="  whyte-bold text-[24px] xl:text-[32px] text-[#1E1E1E] ">
          Short Description (Optional)
        </div>
        <Textarea
          label=""
          className="mt-3 w-full"
          rows={8}
          maxLength={10000}
          {...register(`experience.jobDescription`)}
        />
      </div>

      <button
        className="mt-4   text-[20px] xl:text-[28px] whyte-bold text-[#116AFF]"
        type="button"
        onClick={handleSave}
      >
        +Add Experience
      </button>
    </div>
  );
}
