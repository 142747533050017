import styled, { css } from "styled-components";

export const ArrowContainer = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;

  ${props =>
    props.arrowType === "prev"
      ? css`
          left: -38px;
          border-radius: 16px 0 0 16px;
        `
      : css`
          right: -38px;
          border-radius: 0 16px 16px 0;
        `}
  z-index: 99;
`;
