import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import next from "../../../assets/homePage/next.png";
import { InputText } from "../../../Components/Forms/NewInputText/InputText";
import { InputPhone } from "../../../Components/Forms/NewInputPhone/InputPhone";
import { InputEmail } from "../../../Components/Forms/NewInputEmail/InputEmail";
import { InputPassword } from "../../../Components/Forms/NewInputPassword/InputPassword";
import { Select } from "../../../Components/Forms/NewSelect/Select";
import { Button } from "../../../Components/Button/Button";
import CountryCodes from "../../../staticData/NewCountryCodes.json";
import { ErrorMessage } from "@hookform/error-message";
import "../../../Pages/HomePage/styles.css";

const countryCodes = CountryCodes.map(
  (country) => `${country.flag} ${country.dial_code} (${country.name})`
);

const mailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function Login({ formData, onSubmitStep }) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: formData });
  let passwordRef = useRef({});
  passwordRef.current = watch("password", formData?.password || "");

  return (
    <form
      className="flex flex-col xl:flex-row gap-[10%]  w-full"
      onSubmit={handleSubmit(onSubmitStep)}
    >
      <div className="flex flex-col w-full xl:w-[90%] ">
        <div className=" whyte text-[16px] xl:text-[24px] text-[#A3A3A3] ">
          Let’s get to know your basic information
        </div>

        <div className="flex flex-col xl:flex-row xl:gap-[30px]">
          <div className=" w-full xl:w-1/2">
            <div className=" my-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
              First name*
            </div>

            <InputText
              placeholder={"First name*"}
              className="mt-3"
              {...register("firstName", { required: "input name" })}
            />
            <ErrorMessage
              name="firstName"
              errors={errors}
              render={({ message }) => (
                <p className="text-red-500">{message}</p>
              )}
            />
          </div>

          <div className="w-full xl:w-1/2">
            <div className=" my-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
              Surname*
            </div>
            <InputText
              placeholder={"Surname"}
              className="mt-3"
              {...register("surname", { required: "input surname" })}
            />
            <ErrorMessage
              name="surname"
              errors={errors}
              render={({ message }) => (
                <p className="text-red-500">{message}</p>
              )}
            />
          </div>
        </div>

        <div>
          <div className="my-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
            Email*
          </div>

          <InputEmail
            placeholder={"example@email.com"}
            {...register("email", {
              required: "input email",
              validate: (value) => mailRegex.test(value) || "Invalid E-mail",
            })}
          />
          <ErrorMessage
            name="email"
            errors={errors}
            render={({ message }) => <p className="text-red-500">{message}</p>}
          />
          {/* {errors.email && <p className="text-red-500">{errors.email.message}</p>} */}
        </div>

        <div className="grid grid-col-1 xl:grid-cols-2 xl:gap-4">
          <div>
            <div className=" my-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
              Password*
            </div>

            <InputPassword
              placeholder={"******"}
              className="my-3"
              {...register("password", { required: "input password" })}
            />
            <ErrorMessage
              name="password"
              errors={errors}
              render={({ message }) => (
                <p className="text-red-500">{message}</p>
              )}
            />
            {/* {errors.password && <p className="text-red-500">{errors.password.message}</p>} */}
          </div>

          <div>
            <div className=" my-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
              Repeat Password*
            </div>
            <InputPassword
              placeholder={"******"}
              className="my-3"
              {...register("password_repeat", {
                required: "Passwords doesn't match",
                validate: (value) =>
                  value === passwordRef.current || "Passwords doesn't match",
              })}
            />
            <ErrorMessage
              name="password_repeat"
              errors={errors}
              render={({ message }) => (
                <p className="text-red-500">{message}</p>
              )}
            />
            {/* {errors.password_repeat && <p className="text-red-500">{errors.password_repeat.message}</p>} */}
          </div>

          <div>
            <div className="  my-[13px] whyte-bold text-[24px] xl:text-[32px] ">
              Country Code*
            </div>
            <Select
              control={control}
              options={countryCodes}
              defaultValue="+353 (Ireland)"
              className="mt-3"
              {...register("countryCode", { required: "input country code" })}
            />
            <ErrorMessage
              name="countryCode"
              errors={errors}
              render={({ message }) => (
                <p className="text-red-500">{message}</p>
              )}
            />
          </div>

          <div>
            <div className=" my-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
              Phone*
            </div>
            <InputPhone
              className="mt-3"
              placeholder={"456-7890"}
              {...register("phone", { required: "input phone" })}
            />
            <ErrorMessage
              name="phone"
              errors={errors}
              render={({ message }) => (
                <p className="text-red-500">{message}</p>
              )}
            />
          </div>
        </div>
      </div>
      <div className="xl:w-[10%] self-end ">
        <button
          type="submit"
          className="mt-8 xl:mt-4  w-[80%] xl:w-full xl:h-12  hover:scale-105 transition-all"
        >
          <img src={next} alt="next" />
        </button>
      </div>
    </form>
  );
}
