import { Fragment, useState, useCallback } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth } from '../../hooks/Auth';
import { storageFileInLocalStorage } from '../../utils/storageFileInLocalStorage'
import { useMemo } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Navbar = () => {
  const { user, signOff } = useAuth();
  const navigate = useNavigate();

  const { handleSubmit } = useForm();
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [isOpenEditProfileModal, setIsOpenEditProfileModal] = useState(false);

  const handleModal = () => setIsOpenEditProfileModal(!isOpenEditProfileModal)

  const [profileImageSrc, setProfileImageSrc] = useState(() => {
    const storagedImg = localStorage.getItem('@Swanted/profilePhoto');

    return storagedImg ? `data:image/png;base64,${storagedImg}` : 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
  });

  const navigation = useMemo(() => {
    const companyRoutes = [
      { name: 'Manage Jobs', href: '/manage-vacancies', current: false },
      { name: 'Post a Job', href: '/register-job', current: false },
      { name: 'Jobseekers Database', href: '/painel', current: false },
      { name: 'My Profile', href: '/company-profile', current: false },
    ];

    const jobSeekerRoutes = [
      { name: 'Job Map', href: '/map', current: false },
      { name: 'My Applications', href: '/my-applications', current: false },
      { name: 'Saved Jobs', href: '/saved-jobs', current: false },
      { name: 'Inbox', href: '/my-job-invites', current: false },
      { name: 'My Profile', href: '/profile', current: false },
    ];

    const adminRoutes = [
      { name: 'Import File', href: '/mass-import', current: false },
      { name: 'Manage Vacancies Reports', href: '/manage-vacancies-reports', current: false },
      { name: 'Manage JobSeekers Reports', href: '/manage-jobseekers-reports', current: false },
    ]

    return [
      ...(user.roles.includes('jobSeeker')
      ? jobSeekerRoutes
      : companyRoutes),
      ...(user.roles.includes('admin')
      ? adminRoutes
      : [])
    ] 
      ;
  }, [user]);

  const userPhoto = useMemo(() => {
    const data = user.roles.includes('jobSeeker') ? user.jobSeeker : user.company;

    return data.photo;
  }, [user]);

  const userName = useMemo(() => {
    const data = user.roles.includes('jobSeeker') ? user.jobSeeker : user.company;

    return data.name;
  }, [user]);

  const handleSignOut = useCallback(() => {
    signOff();
  
    navigate('/');
  }, [navigate, signOff]);

  const onEditProfileSubmit = useCallback(async (data) => {

    if(profilePhoto) {
      setProfileImageSrc(
        'data:image/png;base64,' + await storageFileInLocalStorage(profilePhoto, 'profilePhoto'),
      )
    }
  }, [profilePhoto]);

  return (
    <>
      <Disclosure as="nav" className="fixed top-0 left-0 right-0 z-50 bg-primary-700 shadow-lg">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-100 hover:bg-primary-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  
                  {/* Manter o código abaixo, quando tivermos uma landing page isso será usada */}

                  {/* <div className="flex flex-shrink-0 items-center">
                    <div className="w-24 h-9 bg-white rounded-full flex items-center justify-center">
                      <img
                        className="block h-24"
                        src={logo}
                        alt="Swanted"
                      />
                    </div>
                  </div> */}
                  
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current ? 'bg-gray-900 text-white' : 'text-gray-100 hover:bg-primary-800 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="text-left mr-5 font-medium text-gray-100">
                          Welcome, <br />
                          <strong className="text-secondary-700 font-bold">{userName}</strong>
                        </span>
                        <img
                          className="h-8 w-8 rounded-full object-cover object-center"
                          src={userPhoto}
                          alt={userName}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {/* <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={handleModal}
                              className={classNames(active ? 'bg-gray-100 cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Your Profile
                            </span>
                          )}
                        </Menu.Item> */}
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={handleSignOut}
                              className={classNames("w-full", active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 text-left')}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}