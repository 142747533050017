import React, { useMemo } from "react";
import { nanoid } from "nanoid";
import { parseDate } from "../../utils/parseDate";
import "../../Pages/HomePage/styles.css";
import { getDifferenceInNaturalTimeFromToday } from "../../utils/getDifferenceInNaturalTime";
import { useNavigate } from "react-router";
import arrow from "../../assets/homePage/blue-arrow.png";

export function SavedApplicationsVacancy({
  job,
  onClick,
  shouldShowDeletedMessage,
  companyData = null,
}) {
  const navigator = useNavigate();
  const timeSincePost = useMemo(() => {
    if (!job?.date) {
      return null;
    }

    const date = new Date(Number(job.date));

    return getDifferenceInNaturalTimeFromToday(date);
  }, [job]);

  const jobDate = useMemo(() => {
    if (!job) return;
    const date = new Date(Number(job.date));

    return parseDate(date);
  }, [job]);

  const parsedSalary = useMemo(() => {
    if (!job?.salary) {
      return null;
    }

    return job?.salary;
  }, [job]);

  const company = useMemo(() => {
    return companyData || job?.company;
  }, [companyData, job]);

  if (!job) {
    return null;
  }

  return (
    <>
      <button
        key={nanoid()}
        className={
          "relative flex border text-center bg-white justify-start items-center px-8 py-4 mt-2 min-h-[217px] max-h-80 w-full rounded-lg " +
          "group transition duration-200  hover:border-primary-700 " +
          (job.deleted ? "opacity-50" : "")
        }
        onClick={onClick}
      >
        <div className="flex flex-col w-full">
          <div className="w-full  flex justify-between">
            <div className="flex flex-col justify-start items-start">
              <div>
                <span className="text-[20px] xl:text-[24px] whyte-bold ">
                  {job?.jobName}
                </span>
                <span className="text-red-500 font-medium text-sm">
                  {shouldShowDeletedMessage && `(Job already closed)`}
                </span>
              </div>

              <div className=" whyte text-[18px] text-[#A3A3A3]   ">
                {job?.company.name}
              </div>
              <div className="text-[#2E9367] ">Posted {timeSincePost}</div>
            </div>
            <div>
              <img
                className="h-0 w-0 sm:w-16 sm:h-16 rounded-full bg-gray-400 object-cover object-center sm:ml-2"
                src={company?.photo}
                alt={company?.name}
              />
            </div>
          </div>
          <hr className="my-4" />

          <div className="flex justify-start whyte text-[#546787]    ">
            {job?.period}
            {" - "}
            {job?.hourPerWeek} Hours
            {"  - "}
            {job?.category}
            {" - "}
            {jobDate}
            {" - "}
            {job?.contractType}
            {parsedSalary ? " - " + parsedSalary + " per hour " : ""}
            {" - "}
            {job?.disponibleVacancies} position
            {job?.disponibleVacancies !== 1 && "s"}
          </div>

          <div
            onClick={() =>
              navigator(`/applications-job-details`, { state: job })
            }
            className="flex items-center gotham font-medium gap-[3%] justify-start text-[16px] lg:text-[20px] mt-4 text-[#116AFF] "
          >
            <div>View Job Details</div>
            <img className="w-[40px] lg:w-[60px] " src={arrow} alt="arrow" />
          </div>
        </div>
      </button>
    </>
  );
}
