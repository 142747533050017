import { gql } from "@apollo/client";

export const GET_SAVED_VACANCIES = gql`
  query savedVacancies {
    savedVacancies {
      _id
      jobName
      salary
      period
      category
      contractType
      disponibleVacancies
      hourPerWeek
      applicants
      date
      hasUserApplied
      description
      isSalaryNegotiable
      company {
        _id
        name
        latlon
        photo
      }
    }
  }
`;
