import React, { forwardRef } from "react";
import { Input } from "./InputEmail.styles";

export const InputEmail = forwardRef(
  (
    {
      id,
      name,
      className,
      placeholder,
      ariaLabel,
      value,
      maxLength = 100,
      ...props
    },
    ref
  ) => {
    return (
      <div className={"flex flex-col " + className}>
        {/* <label
          className="block mb-2 text-[24px] font-medium text-gray-900"
          htmlFor={name}
        >
          {placeholder}
        </label> */}

        <Input
          type="email"
          id={name}
          name={name}
          placeholder={placeholder}
          className={
            "block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-700 focus:ring-primary-700 sm:text-sm"
          }
          aria-label={ariaLabel}
          value={value}
          maxLength={maxLength}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
