import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { LoadingScreen } from '../../../Components/LoadingScreen/LoadingScreen'
import {
  GET_REPORTED_VACANCIES,
} from './ManageReports.services';
import { Navbar } from '../../../Components/Navbar/Navbar'
import { Vacancy } from '../../../Components/Vacancy/Vacancy'
import { Footer } from '../../../Components/Footer/Footer'
import { ManageReportsPainel } from './ManageReportsPainel';
import { useAutoAnimate } from '@formkit/auto-animate/react'

export function ManageReports() {
  const [parent] = useAutoAnimate();
  const { data, loading, refetch } = useQuery(GET_REPORTED_VACANCIES, {
    variables: {
      shouldQueryDeleted: true
    }
  });
  const [focusedVacancy, setFocusedVacancy] = useState(null);

  const handleSelectApplication = useCallback((selectedApplication) => {
    setFocusedVacancy(selectedApplication);
  }, []);

  const filteredVacancies = useMemo(() => {
    if (loading) {
      return []
    }

    return data.reported_vacancies;
  }, [data, loading]);

  useEffect(() => {
    if (filteredVacancies.length === 0) {
      setFocusedVacancy(null);
      return;
    };

    setFocusedVacancy(filteredVacancies[0])
  }, [filteredVacancies]);

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <>
      <Navbar />

      <div className="bg-gray-50 w-full min-h-[93vh] pt-16">
        <aside className="w-full h-full px-12" aria-label="Sidebar">
          <div className="lg:flex overflow-y-auto py-4 px-3 rounded h-full flex-row-reverse">
            
            {focusedVacancy && (
              <div className={"relative flex flex-col w-full lg:m-8 mt-8 rounded-md shadow-[5px_5px_15px_5px_rgba(106,13,180,60%)] " + (focusedVacancy.deleted ? "opacity-50" : "")}>
                <ManageReportsPainel onCloseJobOrPosition={refetch} setFocusedVacancy={setFocusedVacancy} focusedVacancy={focusedVacancy} />
              </div>
            )}
            
            <ul className="space-y-2 w-full overflow-y-auto mt-10">
              <legend className="flex justify-center text-lg font-bold">Manage Reports</legend>

              <div className="lg:flex lg:flex-col lg:items-center" ref={parent}>
                {filteredVacancies.length === 0 && (
                  <div className="my-6">
                    <h1>No reports were done yet.</h1>
                  </div>
                )}

                {filteredVacancies.map((vacancy, index) => (
                  <Vacancy key={vacancy._id} job={vacancy} onClick={() => handleSelectApplication(filteredVacancies[index])} /> 
                ))}
              </div>
            </ul>

            
          </div>
        </aside>
      </div>

      <Footer />
    </>
  )
}
