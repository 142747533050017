import React from "react";
import "video-react/dist/video-react.css";
import {
  Player,
  BigPlayButton,
  LoadingSpinner,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  ClosedCaptionButton,
} from "video-react";
import intoImg from "../../assets/swantedinto.jpeg";
export const VideoPlayer = ({ srcVideo, height = 300, width = "100%" }) => {
  return (
    <>
      {srcVideo ? (
        <Player
          // poster={poster}
          fluid={false}
          height={height}
          width={width}
        >
          <source src={srcVideo} type="video/mp4" />
          <track kind="captions" srcLang="en" label="Inglês" default />
          <BigPlayButton position="center" />
          <LoadingSpinner />
          <ControlBar>
            <ReplayControl seconds={10} order={1.1} />
            <ForwardControl seconds={10} order={1.2} />
            <VolumeMenuButton />
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7} />
            <ClosedCaptionButton order={7.1} />
          </ControlBar>
        </Player>
      ) : (
        <>
          <img src={intoImg} alt="into video" />
        </>
      )}
    </>
  );
};
