import React, { useRef, useCallback, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import Dialog from "@mui/material/Dialog";
import logo from "../../../assets/logo_swanted_purple.png";
import { Button } from "../../../Components/NewButton/Button";
import { InputText } from "../../../Components/Forms/InputText/InputText";
import { InputCheckbox } from "../../../Components/Forms/InputCheckbox/InputCheckbox";
import { InputNumber } from "../../../Components/Forms/InputNumber/InputNumber";
import { Select } from "../../../Components/Forms/Select/NewSelect";
import { Textarea } from "../../../Components/Forms/Textarea/Textarea";
import { Navbar } from "../../../Components/Navbar/Navbar";
import { Footer } from "../../../Components/Footer/Footer";
import { CREATE_VACANCY } from "./VacanciesForm.services";
import { GET_COMPANY_VACANCIES } from "../../ManageVacancies/ManageVacancies.services";
import banner4Min from "../../../assets/banners/banner 4-min.png";
import { jobCategoryCandidate } from "../../../utils/jobCategoryCandidate";
import { Modal } from "../../../Components/NewModal/Modal";
import { Portal } from "../../../Components/Portal/Portal";
const period = ["Part Time", "Full Time"];

const contractType = ["Permanent", "Temporary"];

const VacanciesFormModal = ({ open, handleClose }) => {
  const client = useApolloClient();
  const [createVacancy, { loading }] = useMutation(CREATE_VACANCY);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  let jobCategoryValue = useRef({});
  let isSalaryNegotiableValue = useRef({});

  jobCategoryValue.current = watch("jobCategory", "");
  isSalaryNegotiableValue.current = watch(`isSalaryNegotiable`, false);

  const onSubmit = useCallback(
    async (data) => {
      if (loading) {
        return;
      }

      let vacancyCategory = data.jobCategory;

      if (vacancyCategory === "Other") {
        vacancyCategory = data.otherJobCategory;
      }

      try {
        await createVacancy({
          variables: {
            vacancyJobName: data.jobName,
            vacancyCategory,
            vacancySalaryNegotiable: data.isSalaryNegotiable,
            vacancyDescription: data.description,
            vacancyPeriod: data.period,
            vacancySalary: data.salary ? `${data.salary}` : null,
            vacancyContractType: data.contractType,
            vacancyDisponibleVacancies: data.disponibleVacancies,
            vacancyHourPerWeek: `${data.hoursPerWeek}`,
          },
        });

        await client.refetchQueries({
          include: [GET_COMPANY_VACANCIES],
        });

        navigate("/manage-vacancies");
        handleClose()
      } catch (error) {
        if (error.message === "Vacancy name already taken") {
          alert("Vacancy name already taken, choose a new one");
          return;
        }

        throw error;
      }
    },
    [navigate, createVacancy, client, loading]
  );

  const handleSalaryNegotiableChange = useCallback(() => {
    setValue("salary", "");
  }, []);

  return (
    <>
      <Portal>
        <Modal closeModal={handleClose} isOpen={open} maxWidth={1250} height="80%">
          <div className="flex flex-col xl:px-10    items-center justify-center    h-fit overflow-auto">
            <form
              className="flex flex-col  w-full pb-5   "
              onSubmit={handleSubmit(onSubmit, console.warn)}
            >
              <div>
                {/* <img
                      className="w-[250px] m-auto my-6"
                      src={logo}
                      alt="Workflow"
                    /> */}
                <div className="my-3 whyte-bold text-[30px]  ">
                  Post a Free Job
                </div>
                {/* <h2 className="my-3 text-center text-3xl font-bold tracking-tight text-gray-900"></h2> */}
              </div>

              <div className="flex flex-col xl:flex-row xl:gap-[30px]  xl:min-w-[900px]  ">
                <div className="w-full xl:w-1/2">
                  <div className="my-3 whyte-bold text-[22px]  ">Job Title</div>
                  <InputText
                    style={{ height: "50px" }}
                    autoComplete="off"
                    placeholder=""
                    className="mt-3"
                    {...register("jobName", { required: true })}
                  />
                  {errors.jobName && (
                    <p className="text-red-500">{errors.jobName.message}</p>
                  )}
                </div>
                <div className="w-full xl:w-1/2">
                  <div className="my-2 whyte-bold text-[22px]  ">
                    Hours per week
                  </div>
                  <InputNumber
                    placeholder=""
                    style={{ height: "50px" }}
                    step={0.1}
                    className="mt-0"
                    min={1}
                    {...register("hoursPerWeek", {
                      required: true,
                      min: 1,
                      valueAsNumber: true,
                    })}
                  />
                  {errors.hoursPerWeek && (
                    <p className="text-red-500">
                      {errors.hoursPerWeek.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col xl:flex-row xl:gap-[30px] ">
                <div className="w-full xl:w-1/2">
                  <div className="my-3 whyte-bold text-[22px]  ">
                    Positions Available
                  </div>
                  <InputNumber
                    style={{ height: "50px" }}
                    defaultValue={1}
                    placeholder=""
                    min={1}
                    className="mt-3"
                    {...register("disponibleVacancies", {
                      required: true,
                      min: 1,
                      valueAsNumber: true,
                    })}
                  />
                  {errors.disponibleVacancies && (
                    <p className="text-red-500">
                      {errors.disponibleVacancies.message}
                    </p>
                  )}
                </div>
                <div className="w-full xl:w-1/2">
                  <div className="my-3 whyte-bold text-[22px]  ">
                    Job Category
                  </div>
                  <Select
                    isMulti
                    maxLength={3}
                    control={control}
                    className="mt-3"
                    label=""
                    placeholder="Select 3 max"
                    options={jobCategoryCandidate}
                    rules={{ required: true }}
                    {...register("jobCategory")}
                  />
                  {jobCategoryValue.current === "Other" && (
                    <InputText
                      style={{ height: "50px" }}
                      autoComplete="off"
                      label="Other Job Category"
                      className="mt-3"
                      {...register("otherJobCategory", { required: true })}
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col xl:flex-row xl:gap-[30px] ">
                <div className="w-full xl:w-1/2">
                  <div className="my-3 whyte-bold text-[22px]  ">
                    Salary per Hour
                  </div>
                  <InputNumber
                    style={{ height: "50px" }}
                    step="any"
                    placeholder=""
                    className="mt-3"
                    {...register("salary", {
                      required: !!isSalaryNegotiableValue.current,
                      disabled: !!isSalaryNegotiableValue.current,
                      min: 1,
                      valueAsNumber: true,
                    })}
                  />

                  {errors.salary && (
                    <p className="text-red-500">{errors.salary.message}</p>
                  )}

                  <InputCheckbox
                    className="mt-2"
                    placeholder="Salary negotiable"
                    {...register("isSalaryNegotiable", {
                      onChange: handleSalaryNegotiableChange,
                    })}
                  />
                </div>
                <div className="w-full xl:w-1/2">
                  <div className="my-3 whyte-bold text-[22px]  ">
                    Job Period
                  </div>
                  <Select
                    control={control}
                    className="mt-3"
                    label=""
                    options={period}
                    {...register("period")}
                    rules={{ required: true }}
                  />
                </div>
              </div>
              <div>
                <div className="my-3 whyte-bold text-[22px]  ">
                  Contract Type
                </div>
                <Select
                  control={control}
                  className="my-3"
                  label=""
                  options={contractType}
                  {...register("contractType")}
                  rules={{ required: true }}
                />
              </div>

              <div>
                <div className="my-3 whyte-bold text-[22px]  ">
                  Short Job Description
                </div>
                <Textarea
                  label=""
                  className="mt-3 w-full"
                  rows={6}
                  maxLength={10000}
                  {...register("description", { required: true })}
                />
                {errors.howKnewAboutUs && (
                  <p className="text-red-500">
                    {errors.howKnewAboutUs.message}
                  </p>
                )}
              </div>
              <Button
                loading={loading}
                type="submit"
                className="mt-4 whyte-bold text-[20px] h-12 xl:h-[62px] rounded-full self-end min-w-[320px] "
              >
                Post Job for FREE
              </Button>
            </form>
          </div>
        </Modal>
      </Portal>
    </>
  );
};

export default VacanciesFormModal;
