import React, { useState, useMemo } from "react";
import {
  FiTag,
  FiFileText,
  FiTarget,
  FiMonitor,
  FiClock,
  FiUser,
  FiBriefcase,
} from "react-icons/fi";
import { BiEuro } from "react-icons/bi";
import { SeeMoreModalNonOfficial } from "../SeeMoreModalNonOfficial/SeeMoreModalNonOfficial";
import { getDifferenceInNaturalTimeFromToday } from "../../utils/getDifferenceInNaturalTime";
import { Button } from "../NewButton/Button";
import { Link, useNavigate } from "react-router-dom";

export default function NonOfficialEnterprisePopup({ company, userIsLogin, setOpenModalNeedLogin, onClose, job }) {
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState(false);
  const navigator = useNavigate();

  const timeSincePost = useMemo(() => {
    const date = new Date(Number(company?.date));
    return getDifferenceInNaturalTimeFromToday(date);
  }, [company]);

  const parsedSalary = useMemo(() => {
    if (!company?.salary) {
      return null;
    }
    return company?.salary;
  }, [company]);

  const seeMore = () => {
    if (userIsLogin) {
      return navigator("/painel/non-enterprise-job-details", { state: company });
    } else {
      return setOpenModalNeedLogin ? setOpenModalNeedLogin(true) : () => {};
    }
  };

  return (
    <>
      {isSeeMoreModalOpen && (
        <SeeMoreModalNonOfficial
          company={company}
          isSeeMoreModalOpen={isSeeMoreModalOpen}
          setSeeMoreModal={setIsSeeMoreModalOpen}
        />
      )}
      <div className=" p-4  relative w-96   h-fit">
        <div className="rounded-tl-3xl rounded-tr-3xl rounded-bl-2xl rounded-br-2xl">
          <div className="">
            <img
              className=" w-fit max-h-[110px] "
              src={company?.photoUrl}
              alt={company?.name}
            />
          </div>
        </div>

        <div className=" whyte-medium  text-[20px] xl:text-[24px] mt-4 ">
          {company?.jobTitle}
        </div>
        <div className="   " style={{ color: "#A3A3A3", fontSize: "16px" }}>
          {company?.name}
        </div>

        <div>
          {company?.date ? (
            <span className="font-normal text-[14px] text-[#2E9367]">
              Posted {timeSincePost}
            </span>
          ) : (
            <></>
          )}
        </div>
        <hr className="my-2" />

        <div className="flex flex-wrap whyte font-normal  w-full text-[#546787]  text-[16px] ">
          {company?.jobType ? <>{company?.jobType} - </> : <></>}
          {company?.salaryNegotiable ? (
            <>Salary Negotiable - </>
          ) : company?.salaryPerHour ? (
            <>{company.salaryPerHour } per hour - </>
          ) : (
            <></>
          )}
          {company?.jobCategory ? <>{company?.jobCategory} - </> : <></>}
          {company?.positionsAvailable} position
          {company?.positionsAvailable !== 1 && "s" + " - "}
          {company?.hoursWeek ? (
            <> {company?.hoursWeek} Hours - </>
          ) : (
            <></>
          )}{" "}
          {company?.contractType ? <> {company?.contractType} </> : <></>}
        </div>

        <div className="relative w-full h-20">
          <footer className="mt-4 z-10 absolute left-2/4 -translate-x-2/4 flex items-center justify-center text-white rounded-xl w-full">
            <Button
              disabled
              onClick={() => {}}
              className="ml-3 whyte-medium text-[18px]  rounded-full  h-[52px]  px-6"
            >
              Apply in Store
            </Button>
            <Button
              invert
              onClick={seeMore}
              className="ml-3 border-none whyte-medium text-[18px]  rounded-full  h-[52px]  px-6"
            >
              See More
            </Button>
          </footer>
        </div>
      </div>
    </>
  );
}
