import React from 'react';
import { Button } from '../NewButton/Button';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

export const PDF = ({ jobSeeker }) => {
  let url = jobSeeker?.cv;
  let parsedUrl = url.split("/")
  let fileNameFolder = parsedUrl[6];
  let convertFileType = parsedUrl[7].replace(".png", ".pdf")
  let fileDownload = `https://res.cloudinary.com/dlhhcz8wt/image/upload/fl_attachment:${fileNameFolder}/${convertFileType}`
  const isPdf = url.includes(".pdf")  ? true : false;

  return (
    <>
      <div className="flex flex-row-reverse">
        <a href={fileDownload}>
          <Button className='whyte-bold rounded-full  flex mt-2 mb-3 w-32'>Download</Button>
        </a>
      </div>

      <div className='w-full h-[500px]'>
        {isPdf 
          ?
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
              <Viewer  fileUrl={`${jobSeeker?.cv}`} defaultScale={1.2}></Viewer>
            </Worker>
          :
            <img src={`${jobSeeker?.cv}`} width="100%" height={500} />
        }
      </div>
    </>
  );
}
