import styled from 'styled-components';

export const Input = styled.input`
    padding: 5px;
    border:none;
    border-radius:none !important;
    border-bottom:1px solid #A3A3A3;

    ::placeholder {
        color:  #A3A3A3;
      
      }
`
