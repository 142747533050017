import React, { forwardRef } from 'react'
import { Modal } from '../NewModal/Modal';
import Privacy from "../../assets/banners/privacy.png";

export const PrivacyPolicy = forwardRef(({
  className,
  ariaLabel,
  value,
  maxLength = 100,
  shouldShowPrivacyPolicy,
  setShouldShowPrivacyPolicy,
  ...props
}, ref) => {

  return (
    <>
      {shouldShowPrivacyPolicy && (
        <Modal width={"90%"}  className="z-[999] " maxWidth={800} height={600} closeModal={() => setShouldShowPrivacyPolicy(false)} isOpen={shouldShowPrivacyPolicy}>
          <div className= ' p-3 xl:p-12 '>
            <img className="mb-5 rounded-3xl" src={Privacy} alt="Term and Conditions Image" /> 
            <p>
              We at Swanted care about your privacy and we respect your privacy rights. Swanted has created this Privacy Policy to demonstrate our firm commitment to your privacy.
              The following outlines this commitment to users of our Website (as defined below) as we want to be able to provide everyone with a user experience that is safe and secure. Swanted endeavours to ensure that any personal data you give us via our Website remains private and is used only for the purposes outlined in this Privacy Policy.
              By using the Website and/or the Swanted Service, you acknowledge the collection and use of your personal data as outlined below. This version of our Privacy Policy was last updated on 16/10/2022.
            </p>
            <h1 className='mt-5'>1 ABOUT OUR PRIVACY POLICY</h1>
            <p>
              1.1 Our website SWANTED which includes any webpage containing this domain name, including any page that is enabled for use by a mobile device or any webpage redirected from another domain name (the “Website”) and the services provided on the Website (for a more complete description of the services see our Terms of Use) (the “Swanted Service”) are operated by Swanted Limited trading as Swanted, The Digital Hub, 161 Thomas Street, Dublin 8, Ireland (“Swanted”, “we”, “us” and “our”).<br />
              1.2 The Website and the Swanted Service help people find, advertise, apply for or express interest in vacancies, and provide employment, consultant or contractor related information. Swanted is a controller (as defined under data protection law) and is responsible for your personal data. Provided you do not deactivate our CV Database Service in Your Account, our CV Database Service will make your CV or resume that you upload to your personal profile or include in your response to a Job Specification (defined below) searchable and viewable on the Website to Swanted customers (i.e. direct employers and recruitment agencies who are registered users of the Website) (“Customers”) and searchable and viewable on the Swanted website. Swanted controls the personal data processed for the Database Service. In certain circumstances Swanted Customers who receive your job applications and CV or resume will also be a controller of some of your personal data. This is the data that you have provided in your CV and Swanted Profile.<br />
              1.3 This Privacy Policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us and your rights in relation to your personal data. If you are a Customer, this Privacy Policy supplements any data protection provisions contained in our contract with you and is not intended to override them. Please read the following carefully to understand our views and practices regarding your personal data, how we will treat it and your data protection rights. The Website and the Swanted Services are not intended for children or people underage and we do not knowingly collect personal data relating to children or people underage.<br />
            </p>
            <h1 className='mt-5'>2 PERSONAL DATA WE COLLECT AND PROCESS</h1>
            <p>
              2.1 When registering for a user account, using any Swanted Service and/or browsing the Website, we will collect and gather the following categories of personal data:<br />
              2.1.1 Identity Data such as first name, surname, gender.<br />
              2.1.2 Contact Data such as address, e-mail address, telephone number.<br />
              2.1.3 Profile Data such as your username and password, personal data contained in resumes/CVs and cover video, employment details, education history, eligibility to work, salary expectations, job applications made by you, your interests, preferences, feedback, company ratings and survey responses.<br />
              2.1.4 Financial Data could include information such as for Customers only, your payment card details, bank details, VAT number, information about payments to and from you and other details of services you have purchased from us.<br />
              2.1.5 Technical Data such as internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access the Website.<br />
              2.1.6 Usage Data such as information about how you use the Website and Swanted Services.<br />
              2.1.7 Marketing and Communications Data such as your preferences in receiving marketing communications from us and third parties and your communication preferences.<br />
              2.2 The type of personal data collected and used by us varies depending upon whether you are a registered user of just browsing the Website, or if you use one or more of the Swanted Services.<br />
              2.3 We collect, use and share aggregated data such as statistical or demographic data. Aggregated data may be derived from your personal data but is not considered personal data under applicable data protection law as this data does not directly or indirectly reveal your identity.<br />
            </p>
            <h1 className='mt-5'>3 HOW WE COLLECT PERSONAL DATA ON THE WEBSITE</h1>
            <p>
              3.1 In order to apply for a job through the Website and to use our CV and video Database Service, you will need to register an account with Swanted, which will involve you giving us your Identity, Contact and Profile Data. We will inform you at the time of collecting personal data from you whether you must provide the personal data to use the Website or a particular Swanted Service, or whether the provision of personal data requested by us is optional (e.g. providing your personal or professional interests and experiences with our products or services is optional).<br />
              3.2 Where we need to collect personal data by law, or under the terms of a contract we have with you (e.g. Terms of Use) and you don’t provide that personal data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with the Swanted Service). In this case, we may not be able to provide all or part of the relevant service<br />
            </p>
            <h1 className='mt-5'>4 METHODS USED TO COLLECT PERSONAL DATA FROM AND ABOUT YOU</h1>
            <p>
              4.1 Direct interactions: You may give us your Identity, Contact, Profile, Financial (Customers only), Marketing and Communications Data by populating your online profile, changing details in Your Account, filling in online forms or by communicating with us. If you are a registered user who applies for an advertised job via the Website, we and the relevant Customer will receive your application and personal data associated with it. The personal data we receive from direct interactions with you includes personal data you provide when you:<br />
              4.1.1 apply or subscribe for our Website or other Swanted Service;<br />
              4.1.2 create a user account on our Website and when you add to and manage your personal profile;<br />
              4.1.3 contact or communicate with us and/or Customers via the Website or other Swanted Service;<br />
              4.1.4 submit responses to Job Specifications (defined below);<br />
              4.1.5 submit responses to surveys or polls, provide feedback, rate companies or make available any other content on the Website;<br />
              4.1.6 request marketing communications to be sent to you;<br />
              4.1.7 make your resumes or CVs available on the Website for third parties to browse in connection with employment, consultant or contractor positions;<br />
              4.1.8 as a Customer, where you save or download a resume or CV from the searchable CV and Video Database Service.<br />
              4.2 Automated technologies or interactions: As you interact with the Website, we collect Technical Data and Usage Data including details of your device, browsing actions and patterns, searches, job advertisements viewed, traffic data, web logs and other communication data and the resources that you access. We collect this personal data by using cookies, tracking codes, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies.<br />
              4.3 Third parties: We may receive personal data about you from various third parties as set out below:<br />
              4.3.1 Technical Data from providers such as Google Analytics, Microsoft Azure.<br />
              4.3.2 Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as Stripe.<br />
              4.3.4 We use a Facebook fan page at Swanted. Facebook provides Page-Insights for that fan page to us. Page-Insights are aggregated data, which allow us to understand how users interact with our fan page. Page Insights can be based on personal data which is collected in relation to a visit of or an interaction with our fan page and its content by users. Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland (“Facebook Ireland”) and we are joint controllers in the sense of Art 26 GDPR for the processing of Insights data and we have entered into an arrangement with Facebook Ireland which you can find at https://www.facebook.com/legal/terms/page_controller_addendum. Legal basis for our use of the fan page and Page-Insights is a legitimate interest in the sense of Art. 6 para 1 f GDPR, in relation to the fan page that means the use of Facebook as channel of communication about our company and in relation to Page Insights the better understanding of the interests of visitors of our fan page so that we can specifically serve these interests.<br />
            </p>
            <h1 className='mt-5'>5 HOW WE USE YOUR PERSONAL DATA</h1>
            <p>
              5.1 Customers post information about employment, consultant or contractor vacancies on the Website, whether on their own behalf or, if a recruitment or consultancy, on behalf of third parties (“Job Specifications”). These Job Specifications can then be accessed and read by other users of the Website. You can search for registered Customers, whether they are direct employers or agencies. Swanted does not vet or monitor the Job Specifications and is not responsible for the data protection practices of Customers.<br />
              5.2 We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:<br />
              5.2.1 Where we need to perform the contract (e.g. the Terms of Use) we are about to enter into or have entered into with you.<br />
              5.2.2 Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.<br />
              5.2.3 Where we need to comply with a legal or regulatory obligation.<br />
              5.3 Generally we do not rely on consent as a legal basis for processing your personal data. If we do rely on your consent to process your personal data this will be made clear to you at the time, and you have the right to decline to provide us with your consent and to withdraw your consent at any time by contacting us.<br />
              5.4 We do not request from you and we request you do not provide any special categories/sensitive personal data (e.g. personal data relating to racial or ethnic origin, political or religious opinions, membership of a trade union, physical or mental health or condition or sexual life or orientation). This type of personal data is subject to special protections under EU law.<br />
              5.5 We may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your personal data. Please contact us using gdpr@swanted.io if you need details, in addition to those set out below, about the specific legal ground we are relying on to process your personal data.<br />
              5.6 We use your personal data in the following ways:<br />
              5.6.1 We will collect and use your Identity, Contact, Profile and Financial Data (Customers only) to register you as a new user of the Website or to register Customers. This is necessary in order to perform our contract (e.g. Terms of Use) with you.<br />
              5.6.2 We will collect and use your Identity, Contact and Profile Data to provide Customers with the information that you submit to the Website in response Job Specifications. This is necessary in order to perform our contract (e.g. Terms of Use) with you. Details of your application for a Job Specification will only ever be shared with the Customer advertising the role. It will never be shared with any other Customer.<br />
              5.6.3 If you use the CV and Video Database Service and your profile is set to public we will process your Identity, Contact and Profile Data to:<br />
              (a) maintain this personal data in our database of searchable and viewable CVs and resumes ("CV Database");
              (b) display your CV or resume and associated personal data for our Customers, so that our Customers are able to evaluate whether you are a suitable candidate for a vacant position (whether or not a Job Specification);
              (c) identify and create a profile of the key information you disclosed in your CV or resume, which we will use for our internal administration purposes to manage the CV Database, to enhance your personal profile and to match your CV or resume to relevant Job Specifications. This processing involves profiling, the consequence of which is that the key personal data we identify from your CV or resume will be used to enhance the likelihood of a Customer, and
              (d) identify from your CV or resume key skills based on your current role and job title contained in your CV or resume, which we will use to match your CV or resume to relevant Job Specifications. This processing involves automated decision making, including profiling, of the kind referred to in Article 22 GDPR. The logic of this processing involves assumptions based on information you submit with your CV or resume, but which you can change at any time by accessing “Account Settings” in “Your Account” of the Website where you can change the key skills we selected on your behalf. You can contact us at gdpr@swanted.io if you need our assistance with this.
              The processing described in this section 5.6.3 is necessary in order for us to perform our contract (e.g. Terms of Use) with you, and in respect of sub-sections (a), (b) and (c) is necessary for our legitimate interest to increase the possibility of registered users gaining employment which in turn improves the Swanted Service. You will be able to control the type of personal data about you which will be processed in this way by accessing “Account Settings” in “Your Account” of the Website.<br />
              5.6.4 We may promote your CV or Introduction Video to a Customer where we believe your CV is a good match to a vacant position. This matching will be based on a profile of the job specifications you have interacted with recently. This processing is necessary in order for us to perform our contract (e.g. Terms of Use) with you. You will be able to control the type of personal data about you which will be displayed in this way through your “Your Profile” page of the Website.<br />
              5.6.5 We will collect and use Profile, Technical and Usage Data for data analytics purposes to improve the Website, products/services, marketing, user/Customer relationships and experience. This involves building a profile of your interests based on the job specifications you have interacted with in the past 45 days. This is necessary for our legitimate interest to ensure that Website content is presented in an effective manner for you and for your computer/device.<br />
              5.6.6 We collect and use Identity, Contact, Technical, Usage and Profile Data and Marketing and Communications Data to send registered users of the Website Job Alerts and Instant Job Alerts by email to help with your job search. This involves building a profile of your interests based on the job specifications you have interacted with in the past 45 days. This is necessary (i) in order for us to perform our contract (e.g. Terms of Use) with you; and (ii) for our legitimate interest to ensure registered users are informed of roles relevant to them which in turn improves our service. To facilitate your ease of use of the Website across multiple devices, Job Alerts by email include a personalised link which will recognize you when you visit the Website by clicking on a link in the email. This makes it easy for you to apply for jobs as your name, email address, and link to your last used CV will automatically be populated in the Job application form. As Job Alert emails are personalised to you, it is important that you do not share these email links with other people or post them on social media. You are responsible for maintaining the security of these personal Job Alert email links. Sharing personalised Job Alerts could result in somebody inadvertently applying for a job in your name.<br />
              5.6.7 We collect and use Technical, Usage and Profile Data for data analytics purposes to build an understanding of the sort of jobs registered users of the Website are most interested in by learning from the jobs you view and apply for to tailor the jobs we present to you. Our understanding of the jobs you might like will adapt and change as it is based on the job content you interact with over a rolling 45 day period. This will involve profiling. This is necessary for our legitimate interest to ensure registered users are informed of roles relevant to them which in turn improves our service;<br />
              5.6.8 We will collect and use Identity, Contact, Technical, Usage, Profile Data and Marketing and Communications Data to communicate to registered users of the Website via email about similar products or services from Swanted that will assist with your job search. This is necessary in order for us to perform our contract (e.g. Terms of Use) with you.<br />
              5.6.9 We will collect and use Identity, Contact, Technical, Usage, Profile Data and Marketing and Communications Data to send you career support and advice emails from Swanted and job and training opportunities from trusted third party partners that you request from us or which we feel may interest you, but only where you have consented to be contacted for such purposes. This processing of your personal data is based on your consent and our legitimate interests to direct market, develop our products/services and grow our business.<br />
              5.6.10 We or a registered Customer may use your Identity and Contact Data to contact you where you are a registered user or if you have responded to a Job Specification. This is necessary for us to perform our contract (e.g. Terms of Use) with you and our legitimate interest to perform services for Customers.<br />
              5.6.11 We will collect and use Identity, Contact, Profile, Technical and Usage Data to allow you to participate in interactive features on the Website and one or more Swanted Services, when you choose to do so. This is necessary for us to perform our contract (e.g. Terms of Use) with you.<br />
              5.6.12 We will collect and use Identity, Contact, Profile, Marketing and Communications Data to manage our relationship with you which will include notifying you about changes to our Website, the Swanted Service, Terms of Use, Cookie Policy and/or Privacy Policy. This is necessary for us (i) to perform our contract (e.g. Terms of Use) with you; and (ii) to comply with a legal obligation.<br />
              5.6.13 We will collect and use Identity; Contact and Technical Data to administer and protect our business and the Website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data) which is necessary (i) for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganization or group restructuring exercise) and (ii) to comply with a legal obligation;<br />
            </p>
            <h1 className='mt-5'>6 MARKETING</h1>
            <p>
              6.1 We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you. We also work with online advertising partners such as  Google,  LinkedIn and Facebook Ads to show you products, services and offers which may be relevant to you. You have a variety of tools to control the data collected by cookies, web beacons, and similar technologies. For example, you can use controls in your internet browser to limit how the websites you visit are able to use cookies and to withdraw your consent by clearing or blocking cookies. In addition, you can opt out using the following links from companies that adhere to the self-regulatory principles for online behavioural advertising and participate in the opt-out programs established by the Digital Advertising Alliance or the European Interactive Digital Advertising Alliance.<br />
              6.2 You will receive marketing communications from us if you have requested information from us or purchased services from us or if you provided us with your details when you entered a competition or registered for a promotion and, in each case, you have not opted out of receiving that marketing.<br />
              6.3 You can opt out of any marketing communications from us at any time by accessing “Account Settings” in “Your Account” of the Website and updating your notification settings or by using the “Unsubscribe” function at the bottom of the marketing email we send you.<br />
            </p>
            <h1 className='mt-5'>7 CHANGE OF PURPOSE</h1>
            <p>
              7.1 We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.<br />
              7.2 Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
            </p>
            <h1 className='mt-5'>8 DISCLOSURE OF PERSONAL DATA TO OTHERS</h1>
            <p>
              8.1 Except as set out in this Privacy Policy, we do not disclose to any third party personal data that we collect or you provide to us. We may disclose your personal data to third parties expressly mentioned in this Privacy Policy and the following third parties:<br />
              8.1.1 Customers to whom you submit job applications via the Website;<br />
              8.1.2 if you have indicated at registration, or at any time during your time as a user of the Website, that you are happy to receive information or promotions from selected third parties about goods and services which may be of interest to you, we will send you such offers or information. See Marketing above on how to change your preferences;<br />
              8.1.3 any member of our group of companies, which means our subsidiaries, our ultimate holding company and its subsidiaries, where it is necessary to do so for the provision and administration of the services we provide to you;<br />
              8.1.4 if you use the CV and Video Database Service and your profile is set to public, we will disclose your CV or resume and the Identity, Contact and Profile Data associated with your CV or resume so that your CV or resume will also be publicly searchable and viewable on the Swanted website and by our registered customers. This disclosure is necessary to perform our contract (e.g. Terms of Use) with you and is necessary for our legitimate interest to increase the possibility of registered users gaining employment which in turn improves the Swanted Service;<br />
              8.1.5 we contract with other entities that perform certain tasks on our behalf and who are under our control (“Service Providers”). This is required in order to provide and manage the Website and one or more Swanted Service. Such Service Providers include IT systems suppliers and support, data storage, IT developers, insurance, credit card companies, payment processors, analytics companies, website hosting providers and other service providers;<br />
              8.1.6 professional advisors such as accountants, auditors, lawyers, bankers, insurers, and other outside professional advisors;<br />
              8.1.7 third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Privacy Policy;<br />
              8.1.8 entities that regulate or have jurisdiction over Swanted. We will disclose your personal data in order to comply with any legal obligation, if we are ordered to do so by a court of competent jurisdiction, law enforcement, regulatory or administrative authorities or in order to enforce or apply our Terms of Use and other agreements, or to protect the rights, property, or safety of Swanted, our Customers, Website users or others. This includes exchanging personal data with third parties for the purposes of fraud protection and credit risk reduction.<br />
              8.1.9 We also use third party advertisements to support the Website.<br />
              8.2 We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our Service Providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions. Unless prevented by applicable law, we will notify you when your personal data may be provided to third parties in ways other than explained above, and you may have the option to prevent such sharing at the time that we notify you.<br />
            </p>
            <h1 className='mt-5'>9 CV AND VIDEO DATABASE SERVICE</h1>
            <p>
              9.1 The Swanted Service includes the CV and Video Database Service as a core service, which makes registered users’ CVs and resumes searchable and viewable by Swanted Customers. You can delete your video at any point if you don’t want it to be seen. You can rerecord or upload a new one at any time to resume this service.<br />
              9.2 All registered users can upload their CVs, Video and resumes to their personal profile on the Website, and can submit their CVs, Video and resumes when responding to a Job Specification. By doing so, their CVs, Video or resume will be viewable and searchable by Swanted Customers unless the registered user chooses to deletes the CV and Video Database Service.<br />
              9.3 The personal data necessary to provide you with the CV and Video Database Service, which typically relates to your Identity, Contact and Profile Data, will be controlled (as described in Article 26 GDPR) by us, Swanted Limited, registered office is at The Digital Hub, 161 Thomas Street Dublin 8, Ireland. We have arrangements in place intended to ensure your personal data is only used by us in accordance with our privacy policies.<br />
              9.4 Please exercise your data protection rights relating to this jointly controlled personal data by using the Account Settings option in Your Account on the Website. Alternatively, please contact us at gdpr@swanted.io<br />
            </p>
            <h1 className='mt-5'>10 CUSTOMER CONTROL OF YOUR PERSONAL DATA</h1>
            <p>
              10.1 If you respond to a Job Specification, upload your CV/resume to your personal profile and your profile is set to public or provide it to a Customer or otherwise communicate with Customers via the Website the relevant Customer will control the personal data you disclose. This will typically relate to your Identity, Contact and Profile Data. Customers can access this personal data in their Website user account, and so can Swanted. Both Swanted and the Customer will jointly control this personal data, and we have arrangements in place intended to ensure your personal data is only used by Customers in accordance with this Privacy Policy. Please exercise your data protection rights relating to this jointly controlled personal data by using the Account Settings option in Your Account on the Website. Alternatively, please contact us at gdpr@swanted.io<br />
              10.2 If a Customer to whom you or we have disclosed any of your personal data downloads or copies the personal data to a medium other than our Website, Swanted will no longer jointly control this personal data. Such data will be subject to the Customer's own data protection and privacy practices. If you wish to understand the Customer's data protection and privacy practices please contact the Customer directly. You will be able to exercise your Data Protection Rights directly with the Customer.<br />
            </p>
            <h1 className='mt-5'>11 INTERNATIONAL DATA TRANSFERS</h1>
            <p>
              11.1 Your personal data may be transferred, stored and accessed within the European Economic Area (“EEA”) or transferred to, stored in, and accessed from countries outside the EEA in order to fulfil the purposes described in this Privacy Policy. For transfers to countries outside the EEA, the data protection regime may be different than in the country in which you are located and will therefore be based on a legally adequate transfer method. Whenever Swanted transfers your personal data out of the EEA, we ensure a similar degree of protection is given to it by ensuring at least one of the following safeguards is implemented:<br />
              11.1.1 Where the country has been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries.<br />
              11.1.2 We may use specific contracts approved by the European Commission which are intended to give personal data the same protection it has in Europe. For further details, see European Commission: Model contracts for the transfer of personal data to third countries.<br />
              11.2 We will provide you on request a list of the countries located outside the EEA to which personal data may be transferred, and an indication of whether they have been determined by the European Commission to grant adequate protection to personal data. Where applicable, you are entitled, upon request to receive a copy of the relevant safeguard (for example, EC model contractual clauses) that has been taken to protect personal data during such transfer.<br />
            </p>
            <h1 className='mt-5'>12 DATA SECURITY</h1>
            <p>
              12.1 Swanted is committed to protecting the personal data you provide us. To prevent unauthorised access or disclosure of personal data under our control, Swanted has appropriate security management systems in place to safeguard the personal data we collect. Firewalls, intrusion detection and virus scanners are used on all parts of the Website. Encryption during transmission is also used on sections of the Website where security is particularly important.<br />
              12.2 While Swanted makes reasonable efforts to protect all personal data, no data transmission over the Internet can be guaranteed to be 100% secure. As a result Swanted cannot ensure or warrant the security of any personal data you transmit to us, you do so at your own risk. You acknowledge that we are not responsible for any intercepted information sent via the Internet, and you hereby waive, release and discharge us from any and all claims arising out of or related to the use of intercepted information in any unauthorised manner.<br />
              12.3 Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Website, you are responsible for keeping this password confidential. You must not to share your Website password with anyone.<br />
              12.4 When you disclose personal data, or request us to make your personal data available, to Customers on the Website (e.g. by submitting a CV to a Customer using the Website) that personal data will be used by the Customer for its own purposes (e.g. to contact you, arrange an interview, review your suitability for a particular position) and such third party purposes and activities are beyond our control.<br />
            </p>
            <h1 className='mt-5'>13 UPDATING YOUR PERSONAL DATA</h1>
            <p>
              13.1 It is important that the personal data we hold is accurate and current. Please keep us informed, using the Your Account section of the Website or alternatively let us know by using the Contact Us information below if any of your personal data changes during your relationship with us. It is your responsibility to keep your personal data up to date at all times.<br />
            </p>
            <h1 className='mt-5'>14 LINKS TO THIRD PARTY WEBSITES</h1>
            <p>
              14.1 Swanted vacancy advertising clients are given the option to have a profile page on the Website. This includes information related to their company such as: company logo, contact details, company profile, web address and related links. This information is disclosed for your convenience and information, however if you access some of the links and web addresses disclosed on these pages, you will leave our Website.<br />
              14.2 The Website contains links to and from the websites of our partner networks, Customers, advertisers and affiliates as well as third party websites to which we have no affiliation. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these websites or their policies.<br />
            </p>
            <h1 className='mt-5'>15 IP ADDRESSES AND COOKIES</h1>
            <p>
              15.1 We may collect information about your computer, including (where available) your IP address, operating system and browser type, for system administration and statistical purposes.<br />
              15.2 For the same reason, we may obtain information about your general internet usage by using a cookie file which is stored on the hard drive of your computer. Cookies contain information that is transferred to your computer's hard drive. On revisiting the Website our computer server will recognise the cookie, giving us information about your last visit. They help us to improve the Website and to deliver a better and more personalised service. They enable us:<br />
              15.2.1 to estimate our audience size and usage pattern;<br />
              15.2.2 to store information about your preferences, and so allow us to customise the Website according to your individual interests and make your usage of the Website more enjoyable; and<br />
              15.2.3 to speed up your searches.<br />
              15.3 You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies. However, if you select this setting you may be unable to access certain parts of the Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to the Website. If you opt out of accepting cookies, the functionality of our website may be limited. We deal with specific cookies or similar technology below. You can also learn more about our use of cookies and similar technologies and your choices below.
              For information on how to control your cookies go to allaboutcookies.
              When you visit the Website, we check if you have provided consent in the last 6 months. If you have not provided consent in the last 6 months, then we display a cookie consent banner and ask for your consent and only trigger necessary cookies and similar tracking technologies until consent is provided. If you provide consent for all cookies and similar technologies in the last 6 months, then we trigger all cookies and similar tracking technologies for 6 months from when this consent was provided.<br />
            </p>
            <h1 className='mt-5'>16 DATA RETENTION</h1>
            <p>
              16.1 We retain personal data for no longer than is allowed under data protection law and, in any case, no longer than such personal data is necessary for the purpose for which it was processed. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure, the purposes for which we process personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.<br />
              16.2 As a registered user of the Website we will retain your Identity, Contact, Profile, Technical, Usage, Marketing and Communications Data for as long as your user account is live. We retain the personal data you provide while your account is in existence or as needed to provide you access to the Website and any Swanted Service you have used. Even if you only use the Website or any other Swanted Service occasionally, we will retain your Identity, Contact, Profile, Technical, Usage, Marketing and Communications Data until you decide to close your user account. In some cases we retain certain Usage Data (e.g. visitor and application statistics in a depersonalized or aggregated form). Also, we retain personal data for longer if required by applicable law or regulation or justified under applicable statutory limitation periods.<br />
              16.3 If you respond to a Job Specification, upload your CV or resume to your personal profile and your profile is set to public or provide it to a Customer or otherwise communicate with Customers via the Website a copy of your job application, CV or resume and a record of the communication will be held on the Customer’s user account. The copy of your job application CV or resume and record of communication will remain on the Customer's user account for no more than 3 months after the job specification has been archived. We will delete that copy of your application, CV or resume and record of communication from the Customer’s user account 3 months from the date the job specification is archived, unless you have exercised Your Legal Rights of erasure of that personal data before this. Details of all the job applications you make through the Website can be viewed by you in Your Account.<br />
            </p>
            <h1 className='mt-5'>17 YOUR LEGAL RIGHTS</h1>
            <p>
              17.1 Under certain circumstances you have rights under applicable data protection law in relation to personal data, namely:<br />
              17.1.1 Request access to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.<br />
              17.1.2 Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.<br />
              17.1.3 Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.<br />
              17.1.4 Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.<br />
              17.1.5 Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.<br />
              17.1.6 Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.<br />
              17.1.7 Withdraw consent at any time if and to the extent we are relying on consent as the legal basis to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.<br />
              17.2 Regarding your right to have your personal data erased, you can delete your user account with Swanted at any time. Deleting your user account deletes records of job applications sent, CVs and cover videos stored in your account, your profile, saved jobs and job alerts. Once your user account is deleted, it is gone forever and cannot be restored. Some personal data however may still be stored and visible in other places on the Website. For example, if you have applied for a job, your application information including your name, email, CV and cover video may still be visible to the Customer in their user account on the Website.<br />
              17.3 You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances. We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.<br />
              17.4 In order to exercise one or more of your rights in respect of your personal data, you can do so from the Account Settings option in Your Account on the Website. Alternatively, please contact us at gdpr@swanted.io. We will respond to your request(s) as soon as reasonably practicable, but in any case within the legally required period of time.<br />
              17.5 You have the right to make a complaint at any time to the Data Protection Commission, the Irish supervisory authority for data protection issues (https://www.dataprotection.ie). We would, however, appreciate the chance to deal with your concerns before you approach the Data Protection Commission so please contact us in the first instance at gdpr@swanted.io<br />
            </p>
            <h1 className='mt-5'>18 CHANGES TO OUR PRIVACY POLICY</h1>
            <p>
              Any changes we may make to our Privacy Policy in the future will be posted on this page, you may also be notified by email. You should visit this page periodically to review any changes to the Privacy Policy.<br />
            </p>
            <h1 className='mt-5'>CONTACT US</h1>
            <p>
              If you have any questions about this Privacy Policy, including any requests to exercise your legal rights, please contact gdpr@swanted.io. You can also request to delete your account, erase your data, access your data and transfer your data through the Account Settings option in Your Account on the Website.<br />
            </p>
          </div>
        </Modal>
      )}
    </>
  )
});