import React from 'react';
import { forwardRef } from 'react';
import { Input } from './InputDate.style';

export const InputDate = forwardRef(({ id, name, ariaLabel, min, max, className, placeholder, ...props }, ref) => {
  return (
    <div className={"flex flex-col "+ className}>
      <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor={name}>{placeholder}</label>

      <Input
        type="date"
        id={name}
        name={name}
        aria-label={ariaLabel}
        min={min}
        max={max}
        ref={ref}
        className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-700 focus:ring-primary-700 sm:text-sm"}
        {...props}
      />
    </div>
  )
});
