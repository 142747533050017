import React, { useCallback, useMemo, useState } from "react";
import { Slider } from "../Slider/Slider";
import {
  FiCalendar,
  FiUser,
  FiClock,
  FiFileText,
  FiStar,
  FiTarget,
  FiBriefcase,
  FiAlertTriangle,
} from "react-icons/fi";

import { useApolloClient, useMutation } from "@apollo/client";
import { BiEuro } from "react-icons/bi";
import { Button } from "../NewButton/Button";
import { ModalMessage } from "../ModalMessage/NewModalMessage";
import { SAVE_VACANCY } from "./EnterprisePopup.services";
import { GET_SAVED_VACANCIES } from "../../Pages/SavedVacancies/SavedVacancies.services";
import { GET_VACANCIES } from "../../Pages/Map/Map.services";
import { Link, useNavigate } from "react-router-dom";
import { SeeMoreModal } from "../SeeMoreModal/SeeMoreModal";
import { getDifferenceInNaturalTimeFromToday } from "../../utils/getDifferenceInNaturalTime";
import "../../Pages/HomePage/styles.css";

export default function EnterprisePopup({ job, onClose, userIsLogin, setOpenModalNeedLogin, refetch }) {
  const client = useApolloClient();
  const [saveVacancy] = useMutation(SAVE_VACANCY);
  const navigator = useNavigate();
  const [isOpenModalMessage, setIsOpenModalMessage] = useState(false);
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState(false);
  const [shouldFillStar, setShouldFillStar] = useState(job.hasUserSaved); 

  const handleModal = () => {
    if (userIsLogin) {
      setIsOpenModalMessage(!isOpenModalMessage);
    } else {
      return setOpenModalNeedLogin ? setOpenModalNeedLogin(true) : () => {};
    }
  };

  const handleSeeMore = () => {
    if (userIsLogin) {
      navigator("/job-details", { state: job });
    } else {
      return setOpenModalNeedLogin ? setOpenModalNeedLogin(true) : () => {};
    }
  };

  const timeSincePost = useMemo(() => {
    const date = new Date(Number(job.date));

    return getDifferenceInNaturalTimeFromToday(date);
  }, [job]);

  const parsedSalary = useMemo(() => {
    if (!job?.salary) {
      return null;
    }

    return job?.salary;
  }, [job]);

  const handleSave = useCallback(async () => {
    let saveOrRemoveVacancy = job.hasUserSaved ? null : job;
    setShouldFillStar(!!saveOrRemoveVacancy);

    const getVacancyData =
      client.readQuery({
        query: GET_VACANCIES,
        variables: {
          shouldGetStaticCompanies: true,
        },
      })?.getVacancyData || {};

    const vacancies = getVacancyData?.vacancies || [];
    const staticCompanies = getVacancyData?.staticCompanies || [];

    const parsedVacancies = vacancies.map((vacancy) => {
      let hasUserSaved = vacancy.hasUserSaved;

      if (job._id === vacancy._id) {
        hasUserSaved = !!saveOrRemoveVacancy;
      }

      return {
        ...vacancy,
        hasUserSaved,
      };
    });

    client.writeQuery({
      query: GET_VACANCIES,
      data: {
        getVacancyData: {
          vacancies: parsedVacancies,
          staticCompanies,
        },
      },
    });

    if (saveOrRemoveVacancy) {
      const savedVacancies =
        client.readQuery({ query: GET_SAVED_VACANCIES })?.savedVacancies || [];

      client.writeQuery({
        query: GET_SAVED_VACANCIES,
        data: {
          savedVacancies: [job, ...savedVacancies],
        },
      });
    } else {
      const savedVacancies =
        client.readQuery({ query: GET_SAVED_VACANCIES })?.savedVacancies || [];

      client.writeQuery({
        query: GET_SAVED_VACANCIES,
        data: {
          savedVacancies: savedVacancies.filter(
            (vacancy) => vacancy._id !== job._id
          ),
        },
      });
    }

    await saveVacancy({
      variables: { vacancyId: job._id },
    });
  }, [saveVacancy, job, client]);

  return (
    <>
      {isSeeMoreModalOpen && (
        <SeeMoreModal
          job={job}
          isSeeMoreModalOpen={isSeeMoreModalOpen}
          setSeeMoreModal={setIsSeeMoreModalOpen}
        />
      )}

      {isOpenModalMessage && (
        <ModalMessage
          onClose={onClose}
          job={job}
          isOpenModalMessage={isOpenModalMessage}
          setIsOpenModalMessage={setIsOpenModalMessage}
        />
      )}

      <div
        className=" relative p-4  xl:min-w-96 min-h-80 "
        style={{ zIndex: "999 !important" }}
      >
        <div className="rounded-tl-3xl rounded-tr-3xl rounded-bl-2xl rounded-br-2xl">
          <Link to={`/companies/${job.company._id}`} className="">
            <img
              className=" w-fit max-h-[110px] "
              src={job.company.photo}
              alt={job.company.name}
            />
          </Link>
        </div>

        <div className=" whyte-medium  text-[20px] xl:text-[24px] mt-4 ">
          {job.jobName}
        </div>
        <Link
          to={`/companies/${job.company._id}`}
          className="   "
          style={{ color: "#A3A3A3", fontSize: "16px" }}
        >
          {job.company.name}
        </Link>

        <div>
          <span className="font-normal text-[14px] text-[#2E9367]">
            Posted {timeSincePost}
          </span>
        </div>
        <hr className="my-2" />

        <div className="flex flex-wrap whyte font-normal  w-full text-[#546787]  text-[16px] ">
          {job.period ? <>{job.period} - </> : <></>}
          {job.salary ? <>{parsedSalary} per hour - </> : <></>}
          {job.category ? <>{job.category} - </> : <></>}
          {job.disponibleVacancies ? (
            <>
              {job.disponibleVacancies} position
              {job.disponibleVacancies !== 1 && "s"} -
            </>
          ) : (
            <></>
          )}
          {job.hourPerWeek ? <>{job.hourPerWeek} hours - </> : <></>}
          {job.contractType ? <>{job.contractType} </> : <></>}
        </div>

        <div className="relative w-full h-20 overflow-hidden">
          <footer className="mt-4 z-10 absolute left-2/4 -translate-x-2/4 flex items-center justify-center text-white rounded-xl w-full">
            <Button
              onClick={handleModal}
              className="ml-3 whyte-medium text-[18px]  rounded-full  h-[52px]  px-6"
            >
              Quick Apply
            </Button>

            <Button
              invert
              // onClick={() => setIsSeeMoreModalOpen(true)}
              onClick={handleSeeMore}
              className="ml-3 border-none whyte-medium text-[18px]  rounded-full  h-[52px]  px-6"
            >
              Job Details
            </Button>

            {userIsLogin ? !job.hasUserApplied && (
              <button
                onClick={handleSave}
                className="bg-none border-0 text-white ml-3"
              >
                <FiStar
                  size={24}
                  className={
                    "text-slate-800 fill-white hover:fill-yellow-500 hover:text-yellow-500 transition duration-500 " +
                    (shouldFillStar ? "fill-yellow-500 text-yellow-500" : "")
                  }
                />
              </button>
            ) : null}
          </footer>

          <div className="absolute bottom-0 right-0"></div>
        </div>
      </div>
    </>
  );
}
