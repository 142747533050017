import React, { forwardRef } from 'react'
import { Modal } from '../NewModal/Modal';
import Cookie from "../../assets/banners/cookie.png";

export const CookiePolicy = forwardRef(({
  className,
  ariaLabel,
  value,
  maxLength = 100,
  shouldShowCookiePolicy,
  setShouldShowCookiePolicy,
  ...props
}, ref) => {

  return (
    <>
      <Modal  width={"90%"} className=" z-50" maxWidth={800} height={600} closeModal={() => setShouldShowCookiePolicy(false)} isOpen={shouldShowCookiePolicy}>
        <div className=' p-3 xl:p-12'>
          <img className="mb-5 rounded-3xl" src={Cookie} alt="Privacy Policy Image" /> 
          <h1 className='mt-5'>WHAT ARE COOKIES?</h1>
          <p className='mt-5'>
            Cookies are very small text files which store very small amounts of information. They are used to remember settings and preferences between visits to the site, as well as information between different pages on the same site.<br /><br />
            For security reasons, cookies can only be read by the site that set them. We have no access to cookies set on your computer by, for example, Google, Yahoo, Microsoft or any other website on the internet. However, through the use of third party cookies, it is possible for sites to collect information about visitors to other sites. For example, online advertisers may use third party cookies to anonymously track an individual’s visits to sites on the web.<br /><br />
            More information about how we allow third party cookies on our sites is given below.<br /><br />
            To find out more about cookies in general, you might find the following link useful:<br />

            <ul className='list-disc ml-12'>
              <li>Wikipedia: HTTP Cookies</li>
            </ul>

          </p>
          
          <h1 className='mt-5'>HOW DO WE USE COOKIES?</h1>
          <p>
            Currently, there are four types of cookies that may be generated as you browse our site: session cookies, preference cookies, analytics cookies and third party cookies.
          </p>

          <h1 className='mt-5'>1. Session Cookies</h1>
          <p>
            As you move through the site, various pieces of information need to be stored in order for the site to function properly. For example, if you perform a search for jobs, the search terms you use will be remembered so that you can change them later. If you log in, we will remember which account you logged in with.<br /><br />
            This information is stored in a database and is referred to as a session. We set one cookie to keep track of which session you are using. Old session data is automatically deleted from our databases multiple times a day, so we do not store this information long-term.<br /><br />
            In addition to the session cookie, we sometimes set a cookie to check if we can set cookies on your computer. This is done for diagnostic purposes when you use our contact form. We use this information to help solve any problems you may have encountered.
          </p>

          <h1 className='mt-5'>How do I control session cookies?</h1>
          <p>
            You can use your browser settings to control whether or not we set session cookies.<br /><br />
            Please be aware that session cookies are critical to the working of the site. If you choose to disable all cookies from this site, you will not be able to log in and the functionality of the site will be greatly reduced.
          </p>

          <h1 className='mt-5'>2. Preference Cookies</h1>
          <p>
            Currently we only set two types of preference cookie. One is set if you manually switch between mobile or desktop versions (e.g. view the desktop version of the site when on a mobile phone). Your choice will be stored in a cookie. The other is set when you provide cookie consent by confirming your cookie preference on either the main cookie banner or by confirming your consent after clicking through to the consent preferences dialog displayed after you click on the 'Setting' link in the main cookie banner.
          </p>

          <h1 className='mt-5'>How do I control preference cookies?</h1>
          <p>
            These cookies are set as a direct result of your choice, and function solely as indicators of what that choice was. We do not believe they pose any risk to your privacy. However, you can always use your browser settings to control whether or not our site can set cookies on your computer.<br /><br />
            Please be aware that cookies are critical to the working of the site. If you choose to disable all cookies from this site, you will not be able to log in and the functionality of the site will be greatly reduced.<br /><br />
            When you visit the Website, we check if you have provided consent in the last 6 months. If you have not provided consent in the last 6 months, then we display a cookie consent banner and ask for your consent and only trigger necessary cookies and similar tracking technologies until consent is provided. If you have provided consent for all cookies and similar technologies in the last 6 months, then we trigger all cookies and similar tracking technologies for 6 months from when this consent was provided. 
          </p>

          <h1 className='mt-5'>3. Analytics Cookies</h1>
          <p>
            In order to monitor how our sites are performing, we collect data about page visits. This information is completely anonymous — we cannot determine who it came from. When we use this data, we look at numbers of visitors overall rather than individual visits.<br /><br />
            Analytics information is used in reports and to improve our site. For example, we have used analytics data to add, remove or change features of the site based on how popular they are with users.<br /><br />
          </p>

          <p>
            We track, for example:
          </p>

          <ul className='list-disc ml-12'>
            <li>the numbers of visitors to individual pages;</li>
            <li>when pages were visited;</li>
            <li>how long users stayed on the page;</li>
            <li>the page they came from;</li>
            <li>technical data about the device being used to browse the site (such as browser and operating system version, and display size) — we use this data to ensure that the technology our site uses is appropriate for the majority of our users, and how best to display the site for users who have less capable browsers.</li>
          </ul>

          <h1 className='mt-5'>More information about analytics cookies</h1>
          <p>
          We use Google Analytics, that enables us to optimize our services in line with your requirements. You can read more about how Google Analytics treats your data at: <u>Google: Safeguarding your data.</u> You can also read <u>Google’s full privacy policy.</u><br /><br />
          Google Analytics uses cookies that are stored on your computer and enable an analysis of your use of the website. The information generated by the cookie about your use of this website (including your IP address) is transferred to servers of the service in Ireland where it is anonymised. Google uses this information to evaluate your use of the website to compile reports on website activities for the website operators and to provide further services connected with the use of the website and the Internet.<br /><br />
          No personal data is stored because of the anonymization.<br /><br />
          As a user of our websites you of course have the option to block cookies at any time in your browser settings.<br /><br />
          The legal basis for processing this data is a legitimate interest under Art. 6 (1) (f) GDPR. The legitimate interest as defined by Art. 6 (1) (f) GDPR that we are pursuing by processing the data described above is our interest in structuring our offerings in a user and demand-driven manner. No conflicting interest is apparent, especially because you may opt out at any time.
          </p>

          <h1 className='mt-5'>How do I control analytics cookies?</h1>
          <p>
            We will not trigger Google Analytics tracking unless you have provided consent for all cookies and similar technologies in the last 6 months from the main cookie consent banner or from the cookie preferences dialog.<br /><br />
            Google provides a <u>tool to opt-out of Google Analytics</u>. This is available for all modern browsers in the form of a browser plugin.
          </p>

          <h1 className='mt-5'>4. Third party cookies</h1>
          <p>
            The advertisements you see displayed on the site are generated by third parties. These services may use their own anonymous cookies to track how many times a particular ad has been displayed to you, and may track your visits to other sites where their ads are displayed. Please note that advertisers can only track visits to sites which display their ads or other content. This tracking is anonymous, meaning that they do not identify who is viewing which ads.<br /><br />
            Tracking in this manner allows advertisers to show you ads that are more likely to be interesting to you, and also limit the number of times you see the same ad across a wide number of sites. As an example of how advertisers operate, if you recently researched holidays, adverts for holiday operators may subsequently appear on our sites.<br /><br />
            We do not have access to, or control over, these third party cookies; nor can we view the data held by these advertisers. Online advertisers provide ways for you to opt-out of your browsing being tracked. More information about the advertisers we use, and how to opt-out, is provided below.
          </p>
          
          <h1 className='mt-5'>Google AdSense</h1>
          <p>
            We display adverts from Google. Google ads are covered by the same privacy policy as Google Analytics.<br /><br />
            You can discover how Google protects your privacy relating to ads at Google’s Advertising privacy FAQ . This page also allows you to control what cookies Google AdSense saves on your computer.
          </p>

          <h1 className='mt-5'>More information about online advertising and tracking</h1>
          <p>
            The International Advertising Bureau has produced a website to give <u>more information about privacy and advertising on the internet</u>.
          </p>
          
          <h1 className='mt-5'>CONTROLLING COOKIES WITHIN YOUR BROWSER</h1>
          <p>
            A browser is a program you use to view web pages. Your browser allows you to control what cookies are set on your computer, and how long they are stored. You may have more than one browser installed on your computer. In this case, you will need to change the settings for each browser you use.
          </p>

          <h1 className='mt-5'>Browser help pages relating to cookie settings</h1>
          <p>
            Browser manufacturers provide help pages relating to cookie management in their products. Please see below for more information.
          </p>

          <ul className='list-disc ml-12'>
            <li>Internet Explorer</li>
            <li>Google Chrome</li>
            <li>Mozilla Firefox</li>
            <li>Safari (Desktop)</li>
            <li>Safari (Mobile)</li>
            <li>Android Browser</li>
            <li>Opera</li>
            <li>Opera Mobile</li>
          </ul>
          
          <br />

          <p>
            For other browsers, please consult the documentation that your browser manufacturer provides.
          </p>

        </div>
      </Modal>
    </>
  )
});