import React, { useEffect, useRef, useState } from 'react'
import { useMap, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import { purpleDot } from '../../utils/map/marker';
import { animatedCircle } from '../../utils/map/AnimateLeaflet';
import EnterprisePopup from '../../Components/EnterprisePopup/NewEnterprisePopup';
import NonOfficialEnterprisePopup from '../../Components/NonOfficialEnterprisePopup/NonOfficialEnterprisePopup';
import { blueDot } from '../../utils/map/blueDot';
import { grayMarker } from '../../utils/map/gray-marker';
import { nonOfficialCompanyPin } from '../../utils/map/nonOfficialCompanyPin';
import { userLocationPin } from '../../utils/map/userLocationPin';
import { nanoid } from 'nanoid';
import { Slider } from '../../Components/Slider/Slider';

export function MapContent({ jobs, userPos, searchedKm, focusedPos, setFocusedPos, refetch, filteredStaticCompanies, openModalNeedLogin, setOpenModalNeedLogin }) {
  const [token, setToken] = useState(false);

  const map = useMap();
  let previousSearchedKm = useRef(null);
  useMapEvents({
    click(event) {
      setFocusedPos([event.latlng.lat, event.latlng.lng]);
    }
  });

  useEffect(() => {
    let searchedRadiusCircle = null;

    if (searchedKm) {
      searchedRadiusCircle = animatedCircle(
        map,
        [focusedPos[0], focusedPos[1]],
        {
          fromRadius: (previousSearchedKm.current || 0) * 1000,
          toRadius: searchedKm * 1000,
        }
      )
    }

    return () => {
      previousSearchedKm.current = searchedKm;

      if (searchedRadiusCircle) {
        searchedRadiusCircle.remove();
      }
    };
  }, [map, focusedPos, searchedKm]);

  useEffect(() => {
    const tokenLocalStorage = localStorage.getItem("@Swanted/Token");
    const thereIsAToken = tokenLocalStorage !== null && tokenLocalStorage !== undefined;
    return setToken ? setToken(thereIsAToken)  : () => {};
  })

  useEffect(() => {
    map.setView(focusedPos, map.getZoom(), {
      animate: true,
      duration: 1,
    });
  }, [focusedPos, map]);

  
  return (
    <>
      <TileLayer 
        url={`https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}.png?access-token=${process.env.REACT_APP_MAP_ACCESS_TOKEN}`}
        subdomains={['mt0','mt1','mt2','mt3']}
        maxZoom={20}
        attribution={'<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank" class="jawg-attrib">&copy; <b>Jawg</b>Maps</a> | <a href="https://www.openstreetmap.org/copyright" title="OpenStreetMap is open data licensed under ODbL" target="_blank" class="osm-attrib">&copy; OSM contributors</a>'}
      />
        {(userPos[0] !== focusedPos[0] && userPos[1] !== focusedPos[1])
          && (
            <Marker
              position={[userPos[0], userPos[1]]}
              icon={userLocationPin}
            />
          )
        }

        <Marker
          position={[focusedPos[0], focusedPos[1]]}
          icon={blueDot}
        />

        {jobs.map(job => (
          <Marker
            key={nanoid()}
            position={[Number(job.vacancy[0]?.company.latlon[0]), Number(job.vacancy[0]?.company.latlon[1])]}
            icon={job.vacancy.some(item => item.hasUserApplied) ? grayMarker : purpleDot}
          >
            <Popup >
              <Slider
                arrows
                className="h-fit-content w-[300px] xl:w-[350px] md:w-[384px] overflow-visible"
                style={{
                  zIndex:"999 !important"
                }}
              >
                {job.vacancy.map((jobVacancy) => (
                  <EnterprisePopup  
                    key={jobVacancy._id} 
                    onClose={() => {}} 
                    job={jobVacancy} 
                    refetch={refetch} 
                    userIsLogin={token} 
                    setOpenModalNeedLogin={setOpenModalNeedLogin} 
                  />
                ))}
              </Slider>
            </Popup>
          </Marker>
        ))}
        {filteredStaticCompanies.map(company => (
          <Marker
            key={nanoid()}
            position={[Number(company.company[0]?.latlon[0]), Number(company.company[0]?.latlon[1])]}
            icon={nonOfficialCompanyPin}
          >
            <Popup>
              <Slider
                arrows
                className="h-fit-content w-[350px] md:w-[384px] overflow-visible"
              >
                {company.company.map((staticCompanyCompany) => (
                  <NonOfficialEnterprisePopup 
                    key={staticCompanyCompany._id} 
                    onClose={() => {}} 
                    company={staticCompanyCompany} 
                    userIsLogin={token}
                    setOpenModalNeedLogin={setOpenModalNeedLogin} 
                  />
                ))}
              </Slider>
            </Popup>
          </Marker>
        ))}
    </>
  )
}
