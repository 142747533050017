import { FiTrash } from "react-icons/fi";

export function JobExperience({ handleRemoveExperience, experience }) {
  return (
    <>
      <div className="flex w-full min-h-16   my-4 border-[1px]  border-[#A3A3A3] rounded-[20px] p-10">
        <div className="flex w-full h-full  ">
          <div className="flex flex-col">
            <div className="whyte-bold text-[20px] lg:text-[24px] ">
              {experience.jobTitle}{" "}
            </div>
            <div className="whyte-bold text-[16px] lg:text-[18px] text-[#A3A3A3] ">
              {experience.companyName}
            </div>

            <div className="flex items-center text-[16px] lg:text-[18px] text-[#2E9367]  whyte">
              <span>
                {experience?.startDate?.slice(
                  experience?.startDate?.indexOf("-") + 1,
                  experience?.startDate?.length
                ) +
                  "-" +
                  experience?.startDate?.slice(
                    0,
                    experience?.startDate?.indexOf("-")
                  )}
              </span>

              <div className="h-px w-2 bg-gray-700 mx-2"></div>
              <span>
                {experience.currentlyWorking
                  ? "present"
                  : experience?.endDate?.slice(
                      experience?.endDate?.indexOf("-") + 1,
                      experience?.endDate?.length
                    ) +
                    "-" +
                    experience?.endDate?.slice(
                      0,
                      experience?.endDate?.indexOf("-")
                    )}
              </span>
            </div>
            <div className="flex whyte-bold text-[16px] lg:text-[18px] text-[#A3A3A3]">
              <span className="text-sm text-gray-400">
                {experience.jobCategory}
              </span>
            </div>

            <div>
              <p className="mt-3 text-[#546787] text-[16px] lg:text-[18px] whyte ">
                {experience.jobDescription?.trim().length
                  ? `${experience.jobDescription}`
                  : ""}
              </p>
            </div>
          </div>
        </div>

        {handleRemoveExperience && (
          <button
            onClick={handleRemoveExperience}
            className="h-full w-4 mr-4 mt-2 flex-col"
          >
            <FiTrash size={16} />
          </button>
        )}
      </div>
    </>
  );
}
