import React, { useState, useEffect } from "react";
import { CardVideo, CardVideosImage, JobSeekerInfo, JobSeekerInfoContent} from "./styles";
import { Modal } from "../../Components/NewModal/Modal";
import { useAuth } from "../../hooks/Auth";
import { PaymentButtons } from "../PaymentButtons/PaymentButtons";

export const CardVideos = ({ onClick, jobSeeker, userData }) => {
  const [openModal, setOpenModal] = useState(false);
  const { user } = useAuth();
  
  const textUserIsNotPremium = (
    <>
      <div style={{ minWidth: "200px" }}>
        
        <div className="flex justify-center whyte-bold text-[20px] xl:text-[24px] leading-[112%] h-[45px] flex items-center gap-[10px] xl:gap-[120px]">
          Sorry, you need to upgrade to see all these wonderful profiles 
        </div>

        <div className="mx-[20px] my-[30px] gotham text-[16px] xl:text-[18px] 2xl:text-[18px] font-normal leading-[112%] text-[#4B4B4B] max-w-[100%] xl:max-w-[700px] 2xl:max-w-[820px] max-h-[324px]">
          To view these profiles you must choose your membership option.
        </div>
      
        <PaymentButtons user={user} />

      </div>
    </>
  );

  const verifyPremiumUser = () => {
    const { isPremium, dueDate } = userData?.getUserPremiumInfo;

    // console.log('dueDate', dueDate);
    // console.log('typeof dueDate', typeof dueDate);

    // const parsedDueDate = new Date(dueDate);
    // const currentDate = new Date();
    // const isCurrentDateGreaterThanDueDate = currentDate > parsedDueDate;

    // console.log('isCurrentDateGreaterThanDueDate', isCurrentDateGreaterThanDueDate);

    const userIsPremium = isPremium;

    if(userIsPremium){ 
      return true 
    } 
    else {
      setOpenModal(true)
      return false;
    }
  }

  const handleClick = () => {
    console.log('verifyPremiumUser()', verifyPremiumUser());

    if(verifyPremiumUser() === true) {
      onClick()
    }
  }
  
  return (
    <>

      {openModal ? <Modal
        children={textUserIsNotPremium}
        closeModal={() => setOpenModal(false)}
        isOpen={true}
      /> : null}

      <li
        className="w-full h-[375px] flex flex-col items-center justify-center text-center transition duration-200 ease-in-out"
        style={{ position: "relative" }}
        onClick={() => handleClick()}
      >
        <img
          className="peer object-cover object-center w-full h-[375px] bg-gray-400 rounded-md"
          src={jobSeeker.photo}
          alt={jobSeeker.name}
        />
        <div className="cursor-pointer bg-gradient-to-t from-black/80 to-transparent absolute flex flex-col justify-end items-center p-8 opacity-0 top-0 right-0 left-0 bottom-0 peer-hover:opacity-100 hover:opacity-100 transition duration-500">
          <h2 className="text-slate-200">
            {jobSeeker.name} {jobSeeker.surname}
          </h2>
          <h3 className="text-slate-200">{jobSeeker.lookingFor} </h3>
        </div>
      </li>
    </>
  );
};
