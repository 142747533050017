import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { CardVideos } from "../../../Components/CardVideos/CardVideos";
import { Navbar } from "../../../Components/Navbar/NewNavbar";
import { Footer } from "../../../Components/Footer/Footer";
import { JobSeekerModal } from "../../../Components/JobSeekerModal/JobSeekerModal";
import { GET_JOBSEEKERS } from "./JobSeekerPainel.services";
import { PREMIUM_INFO } from '../../../services/Payment';
import { LoadingScreen } from "../../../Components/LoadingScreen/LoadingScreen";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Select } from "../../../Components/Forms/Select/Select";
import { useForm } from "react-hook-form";
import { Button } from "../../../Components/NewButton/Button";
import { turnAnyInNull } from "../../../utils/turnAnyInNull";
import { jobCategoryCandidate } from "../../../utils/jobCategoryCandidate";
import arrow from "../../../assets/homePage/job-arrow.png";
import { useAuth } from "../../../hooks/Auth";
import "../../HomePage/styles.css";
import { useNavigate } from "react-router-dom";

const distances = [
  { value: "Any", label: "Any" },
  { value: 1, label: "1 km" },
  { value: 2, label: "2 km" },
  { value: 5, label: "5 km" },
  { value: 10, label: "10 km" },
  { value: 15, label: "15 km" },
  { value: 20, label: "20 km" },
];

const coverVideoOptions = [
  { value: "Any", label: "Any" },
  { value: true, label: "Only with Intro Video" },
];

const availabilities = [
  { value: "Any", label: "Any" },
  { value: "morning", label: "Morning" },
  { value: "afternoon", label: "Afternoon" },
  { value: "night", label: "Night" },
  { value: "weekends", label: "Weekends" },
];

const parsedJobCategories = [
  { value: "Any", label: "Any" },
  ...jobCategoryCandidate.filter((item) => item !== ""),
];

const lookingFor = ["Any", "Part Time", "Full Time"];

export const JobSeekerPainel = () => {
  const navigator = useNavigate();
  const [parent] = useAutoAnimate();
  const { handleSubmit, register, control } = useForm();
  const { user } = useAuth();  
  const { data, loading, refetch } = useQuery(GET_JOBSEEKERS, { variables: { filters: {} } });
  const { data: userData, refetch: refetchPremium } = useQuery(PREMIUM_INFO, { variables: { id: user?.company?._id } });
  const [modalJobSeeker, setModalJobSeeker] = useState(null);

  const jobSeekers = useMemo(() => {
    if (loading) {
      return [];
    }

    return data.jobSeekers;
  }, [data]);

  useEffect(() => {
    refetchPremium()
  }, [data, userData])

  const onSubmit = useCallback(
    async (data) => {
      const {
        distance,
        coverVideo,
        experienceIn,
        availability,
        lookingFor: dataLookingFor,
      } = turnAnyInNull(data);

      await refetch({
        filters: {
          distance: Number(distance),
          coverVideo: Boolean(coverVideo),
          experienceIn,
          availability,
          lookingFor: dataLookingFor,
        },
      });
    },
    [refetch]
  );

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-gray-50 w-full h-full pt-[20px]  ">
      {modalJobSeeker && (
        <JobSeekerModal
          setModalJobSeeker={setModalJobSeeker}
          modalJobSeeker={modalJobSeeker}
        />
      )}
      <Navbar />

      <div className="bg-gray-50  flex min-h-[93vh] pt-24 xl:pt-4">
        <div className="flex flex-col items-center w-full">
          <div className="whyte-bold  text-[24px] xl:text-[32px] w-fit self-start px-14">
            JobSeekers Database
          </div>
          <div className="flex w-full flex-col md:flex-row md:p-0 md:px-16">
            <div className="flex flex-col items-center mb-16 xl:mt-10 md:mr-10 md:mt-0 px-6 md:px-0">
              <form
                className="w-full flex flex-col bg-white min-w-[200px]  p-6 border-[1px] border-[#D3D3D3] rounded-[20px] "
                onSubmit={handleSubmit(onSubmit)}
              >
                <Select
                  control={control}
                  className="whyte-bold text-base xl:text-[18px] w-full  "
                  label="Distance"
                  options={distances}
                  {...register("distance")}
                />

                <Select
                  control={control}
                  className="mt-4 whyte-bold text-base xl:text-[18px]  w-full "
                  label="Intro Video"
                  options={coverVideoOptions}
                  {...register("coverVideo")}
                />

                <Select
                  control={control}
                  className="mt-4 whyte-bold text-base xl:text-[18px]  w-full "
                  label="Looking for"
                  options={lookingFor}
                  isMulti
                  {...register("lookingFor")}
                />

                <Select
                  control={control}
                  className="mt-4 whyte-bold text-base xl:text-[18px]  w-full "
                  label="Experience"
                  options={parsedJobCategories}
                  isMulti
                  {...register("experienceIn")}
                />

                <Select
                  control={control}
                  className="mt-4 whyte-bold text-base xl:text-[18px]  w-full "
                  label="Availability"
                  options={availabilities}
                  isMulti
                  {...register("availability")}
                />

                <Button
                  type="submit"
                  className="mt-4 rounded-full whyte-bold text-[18px] xl:text-[22px] "
                >
                  Apply Filters
                </Button>
              </form>
            </div>

            <ul
              className="list-none  px-[3%] xl:px-0 grid mt-10 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 w-screen"
              ref={parent}
            >
              {jobSeekers.map((jobSeeker) => (
                <React.Fragment key={jobSeeker._id}>
                  <CardVideos
                    onClick={() => navigator("/painel/profile", { state: jobSeeker })}
                    jobSeeker={jobSeeker}
                    userData={userData}
                  />
                </React.Fragment>
              ))}
            </ul>

            {/* <div
              className=" flex gap-[25px] h-fit flex-wrap "
              // ref={parent}
            >
              {jobSeekers.map((jobSeeker) => (
                <React.Fragment key={jobSeeker._id}>
                  <div
                    onClick={() =>
                      navigator(`/painel/profile`, {state:jobSeeker})
                      // setModalJobSeeker({ ...jobSeeker, isFromDatabase: true })
                    }
                    className="flex  justify-between items-center border-[1px] border-[#D3D3D3] px-10 py-5 bg-white xl:min-w-[500px] max-w-[544px] h-[134px] rounded-[20px] "
                  >
                    <div className=" flex  items-center gap-[25px]  ">
                      <img
                        className="w-[80px] h-[80px]  rounded-full"
                        src={jobSeeker.photo}
                        alt={jobSeeker.name}
                      />
                      <div>
                        <span className=" text-[20px] gotham-bold  ">
                          {jobSeeker.name} {jobSeeker.surname}
                        </span>
                        <br />
                        <span className=" text-[#546787] text-[20px] whyte ">
                          {jobSeeker?.experiences[0]?.jobTitle}
                        </span>
                      </div>
                    </div>
                    <div>
                      <img src={arrow} alt={jobSeeker.name} />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div> */}
          </div>
        </div>
      </div>

      <Footer className="p-5" />
    </div>
  );
};
