import styled from 'styled-components';

export const Input = styled.input`
    padding: 5px;
    height:50px;
    ::placeholder { 
    color: #C5C5C5;
    opacity: 1; /* Firefox */
  }
    
`
