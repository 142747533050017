import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $password: String!
    # $countryName: String!
    $companyName: String!
    $companyAddress: String!
    # $companyEircode: String!
    $companyPhoto: FileInput
    $companyManager: String!
    $companyCountryCode: String!
    $companyPhone: String!
    $companyWebsite: String!
    $companyNumberOfEmployees: String!
    $companyRegistrationNumber: String!
    $companyCategory: String!
    $companyDescription: String!
    $companySocialMedias: [SocialMediaInput]
  ) {
    createUser(
      user: {
        email: $email
        password: $password
        company: {
          # countryName: $countryName
          manager: $companyManager
          website: $companyWebsite
          numberOfEmployees: $companyNumberOfEmployees
          companyRegistrationNumber: $companyRegistrationNumber
          companyCategory: $companyCategory
          companyDescription: $companyDescription
          socialMedias: $companySocialMedias
          name: $companyName
          address: $companyAddress
          phone: $companyPhone
          countryCode: $companyCountryCode
          # eircode: $companyEircode
          photo: $companyPhoto
        }
      }
    ) {
      ok
    }
  }
`;
