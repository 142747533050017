import { gql } from "@apollo/client";

export const GET_COMPANY_PROFILE = gql`
  query getCompanyProfile($id: ID!) {
    company(id: $id) {
      _id
      name
      latlon
      address
      eircode
      photo
      owner
      manager
      website
      numberOfEmployees
      companyRegistrationNumber
      companyCategory
      companyDescription
      socialMedias {
        name
        url
      }
      phone
      about
      countryCode
      
      jobs {
        _id
        jobName
        salary
        period
        category
        contractType
        disponibleVacancies
        hourPerWeek
        applicants
        date
        hasUserApplied
        hasUserSaved
        description
        isSalaryNegotiable
        deleted
      }
    }
  }
`;
