import Leaflet from "leaflet";

import pin from "../../assets/swanted-pin-gray.svg";

export const grayMarker = Leaflet.icon({
  iconUrl: pin,

  iconSize: [40, 45],
  iconAnchor: [20, 45],
  popupAnchor: [0, -45 - 8]
});
