import React, { useState, useCallback, Fragment } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { UploadFile } from "../../../Components/UploadFile/UploadFile";
import { Textarea } from "../../../Components/Forms/Textarea/Textarea";
import { InputCheckbox } from "../../../Components/Forms/NewInputCheckbox/InputCheckbox";
import { InputText } from "../../../Components/Forms/NewInputText/InputText";
import { InputDate } from "../../../Components/Forms/NewInputDate/InputDate";
import { Select } from "../../../Components/Forms/NewSelect/Select";
import { Button } from "../../../Components/Button/Button";
import { geoLocationApi } from "../../../services/geolocation";
import { LoadingSpin } from "../../../Components/LoadingSpin/LoadingSpin";
import LanguageNames from "../../../staticData/languageNames.json";
import CountryCodes from "../../../staticData/countryCodes.json";
import ProeficiencyLevels from "../../../staticData/proeficiencyLevels.json";
import { hasTruthyProperty } from "../../../utils/hasTruthyProperty";
import { nanoid } from "nanoid";
import { useRef } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import TextField from "@mui/material/TextField";
import { ErrorMessage } from "@hookform/error-message";
import nextButton from "../../../assets/homePage/next.png";
import backButton from "../../../assets/homePage/back.png";

import "../../HomePage/styles.css";

// const countryNames = CountryCodes.map(country => country.name);
const genders = ["Male", "Female", "Other"];
const lookingFor = ["Part Time", "Full Time"];
const languageNames = LanguageNames.map((language) => language.name);
const proeficiencyLevels = ProeficiencyLevels.map((level) => level.name);

export function GeneralInformation({ stepBack, formData, onSubmitStep }) {
  // const [isLoadingAddressSearch, setIsLoadingAddressSearch] = useState(false);
  // const [errorInAddressSearch, setErrorInAddressSearch] = useState(false);
  const [errorUserDidNotAddImage, setErrorUserDidNotAddImage] = useState(false);
  // const [hasSearchedAddress, setHasSearchedAddress] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...formData,
      // 'countryName': 'Ireland',
      languagesName: "English",
      // 'languagesProeficiency': 'Fluent',
    },
  });
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "languages",
  });
  const availabilityRef = useRef({});
  const countryNameRef = useRef({});
  const addressRef = useRef({});

  availabilityRef.current = watch("availability", {});
  countryNameRef.current = watch("countryName", {});
  addressRef.current = watch("address", {});

  const [searchBox, setSearchBox] = useState();

  const onLoad = (ref) => setSearchBox(ref);

  const onPlacesChanged = () => searchBox?.getPlaces();

  // const handleFetchAddress = useCallback(async (event, type) => {
  //   const inputValue = event?.target?.value || null;

  //   let countryNameValue = type === 'countryName' ? inputValue : countryNameRef.current;
  //   let addressInputValue = type === 'address' ? inputValue :  addressRef.current;

  //   if (!addressInputValue || !countryNameValue) return;

  //   setIsLoadingAddressSearch(true);
  //   const parsedAddress = `${addressInputValue},${countryNameValue}`.replaceAll(" ", "+")

  //   const { data: apiData } = await geoLocationApi.get(`/search?addressdetails=1&q=${parsedAddress}&format=json&limit=1`)

  //   setIsLoadingAddressSearch(false);
  //   setHasSearchedAddress(true);
  //   if (apiData.length === 0) {
  //     setErrorInAddressSearch(true);
  //     return;
  //   }

  //   setErrorInAddressSearch(false);
  // }, []);

  const onSubmit = useCallback(
    (data) => {
      if (!profilePhoto) {
        setErrorUserDidNotAddImage(!errorUserDidNotAddImage);
        return;
      }
      onSubmitStep({ profilePhoto, ...data });
    },
    [onSubmitStep, errorUserDidNotAddImage, profilePhoto]
  );

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className=" whyte text-[16px] xl:text-[24px] text-[#A3A3A3] ">
        Let’s get to know your basic information
      </div>
      {/* <h1 className="mt-3">General Information</h1> */}
      <div className="flex xl:flex-row flex-col ">
        <div className="flex flex-col w-full xl:w-[90%] ">
          <section className="">
            <div className="w-full flex  flex-col xl:flex-row xl:gap-[30px] ">
              <div className="w-full xl:w-1/2">
                <div className=" mt-[20px] xl:mt-0 xl:my-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
                  Date of Birth*
                </div>
                <InputDate
                  style={{ marginTop: "7px" }}
                  className=" "
                  {...register("birthDate", { required: "input birth date" })}
                />
                <ErrorMessage
                  name="birthDate"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>

              <div className=" w-full xl:w-1/2">
                <div className=" mt-[20px] xl:mt-0 xl:my-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
                  Gender*
                </div>
                <Select
                  control={control}
                  options={genders}
                  {...register("gender", { required: "input gender" })}
                />
                <ErrorMessage
                  name="gender"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>
            </div>

            {/* <Select
          control={control}
          options={countryNames}
          label="Country Name*"
          defaultValue="Ireland"
          className="mt-3"
          rules={{ required: true }}
          {...register("countryName", { onChange: ev => handleFetchAddress(ev, 'countryName') })}
        />
        
        <InputText placeholder={'EirCode'} className="mt-3" {...register('eirCode')} /> */}

            <div className="grid grid-cols-1 gap-0 sm:grid-cols-2 sm:gap-4">
              <div>
                <div className=" xl:mt-[39px] xl:mb-[20px] mt-[20px]   whyte-bold text-[24px] xl:text-[32px]">
                  Address*
                </div>
                <div className="pt-[8px]">
                  <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={onPlacesChanged}
                  >
                    <InputText
                      className=" col-span-2"
                      {...register("address", { required: "input address" })}
                    />
                  </StandaloneSearchBox>

                  <ErrorMessage
                    name="address"
                    errors={errors}
                    render={({ message }) => (
                      <p className="text-red-500">{message}</p>
                    )}
                  />
                </div>

                <div className="flex flex-col">
                  <small className="mt-3 text-[#6c6c6c]">
                    Example: 157 Thomas Street, Dublin 8
                  </small>
                </div>
              </div>

              <div className="mt-3">
                <div className=" mt-[20px] xl:mt-[20px] xl:my-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
                  Looking for*
                </div>

                <Select
                  control={control}
                  options={lookingFor}
                  {...register("lookingFor", { required: "input looking for" })}
                />

                <ErrorMessage
                  name="lookingFor"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>
            </div>
          </section>

          {/* <hr className="my-5 h-1 bg-[#6c6c6c]" /> */}

          <section>
            <div className=" xl:mt-[40px] xl:mb-[10px]  mt-[20px]    whyte-bold text-[24px] xl:text-[32px] ">
              Availability*
            </div>

            <div className="mt-3">
              <div className="flex  xl:gap-[30px] ">
                <InputCheckbox
                  placeholder="Morning"
                  {...register("availability.morning", {
                    required: !hasTruthyProperty(availabilityRef.current),
                  })}
                />
                <InputCheckbox
                  placeholder="Afternoon"
                  className="pl-2 ml-2"
                  {...register("availability.afternoon", {
                    required: !hasTruthyProperty(availabilityRef.current),
                  })}
                />
                <InputCheckbox
                  placeholder="Night"
                  className="pl-2 ml-2"
                  {...register("availability.night", {
                    required: !hasTruthyProperty(availabilityRef.current),
                  })}
                />
                <InputCheckbox
                  placeholder="Weekends"
                  className="pl-2 ml-2"
                  {...register("availability.weekends", {
                    required: !hasTruthyProperty(availabilityRef.current),
                  })}
                />
              </div>

              <div className=" mt-[30px]  whyte-bold text-[24px] xl:text-[32px] ">
                Notes (Keep It Short)
              </div>

              <Textarea
                label={""}
                placeholder={
                  "Example: Free from 9am - 1:30 pm Monday to Friday."
                }
                className="mt-3"
                rows={6}
                maxLength={200}
                {...register("availabilityNote", {
                  required: !hasTruthyProperty(availabilityRef.current),
                })}
              />
            </div>
          </section>

          {/* <hr className="my-5 h-1 bg-[#6c6c6c]" /> */}

          <section>
            <div className="xl:grid   xl:grid-cols-2 xl:gap-4">
              <div>
                <div className=" mt-[30px] xl:mb-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
                  Languages*
                </div>
                <Select
                  control={control}
                  options={languageNames}
                  defaultValue="English"
                  label=""
                  className="m-1"
                  {...register("languagesName", { required: "input language" })}
                />
                <ErrorMessage
                  name="languagesName"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>

              <div>
                <div className=" mt-[30px] xl:mb-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
                  Proeficiency*
                </div>
                <Select
                  control={control}
                  options={proeficiencyLevels}
                  defaultValue="Fluent"
                  label=""
                  className="m-1"
                  {...register("languagesProeficiency", {
                    required: "input language proeficiency",
                  })}
                />
                <ErrorMessage
                  name="languagesProeficiency"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>

              {fields.map((field, index) => (
                <Fragment key={field.id}>
                  <div className="mt-[30px] xl:mt-0 flex flex-col ">
                    <div className=" xl:mb-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
                      Languages*
                    </div>
                    <Select
                      control={control}
                      options={languageNames}
                      defaultValue="English"
                      className="m-1"
                      label=""
                      {...register(`languages.${index}.name`, {
                        required: "input language",
                      })}
                    />
                    <ErrorMessage
                      name={`languages.${index}.name`}
                      errors={errors}
                      render={({ message }) => (
                        <p className="text-red-500">{message}</p>
                      )}
                    />
                  </div>
                  <div className="flex flex-col ">
                    <div className=" xl:mb-[20px]  whyte-bold text-[24px] xl:text-[32px] ">
                      Proeficiency*
                    </div>
                    <Select
                      control={control}
                      options={proeficiencyLevels}
                      defaultValue="Fluent"
                      className="m-1"
                      label=""
                      {...register(`languages.${index}.proeficiency`, {
                        required: "input language proeficiency",
                      })}
                    />{" "}
                    <ErrorMessage
                      name={`languages.${index}.proeficiency`}
                      errors={errors}
                      render={({ message }) => (
                        <p className="text-red-500">{message}</p>
                      )}
                    />
                  </div>
                </Fragment>
              ))}

              <div className="my-4 flex flex-row justify-end gap-[10px] col-span-2">
                <Button
                  onClick={() => remove(fields.length - 1)}
                  className="w-[52px] flex items-center  text-[22px] h-[52px] rounded-full bg-[#D3D3D3] text-black"
                  type="button"
                >
                  -
                </Button>
                <Button
                  onClick={() => prepend(nanoid())}
                  className="w-[52px] flex items-center  text-[22px] h-[52px] rounded-full bg-[#D3D3D3] text-black"
                  type="button"
                >
                  +
                </Button>
              </div>
            </div>
          </section>

          <section className="w-full">
            <div>
              <div className="flex flex-col">
                <div className=" my-[20px] whyte-bold text-[24px] xl:text-[32px] ">
                  Upload Profile Photo*
                </div>
                <UploadFile
                  acceptedFile={{ "image/*": [".png", ".jpg", ".jpeg"] }}
                  className="form-input rounded-md mt-3"
                  uploadMessage={"Drag and drop or click to upload an image"}
                  onDrop={setProfilePhoto}
                />
              </div>
              {profilePhoto === null ? (
                errorUserDidNotAddImage ? (
                  <p className="text-red-500">Add a Profile Photo</p>
                ) : null
              ) : null}
            </div>
          </section>
        </div>
        <div className=" mx-4 self-center xl:self-end flex  justify-start gap-[20px] my-8">
          <button
            invert
            type="button"
            onClick={stepBack}
            className="hover:scale-105 transition-all "
          >
            <img className=" w-[80%] xl:w-full " src={backButton} alt="back" />
          </button>
          <button type="submit" className="hover:scale-105 transition-all ">
            <img className=" w-[80%] xl:w-full " src={nextButton} alt="next" />
          </button>
        </div>
      </div>
    </form>
  );
}
