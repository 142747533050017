import React from 'react';
import { forwardRef } from 'react';
import { Input } from './InputText.styles';

export const InputText = forwardRef(({
  id,
  name,
  placeholder,
  ariaLabel,
  value,
  maxLength = 100,
  pattern,
  title,
  required = false,
  inputClassName,
  className,
  shouldShowLabel = true,
  children,
  ...props
}, ref) => {

  return (
    <div className={"flex flex-col "+ className}>
      {/* {shouldShowLabel && (
        <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor={name}>{placeholder}</label>
      )} */}

      <Input
        id={name}
        name={name}
        type={'text'}
        aria-label={ariaLabel}
        value={value}
        placeholder={ placeholder}
        maxLength={maxLength}
        pattern={pattern}
        title={title}
        required={required}
        ref={ref}
        className={"block w-full  text-[#1E1E1E]   shadow-sm  focus:border-primary-700 focus:ring-primary-700 sm:text-sm " + inputClassName}
        {...props}
      />

      {children}

    </div>
  )
});
