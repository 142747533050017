import { useRef, useState, useCallback } from "react";
import { nanoid } from "nanoid";
import { useForm } from "react-hook-form";
import { InputEmail } from "../../Components/Forms/InputEmail/InputEmail";
import { InputText } from "../../Components/Forms/InputText/InputText";
import { InputPassword } from "../../Components/Forms/InputPassword/InputPassword";
import { UploadFile } from "../../Components/UploadFile/UploadFile";
import { Button } from "../../Components/NewButton/Button";
import { Navbar } from "../../Components/Navbar/NewNavbar";
import CountryCodes from "../../staticData/NewCountryCodes.json";
import { Select } from "../../Components/Forms/Select/NewSelect";
import { jobCategoryCompany } from "../../utils/jobCategoryCompany";
import { socialMedia } from "../../staticData/socialMedias";
import { Textarea } from "../../Components/Forms/Textarea/Textarea";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "./CompanyEditProfile.services";
import { transformFileInBase64 } from "../../utils/transformFileInBase64";
import { LoadingSpin } from "../../Components/LoadingSpin/LoadingSpin";
import { useAuth } from "../../hooks/Auth";
import { Footer } from "../../Components/Footer/Footer";
import { geoLocationApi } from "../../services/geolocation";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { ErrorMessage } from "@hookform/error-message";

const countryNames = CountryCodes.map((country) => `${country.flag} ${country.name}`);
const countryCodes = CountryCodes.map(
  (country) => `${country.flag} ${country.dial_code} (${country.name})`
);
const numberOfEmployees = [
  "Under 10",
  "10 to 19",
  "20 to 49",
  "50 to 249",
  "250 and over",
];
const howKnewAboutUsOptions = [
  "Search Engine (Google, Bing, etc)",
  "Word of mouth",
  "Newspapers / Magazines",
  "Direct Email",
  "Social Media (Facebook, Instagram, LinkedIn, etc)",
  "Swanted Staff",
  "Other",
];

export const CompanyEditProfile = () => {
  const { user, updateLocalStorageUser } = useAuth();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: user,
  });
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    fetchPolicy: "no-cache",
  });
  const [profilePhoto, setProfilePhoto] = useState(null);
  // let countryNameRef = useRef({});
  let addressRef = useRef({});
  const [isLoadingAddressSearch, setIsLoadingAddressSearch] = useState(false);
  const [errorInAddressSearch, setErrorInAddressSearch] = useState(false);
  const [hasSearchedAddress, setHasSearchedAddress] = useState(false);
  // countryNameRef.current = watch('company.country', user.company.country || '');
  addressRef.current = watch("company.address", user.company.address || "");
  const [searchBox, setSearchBox] = useState();

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => searchBox.getPlaces();

  const onEditProfileSubmit = useCallback(
    async (data) => {
      if (loading) return;

      let companyPhoto = null;

      if (profilePhoto) {
        companyPhoto = {
          base64: await transformFileInBase64(profilePhoto),
          name: profilePhoto.name,
          type: profilePhoto.type,
        };
      }

      const companySocialMedias = data.company.socialMedias.map(
        (socialMedia) => ({
          name: socialMedia.name,
          url: socialMedia.url,
        })
      );

      try {
        const { data: responseUser } = await updateUser({
          variables: {
            email: data.email,
            password: data.password,
            // countryName: data.company.country,
            companyManager: data.company.manager,
            companyWebsite: data.company.website,
            companyHowKnewAboutUs: data.company.howKnewAboutUs,
            companyNumberOfEmployees: data.company.numberOfEmployees,
            companyRegistrationNumber: data.company.companyRegistrationNumber,
            companyCategory: data.company.companyCategory,
            companyDescription: data.company.companyDescription,
            companyName: data.company.name,
            companyAddress: data.company.address,
            companyEircode: data.company.eircode,
            companyCountryCode: data.company.countryCode,
            companyPhone: data.company.phone,
            companySocialMedias,
            companyPhoto,
          },
        });

        updateLocalStorageUser(responseUser.updateUser);

        alert("Profile successfully updated");
      } catch (error) {
        if (error.message === "E-mail already taken") {
          alert("E-mail already taken, choose a new one");
          return;
        }

        if (error.message === "Company Name already taken") {
          alert("Company Name already taken, choose another one.");
          return;
        }

        if (error.message === "Address not found") {
          alert("Address not found.");
          return;
        }

        throw error;
      }
    },
    [profilePhoto, loading, updateUser, updateLocalStorageUser]
  );

  return (
    <div className="bg-gray-50 w-full h-full pt-[20px]  ">
      <Navbar />
      <div className=" flex justify-center gap-3 p-5 pt-20 relative min-h-full   ">
        <form
          className="flex flex-col bg-white ] rounded-[20px] border-[1px] border-[#D3D3D3]   xl:w-[60%] px-[6%] xl:px-[6%] py-[6%] xl:py-[2%]  "
          onSubmit={handleSubmit(onEditProfileSubmit, console.log)}
        >
          <div className="flex whyte-bold text-[20px] xl:text-[30px]  ">
            Edit Company info
          </div>
          <div className=" text-[#A3A3A3] text-[16px] xl:text-[20px] ">
            Change your company informations
          </div>
          <section>
            <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
              Company Photo / Logo (optional)
            </div>
            <div className="flex my-6">
              <UploadFile
                acceptedFile={{
                  "image/*": [],
                }}
                className="form-input rounded-md mt-3"
                uploadMessage={"Drag and drop or click to upload an image"}
                onDrop={setProfilePhoto}
              >
                <img
                  src={user.company.photo}
                  alt={user.company.name}
                  className="w-20 h-w-20 mr-3 rounded-md object-center object-cover"
                />
              </UploadFile>
            </div>
          </section>
          <div className="flex flex-col xl:flex-row  xl:gap-[30px] ">
            <div className="w-full mt-4 xl:mt-0  xl:w-1/2">
              <div className="flex whyte-bold text-[16px] xl:text-[20px] ">
                Company Name*
              </div>
              <InputText
                style={{ height: "50px" }}
                className="mt-3 "
                {...register("company.name", {
                  required: "input company name",
                })}
              />
              <ErrorMessage
                name="company.name"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>

            <div className="w-full mt-4 xl:mt-0  xl:w-1/2">
              <div className="flex whyte-bold  text-[16px] xl:text-[20px] ">
                Manager Name*
              </div>

              <InputText
                style={{ height: "50px" }}
                className="mt-3"
                {...register("company.manager", {
                  required: "input manager name",
                })}
              />
              <ErrorMessage
                name="company.manager"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>
          </div>

          <div className="flex flex-col xl:flex-row  xl:gap-[30px] ">
            <div className="w-full mt-4 xl:mt-0  xl:w-1/2">
              <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
                E-mail*
              </div>
              <InputEmail
                placeholder={"E-mail*"}
                className="mt-3"
                {...register("email", { required: "input email" })}
              />
              <ErrorMessage
                name="email"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>
            <div className="w-full mt-4 xl:mt-0  xl:w-1/2">
              <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
                Company address*
              </div>

              <StandaloneSearchBox
                onLoad={onLoad}
                onPlacesChanged={onPlacesChanged}
              >
                <InputText
                  style={{ height: "50px" }}
                  className="mt-3"
                  {...register("company.address", {
                    required: "input address",
                  })}
                />
              </StandaloneSearchBox>

              <ErrorMessage
                name="company.address"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />

              <div className="flex flex-col">
                <small className="mt-3 text-[#6c6c6c]">
                  Example: 157 Thomas Street, Dublin 8
                </small>
              </div>
            </div>
          </div>
          <div>
            <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-2">
              Password*
            </div>

            <InputPassword
              placeholder={"Password"}
              className="mt-3"
              {...register("password")}
            />
          </div>

          <div className="flex  flex-col xl:flex-row xl:gap-[30px] ">
            <div className="w-full mt-4 xl:mt-0  xl:w-1/2">
              <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
                Country Code*
              </div>
              <Select
                control={control}
                options={countryCodes}
                label={""}
                defaultValue="+353 (Ireland)"
                className="mt-[6px] h-[50px] w-full "
                {...register("company.countryCode", {
                  required: "input country code",
                })}
              />
              <ErrorMessage
                name="company.countryCode"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>

            <div className="w-full mt-4 xl:mt-0  xl:w-1/2">
              <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
                Phone*
              </div>

              <InputText
                style={{ height: "50px" }}
                className="mt-3"
                {...register("company.phone", { required: "input phone" })}
              />
              <ErrorMessage
                name="company.phone"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>
          </div>

          {/* <Select
            control={control}
            options={countryNames}
            label="Country Name*"
            placeholder="Select..."
            defaultValue="Ireland"
            className="mt-3"
            rules={{ required: true }}
            {...register("company.country")}
          /> */}

          <div className="flex flex-col xl:flex-row xl:gap-[30px] ">
            <div className="w-full mt-4 xl:mt-0  xl:w-1/2">
              <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
                Number of Employees*
              </div>
              <Select
                control={control}
                options={numberOfEmployees}
                label=""
                className="mt-3"
                {...register("company.numberOfEmployees", {
                  required: "input number of employees",
                })}
              />

              <ErrorMessage
                name="company.numberOfEmployees"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>

            <div className="w-full mt-4 xl:mt-0  xl:w-1/2">
              <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
                Company Category*
              </div>
              <Select
                control={control}
                options={jobCategoryCompany}
                label=""
                className="mt-3"
                {...register("company.companyCategory", {
                  required: "input company category",
                })}
              />
              <ErrorMessage
                name="company.companyCategory"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>
          </div>

          {/* <InputText placeholder={'EirCode'} className="mt-3" {...register('company.eircode')} /> */}
          <div>
            <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
              Company Website (optional)
            </div>
            <InputText
              style={{ height: "50px" }}
              placeholder={""}
              className="mt-3  "
              {...register("company.website")}
            />
          </div>

          <div>
            <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
              Company Description (optional)
            </div>
            <Textarea
              label=""
              className="mt-3 w-full"
              rows={5}
              maxLength={10000}
              {...register("company.companyDescription")}
            />
          </div>

          <div>
            <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
              Company Registration Number (optional)
            </div>
            <InputText
              style={{ height: "50px" }}
              placeholder={""}
              className="mt-3"
              {...register("company.companyRegistrationNumber")}
            />
          </div>

          <section>
            <div className="flex whyte-bold  text-[16px] xl:text-[20px]  mt-4">
              Social Media (optional)
            </div>
            {/* <div className="my-4 flex justify-between items-baseline">
              <h1 className="mt-3"></h1>
            </div> */}

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
              {socialMedia.map((socialMedia, index) => (
                <div>
                  <div key={nanoid()} className="flex items-center mt-3 w-full">
                    <input
                      type="hidden"
                      {...register(`company.socialMedias.${index}.name`, { value: socialMedia.name })}
                    />
                    <img
                      src={socialMedia.logo}
                      className="w-10 mt-4 h-10 object-fit object-center rounded-md"
                      alt={socialMedia.name + " Logo"}
                    />

                    <InputText
                      style={{ height: "50px" }}
                      placeholder={`${socialMedia.name} profile`}
                      className="ml-3 w-full"
                      {...register(`company.socialMedias.${index}.url`)}
                    />
                  </div>

                  <p className="mt-3">{socialMedia.tips}</p>

                </div>
              ))}
            </div>
          </section>

          <Button
            className="xl:max-w-[320px] mt-8 h-[52px] xl:h-[62px] rounded-full whyte-bold  text-[16px] xl:text-[20px] "
            type="submit"
            loading={loading}
          >
            Update Profile
          </Button>
        </form>
      </div>
      <Footer />
    </div>
  );
};
