import React, { forwardRef } from 'react';
import { Input } from './InputPhone.styles';

export const InputPhone = forwardRef(({
  id,
  name,
  placeholder,
  ariaLabel,
  value,
  pattern,
  title,
  required = false,
  className,
  ...props
}, ref) => {
  return (
    <div className={"flex flex-col "+ className}>
      {/* <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor={name}>{placeholder}</label> */}

      <Input
        id={name}
        name={name}
        type={'tel'}
        aria-label={ariaLabel}
        value={value}
        placeholder={placeholder}
        pattern={pattern}
        title={title}
        required={required}
        ref={ref}
        // className={"form-input rounded-md"}
        className={"block w-full  border-gray-300 shadow-sm focus:border-primary-700 focus:ring-primary-700 sm:text-sm p-[5px]"}
        {...props}
      />
    </div>
  )
});

