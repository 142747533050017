import React, { useState, useCallback } from 'react'
import { Modal } from "../Modal/Modal";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { PDF } from "../PDF/pdf";
import { JobSeekerProfile } from "../JobSeekerProfile/JobSeekerProfile";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { InviteUserToVacancy } from './InviteUserToVacancy';
import { FiAlertTriangle } from 'react-icons/fi';
import { ReportModal } from '../ReportModal/ReportModal';

export function JobSeekerModal({ setModalJobSeeker, modalJobSeeker, notCompany = false }) {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const handleOpenReportModal = useCallback(() => {
    setIsReportModalOpen(state => !state);
  }, []);

  return (
    <>
      {isReportModalOpen && ( 
        <ReportModal
          type={'job seeker'}
          jobSeeker={modalJobSeeker}
          isReportModalOpen={isReportModalOpen}
          setIsReportModalOpen={setIsReportModalOpen}
          onClose={() => setModalJobSeeker(null)}
        />
      )}

      <Modal
        headerItems={!notCompany ? (
         <button onClick={handleOpenReportModal}>
             <FiAlertTriangle
              size={24}
              className={"text-slate-800 fill-white hover:text-primary-800 transition duration-500 "}
            />
          </button>
        ) : <React.Fragment />}
        closeModal={() => setModalJobSeeker(null)}
        isOpen={!!modalJobSeeker}
        minWidth={'330px'}
        width={'35vw'}
        height={'85%'}
      >
        <Tabs>
          <div className="flex flex-col">
            <TabList>
              {modalJobSeeker?.applicationVideo && (
                <Tab>Cover Video</Tab>
              )}

              {modalJobSeeker?.video && (
                <Tab>Intro Video</Tab>
              )}
              <Tab>Profile</Tab>

              {modalJobSeeker?.cv && (
                <Tab>CV</Tab>
              )}

              {!notCompany && modalJobSeeker?.isFromDatabase && (
                <Tab>Contact Jobseeker</Tab>
              )}
            </TabList>

            {modalJobSeeker?.applicationVideo && (
              <TabPanel>
                <VideoPlayer srcVideo={modalJobSeeker?.applicationVideo} />
              </TabPanel>
            )}

            {modalJobSeeker?.video && (
              <TabPanel>
                <VideoPlayer srcVideo={modalJobSeeker?.video} />
              </TabPanel>
            )}

            <TabPanel>
              <JobSeekerProfile jobSeeker={modalJobSeeker} />
            </TabPanel>

            {modalJobSeeker?.cv && (
              <TabPanel>
                <PDF jobSeeker={modalJobSeeker} />
              </TabPanel>
            )}

            {!notCompany && modalJobSeeker?.isFromDatabase && (
              <TabPanel>
                <InviteUserToVacancy jobSeeker={modalJobSeeker} />
              </TabPanel>
            )}
          </div>
        </Tabs>
      </Modal>
    </>
  )
}
