import React, { useState } from "react";
import { ModalMessage } from "../../Components/ModalMessage/NewModalMessage";
import { SeeMoreModal } from "../../Components/SeeMoreModal/SeeMoreModal";
import { Modal } from "../../Components/NewModal/Modal";
import { Button } from "../../Components/NewButton/Button";
import { useNavigate } from "react-router";

export function SelectActionModal({
  isSelectModalOpen,
  setIsSelectModalOpen,
  job,
}) {
  const [isOpenModalMessage, setIsOpenModalMessage] = useState(false);
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState(false);

  const navigator = useNavigate();
  return (
    <>
      {isSelectModalOpen && (
        <Modal
          isOpen={isSelectModalOpen}
          closeModal={() => setIsSelectModalOpen(false)}
          width={"80%"}
          maxWidth={500}
        >
          <div className="flex flex-col items-center">
            <div className="whyte-bold text-[18px] xl:text-[24px]">
              Choose an action
            </div>

            <div className="w-full flex flex-col   items-center gap-[20px] justify-center mt-8">
              <Button
                className=" min-w-[150px] xl:min-w-[320px] whyte-bold h-[52px] rounded-full text-[20px] xl:text-[24px] "
                onClick={() => {
                  setIsOpenModalMessage(true);
                  setIsSelectModalOpen(false);
                }}
              >
                Apply
              </Button>

              <Button
                className=" border-none  min-w-[150px] xl:min-w-[320px] whyte-bold h-[52px] rounded-full text-[20px] xl:text-[24px] "
                invert
                onClick={() => {
                  // setIsSeeMoreModalOpen(true)
                  setIsSelectModalOpen(false);
                  navigator("/job-details", { state: job });
                }}
              >
                See More
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {isSeeMoreModalOpen && (
        <SeeMoreModal
          job={job}
          isSeeMoreModalOpen={isSeeMoreModalOpen}
          setSeeMoreModal={setIsSeeMoreModalOpen}
        />
      )}

      {isOpenModalMessage && (
        <ModalMessage
          job={job}
          isOpenModalMessage={isOpenModalMessage}
          setIsOpenModalMessage={setIsOpenModalMessage}
        />
      )}
    </>
  );
}
