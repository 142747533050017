import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
}

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 20px;
  border-width: 4px;

  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;

  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  
  transition: border .24s ease-in-out;

  input {
    width: 300px;
    height: 300px;
  }
`;

export const Container1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-width: 4px;
  border-radius: 20px;
  border-color: ${props => getColor(props)};
  border-style: line;

  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  
  transition: border .24s ease-in-out;

  input {
    width: 100px;
    height: 100px;
  }
`;

const messageColor = {
  default: "#bdbdbd",
  error: "#ff1744",
  success: "#00e676"
}

export const UploadMessage = styled.p`
  color: ${props => messageColor[props.type || "default"]};
`;
