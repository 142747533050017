import React, { forwardRef } from 'react';
import '../../../Pages/HomePage/styles.css'
export const InputCheckbox = forwardRef(({
  id,
  name,
  className,
  placeholder,
  ariaLabel,
  value,
  maxLength = 100,
  ...props
}, ref) => {

  return(
    <div className={"flex items-center "+ className}>
      <input
        type="checkbox"
        id={name}
        name={name}
        className={"block rounded-[50%] border-gray-300 shadow-sm focus:border-primary-700 focus:ring-primary-700 sm:text-sm"}
        aria-label={ariaLabel}
        value={value}
        maxLength={maxLength}
        ref={ref}
        {...props}
      />

      <label className="block ml-2 text-[16px] xl:text-[20px] whyte-medium font-normal text-[#A3A3A3] " htmlFor={name}>{placeholder}</label>
  </div>
  )
});
