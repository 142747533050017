import React, { useState, useCallback, Fragment } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { InputCheckbox } from "../../Components/Forms/NewInputCheckbox/InputCheckbox";
import { Textarea } from "../../Components/Forms/Textarea/Textarea";
import { InputEmail } from "../../Components/Forms/InputEmail/InputEmail";
import { InputText } from "../../Components/Forms/InputText/InputText";
import { InputPassword } from "../../Components/Forms/InputPassword/InputPassword";
import { UploadFile } from "../../Components/UploadFile/NewUploadFile";
import { Button } from "../../Components/NewButton/Button";
import { Navbar } from "../../Components/Navbar/NewNavbar";
import { Select } from "../../Components/Forms/Select/NewSelect";
import { transformFileInBase64 } from "../../utils/transformFileInBase64";
import NewCountryCodes from "../../staticData/NewCountryCodes.json";
import LanguageNames from "../../staticData/languageNames.json";
import ProeficiencyLevels from "../../staticData/proeficiencyLevels.json";
import { socialMedia } from "../../staticData/socialMedias";
import { InputDate } from "../../Components/Forms/InputDate/InputDate";
import { nanoid } from "nanoid";
import { useAuth } from "../../hooks/Auth";
import { useMemo } from "react";
import { Footer } from "../../Components/Footer/Footer";
import { ErrorMessage } from "@hookform/error-message";
import { InputText as InputText2 } from "../../Components/Forms/NewInputText/InputText";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { Modal } from "../../Components/Modal/Modal";
import { VideoPlayer } from "../../Components/VideoPlayer/VideoPlayer";
import { JobExperience } from "../../Components/JobExperience/JobExperience";
import { JobExperienceForm } from "../../Components/JobExperienceForm/NewJobExperienceForm";
import { LoadingSpin } from "../../Components/LoadingSpin/LoadingSpin";
import SwantedTutorial from "../../assets/tutorial-video/Swanted_tutorial.mp4";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "./JobSeekerEditProfile.services";
import { geoLocationApi } from "../../services/geolocation";
import { parseDate } from "../../utils/parseDate";
import { hasTruthyProperty } from "../../utils/hasTruthyProperty";
import { useRef } from "react";

const countryCodes = NewCountryCodes.map(
  (country) => `${country.flag} ${country.dial_code} (${country.name})`
);
const countryNames = NewCountryCodes.map((country) => country.name);
const genders = ["Male", "Female", "Other"];
const lookingFor = ["Part Time", "Full Time"];
const languageNames = LanguageNames.map((language) => language.name);
const proeficiencyLevels = ProeficiencyLevels.map((level) => level.name);

export const JobSeekerEditProfile = () => {
  const { user, updateLocalStorageUser } = useAuth();
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    fetchPolicy: "no-cache",
  });

  const parsedUser = useMemo(() => {
    const date = new Date(Number(user.jobSeeker.birth));

    const parsedBirthDate = parseDate(date, { inputDateFormat: true });

    return {
      ...user,
      jobSeeker: {
        ...user.jobSeeker,
        birthDate: parsedBirthDate,
      },
    };
  }, [user]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: parsedUser,
  });
  const [isLoadingAddressSearch, setIsLoadingAddressSearch] = useState(false);
  const [errorInAddressSearch, setErrorInAddressSearch] = useState(false);
  const [hasSearchedAddress, setHasSearchedAddress] = useState(false);

  const availabilityRef = useRef({});
  const countryNameRef = useRef({});
  const addressRef = useRef({});
  availabilityRef.current = watch(
    "jobSeeker.availability",
    user.jobSeeker.availability || {}
  );
  countryNameRef.current = watch(
    "jobSeeker.country",
    user.jobSeeker.country || ""
  );
  addressRef.current = watch("jobSeeker.address", user.jobSeeker.address || "");

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [cv, setCv] = useState(null);
  const [video, setVideo] = useState(null);
  const [shouldShowExampleVideoModal, setShouldShowExampleVideoModal] =
    useState(false);
  const [experiences, setExperiences] = useState(user.jobSeeker.experiences);

  const handleRemoveExperience = useCallback((index) => {
    setExperiences((state) => {
      const newState = [...state].filter((_, i) => i !== index);

      return newState;
    });
  }, []);

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "jobSeeker.languages",
  });

  const handlePushExperience = useCallback((experience) => {
    setExperiences((experiences) => [experience, ...experiences]);
  }, []);

  const onInvalidSubmit = useCallback(() => {
    alert("You still have blank fields. Fill all fields and try again.");
  }, []);

  const onEditProfileSubmit = useCallback(
    async (data) => {
      if (loading) return;

      let jobSeekerPhoto = null;
      let jobSeekerVideo = null;
      let jobSeekerCv = null;

      if (profilePhoto) {
        jobSeekerPhoto = {
          base64: await transformFileInBase64(profilePhoto),
          name: profilePhoto.name,
          type: profilePhoto.type,
        };
      }

      if (video) {
        jobSeekerVideo = {
          base64: await transformFileInBase64(video),
          name: video.name,
          type: video.type,
        };
      }

      if (cv) {
        jobSeekerCv = {
          base64: await transformFileInBase64(cv),
          name: cv.name,
          type: cv.type,
        };
      }

      const jobSeekerExperiences = experiences.map((experience) => ({
        companyName: experience.companyName,
        currentlyWorking: experience.currentlyWorking,
        endDate: experience.endDate,
        jobCategory: experience.jobCategory,
        jobDescription: experience.jobDescription,
        jobTitle: experience.jobTitle,
        startDate: experience.startDate,
      }));

      const jobSeekerSocialMedias = data.jobSeeker.socialMedias.map(
        (socialMedia) => ({
          name: socialMedia.name,
          url: socialMedia.url,
        })
      );

      const jobSeekerLanguages = [data.jobSeeker.languages]
        .flat()
        .map((language) => ({
          name: language.name,
          proeficiency: language.proeficiency,
        }));

      try {
        const { data: responseUser } = await updateUser({
          variables: {
            email: data.email,
            password: data.password,
            jobSeekerName: data.jobSeeker.name,
            jobSeekerSurname: data.jobSeeker.surname,
            jobSeekerAddress: data.jobSeeker.address,
            // jobSeekerEircode: data.jobSeeker.eircode,
            jobSeekerGender: data.jobSeeker.gender,
            jobSeekerBirth: data.jobSeeker.birthDate,
            // jobSeekerCountry: data.jobSeeker.country,
            jobSeekerLookingFor: data.jobSeeker.lookingFor,
            jobSeekerCountryCode: data.jobSeeker.countryCode,
            jobSeekerPhone: data.jobSeeker.phone,
            jobSeekerAvailability: {
              morning: data.jobSeeker.availability.morning,
              afternoon: data.jobSeeker.availability.afternoon,
              night: data.jobSeeker.availability.night,
              weekends: data.jobSeeker.availability.weekends,
              note: data.jobSeeker.availability.note,
            },
            jobSeekerSocialMedias,
            jobSeekerLanguages,
            jobSeekerPhoto,
            jobSeekerVideo,
            jobSeekerCv,
            jobSeekerExperiences,
          },
        });

        updateLocalStorageUser(responseUser.updateUser);

        alert("Profile successfully updated");
      } catch (error) {
        if (error.message === "E-mail already taken") {
          alert("E-mail already taken, choose a new one");
          return;
        }
        if (error.message === "Address not found") {
          alert("Address not found.");
          return;
        }

        throw error;
      }
    },
    [
      user,
      profilePhoto,
      video,
      cv,
      experiences,
      loading,
      updateUser,
      updateLocalStorageUser,
    ]
  );

  const [searchBox, setSearchBox] = useState();

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => searchBox?.getPlaces();

  // const handleFetchAddress = useCallback(async (event, type) => {
  //   const inputValue = event?.target?.value || null;

  //   let countryNameValue = type === 'countryName' ? inputValue : countryNameRef.current;
  //   let addressInputValue = type === 'address' ? inputValue :  addressRef.current;

  //   if (!addressInputValue || !countryNameValue) return;

  //   setIsLoadingAddressSearch(true);
  //   const parsedAddress = `${addressInputValue},${countryNameValue}`.replaceAll(" ", "+")

  //   const { data: apiData } = await geoLocationApi.get(`/search?addressdetails=1&q=${parsedAddress}&format=json&limit=1`)

  //   setIsLoadingAddressSearch(false);
  //   setHasSearchedAddress(true);
  //   if (apiData.length === 0) {
  //     setErrorInAddressSearch(true);

  //     return;
  //   }

  //   setErrorInAddressSearch(false);
  // }, []);

  return (
    <div className="bg-gray-50 w-full h-full py-[20px]  ">
      <Navbar />
      {shouldShowExampleVideoModal && (
        <Modal
          isOpen={shouldShowExampleVideoModal}
          closeModal={() => setShouldShowExampleVideoModal(false)}
        >
          <div>
            <h1 className="my-5 text-center">Video Tutorial</h1>

            <VideoPlayer height={400} width={400} srcVideo={SwantedTutorial} />
          </div>
        </Modal>
      )}

      <div className="bg-gray-50 flex justify-center gap-3 p-5  mt-6 xl:mt-0 relative min-h-full  ">
        <form
          className="  p-4 lg:p-10  left-1/3 w-[95%] xl:w-[70%] "
          onSubmit={handleSubmit(onEditProfileSubmit, onInvalidSubmit)}
        >
          <div className="bg-white rounded-[20px] border-[1px] border-[#D3D3D3] flex flex-col p-6 lg:p-16">
            <div className="flex whyte-bold text-[24px] xl:text-[32px] ">
              Edit Profile
            </div>
            <div className="whyte-bold text-base xl:text-[22px] text-[#A3A3A3] mb-3">
              Change your profile information
            </div>
            <div>
              <label className="block mt-3 mb-2 whyte-bold text-base xl:text-[24px] text-[#344C70]">
                Upload Profile Photo
              </label>
              <div className="flex">
                <UploadFile
                  acceptedFile={{ "image/*": [".png", ".jpg", ".jpeg"] }}
                  className="form-input rounded-md mt-3 "
                  uploadMessage={"Drag and drop or click to upload an image"}
                  onDrop={setProfilePhoto}
                >
                  <img
                    src={user.jobSeeker.photo}
                    alt={user?.jobSeeker?.name}
                    className="w-20 h-w-20 mr-3 rounded-md object-center object-cover"
                  />
                </UploadFile>
              </div>
            </div>
            <div className="flex flex-col xl:flex-row xl:gap-[30px] mt-4 ">
              <div className=" w-full xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
                  First Name*
                </div>
                <InputText
                  style={{ height: "50px" }}
                  className="mt-2"
                  {...register("jobSeeker.name", { required: "input name" })}
                />
                <ErrorMessage
                  name="jobSeeker.name"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>
              <div className=" w-full mt-3 xl:mt-0 xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70]">
                  Surname*
                </div>
                <InputText
                  style={{ height: "50px" }}
                  placeholder={""}
                  className="mt-2"
                  {...register("jobSeeker.surname", {
                    required: "input surname",
                  })}
                />
                <ErrorMessage
                  name="jobSeeker.surname"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col xl:flex-row xl:gap-[30px] ">
              <div className=" w-full xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px] mt-3 text-[#344C70]">
                  Email*
                </div>
                <InputEmail
                  placeholder={""}
                  className="mt-2"
                  {...register("email", { required: "input email" })}
                />
                <ErrorMessage
                  name="email"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>
              <div className=" w-full  xl:mt-0 xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px] mt-3 text-[#344C70]">
                  Password*
                </div>
                <InputPassword
                  placeholder={""}
                  className="mt-2"
                  {...register("password")}
                />
              </div>
            </div>
            <div className="flex flex-col xl:flex-row xl:gap-[30px] mt-4 ">
              <div className=" w-full xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
                  Country Code*
                </div>
                <div>
                  <Select
                    control={control}
                    options={countryCodes}
                    label={""}
                    className="mt-0"
                    {...register("jobSeeker.countryCode", { required: true })}
                  />
                  <ErrorMessage
                    name="jobSeeker.countryCode"
                    errors={errors}
                    render={({ message }) => (
                      <p className="text-red-500">{message}</p>
                    )}
                  />
                </div>
              </div>
              <div className=" w-full mt-3 xl:mt-0 xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
                  Phone*
                </div>
                <div>
                  <InputText
                    style={{ height: "50px" }}
                    placeholder={""}
                    className="mt-2"
                    {...register("jobSeeker.phone", {
                      required: "input phone",
                    })}
                  />
                  <ErrorMessage
                    name="jobSeeker.phone"
                    errors={errors}
                    render={({ message }) => (
                      <p className="text-red-500">{message}</p>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row xl:gap-[30px] mt-4 ">
              <div className=" w-full xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px]  text-[#344C70] ">
                  Date of Birth*
                </div>
                <div>
                  <InputDate
                    style={{ height: "50px" }}
                    placeholder=""
                    className="mt-2"
                    {...register("jobSeeker.birthDate", {
                      required: "input date of birth",
                      valueAsDate: true,
                    })}
                  />
                  <ErrorMessage
                    name="jobSeeker.birthDate"
                    errors={errors}
                    render={({ message }) => (
                      <p className="text-red-500">{message}</p>
                    )}
                  />
                </div>
              </div>
              <div className=" w-full mt-3 xl:mt-0 xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px]   text-[#344C70] ">
                  Gender*
                </div>
                <div>
                  <Select
                    control={control}
                    options={genders}
                    label=""
                    className="mt-2"
                    {...register("jobSeeker.gender", { required: true })}
                  />
                  <ErrorMessage
                    name="jobSeeker.gender"
                    errors={errors}
                    render={({ message }) => (
                      <p className="text-red-500">{message}</p>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row xl:gap-[30px] mt-4 ">
              <div className=" w-full xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px]  text-[#344C70] ">
                  Looking For*
                </div>
                <div>
                  <Select
                    control={control}
                    options={lookingFor}
                    label=""
                    className="mt-0"
                    {...register("jobSeeker.lookingFor", { required: true })}
                  />
                  <ErrorMessage
                    name="jobSeeker.lookingFor"
                    errors={errors}
                    render={({ message }) => (
                      <p className="text-red-500">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className=" w-full mt-3 xl:mt-0 xl:w-1/2">
                <div className="flex whyte-bold text-base xl:text-[24px]  text-[#344C70] ">
                  Address*
                </div>
                <div>
                  <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={onPlacesChanged}
                  >
                    <InputText
                      style={{ height: "50px" }}
                      className="mt-2"
                      placeholder=""
                      {...register("jobSeeker.address", {
                        required: "input address",
                      })}
                    />
                  </StandaloneSearchBox>

                  <div className="flex flex-col">
                    <small className="mt-3 text-[#6c6c6c]">
                      Example: 157 Thomas Street, Dublin 8
                    </small>
                  </div>

                  <ErrorMessage
                    name="jobSeeker.address"
                    errors={errors}
                    render={({ message }) => (
                      <p className="text-red-500">{message}</p>
                    )}
                  />
                </div>
              </div>
            </div>
            <hr className="my-5 h-[1px] bg-[#D3D3D3]" />
            <div className="mt-6 flex whyte-bold text-[24px] xl:text-[32px]  ">
              Languages
            </div>
            <div className="grid  grid-cols-1 xl:grid-cols-2 w-full  gap-[30px] mt-6 ">
              {fields.map((field, index) => (
                <Fragment key={field.id}>
                  <div className="w-full ">
                    <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
                      Language*
                    </div>
                    <Select
                      control={control}
                      options={languageNames}
                      defaultValue="English"
                      className="mt-1"
                      label=""
                      {...register(`jobSeeker.languages.${index}.name`, {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="w-full ">
                    <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
                      Proficiency*
                    </div>
                    <Select
                      control={control}
                      options={proeficiencyLevels}
                      defaultValue="Fluent"
                      className="mt-1"
                      label=""
                      {...register(
                        `jobSeeker.languages.${index}.proeficiency`,
                        {
                          required: true,
                        }
                      )}
                    />
                  </div>
                </Fragment>
              ))}
            </div>
            <div className="my-4 flex flex-row gap-[10px] justify-end col-span-2">
              <Button
                onClick={() => remove(fields.length - 1)}
                className="w-10 h-10 bg-[#9E5BF6] rounded-full"
                type="button"
              >
                -
              </Button>
              <Button
                onClick={() => prepend(nanoid())}
                className="w-10 h-10 bg-[#9E5BF6] rounded-full"
                type="button"
              >
                +
              </Button>
            </div>
            <hr className="my-5 h-[1px] bg-[#D3D3D3]" />
            <section>
              <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
                Availability*
              </div>

              <div className="mt-3">
                <div className="grid justify-items-start  grid-cols-2 xl:grid-cols-4  ">
                  <InputCheckbox
                    placeholder="Morning"
                    {...register("jobSeeker.availability.morning", {
                      required: !hasTruthyProperty(availabilityRef.current),
                    })}
                  />
                  <InputCheckbox
                    placeholder="Afternoon"
                    className="xl:pl-2 xl:ml-2"
                    {...register("jobSeeker.availability.afternoon", {
                      required: !hasTruthyProperty(availabilityRef.current),
                    })}
                  />
                  <InputCheckbox
                    placeholder="Night"
                    className="xl:pl-2 xl:ml-2"
                    {...register("jobSeeker.availability.night", {
                      required: !hasTruthyProperty(availabilityRef.current),
                    })}
                  />
                  <InputCheckbox
                    placeholder="Weekends"
                    className="xl:pl-2 xl:ml-2"
                    {...register("jobSeeker.availability.weekends", {
                      required: !hasTruthyProperty(availabilityRef.current),
                    })}
                  />
                </div>

                <div className="mt-6 flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
                  Notes (Keep It Short)
                </div>

                <Textarea
                  label={""}
                  placeholder={
                    "Example: Free from 9am - 1:30 pm Monday to Friday."
                  }
                  className="mt-2"
                  rows={6}
                  maxLength={200}
                  {...register("jobSeeker.availability.note", {
                    required: !hasTruthyProperty(availabilityRef.current),
                  })}
                />
              </div>
            </section>
          </div>

          {/* Experience */}

          <div className="bg-white rounded-[20px] border-[1px] border-[#D3D3D3] flex flex-col p-6 lg:p-16 mt-6">
            <div className="flex whyte-bold text-[24px] xl:text-[32px]  ">
              Your Experience
            </div>
            <JobExperienceForm
              watch={watch}
              setValue={setValue}
              control={control}
              register={register}
              pushExperience={handlePushExperience}
            />
            {experiences.map((experience, index) => (
              <React.Fragment key={nanoid()}>
                <JobExperience
                  handleRemoveExperience={() => handleRemoveExperience(index)}
                  experience={experience}
                />
              </React.Fragment>
            ))}
          </div>

          {/* <Select
            control={control}
            options={countryNames}
            label="Country Name*"
            defaultValue="Ireland"
            className="mt-3"
            rules={{ required: true }}
            {...register("jobSeeker.country", { onChange: ev => handleFetchAddress(ev, 'countryName') })}
          /> */}

          {/* <InputText placeholder={'EirCode'} className="mt-3" {...register('jobSeeker.eircode')} /> */}

          <div className="bg-white rounded-[20px] border-[1px] border-[#D3D3D3] flex flex-col p-6 lg:p-16 mt-6">
            <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
              Upload Your CV
            </div>

            <div className=" my-6 flex">
              <UploadFile
                acceptedFile={{
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [],
                  "application/pdf": [],
                  "image/*": [],
                }}
                className="form-input rounded-md mt-3"
                uploadMessage={
                  "Drag and drop or click to upload a pdf or image"
                }
                onDrop={setCv}
              >
                {user.jobSeeker.cv && (
                  <embed
                    src={user.jobSeeker.cv}
                    className="w-20 h-w-20 bg-slate-300 mr-3 rounded-md overflow-hidden"
                  />
                )}
              </UploadFile>
            </div>

            <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] mt-6 ">
              Social media (optional)
            </div>

            <div className="flex flex-col xl:flex-row xl:gap-[30px] ">
              {socialMedia.map((socialMedia, index) => (
                <div>
                  <div key={nanoid()} className="flex items-center mt-3 w-full">
                    <input
                      type="hidden"
                      {...register(`jobSeeker.socialMedias.${index}.name`, { value: socialMedia.name })}
                    />
                    <img
                      src={socialMedia.logo}
                      className="w-10 mt-4 h-10 object-fit object-center rounded-md"
                      alt={socialMedia.name + " Logo"}
                    />
                    <div className="ml-3 flex flex-col w-full ">
                      <div className="flex  whyte-bold text-[14px] text-[#344C70] ">
                        {`${socialMedia.name} profile`}
                      </div>
                      <InputText2
                        className=" w-full"
                        {...register(`jobSeeker.socialMedias.${index}.url`)}
                      />
                    </div>
                  </div>

                  <p className="mt-3">{socialMedia.tips}</p>

                </div>
              ))}
            </div>
          </div>
          <div className="bg-white rounded-[20px] border-[1px] border-[#D3D3D3] flex flex-col lg-flex-row gap-[10%] p-6 lg:p-16 mt-6">
         
            <div>
              <div className="flex whyte-bold text-base xl:text-[24px] text-[#344C70] ">
                Edit Intro Video
              </div>
              <div className="flex flex-col w-full">
                <div className="my-3 text-[24px] xl:text-[28px] whyte-bold text-[#1E1E1E] ">
                  Not sure what to say? Then answer{" "}
                  <br className="hidden xl:block " />
                  some of these questions
                </div>
                <div>
                  <ul className="list-disc list-inside mt-3 text-[#A3A3A3] text-[18px] xl:text-[20px] ">
                    <li className="my-1">Who are you?</li>
                    <li className="my-1">Why are you looking for a job?</li>
                    <li className="my-1">What is your passion?</li>
                    <li className="my-1">Why should you be hired?</li>
                  </ul>
                </div>
                <a
                  onClick={() => setShouldShowExampleVideoModal(true)}
                  role="button"
                  className="text-base mt-3 text-blue-400 mb-3 hover:text-blue-500 transition duration-500"
                >
                  Watch Video Tutorial
                </a>
              </div>
            </div>
            
            <div className="xl:flex">
              <UploadFile
                acceptedFile={{ "video/*": [".mp4"] }}
                className="form-input rounded-md mt-3"
                uploadMessage={"Drag and drop or click to upload a video"}
                mbMaxSize={50}
                onDrop={setVideo}
              >
                {user.jobSeeker.video && (
                  <video
                    controls={false}
                    src={user.jobSeeker.video}
                    className="w-20 h-w-20 mr-3 rounded-md object-center object-cover"
                  />
                )}
              </UploadFile>
            </div>
          </div>

          <Button
            className="px-6 mt-10 w-full xl:w-[320px] h-[52px] xl:h-[62px] rounded-full text-[20px] xl:text-[24px] whyte-bold bg-[#9E5BF6] "
            type="submit"
            loading={loading}
          >
            Update Profile
          </Button>
        </form>
      </div>
      <Footer />
    </div>
  );
};
