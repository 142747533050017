import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import "../../Pages/HomePage/styles.css";
import { LoadingScreen } from "../../Components/LoadingScreen/LoadingScreen";
import {
  GET_COMPANY_VACANCIES,
  GET_APPLICATIONS,
  DELETE_VACANCY,
} from "./ManageVacancies.services";
import { Navbar } from "../../Components/Navbar/NewNavbar";
import { Vacancy } from "../../Components/NewVacancy/Vacancy";
import { Footer } from "../../Components/Footer/Footer";
import { ManageVacancyPainel } from "./ManageVacancyPainel";
import { useAutoAnimate } from "@formkit/auto-animate/react";

export function ManageVacancies() {
  const [parent] = useAutoAnimate();
  const { data, loading, refetch } = useQuery(GET_COMPANY_VACANCIES, {
    variables: {
      shouldQueryDeleted: true,
    },
  });
  const [focusedVacancy, setFocusedVacancy] = useState(null);

  
  const handleSelectApplication = useCallback((selectedApplication) => {
    setFocusedVacancy(selectedApplication);
  }, []);

  const filteredVacancies = useMemo(() => {
    if (loading) {
      return [];
    }
    

    return data?.company_vacancies;
  }, [data, loading]);

  useEffect(() => {
    if (filteredVacancies?.length === 0) {
      setFocusedVacancy(null);
      return;
    }

    setFocusedVacancy(filteredVacancies?.[0]);
  }, [filteredVacancies]);

  if (loading) {
    return <LoadingScreen />;
  }


  return (
    <div className="bg-gray-50 w-full h-full pt-[20px]  ">
      <Navbar />
      <div className="w-full min-h-[93vh]  pt-10 xl:pt-0 ">
        <aside className="w-full h-full  px-6 xl:px-12" aria-label="Sidebar">
          <div className=" relative lg:flex overflow-y-auto pb-4 px-3 rounded h-full flex-row-reverse">
            {focusedVacancy && (
              <div
                className={
                  "flex flex-col h-fit  w-full xl:w-2/3  lg:m-8 mt-8 xl:mt-[20px]   border-[1px] border-[#D3D3D3] lg:border-none " +
                  (focusedVacancy.deleted ? "opacity-50" : "")
                }
              >
                <ManageVacancyPainel
                  onCloseJobOrPosition={refetch}
                  setFocusedVacancy={setFocusedVacancy}
                  focusedVacancy={focusedVacancy}
                />
              </div>
            )}

            <ul className="space-y-2 w-full overflow-y-auto mt-3">
              <legend className="flex justify-start text-[24px] xl:text-[32px] whyte-bold   ">
                Manage Jobs
              </legend>
              <div className="lg:flex lg:flex-col lg:items-center" ref={parent}>
                {filteredVacancies?.length === 0 && (
                  <div className="my-6">
                    <h1>You haven't posted a job yet</h1>
                  </div>
                )}
                {filteredVacancies?.map((vacancy, index) => (
                  <Vacancy
                    key={vacancy._id}
                    job={vacancy}
                    onClick={() =>
                      handleSelectApplication(filteredVacancies[index])
                    }
                  />
                ))}
              </div>
            </ul>
          </div>
        </aside>
      </div>

      <Footer />
    </div>
  );
}
