import * as S from "./styles"
import ReactModal from 'react-modal'
import * as Icon from 'react-icons/fi';

export const Modal = ({ children, isOpen, closeModal, maxWidth, minWidth, width, height, textAlign, headerItems }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: 'none',
      padding: '30px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: maxWidth,
      minWidth: minWidth,
      width: width,
      height: height,
      textAlign: textAlign,
      backgroundColor: '#fff',
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      preventScroll={true}
    >
      <S.Container>
        <header className="flex justify-end">
          {headerItems}

          <S.ButtonClose onClick={closeModal}>
            <Icon.FiX size={32} color="#000" />
          </S.ButtonClose>
        </header>

        <main>
          {children}
        </main>
      </S.Container>
    </ReactModal>
  )
}
