import React from "react";
import { useForm } from "react-hook-form";
import * as Icon from "react-icons/fi";

import { Button } from "../NewButton/Button";
import { Select } from "../Forms/Select/Select";
import { jobCategoryCandidate } from "../../utils/jobCategoryCandidate";

const distances = [
  "Any",
  { value: 1, label: "1 km" },
  { value: 2, label: "2 km" },
  { value: 5, label: "5 km" },
  { value: 10, label: "10 km" },
  { value: 15, label: "15 km" },
  { value: 20, label: "20 km" },
];

const contractTypes = ["Any", "Permanent", "Temporary"];

const categories = ["Any", ...jobCategoryCandidate.filter((item) => !!item)];

const periods = ["Any", "Part Time", "Full Time"];

export default function FiltersForm({ shouldShowDistance = true, onSubmit, setHideSidebar }) {
  const { handleSubmit, register, control } = useForm();

  return (
    <>
      <form
        className="w-full flex flex-col bg-white   rounded-[20px] p-3 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <button className="  flex justify-end ">
          <div className="w-fit bg-slate-50 p-1  h-fit  rounded-full"  onClick={() => {
            setHideSidebar((state) => !state);
          
          }}>
            <Icon.FiX size={31} color="#000" />
          </div>
        </button>
        {shouldShowDistance && (
          <div className="flex flex-col">
            <div className="whyte-bold  text-[16px] xl:text-[20px] ">
              Distance
            </div>

            <Select
              control={control}
              className="mb-2"
              label=""
              options={distances}
              {...register("distance")}
            />
          </div>
        )}

        <div className="whyte-bold text-[16px] xl:text-[20px] "> Category</div>
        <Select
          control={control}
          className="mb-2"
          options={categories}
          {...register("category")}
        />

        <div className="whyte-bold text-[16px] xl:text-[20px] ">
          {" "}
          Contract Type
        </div>
        <Select
          control={control}
          className="mb-2"
          label=""
          options={contractTypes}
          {...register("contractType")}
        />

        <div className="whyte-bold text-[16px] xl:text-[20px] "> Period</div>

        <Select
          control={control}
          className="mb-2"
          label=""
          options={periods}
          {...register("period")}
        />

        <Button
          className="my-3 text-[16px] xl:text-[20px] whyte-bold  rounded-full "
          type="submit"
        >
          Apply Filters
        </Button>
      </form>
    </>
  );
}
