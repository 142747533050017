import { useDropzone } from 'react-dropzone';
import { Container, UploadMessage } from "./styles";
import { FileListItem } from "../../Components/FileListItem/FileListItem";
import { useCallback } from 'react';
import { useState } from 'react';
import { FiAlertTriangle, FiCheckCircle, FiUpload } from 'react-icons/fi';

export const Dropzone = ({ uploadMessage, title, onDrop, acceptedFile, multiple = false, mbMaxSize = 10, children }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const handleDrop = useCallback((files) => {
    setErrorMessage(null);
    onDrop(multiple ? files : files[0])
  }, [onDrop, multiple]);

  const onDropRejected = useCallback((errors) => {
    errors.forEach(error => {
      if (error.errors.some(item => item.code === 'file-too-large')) {
        setErrorMessage('File too large');
      }
    })
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ onDrop: handleDrop, accept: acceptedFile, multiple, maxSize: mbMaxSize * 1000000, useFsAccessApi: false, onDropRejected });

  const files = acceptedFiles.map(
    file => 
      <FileListItem file={file} key={file.path} />
  );

  function renderDragMessage(isDragAccept, isDragReject) {
    if (errorMessage) {
      return (
        <UploadMessage type="error">
          <div className="flex flex-col items-center text-center">
            <FiAlertTriangle size={32} className="mb-4" />

            {errorMessage}
          </div>
        </UploadMessage>
      )
    }

    if (isDragAccept) {
      return (
        <UploadMessage type="success">
          <div className="flex flex-col items-center text-center">
            <FiCheckCircle size={32} className="mb-4" />

            Drop your video here
          </div>
        </UploadMessage>
      )
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          <div className="flex flex-col items-center text-center">
            <FiAlertTriangle size={32} className="mb-4" />

            Content not supported
          </div>
        </UploadMessage>
      )
    }

    return (
      <UploadMessage>
        <div className="flex flex-col items-center text-center">
          <FiUpload size={32} className="mb-4" />

          {uploadMessage} {mbMaxSize && `(Max ${mbMaxSize} MB)`}
        </div>
      </UploadMessage>
    )
  } 

  return (
    <>
      <h4>{title}</h4>
      <Container {...getRootProps({isFocused, isDragAccept, isDragReject, className: 'dropzone' })}>
        <input {...getInputProps()}/>
        {renderDragMessage(isDragAccept, isDragReject)}
      </Container>
      <aside>
        <ul>{files}</ul>

        <ul className="mt-2">{files.length === 0 && children}</ul>
      </aside>
    </>
  )
};
