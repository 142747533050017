import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import React, { useMemo, useState, useRef , useEffect} from "react";
import { Button } from "../../Components/NewButton/Button";
import { useNavigate } from "react-router";
import { GET_COMPANY_VACANCIES } from "./ManageVacancies.services";
import {
  GET_APPLICATIONS,
  CLOSE_POSITION,
  DELETE_VACANCY,
  EDIT_VACANCY,
} from "./ManageVacancyPainel.services";
import { JobSeekerModal } from "../../Components/JobSeekerModal/JobSeekerModal";
import { getAgeFromDate } from "../../utils/getAgeFromDate";
import { useCallback } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { FiTrash, FiCheck, FiX, FiUserMinus, FiUserPlus } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { Modal } from "../../Components/NewModal/Modal";
import { InputText } from "../../Components/Forms/InputText/InputText";
import { InputCheckbox } from "../../Components/Forms/InputCheckbox/InputCheckbox";
import { InputNumber } from "../../Components/Forms/InputNumber/InputNumber";
import { Select } from "../../Components/Forms/Select/NewSelect";
import { Textarea } from "../../Components/Forms/Textarea/Textarea";
import { jobCategoryCandidate } from "../../utils/jobCategoryCandidate";
import { HomePage } from "../HomePage/styles.css";
import editIcon from "../../assets/icons/Edit Square.png";
import { getDifferenceInNaturalTimeFromToday } from "../../utils/getDifferenceInNaturalTime";
import videoIcon from "../../assets/icons/video-icon.png";
import { Link } from "react-router-dom";
import { VideoPlayer } from "../../Components/VideoPlayer/VideoPlayer";
const period = ["Part Time", "Full Time"];

const contractType = ["Permanent", "Temporary"];

export function ManageVacancyPainel({ onCloseJobOrPosition, focusedVacancy }) {
  const [parent] = useAutoAnimate();
  const client = useApolloClient();

  const parsedFocusedVacancy = useMemo(() => {
    return {
      ...focusedVacancy,
    };
  }, [focusedVacancy]);

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: parsedFocusedVacancy,
  });
  const [closeEditModal, setCloseEditModal] = useState(false);
  const [closePositionModal, setClosePositionModal] = useState(false);
  const [closeJobModal, setCloseJobModal] = useState(false);
  const [modalJobSeeker, setModalJobSeeker] = useState(null);
  const [closeCoverVideoModal, setCloseCoverVideoModal] = useState(false);
  const navigate = useNavigate();
  const [editVacancy] = useMutation(EDIT_VACANCY);
  const [closePosition] = useMutation(CLOSE_POSITION);
  const [deleteVacancy] = useMutation(DELETE_VACANCY);
  const { data, loading } = useQuery(GET_APPLICATIONS, {
    variables: { vacancyId: focusedVacancy._id },
  });
  let isSalaryNegotiableValue = useRef({});
  let jobCategoryValue = useRef({});

  isSalaryNegotiableValue.current = watch(
    "isSalaryNegotiable",
    parsedFocusedVacancy.isSalaryNegotiable
  );
  jobCategoryValue.current = watch("category", parsedFocusedVacancy.category);

  const handleSalaryNegotiableChange = useCallback(() => {
    setValue("salary", "");
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      if (loading) {
        return;
      }

      let vacancyCategory = data.category;

      if (vacancyCategory === "Other") {
        vacancyCategory = data.otherJobCategory;
      }

      const updatedVacancy = await editVacancy({
        variables: {
          vacancyId: focusedVacancy._id,
          vacancyJobName: data.jobName,
          vacancyCategory,
          vacancySalaryNegotiable: data.isSalaryNegotiable,
          vacancyDescription: data.description,
          vacancyPeriod: data.period,
          vacancySalary: data.salary ? `${data.salary}` : null,
          vacancyContractType: data.contractType,
          vacancyDisponibleVacancies: data.disponibleVacancies,
          vacancyHourPerWeek: `${data.hourPerWeek}`,
        },
      });

      const companyVacanciesQuery = client.readQuery({
        query: GET_COMPANY_VACANCIES,
        variables: {
          shouldQueryDeleted: true,
        },
      });

      client.writeQuery({
        query: GET_COMPANY_VACANCIES,
        data: {
          company_vacancies: companyVacanciesQuery.company_vacancies.map(
            (item) => {
              if (item._id === focusedVacancy._id) {
                return updatedVacancy;
              }

              return item;
            }
          ),
        },
      });

      setCloseEditModal(false);
    },
    [navigate, editVacancy, client, loading, focusedVacancy]
  );

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }


  
  const applications = useMemo(() => {
    if (loading) {
      return [];
    }

    return data?.applications;
  }, [data, loading]);

  const countryCode = useMemo(() => {
    return applications?.countryCode
      ?.split(" ")[0]
      .replace("[", "")
      .replace("]", "");
  }, [applications]);

  const handleSelectJobSeeker = useCallback(({ jobSeeker, ...application }) => {
    const parsedJobSeeker = {
      ...jobSeeker,
      applicationVideo: application.video,
      applicationPhoto: application.photo,
    };

    setModalJobSeeker(parsedJobSeeker);
  }, []);

  const handleCloseJob = useCallback(async () => {
    await deleteVacancy({
      variables: {
        vacancyId: focusedVacancy._id,
      },
    });

    setCloseJobModal(false);
    setCloseEditModal(false);
    await onCloseJobOrPosition();
  }, [deleteVacancy, onCloseJobOrPosition, focusedVacancy]);

  const handleClosePosition = useCallback(
    async (hireThroughSwanted) => {
      await closePosition({
        variables: {
          vacancyId: focusedVacancy._id,
          hireThroughSwanted,
        },
      });

      alert("Successfully closed position");
      setClosePositionModal(false);
      setCloseEditModal(false);

      await onCloseJobOrPosition();
    },
    [closePosition, onCloseJobOrPosition, focusedVacancy]
  );

  // console.log("applications", applications);
  const [coverVideo, setCoverVideo] = useState("");
  const handleSelectJobSeeker2 = (video) => {
    setCoverVideo(video);
    setCloseCoverVideoModal(true);
  };

  return (
    <>
      {closeCoverVideoModal && (
        <Modal
          isOpen={closeCoverVideoModal}
          closeModal={() => {
            setCloseCoverVideoModal(false);
            setCoverVideo("");
          }}
          width={"80%"}
          maxWidth={800}
        >
          <div className=" whyte-bold text-[24px]  mb-6">Cover Video</div>

          <VideoPlayer srcVideo={coverVideo} />
        </Modal>
      )}

      {closePositionModal && (
        <Modal
          isOpen={closePositionModal}
          closeModal={() => setClosePositionModal(false)}
          width={"85%"}
          maxWidth={800}
        >
          <div className="flex flex-col items-center">
            <div className=" whyte-bold text-[20px] xl:text-[32px] ">
              Did you hire through swanted ?
            </div>
            <div className=" my-[10px] xl:my-[30px] text-[#A3A3A3] text-[18px] xl:text-[24px] whyte-bold">
              Tell us, so we can improve our services
            </div>
            <div className="flex flex-col xl:pb-[50px] ">
              <Button
                className="my-[30px] rounded-full font-normal  text-[18px] xl:text-[22px] whyte-medium py-4 xl:px-8 "
                onClick={() => handleClosePosition(true)}
              >
                Yes, I hired through Swanted
              </Button>
              <Button
                onClick={() => handleClosePosition(false)}
                className=" border-none rounded-full  bg-white  whyte-medium  font-normal text-[18px] xl:text-[22px] whyte-medium py-4 xl:px-8 "
                invert
              >
                No, I hired through another platform
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {closeJobModal && (
        <Modal
          isOpen={closeJobModal}
          closeModal={() => setCloseJobModal(false)}
          width={"80%"}
          maxWidth={500}
        >
          <div className="flex flex-col items-center">
            <div className="whyte-bold text-[22px] text-center my-6 ">
              Are you sure you want to close "{focusedVacancy.jobName}" ?
            </div>

            <div className="flex my-7 w-60">
              <Button
                onClick={() => setCloseJobModal(false)}
                className="border-none whyte-bold rounded-full text-[22px] w-2/4"
                invert
              >
                No
              </Button>
              <Button
                className="whyte-bold text-[22px] rounded-full w-2/4 ml-4"
                onClick={handleCloseJob}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {closeEditModal && (
        <Modal
          isOpen={closeEditModal}
          closeModal={() => setCloseEditModal(false)}
          height={"85%"}
          width={"90%"}
          maxWidth={920}
        >
          <div className="px-0 xl:px-6 whyte-bold text-[18px]  xl:text-[24px] ">
            Edit {focusedVacancy.jobName} ?
          </div>
          <div className="flex flex-col  items-center">
            <div className="flex my-1 w-full">
              <form
                className="flex flex-col w-full pb-5 my-4 px-0 xl:px-6"
                onSubmit={handleSubmit(onSubmit, console.warn)}
              >
                <div className="flex  lg:flex-row flex-col lg:gap-[30px]">
                  <div className=" w-full lg:w-1/2">
                    <div className="whyte-bold text-[18px] xl:text-[20px] ">
                      Job Title
                    </div>
                    <InputText
                      autoComplete="off"
                      placeholder=""
                      style={{ height: "50px" }}
                      className="mb-3 mt-2"
                      {...register("jobName", { required: true })}
                    />
                    {errors.jobName && (
                      <p className="text-red-500">{errors.jobName.message}</p>
                    )}
                  </div>
                  <div className=" w-full lg:w-1/2">
                    <div className="whyte-bold text-[18px] xl:text-[20px] ">
                      Hours per week
                    </div>
                    <InputNumber
                      placeholder=""
                      style={{ height: "50px" }}
                      step={0.1}
                      className="mb-3"
                      min={1}
                      {...register("hourPerWeek", {
                        required: true,
                        min: 1,
                        valueAsNumber: true,
                      })}
                    />
                    {errors.hoursPerWeek && (
                      <p className="text-red-500">
                        {errors.hoursPerWeek.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex  lg:flex-row flex-col lg:gap-[30px]">
                  <div className="w-full lg:w-1/2">
                    <div className="whyte-bold text-[18px] xl:text-[20px] mt-3">
                      Positions Available
                    </div>
                    <InputNumber
                      style={{ height: "50px" }}
                      defaultValue={1}
                      placeholder=""
                      min={1}
                      className="mb-3"
                      {...register("disponibleVacancies", {
                        required: true,
                        min: 1,
                        valueAsNumber: true,
                      })}
                    />
                    {errors.disponibleVacancies && (
                      <p className="text-red-500">
                        {errors.disponibleVacancies.message}
                      </p>
                    )}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <div className="whyte-bold text-[18px] xl:text-[20px] mt-3">
                      Job Category
                    </div>
                    <Select
                      isMulti
                      maxLength={3}
                      control={control}
                      className="mb-3"
                      label=""
                      placeholder="Select 3 max"
                      options={jobCategoryCandidate}
                      rules={{ required: true }}
                      {...register("category")}
                    />
                    {jobCategoryValue.current === "Other" && (
                      <InputText
                        autoComplete="off"
                        label="Other Job Category"
                        className="mt-3"
                        {...register("otherJobCategory", { required: true })}
                      />
                    )}
                  </div>
                </div>

                <div className="flex  lg:flex-row flex-col lg:gap-[30px] ">
                  <div className="w-full lg:w-1/2">
                    <div className="whyte-bold text-[18px] xl:text-[20px] mt-3">
                      Salary per Hour
                    </div>
                    <InputNumber
                      step="any"
                      style={{ height: "50px" }}
                      placeholder=""
                      className="mb-3"
                      {...register("salary", {
                        required: !!isSalaryNegotiableValue.current,
                        disabled: !!isSalaryNegotiableValue.current,
                        min: 1,
                        valueAsNumber: true,
                      })}
                    />

                    {errors.salary && (
                      <p className="text-red-500">{errors.salary.message}</p>
                    )}

                    <InputCheckbox
                      className="mt-2"
                      placeholder="Salary negotiable"
                      {...register("isSalaryNegotiable", {
                        onChange: handleSalaryNegotiableChange,
                      })}
                    />
                  </div>
                  <div className="w-full lg:w-1/2">
                    <div className="whyte-bold text-[18px] xl:text-[20px] mt-3">
                      Job Period
                    </div>
                    <Select
                      control={control}
                      className="mb-3"
                      label=""
                      options={period}
                      {...register("period")}
                      rules={{ required: true }}
                    />
                  </div>
                </div>

                <div>
                  <div className="whyte-bold text-[18px] xl:text-[20px] mt-3">
                    Contract Type
                  </div>
                  <Select
                    control={control}
                    className="mb-3"
                    label=""
                    options={contractType}
                    {...register("contractType")}
                    rules={{ required: true }}
                  />
                </div>

                <div className="whyte-bold text-[18px] xl:text-[20px] mt-3">
                  Short Job Description
                </div>
                <Textarea
                  label=""
                  className="mb-3 w-full"
                  rows={6}
                  maxLength={10000}
                  {...register("description", { required: true })}
                />
                {errors.howKnewAboutUs && (
                  <p className="text-red-500">
                    {errors.howKnewAboutUs.message}
                  </p>
                )}

                <div className="mt-5">
                  <Button
                    type="button"
                    className="text-[20px] xl:text-[22px] whyte-bold  rounded-full  xl:h-[52px] flex flex-row transition w-full duration-500"
                    onClick={() => setCloseJobModal(true)}
                  >
                    <div className="flex items-baseline">
                      <FiTrash className="mr-2" />
                      Close Job
                    </div>
                  </Button>
                </div>

                <div className="flex mt-10">
                  <Button
                    onClick={() => setCloseEditModal(false)}
                    className=" whyte-bold text-[20px] xl:text-[24px] border-none rounded-full  w-2/4"
                    invert
                  >
                    Exit
                  </Button>
                  <Button
                    className="whyte-bold text-[20px] xl:text-[24px]  rounded-full w-2/4 ml-4"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      )}

      <JobSeekerModal
        setModalJobSeeker={setModalJobSeeker}
        modalJobSeeker={modalJobSeeker}
      />

      <div
        className={
          "bg-white  w-full  py-2 rounded-[20px] flex flex-col  lg:fixed lg:w-[38%] xl:h-fit  "
        }
      >
        <header className="flex items-center  w-full  justify-start  p-5 h-fit relative  ">
          <div className="flex flex-col w-full   ">
            <div className="flex  items-center  flex-wrap text-[20px] xl:text-[24px]  whyte-bold ">
              {focusedVacancy.jobName}
              <button
                className="text-md ml-4 flex items-center transition duration-500 hover:text-green-600"
                onClick={() => setCloseEditModal(true)}
              >
                {/* <FiUserPlus className="mr-2" />
                Edit Job */}
                <img className="mx-3" src={editIcon} alt="edit icon" />
              </button>
              <Button
                onClick={() => setClosePositionModal(true)}
                className=" rounded-full whyte-medium  px-6 text-base xl:text-[18px] "
              >
                Hiring Complete
              </Button>
            </div>
            <div className=" whyte my-1 text-[18px] text-[#A3A3A3]   ">
              {focusedVacancy?.company.name}
            </div>

            <hr />
            <div className="mt-2 whyte text-[#546787] ">
              {focusedVacancy.period} - {focusedVacancy.hourPerWeek} hours -
              {focusedVacancy?.category}
              {" - "}
              {focusedVacancy?.contractType}
              {focusedVacancy?.salary
                ? " - " + focusedVacancy?.salary + " per hour"
                : " "}
              {" - "}
              {focusedVacancy?.disponibleVacancies} position
              {focusedVacancy?.disponibleVacancies !== 1 && "s"}
            </div>
          </div>

          {!focusedVacancy.deleted && (
            <div className="flex">
              {/* <button
                className="text-md flex items-baseline transition duration-500 hover:text-red-500"
                onClick={() => setClosePositionModal(true)}
              >
                <FiUserMinus className="mr-2" />
                Close Position
              </button>            */}
            </div>
          )}
        </header>

        <main
          className={`px-6 pb-6 flex-1 flex flex-col overflow-y-scroll ${windowDimensions.height < 600 ?"  max-h-[120px] " :" max-h-[150px] xl:max-h-[300px] "}    h-full`}
          ref={parent}
        >
          {applications?.map((application) => (
            <React.Fragment key={application._id}>
              <button 
                className={
                  "flex border flex-col xl:flex-row text-center   xl:items-center px-4 mt-2 py-4 min-h-32 w-full rounded-lg " +
                  "group transition duration-200  hover:border-primary-700"
                }
              >
                <div className="w-1/5 ">
                  <img
                    className="h-20 w-20 rounded-full  object-contain object-center "
                    src={application.jobSeeker.photo}
                    alt={application.jobSeeker.name}
                  />
                </div>

                <div
                  onClick={() =>
                    navigate(`/painel/manage-profile`, {
                      state: application.jobSeeker,
                    })
                  }
                  className="flex flex-col text-center justify-center items-start w-3/5 sm:ml-6"
                >
                  <div className="flex justify-between w-full">
                    <span className="text-[18px] whyte ">
                      {application.jobSeeker.name}{" "}
                      {application.jobSeeker.surname}
                    </span>
                  </div>

                  <div className="grid grid-cols-1 gap-x-8 text-left sm:grid-cols-2 w-full">
                    <span className="flex items-center text-[16px] text-[#546787] ">
                      {countryCode} {application?.jobSeeker?.phone}
                    </span>
                  </div>
                  <div>
                    <span className="flex items-center text-[16px] text-[#C5C5C5] ">
                      {application?.jobSeeker?.email}
                    </span>

                    {/* <span className="flex items-center">
                      Video:{" "}
                      {!!application.jobSeeker.video ? (
                        <span className="ml-2 text-green-600">
                          <FiCheck />
                        </span>
                      ) : (
                        <span className="ml-2 text-red-600">
                          <FiX />
                        </span>
                      )}
                    </span> */}
                    {/* <span className="flex items-center">
                      CV:{" "}
                      {!!application.jobSeeker.cv ? (
                        <span className="ml-2 text-green-600">
                          <FiCheck />
                        </span>
                      ) : (
                        <span className="ml-2 text-red-600">
                          <FiX />
                        </span>
                      )}
                    </span> */}
                  </div>
                </div>
                <div className=" lg:w-1/5">
                  {application?.video ? (
                    <div
                      className=" w-full flex text-[12px]  items-center mx-[1%]  text-[#9E5BF6] whyte-medium "
                      onClick={() => handleSelectJobSeeker2(application.video)}
                    >
                      {/* <video
                    onClick={() => handleSelectJobSeeker(application)}
                    controls={true}
                    muted
                    loop
                    playsInline
                    alt={application.jobSeeker.name}
                    className="h-full object-contain object-center rounded-[25px]  bg-gray-300 m-auto w-full md:object-cover"
                  >
                    <source
                      src={application?.jobSeeker.video}
                      type="video/mp4"
                    />
                  </video> */}
                      <img
                        className="mr-3"
                        src={videoIcon}
                        alt={application.video}
                      />
                      Watch cover video
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </button>
            </React.Fragment>
          ))}
        </main>
      </div>
    </>
  );
}
