import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/Auth';

export function NonAuthOnly({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth.user) {
    const toRedirect = auth.user.roles.includes('company') ? '/manage-vacancies' : '/map';
    return <Navigate to={toRedirect} state={{ from: location }} />;
  }

  return children;
}

export function CompanyOnly({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (!auth.user.roles.includes('company')) {
    return <Navigate to="/map" state={{ from: location }} />;
  }

  return children;
}

export function JobSeekerOnly({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (!auth.user.roles.includes('jobSeeker')) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

export function AdminOnly({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (!auth.user.roles.includes('admin')) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}
