import { gql } from "@apollo/client";

export const INVITE_USER_TO_VACANCY = gql`
  mutation createVacancyInvite(
    $message: String!
    $vacancyId: ID!
    $jobSeekerId: ID!
  ) {
    createVacancyInvite(
      message: $message
      jobSeeker: $jobSeekerId
      vacancy: $vacancyId
    ) {
      _id
    }
  }
`;
