import { gql } from "@apollo/client";

export const GET_VACANCIES = gql`
  query getVacancies($filters: FiltersInput) {
    getVacancyData(filters: $filters, shouldGetStaticCompanies: true) {
      vacancies {
        _id
        jobName
        salary
        period
        category
        contractType
        disponibleVacancies
        description
        hourPerWeek
        applicants
        date
        hasUserApplied
        hasUserSaved

        company {
          _id
          name
          latlon
          photo
        }
      }
    }
  }
`;
