import React, { useMemo } from 'react'
import { nanoid } from 'nanoid';
import { FiCalendar, FiUser, FiClock, FiFileText, FiBriefcase } from 'react-icons/fi';
import { parseDate } from '../../utils/parseDate';
import { BiEuro } from "react-icons/bi";

import { getDifferenceInNaturalTimeFromToday } from '../../utils/getDifferenceInNaturalTime';

export function Vacancy({ job, onClick, shouldShowDeletedMessage, companyData = null }) {
  const timeSincePost = useMemo(() => {
    if (!job?.date) {
      return null;
    }

    const date = new Date(Number(job.date))

    return getDifferenceInNaturalTimeFromToday(date);
  }, [job]);

  const jobDate = useMemo(() => {
    if(!job) return;
    const date = new Date(Number(job.date))

    return parseDate(date);
  }, [job]);

  const parsedSalary = useMemo(() => {
    if (!job?.salary) {
      return null;
    }

    return job?.salary;
  }, [job]);

  const company = useMemo(() => {
    return companyData || job?.company;
  }, [companyData, job]);

  if (!job) {
    return null;
  }
  
  return (
    <>
      <button
        key={nanoid()}
        className={
          "relative flex border text-center justify-start items-center px-6 mt-2 h-72 w-full rounded-lg "
          + "group transition duration-200 hover:text-white hover:bg-primary-700 "
          + (job.deleted ? 'opacity-50' : '')
        }
        onClick={onClick}
      >
        <div>
          <img
            className="h-0 w-0 sm:w-16 sm:h-16 rounded-full bg-gray-400 object-cover object-center sm:ml-2"
            src={company?.photo}
            alt={company?.name}
          />
        </div>
        <div className="flex flex-col p-3 md:justify-items-start text-center justify-center w-full sm:ml-6 text-black-800 sm:text-xl">
          <div className='w-full text-start'>
            <h2>{job?.jobName} <span className='text-red-500 font-medium text-sm'>{shouldShowDeletedMessage && `(Job already closed)`}</span></h2>
            <h3 className="text-xs">{company.name}</h3>
          </div>

          <div className="flex justify-items-center gap-2 text-gray-800 text-xs transition duration-200 group-hover:text-slate-200 mb-3 mt-1">
            <FiCalendar />
              Posted {timeSincePost}
          </div>

          <ul className="grid grid-cols-3 sm:grid-cols-4 gap-2 sm:gap-5 list-none">
            {job?.salary && (
              <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
                <BiEuro className="w-4 h-4 mr-2" />
                {parsedSalary} per hour
              </li>
            )}
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiUser className="w-4 h-4 mr-2" />
              {job?.disponibleVacancies} position{job?.disponibleVacancies !== 1 && 's'}
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiBriefcase className="w-4 h-4 mr-2" />
              {job?.category}
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiBriefcase className="w-4 h-4 mr-2" />
              {job?.contractType}
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiClock className="w-4 h-4 mr-2" />
              {job?.hourPerWeek} Hours
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiClock className="w-4 h-4 mr-2" />
               {jobDate}
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiFileText className="w-4 h-4 mr-2" />
              {job?.period}
            </li>
          </ul>
        </div>
      </button>
    </>
  )
}
