import { BrowserRouter, Routes, Route } from "react-router-dom";

import { HomePage } from "../Pages/HomePage/HomePage";

import { Login } from "../Pages/Login/Login";
import { Register } from "../Pages/Register/Register";
import { UploadInformations } from "../Components/UploadInformations/UploadInformations";
import { Map } from "../Pages/Map/Map";
import { VacanciesList } from "../Pages/VacanciesList/VacanciesList";
import { MyApplications } from "../Pages/MyApplications/NewMyApplications";
import { SavedVacancies } from "../Pages/SavedVacancies/SavedVacancies";
import { JobSeekerEditProfile } from "../Pages/JobSeekerEditProfile/NewJobSeekerEditProfile";
import { CompanyEditProfile } from "../Pages/CompanyEditProfile/CompanyEditProfile";
import { JobSeekerPainel } from "../Pages/company/JobSeekerPainel/JobSeekerPainel";
import { CompanyProfile } from "../Pages/company/CompanyProfile/CompanyProfile";
import { VacanciesForm } from "../Pages/company/VacanciesForm/VacanciesForm";
import { ManageVacancies } from "../Pages/ManageVacancies/ManageVacancies";
import { MassImport } from "../Pages/admin/MassImport/MassImport";
import { ManageReports } from "../Pages/admin/ManageReports/ManageReports";
import { ManageJobseekersReports } from "../Pages/admin/ManageJobseekersReports/ManageJobseekersReports";
import { MyVacancyInvites } from "../Pages/MyVacancyInvites/NewMyVacancyInvites";
import { ResetPassword } from "../Pages/ResetPassword/ResetPassword";
import InvitationVacancyProfile from "../Pages/InvitationVacancyProfile/VacancyProfile";
import { JobSeekerOnly, CompanyOnly, NonAuthOnly, AdminOnly } from "./Wrappers";
import { ChangePassword } from "../Pages/ChangePassword/ChangePassword";
import { ValidateAccount } from "../Pages/ValidateAccount/ValidateAccount";
import JobSeekerPainelProfile from "../Pages/company/JobSeekerPainel/JobSeekerPainelProfile/JobSeekerPainelProfile";
import JobDetails from "../Pages/JobDetails/JobDetails";
import JobDetailsApplication from "../Pages/JobDetails/JobDetailsApplication";
import NonEnterpriseJobDetails from "../Pages/JobDetails/NonEnterpriseJobDetails";
import MangeJobSeekerPainelProfile from "../Pages/company/JobSeekerPainel/JobSeekerPainelProfile/MangeJobSeekerPainelProfile ";

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            // <NonAuthOnly>
            <HomePage />
            // </NonAuthOnly>
          }
        />
        <Route
          path="/login"
          element={
            <NonAuthOnly>
              <Login />
            </NonAuthOnly>
          }
        />
        <Route
          path="/validate-account/:code"
          element={
            <NonAuthOnly>
              <ValidateAccount />
            </NonAuthOnly>
          }
        />
        <Route
          path="/change-password/:password_code"
          element={
            <NonAuthOnly>
              <ChangePassword />
            </NonAuthOnly>
          }
        />
        <Route
          path="/reset-password"
          element={
            <NonAuthOnly>
              <ResetPassword />
            </NonAuthOnly>
          }
        />
        <Route
          path="/register-jobseeker"
          element={
            <NonAuthOnly>
              <Register isJobSeekerForm={true} />
            </NonAuthOnly>
          }
        />
        <Route
          path="/register-company"
          element={
            <NonAuthOnly>
              <Register isJobSeekerForm={false} />
            </NonAuthOnly>
          }
        />

        <Route
          path="/map"
          element={
            <JobSeekerOnly>
              <Map />
            </JobSeekerOnly>
          }
        />
        <Route
          path="/invite-vacancy-profile"
          element={
            <JobSeekerOnly>
              <InvitationVacancyProfile />
            </JobSeekerOnly>
          }
        />
        <Route
          path="/my-applications"
          element={
            <JobSeekerOnly>
              <MyApplications />
            </JobSeekerOnly>
          }
        />

        <Route
          path="/saved-jobs"
          element={
            <JobSeekerOnly>
              <SavedVacancies />
            </JobSeekerOnly>
          }
        />
        <Route
          path="/my-job-invites"
          element={
            <JobSeekerOnly>
              <MyVacancyInvites />
            </JobSeekerOnly>
          }
        />
        <Route
          path="/jobs-list"
          element={
            <JobSeekerOnly>
              <VacanciesList />
            </JobSeekerOnly>
          }
        />

        <Route
          path="/job-details"
          element={
            <JobSeekerOnly>
              <JobDetails />
            </JobSeekerOnly>
          }
        />
        <Route
          path="/applications-job-details"
          element={
            <JobSeekerOnly>
              <JobDetailsApplication />
            </JobSeekerOnly>
          }
        />
        <Route
          path="/painel/non-enterprise-job-details"
          element={
            <JobSeekerOnly>
              <NonEnterpriseJobDetails />
            </JobSeekerOnly>
          }
        />

        <Route
          path="/painel"
          element={
            <CompanyOnly>
              <JobSeekerPainel />
            </CompanyOnly>
          }
        />
        <Route
          path="/painel"
          element={
            <CompanyOnly>
              <JobSeekerPainel />
            </CompanyOnly>
          }
        />
        <Route
          path="/painel/profile"
          element={
            <CompanyOnly>
              <JobSeekerPainelProfile />
            </CompanyOnly>
          }
        />
        <Route
          path="/painel/manage-profile"
          element={
            <CompanyOnly>
              <MangeJobSeekerPainelProfile />
            </CompanyOnly>
          }
        />

        <Route
          path="/register-job"
          element={
            <CompanyOnly>
              <VacanciesForm />
            </CompanyOnly>
          }
        />
        <Route
          path="/manage-vacancies"
          element={
            <CompanyOnly>
              <ManageVacancies />
            </CompanyOnly>
          }
        />

        <Route
          path="/company-profile"
          element={
            <CompanyOnly>
              <CompanyEditProfile />
            </CompanyOnly>
          }
        />
        <Route
          path="/mass-import"
          element={
            <AdminOnly>
              <MassImport />
            </AdminOnly>
          }
        />
        <Route
          path="/manage-vacancies-reports"
          element={
            <AdminOnly>
              <ManageReports />
            </AdminOnly>
          }
        />
        <Route
          path="/manage-jobseekers-reports"
          element={
            <AdminOnly>
              <ManageJobseekersReports />
            </AdminOnly>
          }
        />

        <Route
          path="/companies/:id"
          element={
            <JobSeekerOnly>
              <CompanyProfile />
            </JobSeekerOnly>
          }
        />
        <Route
          path="/profile"
          element={
            <JobSeekerOnly>
              <JobSeekerEditProfile />
            </JobSeekerOnly>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
