import React, { forwardRef, useCallback, useState, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';

export const Select = forwardRef(({ id, name, rules = {}, options, control, label, placeholder, maxLength, onChange: customOnChange, className = '', ...props }, ref) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const parsedOptions = useMemo(() => {
    return options.map(option => ({
      value: option.value || option,
      label: option.label || option,
    }))
  }, [options]);

  const handleChange = useCallback((onChange) => {
    return async (option) => {
      
      if (Array.isArray(option)) {
        setSelectedOptions(option.map(item => item.value));
        onChange(
          option.map(item => item.value).join(', '),
        );

        return;
      }

      setSelectedOptions([option]);
      onChange(option.value);

      try {
        if (customOnChange) {
          await customOnChange();
        }
      } catch (_err) {}
    }
  }, [maxLength, customOnChange]);

  // if (!control) {
  //   return null;
  // }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref, name }}) => (
        <div className={"flex flex-col  "+ className}>
          <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor={name}>{label}</label>

            <ReactSelect
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius:"none",
                border: state.isFocused ?" 1px solid rgb(106,13,18)" :"none",
                maxWidth:"380p",
                borderBottom:"1px solid #A3A3A3 ",
                              
                borderColor: state.isFocused ? ' #6A0DB4 !important' : '',
              }),
            }}
        
            {...props}
            options={parsedOptions}
            // ref={ref}
            
            id={name}
            name={name}
            placeholder={placeholder}
            onChange={handleChange(onChange)}
            defaultInputValue={selectedOptions[0] ? selectedOptions[0]?.label : value}
            className={"block w-full  shadow-sm sm:text-sm "}
            isOptionDisabled={() => {
              if (!maxLength) return;

              return selectedOptions.length >= maxLength
            }}
          />
        </div>
      )}
    />
  )
});
