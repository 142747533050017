import React, { useLayoutEffect, useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  FiUser,
  FiBriefcase,
  FiCamera,
  FiAward,
  FiFile,
  FiCheckCircle,
} from "react-icons/fi";
import { nanoid } from "nanoid";

import { transformFileInBase64 } from "../../utils/transformFileInBase64";
import { Login } from "./Step/Login";
import { GeneralInformation } from "./Step/GeneralInformation";
import { PhotoAndVideo } from "./Step/PhotoAndVideo";
import { CV } from "./Step/CV";
import { StepFive } from "./Step/StepFive";
import { useNavigate } from "react-router";
import { CREATE_USER } from "./JobSeekerForm.services";

import banner1Min from "../../assets/banners/banner 1-min.png";
import banner2Min from "../../assets/banners/banner 2-min.png";
import banner5Min from "../../assets/banners/banner 5-min.png";
import { useAuth } from "../../hooks/Auth";
import { NewNavbar } from "../../Components/NewNavbar/NewNavbar";

const StepsData = [
  {
    icon: <FiUser size={36} className="text-inherit" />,
    name: "Login",
  },
  {
    icon: <FiBriefcase size={36} className="text-inherit" />,
    name: "General Information",
  },
  {
    icon: <FiCamera size={36} className="text-inherit" />,
    name: "Intro Video",
  },
  {
    icon: <FiAward size={36} className="text-inherit" />,
    name: "Experience",
  },
  {
    icon: <FiFile size={36} className="text-inherit" />,
    name: "More about you ",
  },
];

export const JobSeekerForm = ({ setSideImage }) => {
  const { signIn } = useAuth();
  const [createUser, { loading }] = useMutation(CREATE_USER, {
    optimisticResponse: true,
  });
  const navigate = useNavigate();
  const [shouldWarnAboutVideo, setShouldWarnAboutVideo] = useState(false);
  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(0); 

  const onSubmitStep = useCallback((data) => {
    if (data?.video === null) {
      setShouldWarnAboutVideo(true);
    }

    setFormData((state) => ({ ...state, ...data }));
    setStep((state) => state + 1);
  }, []);

  const stepBack = useCallback(() => {
    setStep((state) => state - 1);
  }, []);

  const advance = useCallback(() => {
    setStep((state) => state + 1);
  }, []);

  const stay = useCallback(() => {
    setShouldWarnAboutVideo(false);
  }, []);

  const onSubmitForm = useCallback(
    async (lastStepData) => {
      if (loading) return;

      const data = {
        ...formData,
        ...lastStepData,
      };
      if (!data.agreeWithTerms) return;

      const { profilePhoto, video, cv } = data;

      let jobSeekerPhoto = null;
      let jobSeekerVideo = null;
      let jobSeekerCv = null;

      if (profilePhoto) {
        jobSeekerPhoto = {
          base64: await transformFileInBase64(profilePhoto),
          name: profilePhoto.name,
          type: profilePhoto.type,
        };
      }

      if (video) {
        jobSeekerVideo = {
          base64: await transformFileInBase64(video),
          name: video.name,
          type: video.type,
        };
      }

      if (cv) {
        jobSeekerCv = {
          base64: await transformFileInBase64(cv),
          name: cv.name,
          type: cv.type,
        };
      }

      const jobSeekerExperiences = data.experiences.map((experience) => {
        delete experience.id;

        return experience;
      });

      try {
        await createUser({
          variables: {
            email: data.email,
            password: data.password,
            jobSeekerAvailability: {
              ...data.availability,
              note: data.availabilityNote,
            },
            jobSeekerName: data.firstName,
            jobSeekerSurname: data.surname,
            jobSeekerAddress: data.address,
            // jobSeekerEircode: data.eirCode,
            jobSeekerGender: data.gender,
            jobSeekerBirth: data.birthDate,
            // jobSeekerCountry: data.countryName,
            jobSeekerLookingFor: data.lookingFor,
            jobSeekerCountryCode: data.countryCode,
            jobSeekerPhone: data.phone,
            jobSeekerSocialMedias: data.socialNetworks,
            jobSeekerLanguages: Array.isArray(data.languages)
              ? data.languages
              : [data.languages],
            jobSeekerPhoto,
            jobSeekerVideo,
            jobSeekerCv,
            jobSeekerExperiences,
          },
        });

        alert(
          "We sent an e-mail to validate your email address. If it is not in your inbox, please check your spam folder. Once you find it please mark it as NOT SPAM and it will go into your folder so you can click the link."
        );
        navigate("/");
      } catch (error) {
        if (error.message === "E-mail already taken") {
          alert("E-mail already taken, choose a new one");

          setStep(0);
          return;
        }

        if (error.message === "E-Mail already pending validation") {
          alert(
            "We have sent an email to validate your email address. Please click on the link to continue. Don’t forget to check your spam folders too."
          );

          navigate("/");
          return;
        }
        if (error.message === "Address not found") {
          alert("Address not found.");

          setStep(1);
          return;
        }

        throw error;
      }
    },
    [formData, createUser, loading, signIn]
  );

  useLayoutEffect(() => {
    const sideAssets = [
      banner1Min,
      banner5Min,
      banner2Min,
      banner1Min,
      banner1Min,
    ];

    setSideImage(sideAssets[step]);
  }, [step]);

  const renderStep = useCallback(() => {
    const steps = [
      <Login
        onSubmitStep={onSubmitStep}
        formData={formData}
        stepBack={stepBack}
      />,
      <GeneralInformation
        onSubmitStep={onSubmitStep}
        formData={formData} 
        stepBack={stepBack}
      />,
      <PhotoAndVideo
        onSubmitStep={onSubmitStep}
        formData={formData}
        stepBack={stepBack}
      />,
      <CV
        setShouldWarnAboutVideo={setShouldWarnAboutVideo}
        shouldWarnAboutVideo={shouldWarnAboutVideo}
        onSubmitStep={onSubmitStep}
        loading={loading}
        formData={formData}
        stepBack={stepBack}
        stay={stay}
        advance={advance}
      />,
      <StepFive
        formData={formData}
        stepBack={stepBack}
        loading={loading}
        onSubmitForm={onSubmitForm}
      />,
    ];

    return steps[step];
  }, [
    step,
    onSubmitForm,
    onSubmitStep,
    shouldWarnAboutVideo,
    formData,
    setShouldWarnAboutVideo,
    loading,
  ]);

  return (
    <div className=" lg:py-10 w-full h-full ">
      <nav className="flex justify-center ">
        <NewNavbar />
      </nav>
      <div className=" px-[6%] mt-[60px] flex w-full flex-col xl:flex-row gap-[30px] xl:gap-[60px] ">
        <div className=" min-w-[321px] max-h-[566px] border-[1px] border-[#C5C5C5] rounded-[39px] px-[20px] py-[50px] ">
          <div className=" text-[#630DB1] text-[20px] xl:text-[24px] whyte">
            5 Steps
          </div>
          <div className=" text-[24px] xl:text-[32px] whyte-bold mt-[12px] mb-[30px] ">
            Create Account
          </div>
          <ul className="flex flex-col  gap-[20px] xl:gap-[30px]   ">
            <li
              className={` ${
                step === 0
                  ? " bg-[#9E5BF6] text-white text-[18px] xl:text-[20px]    rounded-[14px]  pt-[10px] pb-[8px]  "
                  : ""
              }  transition-all px-[20px]  text-[20px] xl:text-[24px] whyte`}
            >
              Login
            </li>
            <li
              className={` ${
                step === 1
                  ? " bg-[#9E5BF6] text-white text-[18px] xl:text-[20px]    rounded-[14px]  pt-[10px] pb-[8px]  "
                  : ""
              } transition-all px-[20px]  text-[20px] xl:text-[24px] whyte`}
            >
              General Information
            </li>
            <li
              className={` ${
                step === 2
                  ? " bg-[#9E5BF6] text-white text-[18px] xl:text-[20px]    rounded-[14px]  pt-[10px] pb-[8px]  "
                  : ""
              } transition-all px-[20px]  text-[20px] xl:text-[24px] whyte`}
            >
              Intro Video
            </li>
            <li
              className={` ${
                step === 3
                  ? " bg-[#9E5BF6] text-white text-[18px] xl:text-[20px]    rounded-[14px]  pt-[10px] pb-[8px]  "
                  : ""
              } transition-all px-[20px]  text-[20px] xl:text-[24px] whyte`}
            >
              Experience
            </li>
            <li
              className={` ${
                step === 4
                  ? " bg-[#9E5BF6] text-white text-[18px] xl:text-[20px]    rounded-[14px]  pt-[10px] pb-[8px]  "
                  : ""
              } transition-all px-[20px]  text-[20px] xl:text-[24px] whyte`}
            >
              More about you
            </li>
          </ul>
        </div>

        <div className="w-full">{renderStep()}</div>
      </div>

      {/* <div className="flex flex-col items-center sm:w-3/4 w-[80%]">
        <header className="flex items-center justify-between w-full">
          {StepsData.map((stepInfo, index) => (
            <React.Fragment key={nanoid()}>
              {index !== 0 && (
                <div className="w-4 h-1 bg-slate-600 rounded-lg opacity-50" />
              )}

              <div className={"flex flex-col items-center justify-center transition duration-500 " + (index === step ? 'opacity-100 scale-100 ' : 'opacity-50 scale-75 ')}>
                <button
                  onClick={() => {
                    if (index <= step) { setStep(index) }
                  }}
                    className={"bg-slate-100 p-2 rounded-full transition duration-500 " + (index >= step ? 'text-slate-600 ' : 'text-primary-700 ') + (index <= step ? 'cursor-pointer' : 'cursor-not-allowed')}
                  >
                  {index >= step 
                  ? stepInfo.icon
                  : <FiCheckCircle size={36} className="text-inherit" />}
                </button>

                <strong className={"text-center " + (index === step ? 'font-bold' : 'font-medium')}>{stepInfo.name}</strong>
              </div>
            </React.Fragment>
          ))}
        </header>

        <div className="flex flex-col w-full mt-5">
          {renderStep()}
        </div>
      </div> */}
    </div>
  );
};
