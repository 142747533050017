import React, { useCallback, useState, useLayoutEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import next from "../../../../assets/homePage/next.png";
import { InputText } from "../../../../Components/Forms/NewInputText/InputText";
import { InputPhone } from "../../../../Components/Forms/NewInputPhone/InputPhone";
import { Button } from "../../../../Components/Button/Button";
import { useNavigate } from "react-router";
import { geoLocationApi } from "../../../../services/geolocation";
import { InputPassword } from "../../../../Components/Forms/NewInputPassword/InputPassword";
import { InputEmail } from "../../../../Components/Forms/NewInputEmail/InputEmail";
import { Select } from "../../../../Components/Forms/NewSelect/Select";
import { CREATE_USER } from "../CompanyForm.services";
import { transformFileInBase64 } from "../../../../utils/transformFileInBase64";
import banner3Min from "../../../../assets/banners/banner 3-min.png";
import { useAuth } from "../../../../hooks/Auth";
import CountryCodes from "../../../../staticData/NewCountryCodes.json";
import { ErrorMessage } from "@hookform/error-message";

const countryCodes = CountryCodes.map(
  (country) => `${country.flag} ${country.dial_code} (${country.name})`
);
const mailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const CompanyLogin = ({
  formData,
  handleUpdateFormData,
  handleNextStep,
  setSideImage,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });
  let passwordRef = useRef({});

  passwordRef.current = watch("password", formData?.password || "");

  const onSubmit = useCallback(
    async (data) => {
      handleUpdateFormData(data);
      handleNextStep();
    },
    [handleUpdateFormData]
  );

  useLayoutEffect(() => {
    setSideImage(banner3Min);
  }, []);

  return (
    <div className="flex flex-col items-center    m-4 w-full h-full ">
      <div className="flex flex-col w-full items-center">
        <form
          className="flex xl:flex-row flex-col w-full xl:w-full xl:mt-5 pb-6"
          onSubmit={handleSubmit(onSubmit, () => {})}
        >
          <div className="xl:w-[80%] w-[90%] flex flex-col  xl:mt-5 pb-6">
            <div className=" whyte text-[16px] xl:text-[24px] text-[#A3A3A3] ">
              Let’s get to know your basic information
            </div>
            <div className=" text-[24px] xl:text-[32px] whyte-bold mt-[12px] mb-[30px] ">
              Login
            </div>
            <div>
              <div className=" text-[24px] xl:text-[32px] whyte-bold mt-[12px]  ">
                E-mail*
              </div>
              <InputEmail
                placeholder={"example@mail.com"}
                className="mt-3"
                {...register("email", {
                  required: "input e-mail",
                  validate: (value) =>
                    mailRegex.test(value) || "Invalid E-mail",
                })}
              />
              <ErrorMessage
                name="email"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>

            <div className="grid xl:grid-cols-2 gap-4 mt-[20px] ">
              <div>
                <div className=" text-[24px] xl:text-[32px] whyte-bold mt-[12px]  ">
                  Password
                </div>
                <InputPassword
                  placeholder={"*****"}
                  className="mt-3"
                  {...register("password", { required: "input password" })}
                />
                <ErrorMessage
                  name="password"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>

              <div>
                <div className=" text-[24px] xl:text-[32px] whyte-bold mt-[12px]  ">
                  Repeat Password*
                </div>
                <InputPassword
                  placeholder={"******"}
                  className="mt-3"
                  {...register("password_repeat", {
                    required: "passwords doesn't match",
                    validate: (value) =>
                      value === passwordRef.current ||
                      "passwords doesn't match",
                  })}
                />
                <ErrorMessage
                  name="password_repeat"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>
            </div>

            <div className="col-span-2 mt-[20px] ">
              <div className=" text-[24px] xl:text-[32px] whyte-bold mt-[12px]  ">
                Company Name*
              </div>
              <InputText
                placeholder={"Company Name"}
                className="mt-3"
                {...register("companyName", { required: "input company name" })}
              />
              <ErrorMessage
                name="companyName"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>

            <div className="grid xl:grid-cols-2 gap-4 mt-[20px]">
              <div>
                <div className=" text-[24px] xl:text-[32px] whyte-bold mt-[12px]  ">
                  Country Code*
                </div>
                <Select
                  control={control}
                  options={countryCodes}
                  label={""}
                  defaultValue="+353 (Ireland)"
                  className="mt-3"
                  // rules={{ required: 'input country code' }}
                  {...register("countryCode", {
                    required: "input country code",
                  })}
                />
                <ErrorMessage
                  name="countryCode"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>

              <div>
                <div className=" text-[24px] xl:text-[32px] whyte-bold mt-[12px]  ">
                  Phone*
                </div>
                <InputPhone
                  className="mt-[26px]"
                  placeholder={"123 4567"}
                  label={"Phone*"}
                  {...register("phone", { required: "input phone" })}
                />
                <ErrorMessage
                  name="phone"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>
            </div>

            {/* <Button type="submit" className="my-8 h-12" style={{ width: "100%" }}>
            Next
          </Button> */}
          </div>
          <div className="xl:w-[20%]  flex self-end  px-[5%] xl:mb-10 ">
            <button
              type="submit"
              className="  mt-8 xl:mt-4  w-[80%] xl:w-full xl:h-12  hover:scale-105 transition-all"
            >
              <img src={next} alt="next" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
