import React, { useState, useCallback } from "react";
import { Button } from "../../Components/NewButton/Button";
import { Textarea } from "../../Components/Forms/Textarea/Textarea";
import { ModalMessage } from "../../Components/ModalMessage/NewModalMessage";
import { SeeMoreModal } from "../../Components/SeeMoreModal/SeeMoreModal";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { ModalNotInterested } from "../../Components/ModalMessage/ModalNotInterested";

export function MyVacancyInvitesPainel({ focusedVacancy, refetch }) {
  const [parent] = useAutoAnimate();
  const [isOpenModalMessage, setIsOpenModalMessage] = useState(false);
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState(false);
  const [isNotInterestedModalOpen, setIsNotInterestedModalOpen] = useState(false);
  const navigator = useNavigate();

  const handleApply = useCallback(() => { setIsOpenModalMessage(true) }, []);
  const handleDisabled = useCallback(() => focusedVacancy?.userNotInterested === true ? true : false, [focusedVacancy]);
  const handleSeeMore = useCallback(() => { setIsSeeMoreModalOpen(true) }, []);
  const handleNotInterested = useCallback(() => { setIsNotInterestedModalOpen(true) }, []);

  return (
    <>
      {isSeeMoreModalOpen && (
        <SeeMoreModal
          job={focusedVacancy?.vacancy}
          isSeeMoreModalOpen={isSeeMoreModalOpen}
          setSeeMoreModal={setIsSeeMoreModalOpen}
        />
      )}

      {isOpenModalMessage && (
        <ModalMessage
          job={focusedVacancy?.vacancy}
          isOpenModalMessage={isOpenModalMessage}
          setIsOpenModalMessage={setIsOpenModalMessage}
          onClose={refetch}
        />
      )}

      {isNotInterestedModalOpen && (
        <ModalNotInterested
          job={focusedVacancy}
          isNotInterestedModalOpen={isNotInterestedModalOpen}
          setIsNotInterestedModalOpen={setIsNotInterestedModalOpen}
          refetch={refetch}
        />
      )}

      <div
        ref={parent}
        className="flex-1 bg-white rounded-[10px] flex flex-col h-full"
      >
        <header className="p-6 h-32">
          <div className=" text-[20px] lg:text-[24px] whyte-bold ">
            {focusedVacancy?.vacancy?.jobName}
          </div>
          <div className=" whyte text-[16px] lg:text-[18px] text-[#A3A3A3]">
            {focusedVacancy?.vacancy?.company.name}
          </div>
          <div className="whyte text-[#546787]">
            {focusedVacancy?.vacancy?.period} •{" "}
            {focusedVacancy?.vacancy?.hourPerWeek} hours
          </div>
        </header>

        <main className="p-6 pb-16 flex-1 flex flex-col overflow-y-auto h-full">
          {!focusedVacancy?.hasUserAccepted && (
            <>
              <div>
                <div className="text-[18px] lg:text-[20px] whyte-bold">
                  Company Message
                </div>

                <p className="my-4 p-4 border-2">{focusedVacancy?.message}</p>
              </div>

              {focusedVacancy?.userNotInterested ? (
                <p class="text-red-500 font-medium text-sm">
                  You declined this job
                </p>
              ) : null}

              <div className="flex items-center gap-[10px] my-4">
                <Button
                  className=" w-full xl:w-[320px] bg-[#9E5BF6] h-[52px] text-[16px] lg:text-[20px] whyte-bold rounded-full"
                  onClick={handleApply}
                  disabled={handleDisabled()}
                >
                  Apply
                </Button>

                <Button
                  invert
                  className="w-full xl:w-[320px] bg-white border-[#9E5BF6] h-[52px] text-[16px] lg:text-[20px] white-bold rounded-full "
                  disabled={handleDisabled()}
                  onClick={handleNotInterested}
                >
                  Not Interested
                </Button>

                {/*               
              <Button invert className=" w-full xl:w-[320px] bg-white border-none h-[52px] text-[20px] whyte-bold  rounded-full " onClick={handleSeeMore}>
                See More
              </Button>  */}
              </div>
              <Button
                onClick={() =>
                  navigator("/invite-vacancy-profile", {
                    state: focusedVacancy,
                  })
                }
                invert
                className="w-full my-4 xl:w-[320px] bg-white border-none h-[52px] text-[16px] lg:text-[20px] white-bold rounded-full "
              >
                See More
              </Button>
            </>
          )}
          {focusedVacancy?.hasUserAccepted && (
            <>
              <div className="whyte-bold text-[20px] xl:text-[24px]">
                You have already applied for this position.
              </div>
            </>
          )}
        </main>
      </div>
    </>
  );
}
