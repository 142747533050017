import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router";

import { LoadingScreen } from "../../Components/LoadingScreen/LoadingScreen";
import { Navbar } from "../../Components/Navbar/NewNavbar";
import { SavedVacancy } from "../../Components/NewVacancy/SavedVacancy";
import { SeeMoreModal } from "../../Components/SeeMoreModal/SeeMoreModal";
import { Footer } from "../../Components/Footer/Footer";
import { useQuery } from "@apollo/client";
import { GET_SAVED_VACANCIES } from "./SavedVacancies.services";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { SelectActionModal } from "../../Components/SelectActionModal/SelectActionModal";
import { SavedVacanciesPainel } from "./SavedVacanciesPainel";

export function SavedVacancies() {
  const { data, loading, refetch } = useQuery(GET_SAVED_VACANCIES);
  const [job, setJob] = useState(null);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [focusedVacancy, setFocusedVacancy] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleSelectApplication = useCallback((selectedApplication) => {
    setFocusedVacancy(selectedApplication);
  }, []);

  const handleSelectVacancy = useCallback((selectedJob) => {
    setJob(selectedJob);
    setIsSelectModalOpen((state) => !state);
  }, []);

  const filteredJobs = useMemo(() => {
    if (loading) {
      return [];
    }

    return data.savedVacancies;
  }, [data, loading]);

  useEffect(() => {
    if (filteredJobs.length === 0) {
      setFocusedVacancy(null);
      return;
    }
    
    if(filteredJobs[0].userNotInterested) {
      setDisabled(true)
    }

    setFocusedVacancy(filteredJobs[0]);
  }, [filteredJobs]);



  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-gray-50 w-full pt-[20px] ">
      <SelectActionModal
        job={job}
        isSelectModalOpen={isSelectModalOpen}
        setIsSelectModalOpen={setIsSelectModalOpen}
      />

      <Navbar />

      <div className="bg-gray-50 w-full min-h-[93vh] pt-10 xl:pt-2">
        <aside className="w-full h-full px-6 xl:px-12" aria-label="Sidebar">
          <div className="flex flex-col py-5 px-3 bg-gray-50 rounded h-full">
            <legend className="flex whyte-bold text-[24px] xl:text-[32px]  ">
              Saved Jobs
            </legend>
            <div className=" flex flex-col-reverse lg:flex-row w-full gap-[2%]">
              <div className=" lg:w-[65%]">
                {filteredJobs.map((vacancy, index) => (
                  <SavedVacancy
                    key={vacancy._id}
                    job={vacancy}
                    onClick={() =>
                      //  handleSelectVacancy(vacancy)
                      handleSelectApplication(filteredJobs[index])
                    }
                  />
                ))}
              </div>


              <div className="relative lg:w-[35%]">
                {focusedVacancy && (
                  <div className="lg:fixed lg:top-[230px] lg:w-[30%] h-full ">
                    <SavedVacanciesPainel
                      setFocusedVacancy={setFocusedVacancy}
                      focusedVacancy={focusedVacancy}
                      disabled={disabled}
                      refetch={refetch}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </aside>
      </div>

      <Footer />
    </div>
  );
}
