import React from "react";
import { Button } from "../NewButton/Button";
import tick from "../../assets/homePage/Tick.png";
export function ApplicationConcludedModal({ job, handleModal }) {
  return (
    <>
      <div>
        <img className="h-[100px]" src={tick} alt="tick" />
      </div>
      <div
        className=" text-[20px] max-w-[280px] xl:text-[32px] xl:max-w-[544px] text-center whyte-bold mt-3 mb-4"
        id="modal-title"
      >
        You have successfully applied for {job.jobName} in {job.company.name}
      </div>

      <Button
        className="min-w-60 h-[62px]  whyte-bold text-[20px] xl:text-[24px]  text-white border-none px-[30px] rounded-full bg-[#9E5BF6]"
        onClick={handleModal}
      >
        Close
      </Button>
    </>
  );
}
