import React from 'react'

import logo from '../../assets/logo.jpeg';
import { Navbar } from "../../Components/Navbar/NewNavbar";

export function LoadingScreen() {
  return (
    <div className='bg-gray-50 w-full h-full pt-[20px] '>
      <Navbar />
      <div className="pt-16 flex flex-col items-center justify-center w-full h-full bg-white">
        <img src={logo} alt="Swanted" className="animate-bounce w-16 h-18 mb-6 mt-40" />
      </div>
    </div>
  )
}
