import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

/* console.log(process.env.REACT_APP_API_URL); */
const link = createHttpLink({
  uri: process.env.REACT_APP_API_URL
  /* uri: "http://localhost:4000" */
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("@Swanted/Token");

  return {
    headers: {
      ...headers,
      authentication: token
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({})
});
