import React from 'react';

export function Footer({ className, ...props }) {
  return (
    <>
      <footer className={"h-16 bg-gray-100 rounded-lg shadow flex items-center justify-between p-4 px-8 " + className}>
        <span className="text-sm text-gray-500 sm:text-center">
          © 2022 <span className="hover:underline">Swanted™</span>. All Rights Reserved.
        </span>

        <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500">
          <li>
            <a href="mailto:contact@swanted.io" className="text-blue-400 hover:underline">contact@swanted.io</a>
          </li>
        </ul>
      </footer>
    </>
  );
}
