import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";

import logo from '../../assets/logo_swanted_purple.png';
import SwantedInitialVideo from '../../assets/initial-video/Swanted_compress.mp4';
import { InputEmail } from '../../Components/Forms/InputEmail/InputEmail';
import { InputPassword } from '../../Components/Forms/InputPassword/InputPassword';
import { Button } from '../../Components/Button/Button';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from './ResetPassword.services';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [passwordReset, { loading }] = useMutation(RESET_PASSWORD)
  const [loginError, setLoginError] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = useCallback(async ({ email }) => {
    try {
      setLoginError('');
      await passwordReset({
        variables: {
          email,
        }
      });

    } catch (error) {
      
    } finally {
      alert('We sent an e-mail to reset your password. If it is not in your inbox, please check your spam folder. Once you find it please mark it as NOT SPAM and it will go into your folder so you can click the link.');
    }
  }, [navigate, passwordReset]);

  return (
    <>
      <div className="w-full min-h-screen flex">
        <div className="flex min-h-screen w-full">
          <div className="hidden md:block flex-auto w-32">
            <video
              controls={false}
              autoPlay
              muted
              loop
              playsInline
              alt="Map"
              className="h-full object-cover object-center rounded-tr-md rounded-br-md bg-gray-300"
            >
              <source src={SwantedInitialVideo} type="video/mp4" />
            </video>
          </div>

          <div className="flex flex-col items-center justify-center flex-1 w-full h-full overflow-y-auto overflow-x-hidden shadow-md">
            <div>
              <div>
                <img className="w-[250px] m-auto my-6" src={logo} alt="Workflow" />
                <p className="mt-2 text-center text-sm text-gray-600">See, Swipe, Hire. Simple!</p>
                
              </div>
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Reset your password</h2>
              <form className="flex flex-col mt-8 space-y-6 w-80" onSubmit={handleSubmit(onSubmit, () => {})}>
                <InputEmail
                  placeholder={'Email'}
                  {...register('email', {
                    required: true,
                    validate: 
                      value => /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(value) || 'Invalid E-mail',
                  })}
                />
                {errors.email && <p className="text-red-500">{errors.email.message}</p>}

                <Button loading={loading} type="submit" className="mt-4 h-12" style={{width: '100%'}}>
                  Reset Password
                </Button>
              </form>

              <div className="flex flex-col mt-4">
                <Link to="/login" className="text-blue-400 text-sm mb-4">
                  <a>
                    Go to Sign In
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

