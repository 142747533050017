import { gql } from "@apollo/client";

export const CREATE_VACANCY = gql`
  mutation createVacancy(
    $vacancyJobName: String
    $vacancyPeriod: String
    $vacancySalary: String
    $vacancyCategory: String
    $vacancyContractType: String
    $vacancySalaryNegotiable: Boolean
    $vacancyDescription: String
    $vacancyDisponibleVacancies: Int
    $vacancyHourPerWeek: String
  ) {
    createVacancy(
      vacancy: {
        jobName: $vacancyJobName
        period: $vacancyPeriod
        isSalaryNegotiable: $vacancySalaryNegotiable
        description: $vacancyDescription
        salary: $vacancySalary
        category: $vacancyCategory
        contractType: $vacancyContractType
        disponibleVacancies: $vacancyDisponibleVacancies
        hourPerWeek: $vacancyHourPerWeek
      }
    ) {
      _id
      period
      jobName
      salary
      date
      contractType
      date
      disponibleVacancies
      hourPerWeek
      company {
        _id
        name
        latlon
      }
    }
  }
`;
