import React, { useCallback } from "react";
import { useAuth } from "../../hooks/Auth";
import { APPLY_TO_VACANCY } from "./ModalMessage.services";
import { nanoid } from "nanoid";
import { useMutation, useApolloClient } from "@apollo/client";
import { LoadingSpin } from "../LoadingSpin/LoadingSpin";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { UploadFile } from "../UploadFile/UploadFile";
import { transformFileInBase64 } from "../../utils/transformFileInBase64";
import backIcon from "../../assets/homePage/back.png";
import { GET_MY_APPLICATIONS } from "../../Pages/MyApplications/MyApplications.services";
import { Button } from "../NewButton/Button";
import { useState } from "react";
import { GET_SAVED_VACANCIES } from "../../Pages/SavedVacancies/SavedVacancies.services";

export function SendProfileCvAndCoverVideoModal({
  setApplicationConcluded,
  job,
}) {
  const client = useApolloClient();
  const { user } = useAuth();
  const [applyToVacancy, { loading }] = useMutation(APPLY_TO_VACANCY);
  const [isSendingNewVideo, setIsSendingNewVideo] = useState(true);
  const [video, setVideo] = useState(null);

  const handleApply = useCallback(async () => {
    const { photo, video: jobSeekerVideo, cv } = user.jobSeeker;
    const application = {
      _id: nanoid(),
      photo,
      video: jobSeekerVideo,
      cv,
      vacancy: job,
    };

    const savedJobs =
      client.readQuery({
        query: GET_SAVED_VACANCIES,
      })?.savedVacancies || [];

    client.writeQuery({
      query: GET_SAVED_VACANCIES,
      data: {
        savedVacancies: savedJobs.filter(
          (savedJob) => savedJob._id !== job._id
        ),
      },
    });

    const applications =
      client.readQuery({
        query: GET_MY_APPLICATIONS,
      })?.applications || [];

    client.writeQuery({
      query: GET_MY_APPLICATIONS,
      data: {
        applications: [...applications, application],
      },
    });

    setApplicationConcluded(true);

    const videoData = video
      ? {
          base64: await transformFileInBase64(video),
          name: video.name,
          type: video.type,
        }
      : null;

    await applyToVacancy({
      variables: {
        vacancyId: job._id,
        video: videoData,
        shouldSendCoverVideo: true,
      },
    });
  }, [applyToVacancy, job, user, video]);

  const handleSendNewVideo = useCallback(async () => {
    if (!video) return;

    await handleApply();
  }, [video, handleApply]);

  if (loading) {
    return <LoadingSpin />;
  }

  return (
    <div className="flex flex-col items-center xl:p-10 w-full">
      {/* <div className=" self-start ">
          <img className="w-[56px] " src={backIcon} alt="back" />
        </div> */}
      <div className="flex  items-center justify-between gap-[20px] xl:gap-[50px] ">
        <div
          className="text-[20px] xl:text-[32px] max-w-[544px] text-center whyte-bold mt-3 mb-4"
          id="modal-title"
        >
          Upload Cover Video
        </div>
      </div>
      <div className=" text-[#A3A3A3] text-[16px] mb-2 xl:text-[20px] max-w-[280px] xl:max-w-[544px] text-center whyte-bold mt-3 ">
        The cover video is a way for you to impress the hiring team
      </div>
      {user.jobSeeker.video && (
        <div classname="flex flex-col items-center max-w-[280px]">
          <div className="flex flex-col items-center max-w-[280px] xl:max-w-[544px]">
            <UploadFile
              acceptedFile={{ "video/*": [] }}
              className="form-input rounded-md mt-3"
              uploadMessage={"Drag and drop or click to upload a video"}
              mbMaxSize={50}
              onDrop={setVideo}
            />
          </div>

          <div className="mt-8 flex justify-center">
            <Button
              className="ml-4 min-w-60 h-[62px]  whyte-bold text-[18px] xl:text-[24px]  xl:px-[30px] rounded-full"
              onClick={handleApply}
            >
              Apply with cover video
            </Button>
            {!isSendingNewVideo && (
              <Button
                className="ml-4 min-w-60 h-[62px]  whyte-bold text-[18px] xl:text-[24px]  xl:px-[30px] rounded-full"
                invert
                onClick={() => setIsSendingNewVideo(true)}
              >
                Send New Video
              </Button>
            )}
          </div>
        </div>
      )}

      {!user.jobSeeker.video && (
        <div classname="flex flex-col items-center">
          <div className="flex flex-col items-center">
            <strong classname="text-center">Your Profile Video</strong>
            <UploadFile
              acceptedFile={{ "video/*": [".mp4"] }}
              className="form-input rounded-md mt-3"
              uploadMessage={"Drag and drop or click to upload a video"}
              mbMaxSize={50}
              onDrop={setVideo}
            />
          </div>

          <div className="mt-8 flex justify-center">
            <Button
              onClick={handleSendNewVideo}
              className="ml-4 min-w-60 h-[62px]  whyte-bold text-[18px] xl:text-[24px]  xl:px-[30px] rounded-full"
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
