import React, { Fragment } from 'react'
import { createPortal } from 'react-dom';

export function Portal ({ children }) {
    return (
        createPortal(
            <Fragment>{children}</Fragment>,
            document.getElementById('new-portal')
        )
    ) 
}
