import React, { useCallback } from 'react'
import { useAuth } from '../../hooks/Auth';
import { APPLY_TO_VACANCY } from "./ModalMessage.services";
import { nanoid } from 'nanoid';
import { useMutation, useApolloClient } from "@apollo/client";
import { LoadingSpin } from '../LoadingSpin/LoadingSpin';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { UploadFile } from '../UploadFile/UploadFile';
import { transformFileInBase64 } from '../../utils/transformFileInBase64';

import { GET_MY_APPLICATIONS } from "../../Pages/MyApplications/MyApplications.services";
import { Button } from '../Button/Button';
import { useState } from 'react';
import { GET_SAVED_VACANCIES } from '../../Pages/SavedVacancies/SavedVacancies.services';

export function SendProfileCvAndCoverVideoModal({ setApplicationConcluded, job }) {
  const client = useApolloClient();
  const { user } = useAuth();
  const [applyToVacancy, { loading }] = useMutation(APPLY_TO_VACANCY)
  const [isSendingNewVideo, setIsSendingNewVideo] = useState(true);
  const [video, setVideo] = useState(null);
  
  const handleApply = useCallback(async () => {
    const { photo, video: jobSeekerVideo, cv } = user.jobSeeker;
    const application = {
      _id: nanoid(),
      photo,
      video: jobSeekerVideo,
      cv,
      vacancy: job,
    }

    const savedJobs = (client.readQuery({
      query: GET_SAVED_VACANCIES,
    })?.savedVacancies || []);

    client.writeQuery({
      query: GET_SAVED_VACANCIES,
      data: {
        savedVacancies: savedJobs.filter(savedJob => savedJob._id !== job._id)
      }
    });

    const applications = (client.readQuery({
      query: GET_MY_APPLICATIONS,
    })?.applications || []);

    client.writeQuery({
      query: GET_MY_APPLICATIONS,
      data: {
        applications: [
          ...applications,
          application,
        ]
      }
    });

    setApplicationConcluded(true);

    const videoData = video ? {
      base64: await transformFileInBase64(video),
      name: video.name,
      type: video.type,
    } : null;

    await applyToVacancy({
      variables: {
        vacancyId: job._id,
        video: videoData,
        shouldSendCoverVideo: true,
      }
    })
  }, [applyToVacancy, job, user, video]);

  const handleSendNewVideo = useCallback(async () => {
    if (!video) return;

    await handleApply();
  }, [video, handleApply]);

  if (loading) {
    return <LoadingSpin />
  }

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-lg mb-4">Upload Cover Video</h1>

      {user.jobSeeker.video && (
        <div classname="flex flex-col items-center">
          <div className="flex flex-col items-center">
            <UploadFile
              acceptedFile={{'video/*': ['.mp4']}}
              className="form-input rounded-md mt-3"
              uploadMessage={'Drag and drop or click to upload a video'}
              mbMaxSize={50}
              onDrop={setVideo}
            />
          </div>

          <div className="mt-8 flex justify-center">
            <Button className="ml-4 w-52" onClick={handleApply}>Apply</Button>
            {!isSendingNewVideo && (
              <Button className="ml-4 w-52" invert onClick={() => setIsSendingNewVideo(true)}>Send New Video</Button>
            )}
          </div>
        </div>
      )}

      {!user.jobSeeker.video && (
        <div classname="flex flex-col items-center">
          <div className="flex flex-col items-center">
            <strong classname="text-center">Your Profile Video</strong>
            <UploadFile
              acceptedFile={{'video/*': ['.mp4']}}
              className="form-input rounded-md mt-3"
              uploadMessage={'Drag and drop or click to upload a video'}
              mbMaxSize={50}
              onDrop={setVideo}
            />
          </div>

          <div className="mt-8 flex justify-center">
            <Button onClick={handleSendNewVideo} className="w-64">Apply</Button>
          </div>
        </div>
      )}
    </div>
  )
}
