import React from 'react';
import { forwardRef } from 'react';
import { Input } from './InputPassword.style';

export const InputPassword = forwardRef(({id, name, className, placeholder, ariaLabel, value, maxLength, children, ...props}, ref) => {
  return(
    <div className={"flex flex-col "+ className}>
      {/* <label className="block mb-2 text-[24px] font-medium text-gray-900" htmlFor={name}>{placeholder}</label> */}

      <Input
        type="password"
        name={name}
        className={"block w-full  border-gray-300 shadow-sm focus:border-primary-700 focus:ring-primary-700 sm:text-sm"}
        id={name}
        placeholder={placeholder}
        aria-label={ariaLabel}
        value={value}
        maxLength={maxLength}
        ref={ref}
        {...props}
      />
      {children}
    </div>
  )
});
