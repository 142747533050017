import React, { useCallback } from "react"

import { Portal } from "../../Components/Portal/Portal";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";
import { UPDATE_VACANCY_INVITES } from './ModalNotInterested.services';
import { useMutation } from "@apollo/client";

export const ModalNotInterested = ({ isNotInterestedModalOpen, setIsNotInterestedModalOpen, job, refetch }) => {
  const { _id, vacancy } = job;  
  const [declineVacancyInvite, { loading }] = useMutation(UPDATE_VACANCY_INVITES)

  const closeModal = useCallback(() => {
    setIsNotInterestedModalOpen(false)
  }, [])

  const handleNotInterested = async () => {
    await declineVacancyInvite({
      variables: { jobInviteId: _id }
    })

    refetch()
    setIsNotInterestedModalOpen(false)
  }

  return (
    <>
      <Portal>
        <Modal closeModal={closeModal} isOpen={isNotInterestedModalOpen} maxWidth={550}>
          <div className="w-full flex flex-col items-center">
            <>
              <h3 className="text-lg text-center font-medium leading-6 text-gray-900 mt-3" id="modal-title">
                Confirm that you are not interested in the {vacancy?.jobName} position in {vacancy?.company.name}?
              </h3>

              <div className="bg-white-50 px-4 py-3 sm:flex sm:flex-row justify-center sm:px-6 m-5">
                <Button className="mr-3 w-40" onClick={closeModal}>No</Button>
                <Button invert className="w-40" onClick={handleNotInterested}>Yes</Button>
              </div>
            </>
          </div>
        </Modal>
      </Portal>
    </>
  )
}
