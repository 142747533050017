import React, { useMemo } from "react";
import { nanoid } from "nanoid";
import {
  FiCalendar,
  FiUser,
  FiClock,
  FiFileText,
  FiBriefcase,
} from "react-icons/fi";
import { parseDate } from "../../utils/parseDate";
import { BiEuro } from "react-icons/bi";
import "../../Pages/HomePage/styles.css";
import { getDifferenceInNaturalTimeFromToday } from "../../utils/getDifferenceInNaturalTime";

export function Vacancy({
  job,
  onClick,
  shouldShowDeletedMessage,
  companyData = null,
}) {
  const timeSincePost = useMemo(() => {
    if (!job?.date) {
      return null;
    }

    const date = new Date(Number(job.date));

    return getDifferenceInNaturalTimeFromToday(date);
  }, [job]);

  const jobDate = useMemo(() => {
    if (!job) return;
    const date = new Date(Number(job.date));

    return parseDate(date);
  }, [job]);

  const parsedSalary = useMemo(() => {
    if (!job?.salary) {
      return null;
    }

    return job?.salary;
  }, [job]);

  const company = useMemo(() => {
    return companyData || job?.company;
  }, [companyData, job]);

  if (!job) {
    return null;
  }
  

  return (
    <>
      <button
        key={nanoid()}
        className={
          "relative flex border text-center bg-white justify-start items-center px-8 mt-2 min-h-[217px] max-h-72 w-full rounded-lg " +
          "group transition duration-200  hover:border-primary-700 " +
          (job.deleted ? "opacity-50" : "")
        }
        onClick={onClick}
      >
        
        <div className="flex flex-col w-full">
          <div className="w-full  flex justify-between">
            <div className="flex flex-col justify-start items-start">
              <div >                
                <span className="text-[20px] xl:text-[24px] whyte-bold ">
                  {job?.jobName}
                </span>
                <span className="text-red-500 font-medium text-sm">
                  {shouldShowDeletedMessage && `(Job already closed)`}
                </span>
              </div>

              <div className=" whyte text-[18px] text-[#A3A3A3]   ">
                {job?.company.name}
              </div>
              <div className="text-[#2E9367] ">Posted {timeSincePost}</div>
            </div>
            <div>
              <img
                className="h-0 w-0 sm:w-16 sm:h-16 rounded-full bg-gray-400 object-cover object-center sm:ml-2"
                src={company?.photo}
                alt={company?.name}
              />
            </div>
          </div>
          <hr className="my-4" />

          <div className="flex justify-start whyte text-[#546787]    ">
            {job?.period}
            {" - "}
            {job?.hourPerWeek} Hours
            {"  - "}
            {job?.category}
            {" - "}
            {jobDate}
            {" - "}
            {job?.contractType}
            {parsedSalary?  " - "+ parsedSalary + " per hour ":"" } 
            {" - "}
            {job?.disponibleVacancies} position            
            {job?.disponibleVacancies !== 1 && "s"}
          </div>
        </div>

        {/* <div>
          <img
            className="h-0 w-0 sm:w-16 sm:h-16 rounded-full bg-gray-400 object-cover object-center sm:ml-2"
            src={company?.photo}
            alt={company?.name}
          />
        </div>
        <div className="flex flex-col p-3 md:justify-items-start text-center justify-center w-full sm:ml-6 text-black-800 sm:text-xl">
          <div className='w-full text-start'>
            <h2>{job?.jobName} <span className='text-red-500 font-medium text-sm'>{shouldShowDeletedMessage && `(Job already closed)`}</span></h2>
            <h3 className="text-xs">{company.name}</h3>
          </div>

          <div className="flex justify-items-center gap-2 text-gray-800 text-xs transition duration-200 group-hover:text-slate-200 mb-3 mt-1">
            <FiCalendar />
              Posted {timeSincePost}
          </div>

          <ul className="grid grid-cols-3 sm:grid-cols-4 gap-2 sm:gap-5 list-none">
            {job?.salary && (
              <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
                <BiEuro className="w-4 h-4 mr-2" />
                {parsedSalary} per hour
              </li>
            )}
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiUser className="w-4 h-4 mr-2" />
              {job?.disponibleVacancies} position{job?.disponibleVacancies !== 1 && 's'}
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiBriefcase className="w-4 h-4 mr-2" />
              {job?.category}
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiBriefcase className="w-4 h-4 mr-2" />
              {job?.contractType}
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiClock className="w-4 h-4 mr-2" />
              {job?.hourPerWeek} Hours
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiClock className="w-4 h-4 mr-2" />
               {jobDate}
            </li>
            <li className="flex flex-col gap-2 items-center text-gray-800 text-xs transition duration-200 group-hover:text-slate-200  ">
              <FiFileText className="w-4 h-4 mr-2" />
              {job?.period}
            </li>
          </ul>
        </div> */}
      </button>
    </>
  );
}
