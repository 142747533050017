import * as L from "leaflet";

export function animatedCircle(map, [lat, lon], options) {
  const { fromRadius, toRadius } = options;

  let circle;

  circle = L.circle([lat, lon], {
    radius: fromRadius
  }).addTo(map);

  circle.setStyle({ fillColor: "rgba(148, 0, 211, 0.2)" });

  let interval;

  const time = 400;
  let increaseCoeficient;
  const radiusDelta = Math.abs(toRadius - fromRadius);
  increaseCoeficient = (radiusDelta / time) * 10;

  interval = setInterval(() => {
    var currentRadius = circle.getRadius();

    if (currentRadius < toRadius) {
      circle.setRadius((currentRadius += increaseCoeficient));
    } else if (currentRadius > toRadius) {
      circle.setRadius((currentRadius -= increaseCoeficient));
    } else {
      clearTimeout(interval);
    }
  }, 10);

  return circle;
}
