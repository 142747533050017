import React from "react";
import { Button } from "../NewButton/Button";

export function UserAppliedModal({ job, handleModal }) {
  return (
    <>
      <div
        className=" text-center text-[20px] mt-4 max-w-[280px] xl:max-w-full xl:text-[24px] whyte-bold "
        id="modal-title"
      >
        You have already applied for the {job.jobName} position in{" "}
        {job.company.name}
      </div>

      <div className="bg-white-50 px-4 py-3 sm:flex sm:flex-row justify-center sm:px-6 m-5">
        {/* <Button className="mt-6 w-60 h-24" onClick={handleUnapply}>
      Unapply
    </Button> */}

        <Button
          className="ml-3 mt-6 w-60 h-[52px] rounded-full border-none text-[20px] xl:text-[24px] whyte-bold "
          invert
          onClick={handleModal}
        >
          Close
        </Button>
      </div>
    </>
  );
}
