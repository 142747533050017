import React, { useCallback } from 'react'
import { useAuth } from '../../hooks/Auth';
import { APPLY_TO_VACANCY } from "./ModalMessage.services";
import { nanoid } from 'nanoid';
import { useMutation, useApolloClient } from "@apollo/client";
import { Button } from '../Button/Button';
import { LoadingSpin } from '../LoadingSpin/LoadingSpin';

import { GET_MY_APPLICATIONS } from "../../Pages/MyApplications/MyApplications.services";
import { GET_SAVED_VACANCIES } from '../../Pages/SavedVacancies/SavedVacancies.services';

export function SendProfileCvModal({ setApplicationConcluded, job, setSubApplyModalOpen }) {
  const client = useApolloClient();
  const { user } = useAuth();
  const [applyToVacancy, { loading }] = useMutation(APPLY_TO_VACANCY)

  const handleApply = useCallback(async () => {
    const { photo, video, cv } = user.jobSeeker;
    const application = {
      _id: nanoid(),
      photo,
      video,
      cv,
      vacancy: job,
    }

    const savedJobs = (client.readQuery({
      query: GET_SAVED_VACANCIES,
    })?.savedVacancies || []);

    client.writeQuery({
      query: GET_SAVED_VACANCIES,
      data: {
        savedVacancies: savedJobs.filter(savedJob => savedJob._id !== job._id)
      }
    });

    const applications = (client.readQuery({
      query: GET_MY_APPLICATIONS,
    })?.applications || []);

    client.writeQuery({
      query: GET_MY_APPLICATIONS,
      data: {
        applications: [
          ...applications,
          application,
        ]
      }
    });

    setApplicationConcluded(true);

    await applyToVacancy({
      variables: {
        vacancyId: job._id,
        shouldSendCoverVideo: false,
      }
    })
  }, [applyToVacancy, job, user]);


  return (
    <div className="flex flex-col items-center">
      
      <header>
        <h1 className="mb-4 text-lg">Send Profile/CV</h1>
      </header>

      <h2>
        Uploading your cover video will increase your chances of finding a job by 70%. Don't forget!
      </h2>

      <div className="flex mt-6">
      
        <Button className="mr-2" invert onClick={handleApply}>Apply Anyway</Button>  
        <Button className="mr-2" onClick={() => setSubApplyModalOpen(2)}>Add Cover Video</Button>

      </div>
    </div>
  )
}
