import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  /* background-color: transparent; */

  > header {
    background-color: transparent;
    /* display: flex; */

    /* margin-bottom: 36px; */
  }

  > main {
    width: 100%;
  }
`;

export const ButtonClose = styled.button`
  background-color: white;
  border-radius: 50%;
  padding: 5px;


  border: none;
  outline: none;

  width: 32px;
  height: 32px;

  cursor: pointer;
  display: flex;

  align-items: center;
  float: right;
`;