import { useApolloClient, useMutation } from '@apollo/client';
import React, { useState, useCallback } from 'react'
import { Button } from '../../../Components/Button/Button'
import { Footer } from '../../../Components/Footer/Footer';
import { Navbar } from '../../../Components/Navbar/Navbar'
import { UploadFile } from '../../../Components/UploadFile/UploadFile'
import { useAuth } from '../../../hooks/Auth';
import { transformFileInBase64 } from '../../../utils/transformFileInBase64';
import { GET_VACANCIES } from '../../Map/Map.services';
import { MASS_IMPORT, CLEAR_IMPORT } from './MassImport.services';
import massImportfile from '../../../assets/csv/mass_import_file_sample.xlsx';

export function MassImport() {
  const client = useApolloClient();
  const { user } = useAuth();
  const [file, setFile] = useState();
  const [massImport, { loading }] = useMutation(MASS_IMPORT, { fetchPolicy: 'no-cache' });
  const [clearImport, { loading: loadingClear }] = useMutation(CLEAR_IMPORT, { fetchPolicy: 'no-cache' });

  const handleSubmit = useCallback(async () => {
    if (!file || loading) return;

    const fileInput = {
      name: file.name,
      type: file.type,
      base64: await transformFileInBase64(file),
    }

    try {
      await massImport({
        variables: {
          file: fileInput,
        }
      });

      if (!user.roles.includes('jobSeeker')) return;

      await client.refetchQueries({
        include: [GET_VACANCIES],
      });
    } catch (err) {
      throw err
      return alert('An error occoured, try again soon');
    }
    
    alert('Ok')
  }, [file, massImport, loading, client]);

  const handleClear = useCallback(async () => {
    try {
      await clearImport();

      if (!user.roles.includes('jobSeeker')) return;
      
      await client.refetchQueries({
        include: [GET_VACANCIES],
      })
    } catch (err) {
      return alert('An error occoured, try again soon');
      console.log(err)
    }
    
    alert('Ok')
  }, [file, massImport, loading, client]);

  return (
    <>
      <Navbar />

      <main className="pt-20 min-h-[93vh]">
        <div className="flex flex-col items-center">
          <h1 className="flex justify-center text-lg font-bold mt-1 mb-3">Import File</h1>

          <UploadFile
            acceptedFile={{
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            }}
            className="form-input rounded-md"
            uploadMessage={'Drag and drop or click to upload a .xslx file'}
            onDrop={setFile}
          />

          <Button
            type="button"
            onClick={handleSubmit}
            className="mt-7 w-48"
            loading={loading}
          >Import File</Button>

          <Button
            invert
            type="button"
            onClick={handleClear}
            className="mt-7 w-48"
            loading={loadingClear}
          >Clear all imported companies</Button>

          <a
            download
            href={massImportfile}
            invert
            className="mt-7 w-48 flex items-center justify-center font-semibold text-center w-100 rounded-lg py-2 px-3 duration-150 transition
            bg-primary-700 text-slate-100 hover:bg-primary-800"
          >Download Template</a>
        </div>
      </main>
      <Footer />
    </>
  )
}
