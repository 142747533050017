import { gql } from '@apollo/client';

export const CREATE_PAYMENT_ORDER = gql`
  mutation createPaymentOrder(
    $userId: String,
    $userCompanyName: String,
    $premiumType: String,
  ) {
    createPaymentOrder(
      input: {
        userId: $userId,
        userCompanyName: $userCompanyName,
        premiumType: $premiumType
      }
    ) {
      url
    }
  }
`;

export const PREMIUM_INFO = gql`
  query getUserPremiumInfo($id: ID!) {
    getUserPremiumInfo(id: $id) {
      isPremium
      dueDate
    }
  }
`;
