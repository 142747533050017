import { gql } from "@apollo/client";

export const GET_JOBSEEKERS = gql`
  query reportedJobSeekers {
    reportedJobSeekers {
      _id
      name
      surname
      address
      eircode
      email
      gender
      birth
      country
      countryCode
      phone
      lookingFor
      availability {
        morning
        afternoon
        night
        weekends
        note
      }
      experiences {
        companyName
        currentlyWorking
        endDate
        jobCategory
        jobDescription
        jobTitle
        startDate
      }
      socialMedias {
        name
        url
      }
      languages {
        name
        proeficiency
      }
      photo
      video
      cv
    }
  }
`;
