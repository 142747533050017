import React, { useState, useCallback } from "react";

import { useAuth } from "../../hooks/Auth";
import { Portal } from "../Portal/Portal";
import { Modal } from "../NewModal/Modal";
import { Button } from "../NewButton/Button";
import { ApplicationConcludedModal } from "./ApplicationConcludedModal";
import { UserAppliedModal } from "./UserAppliedModal";
import { SendProfileCvModal } from "./NewSendProfileCvModal";
import { SendProfileCvAndCoverVideoModal } from "./NewSendProfileCvAndCoverVideoModal";

export const ModalMessage = ({
  isOpenModalMessage,
  setIsOpenModalMessage,
  job,
  onClose,
}) => {
  const [applicationConcluded, setApplicationConcluded] = useState(false);
  const [subApplyModalOpen, setSubApplyModalOpen] = useState(null);

  const handleModal = useCallback(() => {
    if (onClose) {
      onClose();
    }

    setIsOpenModalMessage((state) => !state);
  }, [onClose]);
  // const handleUnapply = useCallback(async () => {

  // }, []);

  return (
    <>
      <Portal>
        <Modal
          closeModal={handleModal}
          isOpen={isOpenModalMessage}
          maxWidth={850}
        >
          <div className="w-full flex flex-col items-center ">
            {applicationConcluded ? (
              <ApplicationConcludedModal handleModal={handleModal} job={job} />
            ) : (
              <>
                {job.hasUserApplied ? (
                  <UserAppliedModal handleModal={handleModal} job={job} />
                ) : (
                  <>
                    {!subApplyModalOpen && (
                      <>
                        <div
                          className="text-[18px] xl:text-[32px] max-w-[300px] xl:max-w-[544px] text-center whyte-bold mt-3"
                          id="modal-title"
                        >
                          Confirm application for the {job.jobName} position in{" "}
                          {job.company.name}?
                        </div>

                        <div className=" text-[#A3A3A3] text-[16px] xl:text-[20px] max-w-[300px] xl:max-w-[544px] text-center whyte-bold mt-3 ">
                          Increase your chances! Show the company why you want
                          to work for them.
                        </div>
                        <div className="bg-white-50 xl:px-4 py-3  flex flex-col  gap-[20px] sm:px-6 m-5">
                          <Button
                            className=" min-w-60 xl:min-w-60 h-[62px]   whyte-bold  text-[20px] xl:text-[24px]  xl:px-[30px] rounded-full "
                            onClick={() => setSubApplyModalOpen(2)}
                          >
                            Apply with Cover Video
                          </Button>
                          <Button
                            invert
                            className=" border-none min-w-60 xl:min-w-60 h-[62px]  whyte-bold  text-[20px] xl:text-[24px]  xl:px-[30px] rounded-full "
                            onClick={() => setSubApplyModalOpen(1)}
                          >
                            Apply without Cover video
                          </Button>
                        </div>
                      </>
                    )}

                    {subApplyModalOpen === 1 && (
                      <SendProfileCvModal
                        setSubApplyModalOpen={setSubApplyModalOpen}
                        setApplicationConcluded={setApplicationConcluded}
                        job={job}
                      />
                    )}

                    {subApplyModalOpen === 2 && (
                      <SendProfileCvAndCoverVideoModal
                        setSubApplyModalOpen={setSubApplyModalOpen}
                        setApplicationConcluded={setApplicationConcluded}
                        job={job}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Modal>
      </Portal>
    </>
  );
};
