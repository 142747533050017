import Leaflet from "leaflet";

import userLocationPinSvg from "../../assets/pin-orange.svg";

export const userLocationPin = Leaflet.icon({
  iconUrl: userLocationPinSvg,

  iconSize: [65, 65],
  iconAnchor: [20, 45],
  popupAnchor: [0, -45 - 8]
});
