import React, { useCallback, useState, useLayoutEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import { useMutation } from "@apollo/client";
import nextButton from "../../../../assets/homePage/next.png";
import backButton from "../../../../assets/homePage/back.png";
import { Textarea } from "../../../../Components/Forms/Textarea/Textarea";
import { Select } from "../../../../Components/Forms/NewSelect/Select";
import { InputText } from "../../../../Components/Forms/NewInputText/InputText";
import { TermsAndPolicy } from "../../../../Components/TermsAndPolicy/TermsAndPolicy";
import { Button } from "../../../../Components/NewButton/Button";
import { useNavigate } from "react-router";
import { geoLocationApi } from "../../../../services/geolocation";
import { LoadingSpin } from "../../../../Components/LoadingSpin/LoadingSpin";
import { InputPassword } from "../../../../Components/Forms/InputPassword/InputPassword";
import { InputEmail } from "../../../../Components/Forms/InputEmail/InputEmail";
import { CREATE_USER } from "../CompanyForm.services";
import { UploadFile } from "../../../../Components/UploadFile/UploadFile";
import { transformFileInBase64 } from "../../../../utils/transformFileInBase64";
import banner3Min from "../../../../assets/banners/banner 3-min.png";
import { useAuth } from "../../../../hooks/Auth";
import { socialMedia } from "../../../../staticData/socialMedias";
import CountryCodes from "../../../../staticData/countryCodes.json";
import { jobCategoryCompany } from "../../../../utils/jobCategoryCompany";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { ErrorMessage } from "@hookform/error-message";

const countryNames = CountryCodes.map((country) => country.name);
const numberOfEmployees = [
  "",
  "Under 10",
  "10 to 19",
  "20 to 49",
  "50 to 249",
  "250 and over",
];

const howKnewAboutUsOptions = [
  "",
  "Search Engine (Google, Bing, etc)",
  "Word of mouth",
  "Newspapers / Magazines",
  "Direct Email",
  "Social Media (Facebook, Instagram, LinkedIn, etc)",
  "Swanted Staff",
  "Other",
];

export const CompanyForm = ({ formData, setSideImage, stepBack }) => {
  const { signIn } = useAuth();
  const [createUser, { loading }] = useMutation(CREATE_USER);
  const navigate = useNavigate();
  const { register, handleSubmit, control, watch, formState: { errors } } = useForm({ defaultValues: formData });

  let passwordRef = useRef({});
  let howKnewAboutUsRef = useRef({});
  let categoryRef = useRef({});
  let countryNameRef = useRef({});
  let addressRef = useRef({});

  const [isLoadingAddressSearch, setIsLoadingAddressSearch] = useState(false);
  const [errorInAddressSearch, setErrorInAddressSearch] = useState(false);
  const [hasSearchedAddress, setHasSearchedAddress] = useState(false);
  const [photo, setPhoto] = useState(null);

  passwordRef.current = watch("password", "");
  howKnewAboutUsRef.current = watch("howKnewAboutUs", "");
  categoryRef.current = watch("companyCategory", "");
  // countryNameRef.current = watch('countryName', '');
  addressRef.current = watch("address", "");
  const [searchBox, setSearchBox] = useState();

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => searchBox?.getPlaces();

  // const handleFetchAddress = useCallback(async (event, type) => {
  //   const inputValue = event?.target?.value || null;

  //   let countryNameValue = type === 'countryName' ? inputValue : countryNameRef.current;
  //   let addressInputValue = type === 'address' ? inputValue :  addressRef.current;

  //   if (!addressInputValue || !countryNameValue) return;

  //   setIsLoadingAddressSearch(true);
  //   const parsedAddress = `${addressInputValue},${countryNameValue}`.replaceAll(" ", "+")

  //   const { data: apiData } = await geoLocationApi.get(`/search?addressdetails=1&q=${parsedAddress}&format=json&limit=1`)

  //   setIsLoadingAddressSearch(false);
  //   setHasSearchedAddress(true);
  //   if (apiData.length === 0) {
  //     setErrorInAddressSearch(true);

  //     return;
  //   }

  //   setErrorInAddressSearch(false);
  // }, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (loading) return;
        const totalData = {
          ...formData,
          ...data,
        };

        console.log('totalData', totalData);

        // if (!hasSearchedAddress || errorInAddressSearch || isLoadingAddressSearch) {
        //   return;
        // }

        if (!totalData.agreeWithTerms) return;

        const companyPhoto = photo
          ? {
              base64: await transformFileInBase64(photo),
              name: photo.name,
              type: photo.type,
            }
          : null;

        const howKnewAboutUs =
          totalData.howKnewAboutUs === "Other"
            ? totalData.howKnewAboutUsDetail
            : totalData.howKnewAboutUs;

        const companyCategory =
          totalData.companyCategory === "Other"
            ? totalData.companyCategoryDetail
            : totalData.companyCategory;

        await createUser({
          variables: {
            email: totalData.email,
            password: totalData.password,
            companyManager: totalData.managerName,
            // countryName: totalData.countryName,
            companyWebsite: totalData.companyWebsite,
            companyHowKnewAboutUs: howKnewAboutUs,
            companyNumberOfEmployees: totalData.numberOfEmployees,
            companyRegistrationNumber: totalData.companyRegistrationNumber,
            companyCategory: companyCategory,
            companyDescription: totalData.companyDescription,
            companySocialMedias: totalData.socialNetworks,
            companyName: totalData.companyName,
            companyAddress: totalData.address,
            companyCountryCode: totalData.countryCode,
            companyPhone: totalData.phone,
            // companyEircode: totalData.eircode,
            companyPhoto,
          },
        });

        alert(
          "We sent an e-mail to validate your email address. If it is not in your inbox, please check your spam folder. Once you find it please mark it as NOT SPAM and it will go into your folder so you can click the link."
        );
        navigate("/");
      } catch (error) {
        if (error.message === "E-mail already taken") {
          alert("E-mail already taken, choose another one.");
          return;
        }

        if (error.message === "Company Name already taken") {
          alert("Company Name already taken, choose another one.");
          return;
        }

        throw error;
      }
    },
    [
      navigate,
      createUser,
      photo,
      loading,
      signIn,
      formData,
      navigate,
      hasSearchedAddress,
      errorInAddressSearch,
      isLoadingAddressSearch,
    ]
  );

  useLayoutEffect(() => {
    setSideImage(banner3Min);
  }, []);

  return (
    <div className="flex flex-col items-center m-4 xl:w-[60%]">
      <div className="flex flex-col w-full items-center">
        <form
          className="flex flex-col w-full h-full pb-6"
          onSubmit={handleSubmit(onSubmit, () => {})}
        >
          <div className=" whyte text-[16px] xl:text-[24px] text-[#A3A3A3] ">
            Let’s get to know your basic information
          </div>
          <div className=" whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
            Company Information
          </div>

          <div className="col-span-2 mb-[10px] xl:mb-[20px]">
            <div className=" mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
              Manager Name*
            </div>
            <InputText
              placeholder={"Manager Name*"}
              className=""
              {...register("managerName", { required: "input manager name" })}
            />
            <ErrorMessage
              name="managerName"
              errors={errors}
              render={({ message }) => (
                <p className="text-red-500">{message}</p>
              )}
            />
          </div>

          {/* <Select
              control={control}
              options={countryNames}
              label="Country Name*"
              placeholder="Select..."
              defaultValue="Ireland"
              className="mt-3"
              rules={{ required: true }}
              {...register("countryName")}
            /> */}
          <div className="flex flex-col xl:flex-row xl:gap-[30px] mb-[10px] xl:mb-[20px]">
            <div className="w-full xl:w-1/2 mb-[10px] xl:mb-[0px]">
              <div className=" mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
                Company Address*
              </div>
              <StandaloneSearchBox
                onLoad={onLoad}
                onPlacesChanged={onPlacesChanged}
              >
                <InputText
                  placeholder={"Company Address*"}
                  className=""
                  {...register("address", { required: "input address" })}
                />
              </StandaloneSearchBox>
              <small className="mt-3 text-[#6c6c6c]">
                Example: 157 Thomas Street, Dublin 8
              </small>
              <ErrorMessage
                name="address"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>
            {/* <InputText placeholder={'Eircode'} className="mt-3" {...register('eircode')} />
            {errors.eircode?.message && <p className="text-red-500">{errors.eircode.message}</p>} */}
            <div className=" w-full xl:w-1/2">
              <div className=" mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
                Company Website
              </div>
              <InputText
                placeholder={"Company Website (optional)"}
                className=""
                {...register("companyWebsite")}
              />
            </div>
          </div>
          <div className=" flex xl:flex-row flex-col xl:gap-[30px] mb-[10px] xl:mb-[20px]">
            <div className="w-full xl:w-1/2 mb-[10px] xl:mb-[0px]">
              <div className=" mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
                Number of Employees*
              </div>
              <Select
                control={control}
                options={numberOfEmployees}
                label=""
                className=""
                {...register("numberOfEmployees", {
                  required: "input number of employees",
                })}
              />
              <ErrorMessage
                name="numberOfEmployees"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <div className=" mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
                Company Category*
              </div>
              <Select
                control={control}
                label=""
                className=""
                options={jobCategoryCompany}
                {...register("companyCategory", {
                  required: "input company category",
                })}
              />
              <ErrorMessage
                name="companyCategory"
                errors={errors}
                render={({ message }) => (
                  <p className="text-red-500">{message}</p>
                )}
              />
              {categoryRef.current === "Other" && (
                <InputText
                  placeholder={"Write Your Company's Category*"}
                  className="mt-3"
                  {...register("companyCategoryDetail", { required: true })}
                >
                  {errors.companyWebsite?.message && (
                    <p className="text-red-500">
                      {errors.companyCategoryDetail.message}
                    </p>
                  )}
                </InputText>
              )}
            </div>
          </div>

          <div className=" mb-[10px] xl:mb-[20px]">
            <div className=" mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
              How Did You Hear About Us?*
            </div>
            <Select
              control={control}
              options={howKnewAboutUsOptions}
              label=""
              className=" col-span-2"
              {...register("howKnewAboutUs", {
                required: "input How Did You Hear About Us",
              })}
            />
            <ErrorMessage
              name="howKnewAboutUs"
              errors={errors}
              render={({ message }) => (
                <p className="text-red-500">{message}</p>
              )}
            />

            {howKnewAboutUsRef.current === "Other" && (
              <div>
                <div className=" mt-[20px] whyte-bold text-[16px] xl:text-[24px] text-[##1E1E1E] ">
                  Write How You Heard About Us*
                </div>
                <Textarea
                  label=""
                  className="mt-5 w-full"
                  rows={5}
                  maxLength={10000}
                  {...register("howKnewAboutUsDetail", {
                    required: "input Write How You Heard About Us",
                  })}
                />
                <ErrorMessage
                  name="howKnewAboutUsDetail"
                  errors={errors}
                  render={({ message }) => (
                    <p className="text-red-500">{message}</p>
                  )}
                />
              </div>
            )}
          </div>
          <div className="mb-[10px] xl:mb-[20px] ">
            <div className=" mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
              Company Description (optional)
            </div>
            <Textarea
              label=""
              className="mt-5 w-full"
              rows={5}
              maxLength={10000}
              {...register("companyDescription")}
            />
            {errors.companyDescription?.message && (
              <p className="text-red-500">
                {errors.companyDescription.message}
              </p>
            )}
          </div>

          <div className="col-span-2 mb-[10px] xl:mb-[20px]">
            <div className=" mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
              Company Registration Number (optional)
            </div>
            <InputText
              placeholder={"Company Registration Number "}
              className="mt-5"
              {...register("companyRegistrationNumber", { required: false })}
            />

            {!!errors.companyRegistrationNumber?.message && (
              <p className="text-red-500">
                {errors.companyRegistrationNumber.message}
              </p>
            )}
          </div>

          <section>
            <div className="  mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E]  mb-[10px] xl:mb-[20px] ">
              Company Photo / Logo (optional)
            </div>

            <UploadFile
              acceptedFile={{
                "image/*": [],
              }}
              className="form-input rounded-md mt-3"
              uploadMessage={"Drag and drop or click to upload an image"}
              onDrop={setPhoto}
            />
          </section>

          <section>
            <div className="my-4 flex justify-between items-baseline">
              <div className="  mt-[20px] whyte-bold text-[16px] xl:text-[32px] text-[##1E1E1E] ">
                Social Media (optional)
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {socialMedia.map((socialMedia, index) => (
                <div>
                  <div key={nanoid()} className="flex items-center mt-3 w-full">
                    <input
                      type="hidden"
                      {...register(`socialNetworks.${index}.name`, {
                        value: socialMedia.name,
                      })}
                    />
                    <img
                      src={socialMedia.logo}
                      className="w-10 h-10 object-fit object-center rounded-md"
                      alt={socialMedia.name + " Logo"}
                    />

                    <InputText
                      placeholder={`${socialMedia.name} profile`}
                      className="ml-3 w-full"
                      {...register(`socialNetworks.${index}.url`)}
                      // {...register(`socialNetworks.${index}.url`, { pattern: socialMedia.urlPattern })}
                    />
                  </div>
                  
                  <p className="mt-3">{socialMedia.tips}</p>

                </div>
              ))}
            </div>
          </section>

          <section className="mt-4">
            <TermsAndPolicy
              {...register("agreeWithTerms", { required: true })}
              className="mt-8"
            />
          </section>

          <div className="flex gap-[10px] items-center my-8">
            <Button invert type="button" className="border-none" onClick={stepBack}>
              <img
                className="w-[80%] xl:w-full"
                src={backButton}
                alt="Previous step"
              />
            </Button>
            <Button
              loading={loading}
              type="submit"
              className="bg-[#9E5BF6] text-[16px] xl:text-[24px] text-white  rounded-[81px] h-[64px] xl:h-[74px] px-[25px] whyte-bold w-80"
            >
              Post a Job For Free
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
