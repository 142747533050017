import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";

import { UploadFile } from "../../../Components/UploadFile/UploadFile";
import { Button } from "../../../Components/Button/Button";
import "../../../Pages/HomePage/styles.css";
import { VideoPlayer } from "../../../Components/VideoPlayer/VideoPlayer";
import SwantedTutorial from "../../../assets/tutorial-video/Swanted_tutorial.mp4";
import nextButton from "../../../assets/homePage/next.png";
import backButton from "../../../assets/homePage/back.png";

export function PhotoAndVideo({ stepBack, onSubmitStep, formData }) {
  const { handleSubmit } = useForm({ defaultValues: formData });
  const [video, setVideo] = useState(null);

  const onSubmit = useCallback(
    (data) => {
      onSubmitStep({ ...data, video });
    },
    [onSubmitStep, video]
  );

  return (
    <>
      <form className="flex xl:flex-row flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col w-full">
          <div className=" whyte text-[16px] xl:text-[24px] text-[#A3A3A3] ">
            Let’s get to know your basic information
          </div>

          <div className="my-3 text-[24px] xl:text-[32px] whyte-bold text-[#1E1E1E] ">
            Not sure what to say? Then answer <br className="hidden xl:block " />
            some of these questions
          </div>
          <div>
            <ul className="list-disc list-inside mt-3 text-[#A3A3A3] text-[20px] xl:text-[24px] ">
              <li className="my-1">Who are you?</li>
              <li className="my-1">Why are you looking for a job?</li>
              <li className="my-1">What is your passion?</li>
              <li className="my-1">Why should you be hired?</li>
            </ul>
          </div>

          <div className="flex flex-col w-[100%] ">
            <div className=" my-[30px]  whyte-bold text-[24px] xl:text-[32px] ">
              Add Intro Video
            </div>

            <UploadFile
              // acceptedFile={{ "video/*": [] }}
              className="form-input rounded-md mt-3"
              mbMaxSize={50}
              uploadMessage={"Drag and drop or click to upload a video"}
              onDrop={setVideo}
            />
          </div>

          <div className=" my-[30px]  whyte-bold text-[24px] xl:text-[32px] ">
            Watch Video Tutorial
          </div>
          <div className="flex flex-col justify-center items-center flex-wrap w-[100%] ">
            <VideoPlayer height={400} srcVideo={SwantedTutorial} />
          </div>
        </div>

        <div className=" mx-4 self-center xl:self-end flex  justify-start gap-[20px] my-8">
          <button
            invert
            type="button"
            onClick={stepBack}
            className=" hover:scale-105 transition-all "
          >
            <img className=" w-[80%] xl:w-full" src={backButton} alt="back" />
          </button>
          <button type="submit" className="hover:scale-105 transition-all ">
            <img className=" w-[80%] xl:w-full" src={nextButton} alt="next" />
          </button>
        </div>
      </form>
    </>
  );
}
