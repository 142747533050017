import styled from "styled-components";

export const CardVideo = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  transition: all 0.2s ease-in-out;

  justify-content: center;
  align-items: center;

  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;

  &:hover {
    display: block;
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    transition: opacity 1s;
    background: rgba(0, 0, 0, 0.7);
    transition: 0.4s;
    cursor: pointer;
    
  }

  h2 {
    visibility: hidden;
    color: white;
  }

  :hover h2 {
    visibility: visible;
    transition: all 0.2s ease-in-out;
    color: white;
  }

  span {
    visibility: hidden;
    color: white;
  }

  :hover span {
    visibility: visible;
    transition: all 0.8s ease-in-out;
  }

  div {
    visibility: hidden;
    color: white;
  }

  :hover div {
    visibility: visible;
    transition: all 0.8s ease-in-out;
  }
`;

export const CardVideosImage = styled.img`
  height: 500px;
  width: 300px;
  margin: auto auto;
`;

export const JobSeekerInfo = styled.div`

  :hover {
    background: rgba(0, 0, 0, 0.7);
    opacity: 1;
    transition: 0.4s;
  }
`;

export const JobSeekerInfoContent = styled.div`
  margin-top: -100px;
  transition: margin 0.5s;
`;

export const CardInfoName = styled.h2`
`;

export const ModalContainer = styled.button`
  position: "fixed";
  z-index: 1;
  left: 0;
  top: 0;
  width: "100vw";
  height: "100vh";
  overflow: "auto";
  background-color: "rgba(0, 0, 0, 0.8)";
`;

export const Title = styled.h2`
  text-align: center;
`
