export async function storageFileInLocalStorage(file, key) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        localStorage.setItem(`@Swanted/${key}`, base64String);

        resolve(base64String);
      };

      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
}
