import React, { useState, useCallback, useMemo } from "react";
import { ModalMessage } from "../../Components/ModalMessage/NewModalMessage";
import { Link, useLocation } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/NewNavbar";
import { Button } from "../../Components/NewButton/Button";
import blueArrow from "../../assets/homePage/blue-arrow.png";
import { useQuery } from "@apollo/client";
import { getDifferenceInNaturalTimeFromToday } from "../../utils/getDifferenceInNaturalTime";
import { GET_JOB_PROFILE } from "./JobDetails.services.js";
import { LoadingScreen } from "../../Components/LoadingScreen/LoadingScreen";
import { FiAlertTriangle } from "react-icons/fi";
import { ReportModal } from "../../Components/ReportModal/ReportModal";

const NonEnterpriseJobDetails = () => {
  const { state } = useLocation();
  // const { data, loading } = useQuery(GET_JOB_PROFILE, { variables:  state._id });

  //   console.log(data)
  //   const { data, loading, refetch } = useQuery(GET_VACANCY_INVITES);
  const [isOpenModalMessage, setIsOpenModalMessage] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const handleOpenReportModal = useCallback(() => {
    setIsReportModalOpen((state) => !state);
  }, []);

  const handleApply = useCallback(() => {
    setIsOpenModalMessage(true);
  }, []);

  const timeSincePost = useMemo(() => {
    if (!state?.date) {
      return null;
    }

    const date = new Date(Number(state?.date));

    return getDifferenceInNaturalTimeFromToday(date);
  }, [state]);

  //   const company = useMemo(() => {
  //     if (loading) {
  //       return null;
  //     }

  //     const companyData = {
  //       ...data?.company,
  //       jobs: data?.company.jobs?.map(job => {
  //         return {
  //           ...job,
  //           company: data?.company,
  //         };
  //       }),
  //     }

  //     return companyData;
  //   }, [data, loading]);

  //   if (loading || !company) {
  //     return (
  //       <>
  //         <LoadingScreen />
  //       </>
  //     )
  //   }

  console.log("data", state);
  return (
    <div className="bg-gray-50  w-full h-full pt-[20px] ">
      {isOpenModalMessage && (
        <ModalMessage
          job={state}
          isOpenModalMessage={isOpenModalMessage}
          setIsOpenModalMessage={setIsOpenModalMessage}
          // onClose={refetch}
        />
      )}
      {isReportModalOpen && (
        <ReportModal
          type={"job"}
          job={state}
          isReportModalOpen={isReportModalOpen}
          setIsReportModalOpen={setIsReportModalOpen}
        />
      )}
      <Navbar />
      <div className="whyte-bold  pt-16 xl:pt-6 px-[4%] text-[24px] lg:text-[32px]">
        Job Details
      </div>
      <div className=" py-1 xl:py-4 w-[100%] flex flex-col lg:flex-row px-[4%] justify-between gap-[20px]  bg-gray-50 ">
        <div className=" w-full lg:w-2/3 flex flex-col  gap-[20px] ">
          <div className="w-full flex lg:items-center  justify-between p-[20px] xl:p-[40px]  h-fit lg:h-[254px] bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div>
              <div className=" whyte-bold text-[20px] lg:text-[24px] ">
                {state?.jobTitle}
              </div>
              <div className=" whyte-medium  text-base lg:text-[18px] text-[#A3A3A3]">
                {state?.name}
              </div>
              <div className="mb-4 text-[#2E9367] ">{timeSincePost}</div>
              <hr className="" />
              <Button
                className="text-base lg:text-[18px] whyte-bold  w-[150px] lg:w-[320px]  rounded-full my-4 "
                disabled={true}
                onClick={handleApply}
              >
                Apply in Store
              </Button>
            </div>
            <div>
              <div className="flex justify-end ">
                {/* <button onClick={handleOpenReportModal}>
                  <FiAlertTriangle
                    size={24}
                    className={
                      "text-slate-800 fill-white hover:text-primary-800 transition duration-500 mb-2 "
                    }
                  />
                </button> */}
              </div>
              <img
                className="w-[125px] "
                src={state?.photoUrl}
                alt={state?.name}
              />
            </div>
          </div>

          <div className="w-full flex flex-col   justify-between p-[20px] lg:p-[40px]  min-h-[254px] bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] lg:text-[24px] ">
              Job Details
            </div>
            <div className="flex  gap-[40px] mt-6 w-full ">
              <div className="flex flex-col  w-full text-[#546787] text-[16px] lg:text-[20px] whyte-medium gap-[20px] ">
                <div className="flex gap-8">
                  <div className="w-1/2">Job type</div>
                  <div className="w-1/2">{state?.jobType}</div>
                </div>

                <div className="flex gap-8">
                  <div className="w-1/2">
                    {state?.salaryPerHour ? (
                      "Salary"
                    ) : state?.isSalaryNegotiable ? (
                      "Salary"
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="w-1/2">
                    {state?.isSalaryNegotiable ? (
                      "Negotiable"
                    ) : state?.salaryPerHour ? (
                      state?.salaryPerHour + " per hour "
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="flex gap-8">
                  <div className="w-1/2">Job Categories </div>
                  <div className="w-1/2">{state?.jobCategory}</div>
                </div>

                <div className="flex gap-8">
                  <div className="w-1/2">Positions</div>
                  <div className="w-1/2">
                    {state?.positionsAvailable > 1
                      ? state?.positionsAvailable + " positions"
                      : state?.positionsAvailable + " position"}
                  </div>
                </div>

                <div className="flex gap-8">
                  <div className="w-1/2">Contract type</div>
                  <div className="w-1/2">{state?.contractType}</div>
                </div>

                <div className="flex gap-8">
                  <div className="w-1/2">Hour per week</div>
                  <div className="w-1/2">{state?.hoursWeek} hours</div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col   justify-between p-[20px] lg:p-[40px]  min-h-fit bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] lg:text-[24px] ">
              Job Requirements
            </div>
            <div className="mt-6 gotham text-[20px] ">
              {state?.jobDescription}
            </div>
          </div>
        </div>
        <div className="lg:w-1/3 ">
          <div className=" w-full h-fit p-6 flex flex-col  bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] lg:text-[24px] ">
              About The Company
            </div>
            <div>
              <img
                className="w-[125px] my-6 "
                src={state?.photoUrl}
                alt={state?.name}
              />
            </div>
            <div className=" text-[20px] lg:text-[24px] gotham-medium">
              {state?.name}
            </div>
            {/* <div className="text-[18px] lg:text-[20px] gotham-medium">
              {state?.company.description}
            </div> */}
            {/* <div className="flex gap-[5px] lg:gap-[20px] items-center my-6 ">
              <Link
                className="text-[#116AFF] text-[16px] lg:text-[18px] gotham-medium "
                to={`/companies/${state?.company._id}`}
              >
                Check all the company jobs
              </Link>
              <img
                className=" w-[50px] lg:w-fit"
                src={blueArrow}
                alt="blue arrow"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonEnterpriseJobDetails;
