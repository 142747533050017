import React, { useState, useCallback } from "react"

import { useAuth } from '../../hooks/Auth';
import { Portal } from "../../Components/Portal/Portal";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";
import { ApplicationConcludedModal } from "./ApplicationConcludedModal";
import { UserAppliedModal } from "./UserAppliedModal";
import { SendProfileCvModal } from "./SendProfileCvModal";
import { SendProfileCvAndCoverVideoModal } from "./SendProfileCvAndCoverVideoModal";

export const ModalMessage = ({ isOpenModalMessage, setIsOpenModalMessage, job, onClose }) => {
  const [applicationConcluded, setApplicationConcluded] = useState(false);
  const [subApplyModalOpen, setSubApplyModalOpen] = useState(null);

  const handleModal = useCallback(() => {
    if (onClose) {
      onClose();
    }

    setIsOpenModalMessage(state => !state)
  }, [onClose]);
  // const handleUnapply = useCallback(async () => {

  // }, []);

  return (
    <>
      <Portal>
        <Modal closeModal={handleModal} isOpen={isOpenModalMessage} maxWidth={850}>
          <div className="w-full flex flex-col items-center">
            {applicationConcluded ? (
              <ApplicationConcludedModal handleModal={handleModal} job={job} />
            ) : (
              <>
                {job.hasUserApplied
                  ? (
                    <UserAppliedModal handleModal={handleModal} job={job} />
                    )
                    : (
                      <>
                        {!subApplyModalOpen && (
                          <>
                            <h3 className="text-lg text-center font-medium leading-6 text-gray-900 mt-3" id="modal-title">
                              Confirm application for the {job.jobName} position in {job.company.name}?
                            </h3>

                            <div className="bg-white-50 px-4 py-3 sm:flex sm:flex-row justify-center sm:px-6 m-5">
                              <Button invert className="mr-3 w-60" onClick={() => setSubApplyModalOpen(1)}>
                                Apply without Cover video
                              </Button>

                              <Button className="w-60" onClick={() => setSubApplyModalOpen(2)}>
                                Apply with Cover Video
                              </Button>
                            </div>
                          </>
                        )}

                        {subApplyModalOpen === 1 && (
                          <SendProfileCvModal
                            setSubApplyModalOpen={setSubApplyModalOpen}
                            setApplicationConcluded={setApplicationConcluded}
                            job={job}
                          />
                        )}

                        {subApplyModalOpen === 2 && (
                          <SendProfileCvAndCoverVideoModal 
                            setSubApplyModalOpen={setSubApplyModalOpen}
                            setApplicationConcluded={setApplicationConcluded}
                            job={job}
                          />
                        )}
                      </>
                    )}
            </>
          )}
          </div>
        </Modal>
      </Portal>
    </>
  )
}
