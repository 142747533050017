import { gql } from "@apollo/client";

export const GET_VACANCY_INVITES = gql`
  query vacancy_invites {
    vacancy_invites {
      _id
      message
      hasUserAccepted
      userNotInterested
      vacancy {
        _id
        jobName
        salary
        period
        category
        contractType
        disponibleVacancies
        hourPerWeek
        applicants
        date
        hasUserApplied
        hasUserSaved
        description
        isSalaryNegotiable

        company {
          _id
          name
          latlon
          photo
        }
      }
    }
  }
`;
