import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Footer } from "../../Components/Footer/Footer";

import { Navbar } from "../../Components/Navbar/NewNavbar";
import { SavedApplicationsVacancy } from "../../Components/NewVacancy/SavedApplicationsVacancy";
import { LoadingScreen } from "../../Components/LoadingScreen/LoadingScreen";
import { SeeMoreModal } from "../../Components/SeeMoreModal/SeeMoreModal";
import { ApplicationChat } from "./ApplicationChat";
import { GET_MY_APPLICATIONS } from "./MyApplications.services";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { MyApplicationsPainel } from "./MyApplicationsPainel";

export function MyApplications() {
  const [parent] = useAutoAnimate();
  const { data, loading, refetch } = useQuery(GET_MY_APPLICATIONS);
  const [focusedApplication, setFocusedApplication] = useState(null);
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState(false);
  const [focusedVacancy, setFocusedVacancy] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const navigator = useNavigate();
  const handleSelectApplication = useCallback((selectedApplication) => {
    // console.log("here", selectedApplication)
    setFocusedVacancy(selectedApplication);
    // navigator("/applications-job-details", {
    //   state: selectedApplication?.vacancy,
    // });
    // setIsSeeMoreModalOpen(true);
    setFocusedApplication(selectedApplication);
  }, []);

  const filteredApplications = useMemo(() => {
    if (loading) {
      return [];
    }

    return data.applications;
  }, [data, loading]);

  useEffect(() => {
    if (filteredApplications.length === 0) {
      setFocusedVacancy(null);
      return;
    }

    if (filteredApplications[0].userNotInterested) {
      setDisabled(true);
    }

    setFocusedVacancy(filteredApplications[0]);
  }, [filteredApplications]);

  useEffect(() => {
    if (filteredApplications.length === 0) return;

    setFocusedApplication(filteredApplications[0]);
  }, [filteredApplications]);

  const vacancies = useMemo(() => {
    return filteredApplications.map((application) => application.vacancy);
  }, [filteredApplications]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-gray-50 w-full pt-[20px] ">
      {isSeeMoreModalOpen && focusedApplication && (
        <SeeMoreModal
          job={focusedApplication.vacancy}
          isSeeMoreModalOpen={isSeeMoreModalOpen}
          setSeeMoreModal={setIsSeeMoreModalOpen}
        />
      )}
      <Navbar />

      <div className="bg-gray-50 w-full min-h-[93vh] pt-10 xl:pt-4">
        <aside className="w-full h-full  px-6 xl:px-12 " aria-label="Sidebar">
          <div className="flex overflow-y-auto py-5 px-3 bg-gray-50 rounded h-full">
            <ul className="space-y-2 w-full overflow-y-auto">
              <legend className="flex whyte-bold text-[24px] xl:text-[32px] ">
                My Applications
              </legend>
              <div className=" flex  lg:flex-row w-full gap-[2%] flex-col-reverse">
                <div className=" lg:w-[65%]">
                  {vacancies.map((vacancy, index) => (
                    <SavedApplicationsVacancy
                      key={nanoid()}
                      job={vacancy}
                      onClick={() =>
                        handleSelectApplication(filteredApplications[index])
                      }
                      shouldShowDeletedMessage={vacancy?.deleted}
                    />
                  ))}
                </div>
                <div className="relative lg:w-[35%]">
                  {focusedVacancy && (
                    <div className="lg:fixed lg:top-[200px] lg:w-[30%] h-full ">
                      <MyApplicationsPainel
                        setFocusedVacancy={setFocusedVacancy}
                        focusedVacancy={focusedVacancy}
                        disabled={disabled}
                        refetch={refetch}
                      />
                      {/* <MyApplicationsPainel
                        setFocusedVacancy={setFocusedVacancy}
                        focusedVacancy={focusedVacancy}
                        disabled={disabled}
                        refetch={refetch}
                      /> */}
                    </div>
                  )}
                </div>
              </div>
            </ul>

            {/* <div className="relative flex flex-col shadow-xl w-full m-8 rounded-md">
              {focusedApplication && (
                <ApplicationChat focusedApplication={focusedApplication} />
              )}
            </div> */}
          </div>
        </aside>
      </div>

      <Footer />
    </div>
  );
}
