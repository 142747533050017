import React from 'react';
import { forwardRef } from 'react';

export const Textarea = forwardRef(({
  id,
  name,
  label,
  ariaLabel,
  value,
  maxLength = 100,
  pattern,
  placeholder,
  title,
  required = false,
  rows,
  cols,
  className,
  ...props
}, ref) => {

  return (
    <div className={"flex flex-col "+ className}>
      <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor={name}>{label}</label>

      <textarea
        id={name}
        name={name}
        type={'text'}
        aria-label={ariaLabel}
        value={value}
        maxLength={maxLength}
        pattern={pattern}
        placeholder={placeholder}
        title={title}
        cols={cols}
        rows={rows}
        required={required}
        ref={ref}
        className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-700 focus:ring-primary-700 sm:text-sm"}
        {...props}
      />
    </div>
  )
});
