import { gql } from "@apollo/client";

export const APPLY_TO_VACANCY = gql`
  mutation createApplication(
    $vacancyId: ID!
    $shouldSendCoverVideo: Boolean!
    $video: FileInput
  ) {
    createApplication(
      vacancyId: $vacancyId
      shouldSendCoverVideo: $shouldSendCoverVideo
      video: $video
    ) {
      _id
      photo
      video
      cv
      vacancy {
        _id
        jobName
        period
        salary
        category
        contractType
        date
        disponibleVacancies
        hourPerWeek
        applicants
        company {
          _id
          name
        }
      }
    }
  }
`;
