import { gql } from "@apollo/client";

export const GET_Vacancy_PROFILE = gql`
  query getVacancyProfile($id: ID!) {
          vacancy(id: $id) {
          _id
          jobName
          salary
          isSalaryNegotiable
          period
          category
          contractType
          disponibleVacancies
          hourPerWeek
          applicants
          description
          date
          hasUserApplied
          hasUserSaved
          company {
            _id
            name
            latlon
            address
            photo
          }
        }
      
  }
`;
