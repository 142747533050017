export async function transformFileInBase64(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        resolve(base64String);
      };

      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
}
