import React, { useState, useMemo, useCallback } from "react";
import { MapContainer } from "react-leaflet";
import { MapContent } from "./MapContent";
import { Navbar } from "../../Components/Navbar/NewNavbar";
import { Footer } from "../../Components/Footer/Footer";
import { Button } from "../../Components/Button/Button";
import { useNavigate } from "react-router";
import { LoadingScreen } from "../../Components/LoadingScreen/LoadingScreen";
import { useQuery } from "@apollo/client";
import { GET_VACANCIES } from "./Map.services";
import { useAuth } from "../../hooks/Auth";
import filtersAfter from "../../assets/icons/filters-after.png";
import filters from "../../assets/icons/filters.png";
import filterBox from "../../assets/icons/box.png";
import { isInRange } from "../../utils/map/isInRange";
import { turnAnyInNull } from "../../utils/turnAnyInNull";
import FiltersForm from "../../Components/FiltersForm/NewFiltersForm";

export function Map() {
  const navigate = useNavigate();

  const [checked, setChecked] = useState(true);
  function toggle(value) {
    return !value;
  }

  // console.log("checked", checked)
  const { user } = useAuth();
  const {
    data: vacanciesData = {},
    loading: loadingVacancies,
    refetch,
  } = useQuery(GET_VACANCIES, {
    variables: { shouldGetStaticCompanies: true },
  });
  
  const [focusedPos, setFocusedPos] = useState(user.jobSeeker.latlon.map(Number));
  const [userPos] = useState(user.jobSeeker.latlon.map(Number));
  const [searchedKm, setSearchedKm] = useState(null);
  const [hideSidebar, setHideSidebar] = useState(true);

  const vacancies = useMemo(() => {
    const { getMapData } = vacanciesData;

    if (!getMapData) {
      return [];
    }

    return getMapData.vacancies;
  }, [vacanciesData]);

  const staticCompanies = useMemo(() => {
    const { getMapData } = vacanciesData;

    if (!getMapData) {
      return [];
    }

    return getMapData.staticCompanies;
  }, [vacanciesData]);

  const [overlay, setOverlay] = useState(localStorage.getItem('overlay'));
  const handleOverlay = () => {
    localStorage.setItem('overlay', 1);
    setOverlay(overlay + 1);
  };

  const handleChangeVacanciesView = useCallback(() => {
    navigate("/jobs-list");
  }, [navigate]);

  const filterByDistance = useCallback(
    (elements, isVacancy) => {
      if (!elements.length) {
        return [];
      }
      if (!searchedKm) {
        return elements;
      }
      return elements.filter((element) => {
        let elementLatLon = null;

        elementLatLon = element.latlon.split(",").map(Number).reverse();

        if (elementLatLon.some(Number.isNaN)) {
          return;
        }

        return isInRange(
          elementLatLon,
          [focusedPos[1], focusedPos[0]],
          searchedKm
        );
      });
    },
    [focusedPos, searchedKm]
  );

  const onSubmit = useCallback((data) => {
    const { distance, ...filters } = turnAnyInNull(data);

    setSearchedKm(distance);

    refetch({
      shouldGetStaticCompanies: true,
      filters,
    });
  }, []);

  if (loadingVacancies) {
    return <LoadingScreen />;
  }

  return (
    <div className="h-full w-full">
      <div className=" absolute  left-10 w-[96%] z-[2] top-[20px]">
        <Navbar className=" " />
      </div>
      <div className="absolute pt-0 flex w-full  z-[1] min-h-[100vh] mt-14 lg:mt-0">
        <div className="absolute flex justify-center  items-center whyte-bold text-[#546787] text-[16px] lg:text-[20px] left-[15%] lg:left-[70%] top-[3%] lg:top-[20%] z-[40] bg-[#FFFFFF] rounded-full w-[270px] lg:w-[388px] h-[45px] lg:h-[62px]  ">
          <span>List View</span>
          <input
            style={{ width: "0", height: "0", visibility: "hidden" }}
            type="checkbox"
            name="switch"
            id="switch"
            checked={checked}
            onChange={(e) => navigate("/jobs-list")}
          />
          <label
            className=" w-[60px] h-[30px] xl:w-[80px] xl:h-[40px] block mx-[15px] relative "
            id="Label"
            style={{
              background: "linear-gradient(180deg, #630DB1 0%, #116AFF 100%)",
              borderRadius: "100px",
              cursor: "pointer",
              transition: "0.5s",
              boxShadow: "0px 4px 39px rgba(124, 124, 124, 0.25)",
            }}
            htmlFor="switch"
          ></label>
          <span>Map View</span>
        </div>
        <aside
          className={
            "absolute md:flex left-0 top-16 bottom-0 w-96 z-20 transition duration-600 " +
            (hideSidebar ? "-translate-x-full" : "")
          }
        >
          <div
            className={
              "relative h-fit pb-10 ml-1 xl:ml-10 rounded-[20px] w-96 mt-28 px-5 flex flex-col items-center bg-white"
            }
          >
            <br />
            <FiltersForm onSubmit={onSubmit} setHideSidebar={setHideSidebar} />
            <div className="mt-2 border-t-2 pt-1 w-full flex flex-col items-center">
              {userPos[0] !== focusedPos[0] && userPos[1] !== focusedPos[1] && (
                <>
                  <Button
                    className="mt-4 w-full"
                    invert
                    secondary
                    onClick={() => setFocusedPos(userPos)}
                  >
                    Focus Your Location
                  </Button>
                </>
              )}
            </div>
          </div>
        </aside>
        
        <div className={"w-full min-h-[80vh] transition duration-600"}>
          <MapContainer
            center={focusedPos}
            zoom={12}
            style={{ width: "100%", height: "100%" }}
          >
            <MapContent
              setFocusedPos={setFocusedPos}
              userPos={userPos}
              searchedKm={searchedKm}
              jobs={filterByDistance(vacancies, true)}
              filteredStaticCompanies={filterByDistance(staticCompanies, false)}
              focusedPos={focusedPos}
              refetch={refetch}
            />
          </MapContainer>
        </div>

      </div>
      <div
        className={`  absolute left-[5%] ${
          overlay === "0" ? "  xl:bottom-[5%] " : "  lg:bottom-[0%] 2xl:bottom-[2%] "
        } bottom-[0%] z-[999] `}
      >
        <button
          onClick={() => {
            setHideSidebar((state) => !state);
            handleOverlay();
          }}
          className="flex  w-[152px] h-[152px]"
        >
          {hideSidebar ? (
            <div className="flex items-center">
              <img
                className=" max-w-[100px] xl:max-w-[140px]"
                src={filters}
                alt="filter"
              />
              <div className={` ${!overlay ? " relative" : "hidden"}`}>
                <img
                  className="min-w-[280px] xl:min-w-[470px]"
                  src={filterBox}
                  alt="filterBox"
                />
                <div className="absolute flex items-center top-[30%] max-w-[220px] xl:max-w-[300px] left-[10%] xl:left-[20%] whyte-bold text-white  text-[16px] xl:text-[20px] ">
                  <div>👈</div> Search for something or filter Your Search
                  Results
                </div>
              </div>
            </div>
          ) : (
            <img
              className="max-w-[100px] xl:max-w-[140px]"
              src={filtersAfter}
              alt="filter"
            />
          )}
        </button>
      </div>
      <div
        className={`${
          !overlay  ? " absolute " : "hidden"
        } absolute min-h-[100vh] top-0  left-0  w-full z-[10] bg-[rgba(0,0,0,0.5)] `}
        onClick={handleOverlay}
      ></div>
      <Footer />
    </div>
  );
}
