import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { TermsAndPolicy } from "../../../Components/TermsAndPolicy/TermsAndPolicy";
import { UploadFile } from "../../../Components/UploadFile/UploadFile";
import { socialMedia } from "../../../staticData/socialMedias";
import { InputText } from "../../../Components/Forms/NewInputText/InputText";
import { nanoid } from "nanoid";
import { Button } from "../../../Components/NewButton/Button";
import { ErrorMessage } from "@hookform/error-message";
import "../../../Pages/HomePage/styles.css";
import nextButton from "../../../assets/homePage/next.png";
import backButton from "../../../assets/homePage/back.png";

export function StepFive({ stepBack, onSubmitForm, loading }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});
  const [cv, setCv] = useState(null);

  const onSubmit = useCallback(
    async (data) => {
      await onSubmitForm({ cv, ...data });
    },
    [onSubmitForm, cv]
  );

  return (
    <>
      <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4 flex justify-between items-baseline">
          <div className="  whyte-bold text-[24px] xl:text-[32px] text-[#1E1E1E] ">
            Upload Your CV
          </div>
        </div>
        <div className="mt-3">
          <UploadFile
            acceptedFile={{
              "application/pdf": [],
              "image/*": [],
            }}
            className="form-input rounded-md mt-3"
            uploadMessage={"Drag and drop or click to upload a pdf or image"}
            onDrop={setCv}
          />
        </div>

        <div className="my-4 flex justify-between items-baseline">
          <div className=" my-4 whyte-bold text-[24px] xl:text-[32px] text-[#1E1E1E] ">
            Social media (optional)
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {socialMedia.map((socialMedia, index) => (
            <div>
              <div key={nanoid()} className="flex items-center mt-3 w-full">                
                <input
                  type="hidden"
                  {...register(`socialNetworks.${index}.name`, { value: socialMedia.name })}
                />
                <img
                  src={socialMedia.logo}
                  className="w-10 h-10 object-fit object-center rounded-md"
                  alt={socialMedia.name + " Logo"}
                />
                <InputText
                  placeholder={`${socialMedia.name} profile`}
                  className="ml-3 w-full"
                  {...register(`socialNetworks.${index}.url`)}
                  // {...register(`socialNetworks.${index}.url`, { pattern: socialMedia.urlPattern })}
                />
              </div>

              <p className="mt-3">{socialMedia.tips}</p>

            </div>
          ))}
        </div>
        <div>
          <TermsAndPolicy
            {...register("agreeWithTerms", {
              required: "click to agree to policies",
            })}
            className="my-9"
          />
          <ErrorMessage
            name="agreeWithTerms"
            errors={errors}
            render={({ message }) => <p className="text-red-500">{message}</p>}
          />
        </div>
        <div className="flex gap-[10px] items-center my-8">
          <Button invert type="button" onClick={stepBack}>
            <img
              className=" w-[80%] xl:w-full "
              src={backButton}
              alt="Previous step"
            />
          </Button>
          <Button
            loading={loading}
            type="submit"
            className="bg-[#9E5BF6] text-[16px] xl:text-[24px] text-white  rounded-[81px] h-[64px] xl:h-[74px]  px-[25px] whyte-bold "
          >
            Find local jobs NOW
          </Button>
        </div>
      </form>
    </>
  );
}
