import { useApolloClient, useMutation } from "@apollo/client";
import React from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button } from "../NewButton/Button";
import { Textarea } from "../Forms/Textarea/Textarea";
import { Modal } from "../NewModal/Modal";
import { REPORT } from "./ReportModal.services";
import { GET_VACANCIES } from "../../Pages/Map/Map.services";
import { GET_VACANCIES as vacanciesListGET_VACANCIES } from "../../Pages/VacanciesList/VacanciesList.services";
import { GET_JOBSEEKERS } from "../../Pages/company/JobSeekerPainel/JobSeekerPainel.services";

export function ReportModal({
  type,
  job,
  isReportModalOpen,
  setIsReportModalOpen,
  jobSeeker,
  onClose,
}) {
  const [report, { loading }] = useMutation(REPORT);
  const client = useApolloClient();
  const { handleSubmit, register } = useForm();

  const onSubmit = useCallback(
    async (data) => {
      const { reason } = data;
      const itemId = jobSeeker ? jobSeeker._id : job._id;

      await report({
        variables: {
          type,
          reason,
          itemId,
        },
      });

      alert(`This ${type} was successfully reported.`);

      if (type === "job") {
        await client.refetchQueries({
          include: [GET_VACANCIES, vacanciesListGET_VACANCIES],
        });
      } else {
        await client.refetchQueries({
          include: [GET_JOBSEEKERS],
        });
      }

      setIsReportModalOpen(false);

      if (onClose) {
        await onClose();
      }
    },
    [report, jobSeeker, job, type, onClose, client]
  );

  return (
    <Modal
      closeModal={() => setIsReportModalOpen(false)}
      isOpen={isReportModalOpen}
      width={"85%"}
      maxWidth={800}
    >
      <div className="flex flex-col items-center p-2 xl:px-8 ">
        {job && (
          <div className="whyte-bold text-[20px] xl:text-[24px] ">
            Report <span className="text-primary-800">{job.jobName}</span>, from{" "}
            <span className="text-primary-800">{job.company.name}</span>?
          </div>
        )}

        {jobSeeker && (
          <div className="whyte-bold text-[20px] xl:text-[24px] ">
            Report <span className="text-primary-800">{jobSeeker.name}</span>?
          </div>
        )}

        <div className="w-full lg:w-[540px] items-center  p-5 rounded-lg mt-3">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center"
          >
            <Textarea
              className="w-full"
              label="Describe the reason your report"
              maxLength={500}
              rows={10}
              {...register("reason", {
                required: true,
                minLength: 3,
                maxLength: 500,
              })}
            />

            <Button
              loading={loading}
              type="submit"
              className="whyte-bold  rounded-full text-[18px] xl:text-[20px] mt-4"
            >
              Report {type}
            </Button>
          </form>
        </div>
      </div>
    </Modal>
  );
}
