import styled from 'styled-components';

export const Input = styled.input`
    padding: 5px;
    border:none;
    border-radius:0;
    border-bottom:1px solid #A3A3A3;
    max-width:380px;
    color:#A3A3A3;
  
`
