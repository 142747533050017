import React from 'react';
import { useCallback } from 'react';
import { LoadingSpin } from '../LoadingSpin/LoadingSpin';

export function Button({ children, invert, secondary, loading, className = '', ...props }) {
  const getButtonStyles = useCallback(() => {
    const defaultStyles = 'flex items-center justify-center  text-center w-100 rounded-lg py-2 px-3 duration-150 transition '

    let bgAndText;

    if (secondary) {
      if (invert) {
        bgAndText = 'bg-white text-secondary-500 hover:bg-gray-100 border-secondary-500 border-2 '
        
      } else {
        bgAndText = 'bg-secondary-500 text-slate-100 hover:bg-secondary-600 '
      }
    } else {
      if (invert) {
        bgAndText = 'bg-white text-primary-700 hover:bg-slate-200 border-primary-700 border-2 '
        
      } else {
        bgAndText = 'bg-[#9E5BF6] text-slate-100 hover:bg-primary-800 '
      }
    }

    return defaultStyles + bgAndText
  }, [invert])

  return (
    <button
      className={getButtonStyles() + className}
      {...props}
    >
      {loading
        ? (
          <LoadingSpin />
        )
        : (
          <span>
            {children}
          </span>
        )}
    </button>
  )
}
