export const jobCategoryCandidate = [
  "Barista",
  "Chef & Cook",
  "Host & Server",
  "Bartender",
  "Food",
  "Retail",
  "Hotel & Leisure",
  "Sales & Marketing",
  "Customer Service",
  "Personal Care",
  "Delivery & Transport",
  "Cleaning & Maintenance",
  "General Labour",
  "Office",
  "Other"
];
