import React, { useState, useCallback, useMemo } from "react";
import { ModalMessage } from "../../Components/ModalMessage/ModalMessage";
import { Link, useLocation } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/NewNavbar";
import { Button } from "../../Components/NewButton/Button";
import blueArrow from "../../assets/homePage/blue-arrow.png";
import { useQuery } from "@apollo/client";
import { GET_VACANCY_INVITES } from "../MyVacancyInvites/MyVacancyInvites.services";
import { getDifferenceInNaturalTimeFromToday } from "../../utils/getDifferenceInNaturalTime";
const VacancyProfile = () => {
  const { state } = useLocation();
  const { data, loading, refetch } = useQuery(GET_VACANCY_INVITES);
  const [isOpenModalMessage, setIsOpenModalMessage] = useState(false);
  const handleApply = useCallback(() => {
    setIsOpenModalMessage(true);
  }, []);

  const timeSincePost = useMemo(() => {
    if (!state?.vacancy?.date) {
      return null;
    }

    const date = new Date(Number(state?.vacancy?.date));

    return getDifferenceInNaturalTimeFromToday(date);
  }, [state]);

  // console.log(state)
  return (
    <div className="w-full bg-gray-50 h-full py-[20px] ">
      {isOpenModalMessage && (
        <ModalMessage
          job={state?.vacancy}
          isOpenModalMessage={isOpenModalMessage}
          setIsOpenModalMessage={setIsOpenModalMessage}
          onClose={refetch}
        />
      )}
      <Navbar />
      <div className=" py-20 xl:py-8 w-[100%] flex flex-col xl:flex-row px-[4%] justify-between gap-[20px]  bg-gray-50 ">
        <div className="xl:w-2/3 flex flex-col gap-[20px] ">
          <div className="w-full flex items-center  justify-between p-[40px]  h-[254px] bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div>
              <div className=" whyte-bold text-[20px] xl:text-[24px] ">
                {state?.vacancy?.jobName}
              </div>
              <div className=" whyte-medium text-[16px] xl:text-[18px] text-[#A3A3A3]">
                {state?.vacancy?.company?.name}
              </div>
              <div className="mb-4 text-[#2E9367] ">{timeSincePost}</div>
              <hr />
              <Button
                className="text-[18px] xl:text-[20px] whyte-bold  xl:w-[320px]  rounded-full my-4 "
                onClick={handleApply}
              >
                Apply Here
              </Button>
            </div>
            <div>
              <img
                className="w-[125px] "
                src={state?.vacancy?.company.photo}
                alt={state?.vacancy?.company.name}
              />
            </div>
          </div>

          <div className="w-full flex flex-col  justify-between p-[40px]  min-h-[254px] bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] xl:text-[24px] ">
              Job Details
            </div>
            <div className="flex  gap-[40px] mt-6 ">
              <div className="flex w-1/5 flex-col text-[#546787] text-[18px] xl:text-[20px] whyte-medium gap-[10px] ">
                <div>Job type</div>
                <div>Salary</div>
                <div>Job Categories </div>
                <div>Positions</div>
                <div>contract type</div>
                <div>hour per week</div>
              </div>
              <div className="flex flex-col text-[#546787] text-[18px] xl:text-[20px] whyte-medium gap-[10px] ">
                <div>{state?.vacancy?.period}</div>
                <div>
                  {state?.vacancy?.isSalaryNegotiable
                    ? "Negotiable"
                    : state?.vacancy?.salary + " per hour"}
                </div>
                <div>{state?.vacancy?.category}</div>
                <div>
                  {state?.vacancy?.disponibleVacancies > 1
                    ? state?.vacancy?.disponibleVacancies + " positions"
                    : state?.vacancy?.disponibleVacancies + " position"}
                </div>
                <div>{state?.vacancy?.contractType}</div>
                <div>{state?.vacancy?.hourPerWeek} hours</div>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col   justify-between p-[40px]  min-h-fit bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] xl:text-[24px] ">
              Job Requirements{" "}
            </div>
            <div className="mt-6 gotham text-[20px] ">
              {state?.vacancy?.description}
            </div>
          </div>
        </div>
        <div className="xl:w-1/3 h-fit ">
          <div className=" w-full h-fit p-6 flex flex-col  bg-white border-[1px] border-[#D3D3D3] rounded-[20px] ">
            <div className=" whyte-bold text-[20px] xl:text-[24px] ">
              About The Company
            </div>
            <div>
              <img
                className="w-[125px] my-6 "
                src={state?.vacancy?.company.photo}
                alt={state?.vacancy?.company.name}
              />
            </div>
            <div className="text-[20px] xl:text-[24px] gotham-medium">
              {state?.vacancy?.company.name}
            </div>
            <div className="text-[18px] xl:text-[20px] gotham-medium">
              {state?.vacancy?.company.description}
            </div>
            <div className="flex gap-[20px] items-center my-6 ">
              <Link
                className="text-[#116AFF] text-[16px] xl:text-[18px] gotham-medium "
                to={`/companies/${state?.vacancy?.company._id}`}
              >
                Check all the company jobs
              </Link>
              <img className="" src={blueArrow} alt="blue arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacancyProfile;
