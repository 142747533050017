import React from 'react';
import { FiMail, FiMapPin, FiPhone } from 'react-icons/fi';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';
import { parseDate } from '../../utils/parseDate';
import { socialMedia } from '../../../src/staticData/socialMedias';

export const JobSeekerProfile = ({ jobSeeker }) => {
  const filteredSocialmedia = useMemo(() => {
    return jobSeeker?.socialMedias?.filter(socialMedia => !!socialMedia.url);
  }, [jobSeeker]);

  const countryCode = useMemo(() => {
    return jobSeeker?.countryCode.split(' ')[0].replace('[', '').replace(']', '');
  }, [jobSeeker]);
  
  const parsedBirthDate = useMemo(() => {
    const date = new Date(Number(jobSeeker?.birth));
    return parseDate(date);
  }, [jobSeeker]);

  if (!jobSeeker) {
    return null;
  }

  return (
    <div className="bg-[#590DAA] grid lg:grid-cols-2 grid-flow-row min-h-[70vh] mb-5">
      
      <section className=" p-5">

        <div className="flex justify-center">
          <img className="w-[260px] h-[260px] object-cover rounded-full" src={jobSeeker?.photo} alt={jobSeeker?.name} />
        </div>

      </section>

      <section className="bg-slate-100 p-5">

        <h1 className="text-2xl	text-center mb-3 text-[#6A0DB4]">{jobSeeker?.name + ' ' + jobSeeker?.surname}</h1>

        {filteredSocialmedia.length > 0 && (
          <>
            <section className='my-3 flex flex-row justify-center'>
              {filteredSocialmedia.map((item) => (
                <div key={nanoid()} className="flex flex-row items-center">
                  {socialMedia.map(socialMedia => socialMedia.name === item.name ? 
                    (<a href={socialMedia.url + item.url} target='_blank' rel='noreferrer noopener'>
                      <img 
                        alt={item.name} 
                        className="w-8 h-8 object-fit object-center mr-3" 
                        src={socialMedia.logo} 
                      />
                    </a>)
                  : '')}
                </div>
              ))}

            </section>
          </>
        )}

        <div>

          <div className="flex mt-5">
            <FiMail size={18} className="text-black" />
            <a href={`mailto:` + jobSeeker?.email} className="ml-3"> {jobSeeker?.email}</a>
          </div>

          <div className="flex items-baseline mt-1">
            <FiPhone size={18} className="text-black" />
            <a href={`tel:` + countryCode + ' ' + jobSeeker?.phone} className="ml-3"> {countryCode} {jobSeeker?.phone}</a>
          </div>
        
        </div>

      </section>

      <section className='bg-[#590DAA] text-white p-5'>

        <h1 className="font-bold text-lg text-center text-white">Availability</h1>

        {jobSeeker?.availability && <div>{Object?.keys(jobSeeker?.availability)?.map(item => 
          jobSeeker?.availability[item] && item !== "__typename" && 
          (typeof jobSeeker?.availability[item] === 'string' ? 
            <>
              <p className="font-bold mt-3">Notes: </p>
              <p>{jobSeeker?.availability[item]}</p>
            </>
            : jobSeeker?.availability[item] ? <li>{item}</li> : '')
          )}
        </div>}

        <h1 className="mt-3 font-bold text-lg text-center text-white">Looking For</h1>

        <div className="flex items-baseline mt-1">
          <li>{jobSeeker?.lookingFor}</li>
        </div>

      </section>

      <section className='bg-white row-span-3 p-5'>

        <h1 className="font-bold text-lg text-center text-[#6A0DB4]">Your Experience</h1>
        
        {
          jobSeeker.experiences?.map((experience) => (
            <div className='my-5'>
              <p className='font-bold text-lg text-[#6A0DB4]'>
                {experience.jobTitle} ({experience.companyName})
              </p>

              <p className="text-sm text-gray-400">{experience.jobCategory}</p>

              <div className='flex gap-4 text-gray-500'>
                
                <span>
                  {
                    experience?.startDate?.slice(experience?.startDate?.indexOf('-') + 1, experience?.startDate?.length)
                    + '-' +
                    experience?.startDate?.slice(0, experience?.startDate?.indexOf('-'))
                  }
                </span>
                 - 
                <span>
                  {!!experience?.currentlyWorking ? 'present' :
                    experience?.endDate?.slice(experience?.endDate?.indexOf('-') + 1, experience?.endDate?.length)
                    + '-' +
                    experience?.endDate?.slice(0, experience?.endDate?.indexOf('-'))
                  }
                </span>

              </div>

              <p className='mt-3'>{experience.jobDescription}</p>
              
            </div>
          ))
        }
      </section>

      <section className='bg-[#590DAA] text-white p-5'>

        <h1 className="font-bold text-lg text-center text-white">Languages</h1>
        
        {jobSeeker?.languages?.map((item) => (
          <div key={nanoid()} className="mt-3 flex flex-row items-center">
            <li>{item.name}: {item.proeficiency}</li>
          </div>
        ))}

      </section>

    </div>
  );
}
