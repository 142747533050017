import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation updateUser(
    $email: String!
    $password: String!
    $companyName: String!
    # $countryName: String!
    $companyAddress: String!
    # $companyEircode: String!
    $companyPhoto: FileInput
    $companyManager: String!
    $companyCountryCode: String!
    $companyPhone: String!
    $companyWebsite: String!
    $companyNumberOfEmployees: String!
    $companyRegistrationNumber: String!
    $companyCategory: String!
    $companyDescription: String!
    $companySocialMedias: [SocialMediaInput]
  ) {
    updateUser(
      user: {
        email: $email
        password: $password
        company: {
          # country: $countryName
          manager: $companyManager
          website: $companyWebsite
          numberOfEmployees: $companyNumberOfEmployees
          companyRegistrationNumber: $companyRegistrationNumber
          companyCategory: $companyCategory
          companyDescription: $companyDescription
          socialMedias: $companySocialMedias
          name: $companyName
          address: $companyAddress
          phone: $companyPhone
          countryCode: $companyCountryCode
          # eircode: $companyEircode
          photo: $companyPhoto
        }
      }
    ) {
      _id
      email
      roles
      company {
        _id
        name
        # country
        latlon
        address
        phone
        countryCode
        # eircode
        photo
        owner
        manager
        website
        numberOfEmployees
        companyRegistrationNumber
        companyCategory
        companyDescription
        socialMedias {
          name
          url
        }
        about
      }
    }
  }
`;