import { gql } from "@apollo/client";

export const AUTH_USER = gql`
  mutation authUser($email: String, $password: String) {
    authUser(email: $email, password: $password) {
      token
      user {
        _id
        email
        roles
        jobSeeker {
          _id
          name
          surname
          address
          eircode
          gender
          birth
          country
          countryCode
          latlon
          phone
          lookingFor
          availability {
            morning
            afternoon
            night
            weekends
            note
          }
          experiences {
            companyName
            currentlyWorking
            endDate
            jobCategory
            jobDescription
            jobTitle
            startDate
          }
          socialMedias {
            name
            url
          }
          languages {
            name
            proeficiency
          }
          photo
          video
          cv
        }
        company {
          _id
          name
          latlon
          country
          address
          eircode
          photo
          owner
          premium {
            isPremium
            dueDate
          }
          manager
          website
          numberOfEmployees
          phone
          countryCode
          companyRegistrationNumber
          companyCategory
          companyDescription
          socialMedias {
            name
            url
          }
          about
        }
      }
    }
  }
`;
