import React, { useCallback, useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../../Components/Button/Button";
import { Portal } from "../../../Components/Portal/Portal";
import { FiAlertTriangle } from "react-icons/fi";

import { Modal } from "../../../Components/NewModal/Modal";
import { JobExperienceForm } from "../../../Components/NewJobExperienceForm/JobExperienceForm";
import { JobExperience } from "../../../Components/JobExperience/JobExperience";
import nextButton from "../../../assets/homePage/next.png";
import backButton from "../../../assets/homePage/back.png";
export function CV({
  stepBack,
  formData,
  shouldWarnAboutVideo,
  setShouldWarnAboutVideo,
  onSubmitStep,
  loading,
  stay,
}) {
  const [experiences, setExperiences] = useState([]);
  const [errorExperience, setErrorExperience] = useState(false);
  const { watch, setValue, control, register, handleSubmit } = useForm({
    defaultValues: { ...formData },
  });

  const handleRemoveExperience = useCallback((index) => {
    setExperiences((state) => {
      const newState = [...state].filter((_, i) => i !== index);
      return newState;
    });
  }, []);

  const handlePushExperience = useCallback((experience) => {
    setExperiences((experiences) => [experience, ...experiences]);
  }, []);

  const onSubmit = useCallback(
    (data) => {
      if (experiences.length > 0) {
        onSubmitStep({ experiences, ...data });
      } else {
        setErrorExperience(true);
        return null;
      }
    },
    [experiences, onSubmitStep]
  );

  function errorIfUserDoesNotPutExperience() {
    experiences.length >= 0
      ? setErrorExperience(false)
      : setErrorExperience(true);
  }

  useEffect(() => errorIfUserDoesNotPutExperience(), [experiences]);

  return (
    <>
      {shouldWarnAboutVideo && (
        <Portal>
          <Modal
            closeModal={() => setShouldWarnAboutVideo(false)}
            isOpen={!!shouldWarnAboutVideo}
            textAlign={"center"}
             width={'60%'}
            maxWidth={"800px"}
          >
            <div className="  w-full flex justify-center ">
              <div className=" whyte-bold text-[20px] xl:text-[32px] ">
                Uploading an intro video will increase your chances of getting
                hired by 70%. Don't forget!
              </div>
              {/* <div className="text-[18px] xl:text-[24px] text-[#A3A3A3] mt-[15px]">
                This will lower your chances of being accepted.
              </div> */}
            </div>
            <div className="flex flex-col  justify-center items-center mt-[30px] ">
              <button
                invert
                type="button"
                onClick={stepBack}
                className=" max-w-[321px] h-[52px] xl:h-[74px] rounded-[81px] bg-[#9E5BF6] text-[20px] xl:text-[24px] text-white flex justify-start items-center px-[25px]"
              >
                Upload Intro Video
              </button>

              <button
                invert
                type="button"
                onClick={stay}
                className=" mt-[20px] h-[74px] rounded-[81px]   text-[20px] xl:text-[24px] text-[#9E5BF6] flex justify-start items-center px-[25px]"
              >
                Reduce my chances
              </button>
            </div>
          </Modal>
        </Portal>
      )}

      <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
        <header className="mt-6 flex items-baseline justify-between">
          <div>
            <div className=" whyte text-[16px] xl:text-[24px] text-[#A3A3A3] ">
              Let’s get to know your basic information
            </div>
            <div className="my-3 text-[24px] xl:text-[32px] whyte-bold text-[#1E1E1E] ">
              Your Experience
            </div>
          </div>
        </header>

        <JobExperienceForm
          watch={watch}
          setValue={setValue}
          control={control}
          register={register}
          pushExperience={handlePushExperience}
        />

        {experiences.map((experience, index) => (
          <Fragment key={experience.id}>
            <JobExperience
              handleRemoveExperience={() => handleRemoveExperience(index)}
              experience={experience}
            />
            <hr className="my-4" />
          </Fragment>
        ))}

        {errorExperience && experiences.length <= 0 ? (
          <p className="my-4 text-red-500">Add at least one experience</p>
        ) : null}

        <div className="flex justify-center  gap-[20px] xl:gap-0 xl:justify-around my-8">
          <button
            invert
            type="button"
            onClick={stepBack}
            className="hover:scale-105 transition-all "
          >
            <img
              className=" w-[80%] xl:w-full "
              src={backButton}
              alt="Previous step"
            />
          </button>
          <button type="submit" className="hover:scale-105 transition-all ">
            <img
              className=" w-[80%] xl:w-full "
              src={nextButton}
              alt="next step"
            />
          </button>
        </div>
      </form>
    </>
  );
}
