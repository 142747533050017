import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Button } from "../../Components/NewButton/Button";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useNavigate } from "react-router";
import { getDifferenceInNaturalTimeFromToday } from "../../utils/getDifferenceInNaturalTime";
import { VideoPlayer } from "../../Components/VideoPlayer/VideoPlayer";
import { Modal } from "../../Components/NewModal/Modal";
export function MyApplicationsPainel({ focusedVacancy, disabled, refetch }) {
  const [parent] = useAutoAnimate();
  const [isOpenModalMessage, setIsOpenModalMessage] = useState(false);
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState(false);
  const [isNotInterestedModalOpen, setIsNotInterestedModalOpen] =
    useState(false);
  const navigator = useNavigate();
  const [job, setJob] = useState(null);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  const handleSelectVacancy = useCallback((selectedJob) => {
    setJob(selectedJob);
    setIsSelectModalOpen((state) => !state);
  }, []);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const timeSincePost = useMemo(() => {
    if (!focusedVacancy?.vacancy.date) {
      return null;
    }

    const date = new Date(Number(focusedVacancy?.vacancy.date));

    return getDifferenceInNaturalTimeFromToday(date);
  }, [focusedVacancy]);

  const parsedSalary = useMemo(() => {
    if (!focusedVacancy?.vacancy.salary) {
      return null;
    }

    return focusedVacancy?.vacancy.salary;
  }, [focusedVacancy]);
  const [closeCoverVideoModal, setCloseCoverVideoModal] = useState(false);
  const [coverVideo, setCoverVideo] = useState("");
  const handleSelectJobSeeker2 = (video) => {
    setCoverVideo(video);
    setCloseCoverVideoModal(true);
  };

  return (
    <>
      {closeCoverVideoModal && (
        <Modal
          isOpen={closeCoverVideoModal}
          closeModal={() => {
            setCloseCoverVideoModal(false);
            setCoverVideo("");
          }}
          width={"80%"}
          maxWidth={800}
        >
          <div className=" whyte-bold text-[24px]  mb-6">Cover Video</div>

          <VideoPlayer srcVideo={coverVideo} />
        </Modal>
      )}
      <div
        ref={parent}
        className={` bg-white rounded-[10px] flex flex-col  ${windowDimensions.height < 750 ? " xl:h-[270px]": " min-h-[450px] xl:min-h-[500px] "} overflow-auto pb-10 border-[1px] hover:border-[#630DB1] `}
      >
        <div className="p-6 h-32">
          <div className=" text-[20px] lg:text-[24px] whyte-bold ">
            {focusedVacancy?.vacancy.jobName}
          </div>
          <div className=" whyte text-[16px] lg:text-[18px] text-[#A3A3A3]">
            {focusedVacancy?.vacancy.company.name}
          </div>

          <div className="text-[#2E9367] my-2 lg:my-3 ">
            Posted {timeSincePost}
          </div>
          <div className="whyte text-[#546787] ">
            {focusedVacancy?.vacancy.period} •{" "}
            {focusedVacancy?.vacancy.hourPerWeek} hours •{" "}
            {focusedVacancy?.vacancy.contractType}{" "}
            {parsedSalary ? " • " + parsedSalary + "  € per hour " : ""}•
            {focusedVacancy?.vacancy.disponibleVacancies} position
            {focusedVacancy?.vacancy.disponibleVacancies !== 1 && "s"}
          </div>
          <hr className=" my-4 lg:my-6" />

          <div>
            <div className="text-[20px] lg:text-[24px] whyte-bold ">
              Job Details
            </div>
            <div className="flex flex-col gap-[10px] text-[#546787] mt-4  whyte-medium">
              <div className="flex gap-[10%]">
                <span className="w-2/5">Job Type:</span>
                <span> {focusedVacancy?.vacancy.period}</span>
              </div>
              <div className="flex gap-[10%]">
                <span className="w-2/5">Salary:</span>
                <span> {parsedSalary ? parsedSalary + " per hour " : ""}</span>
              </div>
              <div className="flex gap-[10%]">
                <span className="w-2/5">Job Categories:</span>
                <span>{focusedVacancy?.vacancy.category}</span>
              </div>

              <div className="flex gap-[10%]">
                <span className="w-2/5">Positions:</span>
                <span>
                  {focusedVacancy?.vacancy.disponibleVacancies} position
                  {focusedVacancy?.vacancy.disponibleVacancies !== 1 && "s"}
                </span>
              </div>
            </div>
            {focusedVacancy.video ? (
              <Button
                onClick={() => handleSelectJobSeeker2(focusedVacancy.video)}
                className="mt-4   text-[18px] lg:text-[20px] whyte-bold rounded-full px-5 "
              >
                Watch Cover Video
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
