import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import React, { useMemo, useState, useRef } from 'react'
import { Button } from '../../../Components/Button/Button';
import { useCallback } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { FiTrash, FiUser, FiUserMinus, FiUserPlus } from 'react-icons/fi';
import { Modal } from '../../../Components/Modal/Modal';
import { DELETE_VACANCY } from './ManageReportsPainel.services';


export function ManageReportsPainel({ onCloseJobOrPosition, focusedVacancy }) {
  const [parent] = useAutoAnimate();
  const [closeJobModal, setCloseJobModal] = useState(false);
  const [deleteVacancy] = useMutation(DELETE_VACANCY);
  const handleCloseJob = useCallback(async () => {
    await deleteVacancy({
      variables: {
        vacancyId: focusedVacancy._id,
      },
    });

    setCloseJobModal(false);
    await onCloseJobOrPosition();
  }, [deleteVacancy, onCloseJobOrPosition, focusedVacancy]);

  return (
    <>
      {closeJobModal && (
        <Modal isOpen={closeJobModal} closeModal={() => setCloseJobModal(false)} width={'80%'} maxWidth={500}>
          <div className="flex flex-col items-center">
            <h1>Are you sure you want to close "{focusedVacancy.jobName}" ?</h1>

            <div className="flex my-7 w-60">

              <Button onClick={() => setCloseJobModal(false)} className="w-2/4" invert>No</Button>
              <Button className="w-2/4 ml-4" onClick={handleCloseJob}>Yes</Button>

            </div>
          </div>
        </Modal>
      )}
      <div className={"flex-1 flex flex-col h-full"}>
        <header className="flex items-center justify-between shadow-md p-6 h-32 relative">
          <div className="flex flex-col">
            <h1 className="font-bold text-lg">
              {focusedVacancy.jobName}
            </h1>
            <h3 className="text-slate-600 font-medium text-md">{focusedVacancy.period} • {focusedVacancy.hourPerWeek} hours</h3>
            <h3 className="text-slate-600 mt-2 font-bold text-md">{focusedVacancy.company.name}</h3>
          </div>

          {!focusedVacancy.deleted && (
            <div className="flex">
              <button className="text-md ml-4 flex transition duration-500 hover:text-green-600" onClick={() => setCloseJobModal(true)}>
                <FiTrash className="mr-2" />
                Close Job
              </button>
            </div>
          )}
        </header>

        <main className="p-6 pb-16 flex-1 flex flex-col overflow-y-auto h-full" ref={parent}>
          {focusedVacancy?.usersThatReported?.map((report, i) => (
            <div className="flex" key={`${report.reason}-${i}`}>
              <img
                className="h-0 w-0 sm:h-16 sm:w-16 rounded-full bg-gray-400 object-cover object-center sm:ml-2"
                src={report.user.jobSeeker.photo}
                alt={report.user.jobSeeker.name}
              />

              <div className="ml-4 flex flex-col">
                <strong>{report.user.jobSeeker.name} {report.user.jobSeeker.surname}</strong>

                <p className="mt-3 overflow-y-auto break-words max-h-[120px] max-w-[400px] text-gray-700">
                  {report.reason}
                </p>
              </div>
            </div>
          ))}
        </main>
      </div>
    </>
  );
}
